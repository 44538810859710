/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import Asset, { OptimizedAssetContent, VersionsAsset } from "../../../foundation/model/Asset";
import BasicRecord from "../../../foundation/utils/BasicRecord";

import AppActionTypes from "../app/AppActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";

import { AllPayloads } from "../payloads";

import * as Payloads from "./AssetsActionPayloads";
import AssetsActionTypes from "./AssetsActionTypes";

interface State {
    versionsAssets: BasicRecord<VersionsAsset[]>;
    componentsAssets: BasicRecord<Asset[]>;
}

class AssetsStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            versionsAssets: {},
            componentsAssets: {}
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    setVersionAssets(state: State, {
        vid,
        assets
    }: Payloads.SetVersionAssets): State {
        return {
            ...state,
            versionsAssets: { ...state.versionsAssets, [vid]: assets }
        };
    }

    setComponentAssets(state: State, {
        coid,
        assets
    }: Payloads.SetComponentAssets): State {
        return {
            ...state,
            componentsAssets: { ...state.componentsAssets, [coid]: assets }
        };
    }

    setMultipleComponentAssets(state: State, {
        componentAssets
    }: Payloads.SetMultipleComponentAssets): State {
        const newComponentsAssets = componentAssets.reduce((acc, current) => {
            const { coid, assets } = current;
            acc[coid] = assets;
            return acc;
        }, {} as BasicRecord<Asset[]>);

        return {
            ...state,
            componentsAssets: {
                ...state.componentsAssets,
                ...newComponentsAssets
            }
        };
    }

    updateVersionAssetOptimizedContents(state: State, {
        vid,
        assets
    }: Payloads.UpdateVersionAssetOptimizedContents): State {
        const versionAssets = state.versionsAssets[vid];

        if (!versionAssets || !versionAssets.length) {
            return state;
        }

        return {
            ...state,
            versionsAssets: {
                ...state.versionsAssets,
                [vid]: versionAssets.map(asset => {
                    const updatedAsset = assets.find(({ _id: vaid }) => vaid === asset._id);

                    if (!updatedAsset) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            const updatedContent = updatedAsset.contents.find(
                                ({ _id: vacid, originalContentId }) => content._id === (originalContentId || vacid)
                            );

                            if (!updatedContent) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized,
                                    ...updatedContent
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    updateVersionAssetOptimizedContent(state: State, { vid, vaid, vacid, patch }: {
        vid: string;
        vaid: string;
        vacid: string;
        patch: Partial<OptimizedAssetContent>;
    }): State {
        const versionAssets = state.versionsAssets[vid];

        if (!versionAssets || !versionAssets.length) {
            return state;
        }

        return {
            ...state,
            versionsAssets: {
                ...state.versionsAssets,
                [vid]: versionAssets.map(asset => {
                    if (asset._id !== vaid) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            if (content._id !== vacid) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized!,
                                    ...patch
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    processVersionAssetContentRequest(state: State, {
        vid,
        vaid,
        vacid
    }: Payloads.ProcessVersionAssetContent): State {
        return this.updateVersionAssetOptimizedContent(state, { vid, vaid, vacid, patch: { status: "processing" } });
    }

    processVersionAssetContentFailure(state: State, {
        vid,
        vaid,
        vacid
    }: Payloads.ProcessVersionAssetContentFailure): State {
        return this.updateVersionAssetOptimizedContent(state, { vid, vaid, vacid, patch: { status: "error" } });
    }

    updateComponentVersionAssetOptimizedContents(state: State, {
        coid,
        assets
    }: Payloads.UpdateComponentVersionAssetOptimizedContents): State {
        const componentAssets = state.componentsAssets[coid];

        if (!componentAssets || !componentAssets.length) {
            return state;
        }

        return {
            ...state,
            componentsAssets: {
                ...state.componentsAssets,
                [coid]: componentAssets.map(asset => {
                    const updatedAsset = assets.find(({ _id: vaid }) => vaid === asset._id);

                    if (!updatedAsset) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            const updatedContent = updatedAsset.contents.find(
                                ({ _id: vacid, originalContentId }) => content._id === (originalContentId || vacid)
                            );

                            if (!updatedContent) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized,
                                    ...updatedContent
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    updateComponentVersionAssetOptimizedContent(state: State, { coid, vaid, vacid, patch }: {
        coid: string;
        vaid: string;
        vacid: string;
        patch: Partial<OptimizedAssetContent>;
    }): State {
        const componentAssets = state.componentsAssets[coid];

        if (!componentAssets || !componentAssets.length) {
            return state;
        }

        return {
            ...state,
            componentsAssets: {
                ...state.componentsAssets,
                [coid]: componentAssets.map(asset => {
                    if (asset._id !== vaid) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            if (content._id !== vacid) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized!,
                                    ...patch
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    processComponentVersionAssetContentRequest(state: State, {
        coid,
        vaid,
        vacid
    }: Payloads.ProcessComponentVersionAssetContent): State {
        return this.updateComponentVersionAssetOptimizedContent(state, {
            coid, vaid, vacid, patch: { status: "processing" }
        });
    }

    processComponentVersionAssetContentFailure(state: State, {
        coid,
        vaid,
        vacid
    }: Payloads.ProcessComponentVersionAssetContentFailure): State {
        return this.updateComponentVersionAssetOptimizedContent(state, {
            coid, vaid, vacid, patch: { status: "error" }
        });
    }

    changeComponentAssetName(state: State, {
        component,
        assetId,
        name
    }: BarrelActionPayloads.ChangeAssetName): State {
        if (!component) {
            return state;
        }

        const coid = component._id;
        const componentAssets = state.componentsAssets[coid];

        if (!componentAssets || componentAssets.length === 0) {
            return state;
        }

        return {
            ...state,
            componentsAssets: {
                ...state.componentsAssets,
                [coid]: componentAssets.map(asset => {
                    if (asset._id !== assetId) {
                        return asset;
                    }

                    return {
                        ...asset,
                        localName: name
                    };
                })
            }
        };
    }

    changeVersionAssetName(state: State, {
        versionId: vid,
        assetId,
        name
    }: BarrelActionPayloads.ChangeAssetName): State {
        if (!vid) {
            return state;
        }

        const versionAssets = state.versionsAssets[vid];

        if (!versionAssets || versionAssets.length === 0) {
            return state;
        }

        return {
            ...state,
            versionsAssets: {
                ...state.versionsAssets,
                [vid]: versionAssets.map(asset => {
                    if (asset._id !== assetId) {
                        return asset;
                    }

                    return {
                        ...asset,
                        localName: name
                    };
                })
            }
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
                return this.reset();

            case AssetsActionTypes.SET_VERSION_ASSETS:
                return this.setVersionAssets(state, action);

            case AssetsActionTypes.SET_COMPONENT_ASSETS:
                return this.setComponentAssets(state, action);
            case AssetsActionTypes.SET_MULTIPLE_COMPONENT_ASSETS:
                return this.setMultipleComponentAssets(state, action);

            case AssetsActionTypes.UPDATE_VERSION_ASSET_OPTIMIZED_CONTENTS:
                return this.updateVersionAssetOptimizedContents(state, action);
            case AssetsActionTypes.PROCESS_VERSION_ASSET_CONTENT_REQUEST:
                return this.processVersionAssetContentRequest(state, action);
            case AssetsActionTypes.PROCESS_VERSION_ASSET_CONTENT_FAILURE:
                return this.processVersionAssetContentFailure(state, action);

            case AssetsActionTypes.UPDATE_COMPONENT_VERSION_ASSET_OPTIMIZED_CONTENTS:
                return this.updateComponentVersionAssetOptimizedContents(state, action);
            case AssetsActionTypes.PROCESS_COMPONENT_VERSION_ASSET_CONTENT_REQUEST:
                return this.processComponentVersionAssetContentRequest(state, action);
            case AssetsActionTypes.PROCESS_COMPONENT_VERSION_ASSET_CONTENT_FAILURE:
                return this.processComponentVersionAssetContentFailure(state, action);

            case BarrelActionTypes.CHANGE_ASSET_NAME:
                if (action.component) {
                    return this.changeComponentAssetName(state, action);
                } else if (action.versionId) {
                    return this.changeVersionAssetName(state, action);
                }

                return state;
            default:
                return state;
        }
    }
}

export default AssetsStore;
export { State as AssetsStoreState };
