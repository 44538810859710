enum ActionTypes {
    CHANGE_SIDEBAR_VIEW = "inspectableView/changeSidebarView",
    CHANGE_SIDEBAR_WIDTH = "inspectableView/changeSidebarWidth",
    COLLAPSE_SIDEBAR = "inspectableView/collapseSidebar",
    EXPAND_SIDEBAR = "inspectableView/expandSidebar",
    OPEN_SOURCE_FILE = "inspectableView/openSourceFile",
    OPEN_SOURCE_FOLDER = "inspectableView/openSourceFolder",
    OPEN_POPOUT = "inspectableView/openPopout",
    SET_MOVING_REMARK_RECT = "inspectableView/setMovingRemarkRect",

    // Layers
    SELECT_LAYER = "inspectableView/selectLayer",
    DESELECT_LAYER = "inspectableView/deselectLayer",
    ENTER_LAYER = "inspectableView/enterLayer",
    LEAVE_LAYER = "inspectableView/leaveLayer",
    HIGHLIGHT_LAYER = "inspectableView/highlightLayer",
    UNHIGHLIGHT_LAYER = "inspectableView/unhighlightLayer",
    SET_ZOOM_TO_SELECTED_LAYER = "inspectableView/setZoomToSelectedLayer",
    CHANGE_LAYOUT_PADDING_VISIBILITY = "inspectableView/changeLayoutPaddingVisibility",
    CHANGE_LAYOUT_GAP_VISIBILITY = "inspectableView/changeLayoutGapVisibility",

    // MARK = Zoom action types
    CHANGE_ZOOM_LEVEL = "inspectableView/changeZoomLevel",
    ZOOM_IN = "inspectableView/zoomIn",
    ZOOM_OUT = "inspectableView/zoomOut",
    RESET_ZOOM = "inspectableView/resetZoom",
    ZOOM_APPLIED = "inspectableView/zoomApplied",
    START_GESTURE = "inspectableView/startGesture",
    ZOOM_WITH_GESTURE = "inspectableView/zoomWithGesture",
    ZOOM_WITH_WHEEL = "inspectableView/zoomWithWheel",
    ZOOM_END = "inspectableView/zoomEnd",

    CHANGE_SCROLL_POSITION = "inspectableView/changeScrollPosition",

    // Versions
    SET_VERSION_ID = "inspectableView/setVersionId",
    SHOW_VERSIONS = "inspectableView/showVersions",
    HIDE_VERSIONS = "inspectableView/hideVersions",
    TOGGLE_VERSIONS = "inspectableView/toggleVersions",
    CHANGE_VERSIONS_SIDEBAR_WIDTH = "inspectableView/changeVersionsSidebarWidth",
    SHOW_VERSION_DIFFS = "inspectableView/showVersionDiffs",
    HIDE_VERSION_DIFFS = "inspectableView/hideVersionDiffs",
    CALCULATE_VERSION_DIFFS = "inspectableView/calculateVersionDiffs",

    // MARK = Grid action types
    TOGGLE_GRID_WIDGET = "inspectableView/toggleGridWidget",
    TOGGLE_GRID_LAYOUT = "inspectableView/toggleGridLayout",
}

export default ActionTypes;
