/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import WebhooksActionTypes from "./WebhooksActionTypes";

import Webhook from "../../../foundation/model/Webhook";
import { AllPayloads } from "../payloads";
import * as Payloads from "./WebhooksActionPayloads";

interface State {
    isGetWebhooksPending: boolean;
    isGetWebhookPending: boolean;
    isCreateWebhookPending: boolean;
    isUpdateWebhookPending: boolean;
    isDeleteWebhookPending: boolean;
    webhooks: Webhook[];
}

class WebhooksDataStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            isGetWebhooksPending: true,
            isGetWebhookPending: false,
            isCreateWebhookPending: false,
            isUpdateWebhookPending: false,
            isDeleteWebhookPending: false,
            webhooks: []
        };
    }

    getWebhooksSuccess(state: State, {
        webhooks
    }: Payloads.GetWebhooksSuccess): State {
        return {
            ...state,
            webhooks: webhooks.map(webhook => new Webhook(webhook)),
            isGetWebhooksPending: false
        };
    }

    getWebhookRequest(state: State): State {
        return {
            ...state,
            isGetWebhookPending: true
        };
    }

    getWebhookSuccess(state: State, {
        webhook
    }: Payloads.GetWebhookSuccess): State {
        const { webhooks } = state;

        return {
            ...state,
            webhooks: webhooks.map(item => {
                if (item._id === webhook._id) {
                    return new Webhook({
                        ...webhook,
                        complete: true
                    });
                }
                return item;
            }),
            isGetWebhookPending: false
        };
    }

    getWebhookFailure(state: State): State {
        return {
            ...state,
            isGetWebhookPending: false
        };
    }

    createWebhookRequest(state: State): State {
        return {
            ...state,
            isCreateWebhookPending: true
        };
    }

    createWebhookSuccess(state: State, {
        webhook
    }: Payloads.CreateWebhookSuccess): State {
        return {
            ...state,
            webhooks: state.webhooks
                .filter(({ _id }) => _id !== webhook._id)
                .concat(new Webhook(webhook)),
            isCreateWebhookPending: false
        };
    }

    createWebhookFailure(state: State): State {
        return {
            ...state,
            isCreateWebhookPending: false
        };
    }

    updateWebhookRequest(state: State): State {
        return {
            ...state,
            isUpdateWebhookPending: true
        };
    }

    updateWebhookSuccess(state: State, {
        webhook
    }: Payloads.UpdateWebhookSuccess): State {
        return {
            ...state,
            webhooks: state.webhooks.map(item => {
                if (item._id === webhook._id) {
                    return new Webhook(webhook);
                }
                return item;
            }),
            isUpdateWebhookPending: false
        };
    }

    updateWebhookFailure(state: State): State {
        return {
            ...state,
            isUpdateWebhookPending: false
        };
    }

    deleteWebhookRequest(state: State): State {
        return {
            ...state,
            isDeleteWebhookPending: true
        };
    }

    deleteWebhookSuccess(state: State, {
        webhookId
    }: Payloads.DeleteWebhookSuccess): State {
        return {
            ...state,
            webhooks: state.webhooks.filter(({ _id }) => _id !== webhookId),
            isDeleteWebhookPending: false
        };
    }

    deleteWebhookFailure(state: State): State {
        return {
            ...state,
            isDeleteWebhookPending: false
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case WebhooksActionTypes.GET_WEBHOOKS_SUCCESS:
                return this.getWebhooksSuccess(state, action);

            case WebhooksActionTypes.GET_WEBHOOK_REQUEST:
                return this.getWebhookRequest(state);
            case WebhooksActionTypes.GET_WEBHOOK_SUCCESS:
                return this.getWebhookSuccess(state, action);
            case WebhooksActionTypes.GET_WEBHOOK_FAILURE:
                return this.getWebhookFailure(state);

            case WebhooksActionTypes.CREATE_WEBHOOK_REQUEST:
                return this.createWebhookRequest(state);
            case WebhooksActionTypes.CREATE_WEBHOOK_SUCCESS:
                return this.createWebhookSuccess(state, action);
            case WebhooksActionTypes.CREATE_WEBHOOK_FAILURE:
                return this.createWebhookFailure(state);

            case WebhooksActionTypes.UPDATE_WEBHOOK_REQUEST:
                return this.updateWebhookRequest(state);
            case WebhooksActionTypes.UPDATE_WEBHOOK_SUCCESS:
                return this.updateWebhookSuccess(state, action);
            case WebhooksActionTypes.UPDATE_WEBHOOK_FAILURE:
                return this.updateWebhookFailure(state);

            case WebhooksActionTypes.DELETE_WEBHOOK_REQUEST:
                return this.deleteWebhookRequest(state);
            case WebhooksActionTypes.DELETE_WEBHOOK_SUCCESS:
                return this.deleteWebhookSuccess(state, action);
            case WebhooksActionTypes.DELETE_WEBHOOK_FAILURE:
                return this.deleteWebhookFailure(state);

            default:
                return state;
        }
    }
}

export default WebhooksDataStore;
export {
    State as WebhooksDataStoreState
};
