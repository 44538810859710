/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import TeamsIntegrationActionTypes from "./TeamsIntegrationActionTypes";
import * as Payloads from "./TeamsIntegrationActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import TeamsIntegrationChannel from "../../../foundation/api/model/integrations/teams/TeamsIntegrationChannel";
import { Dispatcher } from "flux";
import { AllPayloads } from "../payloads";

interface State {
    channels: TeamsIntegrationChannel[];
    loading: boolean;
}

class TeamsIntegrationStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            channels: [],
            loading: true
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    setTeamsIntegration(state: State, {
        channels
    }: Payloads.SetTeamsIntegration): State {
        return {
            ...state,
            channels,
            loading: false
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.RESET:
                return this.reset();
            case TeamsIntegrationActionTypes.SET_TEAMS_INTEGRATION:
                return this.setTeamsIntegration(state, action);

            default:
                return state;
        }
    }
}

export default TeamsIntegrationStore;
export {
    State as TeamsIntegrationStoreState
};
