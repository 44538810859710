enum ActionTypes {
    AddNotification = "notifications/addNotification",
    GetNotificationsRequested = "notifications/getNotificationsRequested",
    GetNotificationsSuccess = "notifications/getNotificationsSuccess",
    GetNotificationsFailed = "notifications/getNotificationsFailed",
    MarkAllAsRead = "notifications/markAllAsRead",
    RestoreReadStatus = "notifications/restoreNotificationReadStatus",
    SetReadStatusOffline = "notifications/setReadStatusOffline",
    SetReadStatusOfflineByNotifications = "notifications/setReadStatusOfflineByNotifications",
    SetEventReadStatus = "notifications/setEventReadStatus",
    SetEventReadStatusOffline = "notifications/setEventReadStatusOffline",
    DeleteEvent = "notifications/deleteEvent"
}

export default ActionTypes;
