import Konva from "konva-extended";

import { HEADER_HEIGHT as LIBRARY_HEADER_HEIGHT } from "../../../../../../library/v2/Header";

import { BOARD_TAB_BAR_HEIGHT } from "../Flow/BoardTabBar/BoardTabBar";

export const HEADER_HEIGHT = LIBRARY_HEADER_HEIGHT + BOARD_TAB_BAR_HEIGHT;

export const GRID_SIZE = 160;

export const ZOOM_LEVEL_GRID_SIZE_2X = 0.093;
export const ZOOM_LEVEL_GRID_SIZE_4X = 0.046;

export const SELECTION_RECT_STROKE_WIDTH = 1;
export const MAX_STROKE_WIDTH_MULTIPLIER = 8;

export const DASH_VALUE = [4, 8];
export const getScaledDashValue = (stage: Konva.Stage) => DASH_VALUE.map(v => v / stage.scaleX());
export const GROUP_DASH_VALUE = [8, 16];
export const getScaledGroupDashValue = (stage: Konva.Stage) => GROUP_DASH_VALUE.map(v => v / stage.scaleX());

export const PADDING = 24;
export const SCALE_FACTOR = 100;

export const ZOOM_LEVELS = Object.freeze([0.031, 0.0625, 0.125, 0.25, 0.5, 0.75, 1, 1.5, 2]);
export const ZOOM_LEVEL_DEFAULT = 0.5;
export const [ZOOM_LEVEL_MIN] = ZOOM_LEVELS;
export const ZOOM_LEVEL_MAX = ZOOM_LEVELS[ZOOM_LEVELS.length - 1];
export const MENU_BUTTON_WIDTH = 24;
export const MENU_BUTTON_HEIGHT = 20;
export const MENU_BUTTON_OFFSET_Y = -8;
export const GROUP_RECTANGLE_PADDING = 72;
export const MENU_BOTTOM_OFFSET = MENU_BUTTON_HEIGHT - MENU_BUTTON_OFFSET_Y;
export const MENU_OFFSET = 4;

export const DRAGGABLE_NODES = [
    "labelGroup",
    "screenGroup",
    "connectorGroup",
    "connectorLabelGroup",
    "groupLabel",
    "shapeGroup",
    "noteGroup",
    "placeholderGroup"
];

export const MULTI_DRAGGABLE_NODES = [
    "groupLabel",
    "noteGroup"
];

export const SNAPPABLE_NODES = ["screenGroup", "labelGroup", "shapeGroup", "placeholderGroup"];
export const GROUPABLE_NODES = ["screenGroup", "labelGroup", "shapeGroup", "placeholderGroup"];
export const DUPLICATABLE_NODES = ["screenGroup", "labelGroup", "shapeGroup", "placeholderGroup"];
export const DISTRIBUTABLE_NODES = ["screenGroup", "shapeGroup", "placeholderGroup"];
export const CONNECTABLE_NODES = ["screenGroup", "shapeGroup", "placeholderGroup"];
export const COPYABLE_NODES = ["screenGroup", "labelGroup", "shapeGroup", "placeholderGroup"];

export const EXPORT_AS_PDF_TOAST_SOURCE = "exportBoardAsPDF";
