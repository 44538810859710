import BarrelHelpers from "../barrel/BarrelHelpers";
import fluxRuntime from "../fluxRuntime";

import ComponentSection from "../../../foundation/model/ComponentSection";
import { generateColorSectionsData } from "../../../foundation/utils/color";
import ColorSection from "../../../foundation/api/model/colors/ColorSection";

function getCollapsableComponentSectionIds(): string[] {
    const styleguidesData = BarrelHelpers.getStyleguidesData();

    const collapsableSectionIds: string[] = [];
    styleguidesData.forEach(({ componentSections }) => {
        componentSections!.forEach((section: ComponentSection) => {
            if (section.name !== "default") {
                collapsableSectionIds.push(section._id);
            }
        });
    });

    return collapsableSectionIds;
}

function getCollapsableColorSectionIds(): string[] {
    const styleguidesData = BarrelHelpers.getStyleguidesData();

    const colorsData = styleguidesData.filter(({ colors: { length } = [] }) => length)
        .map(({ id, name, colors }) => ({
            id,
            name,
            sections: generateColorSectionsData(colors ?? [], id)
        }));

    const collapsableSectionIds: string[] = [];
    colorsData.forEach(({ sections }) => {
        sections!.forEach((section: ColorSection) => {
            // if section name equals to styleguide id, it means default section
            if (section.name !== undefined) {
                collapsableSectionIds.push(section.id);
            }
        });
    });

    return collapsableSectionIds;
}

function getCollapsedSectionIds(resourceType: "color" | "component"): string[] {
    const { collapsedSections } = fluxRuntime.StyleguideStore.getState();

    let collapsableSectionIds: string[] = [];
    if (resourceType === "color") {
        collapsableSectionIds = getCollapsableColorSectionIds();
    } else if (resourceType === "component") {
        collapsableSectionIds = getCollapsableComponentSectionIds();
    }

    return collapsableSectionIds.filter(id => collapsedSections[id]);
}

function getExpandedSectionIds(resourceType: "color" | "component"): string[] {
    const { collapsedSections } = fluxRuntime.StyleguideStore.getState();

    let collapsableSectionIds: string[] = [];
    if (resourceType === "color") {
        collapsableSectionIds = getCollapsableColorSectionIds();
    } else if (resourceType === "component") {
        collapsableSectionIds = getCollapsableComponentSectionIds();
    }

    return collapsableSectionIds.filter(id => !collapsedSections[id]);
}

export default {
    getCollapsableColorSectionIds,
    getCollapsableComponentSectionIds,
    getCollapsedSectionIds,
    getExpandedSectionIds
};
