enum ActionTypes {
    SHOW_IMPORTS_LIST = "versionImports/showImportsList",
    HIDE_IMPORTS_LIST = "versionImports/hideImportsList",
    HIDE_IMPORTS_INDICATOR = "versionImports/hideImportsIndicator",
    SET_SUGGESTED_LOCAL_RESOURCES = "versionImports/setSuggestedLocalResources",
    SUGGESTED_REMOTE_COLORS_FILE_RECEIVED = "versionImports/suggestedRemoteColorsFileReceived",
    SUGGESTED_REMOTE_TEXT_STYLES_FILE_RECEIVED = "versionImports/suggestedRemoteTextStylesFileReceived",
    SUGGESTED_REMOTE_COLORS_RECEIVED = "versionImports/suggestedRemoteColorsReceived",
    SUGGESTED_REMOTE_TEXT_STYLES_RECEIVED = "versionImports/suggestedRemoteTextStylesReceived",
    HANDLE_IMPORTS = "versionImports/handleImports",
    GO_TO_IMPORTED_VERSION = "versionImports/goToImportedVersion",
    RETRY_IMPORT = "versionImports/retryImport",
    CANCEL_IMPORT = "versionImports/cancelImport",
    RETRY_GET_PROJECT_FOR_IMPORTS = "versionImports/retryGetProjectForImports",
    CLEAR_IMPORTS = "versionImports/clearImports",
    HIDE_COMPONENT_LIMIT_HINT = "versionImports/hideComponentLimitHint",
    SEE_PLANS = "versionImports/seePlans",
    HIDE_MULTIPLE_COLORS_SUPPORTED_HINT_BOX = "versionImports/hideMultipleColorsSupportedHintBox",
    HIDE_EXPORT_TO_STYLEGUIDE_HINT_BOX = "versionImports/hideExportToStyleguideHintBox",
    SHOW_XD_PLUGIN_RELEASED_MESSAGE = "versionImports/showXdPluginReleasedMessage",
    HIDE_XD_PLUGIN_RELEASED_MESSAGE = "versionImports/hideXdPluginReleasedMessage"
}

export default ActionTypes;
