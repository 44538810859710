import MenuItem, { MenuItemDescription } from "../../../../foundation/electron/MenuItem";
import { isMacOS } from "../../../../foundation/utils/ua-utils";

import MenuViewType from "./MenuViewType";
import MenuItemID from "./MenuItemID";
import separator from "./separator";
import helpSubmenuLinks from "./helpSubmenuLinks";
import {
    AppUpdaterState,
    appUpdateLabels,
    PluginUpdaterState,
    pluginUpdateLabels
} from "./updaterStates";

const updateMenuItems = ({
    appUpdaterState,
    pluginUpdaterState
}: {
    appUpdaterState: AppUpdaterState;
    pluginUpdaterState: PluginUpdaterState;
}): MenuItemDescription[] => {
    if (isMacOS()) {
        return [];
    }

    const appUpdateMenuItem = {
        id: MenuItemID.CHECK_FOR_UPDATES,
        label: appUpdateLabels[appUpdaterState],
        enabled: appUpdaterState === AppUpdaterState.Idle
    };
    if (pluginUpdaterState === PluginUpdaterState.Disabled) {
        return [appUpdateMenuItem];
    }
    return [
        appUpdateMenuItem,
        {
            id: MenuItemID.CHECK_FOR_PLUGIN_UPDATES,
            label: pluginUpdateLabels[pluginUpdaterState],
            enabled: pluginUpdaterState === PluginUpdaterState.Idle
        }
    ];
};

const installUpdatesMenuItem = (
    { installUpdatesAutomatically }: { installUpdatesAutomatically: boolean; }
): MenuItemDescription[] => {
    if (isMacOS()) {
        return [];
    }

    return [{
        id: MenuItemID.INSTALL_UPDATES_AUTOMATICALLY,
        label: "Install Updates Automatically",
        type: "checkbox" as const,
        checked: installUpdatesAutomatically
    }];
};

const installPluginMenuItems = (): MenuItemDescription[] => {
    if (isMacOS()) {
        return [];
    }

    return [{
        id: MenuItemID.INSTALL_FIGMA_PLUGIN,
        label: "Install Figma Plugin"
    }, {
        id: MenuItemID.INSTALL_PHOTOSHOP_PLUGIN,
        label: "Install Adobe Photoshop Plugin"
    },
    separator];
};

const showLogsMenuItems = (): MenuItemDescription => ({
    id: isMacOS() ? MenuItemID.SHOW_LOGS_IN_FINDER : MenuItemID.SHOW_LOGS_IN_EXPLORER,
    label: `Show Logs in ${isMacOS() ? "Finder" : "Explorer"}`
});

const resetSuggestedColors = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.RESET_SUGGESTED_COLORS,
    label: "Reset Suggested Colors",
    enabled
});

const resetSuggestedTextStyles = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.RESET_SUGGESTED_TEXT_STYLES,
    label: "Reset Suggested Text Styles",
    enabled
});

const clearAndResetMenuItems = (): MenuItemDescription[] => {
    if (isMacOS()) {
        return [{
            id: MenuItemID.CLEAR_CACHE_AND_RESTART,
            label: "Clear Cache and Restart"
        }];
    }

    return [{
        id: MenuItemID.CLEAR_CACHE_AND_RESTART,
        label: "Clear Cache and Restart"
    }, {
        id: MenuItemID.RESET_ZEPLIN,
        label: "Reset Zeplin"
    }];
};

const openHelpCenterMenuItem = (): MenuItemDescription => ({
    id: MenuItemID.OPEN_HELP_CENTER,
    label: "Open Help Center",
    accelerator: "CmdOrCtrl+?"
});

const aboutZeplinMenuItem = (): MenuItemDescription[] => {
    if (isMacOS()) {
        return [];
    }

    return [separator, {
        id: MenuItemID.ABOUT_ZEPLIN,
        label: "About Zeplin"
    }];
};

function enabledHelpMenu({
    menuViewType,
    appUpdaterState,
    pluginUpdaterState,
    installUpdatesAutomatically
}: {
    menuViewType: MenuViewType;
    appUpdaterState: AppUpdaterState;
    pluginUpdaterState: PluginUpdaterState;
    installUpdatesAutomatically: boolean;
}): MenuItem {
    return new MenuItem({
        id: MenuItemID.HELP_SUBMENU,
        label: "Help",
        submenu: [
            ...updateMenuItems({ appUpdaterState, pluginUpdaterState }),
            ...installUpdatesMenuItem({ installUpdatesAutomatically }),
            separator,
            ...installPluginMenuItems(),
            showLogsMenuItems(),
            resetSuggestedColors({ enabled: menuViewType !== MenuViewType.LOGIN }),
            resetSuggestedTextStyles({ enabled: menuViewType !== MenuViewType.LOGIN }),
            ...clearAndResetMenuItems(),
            openHelpCenterMenuItem(),
            ...aboutZeplinMenuItem(),
            separator,
            ...helpSubmenuLinks()
        ]
    });
}

function disabledHelpMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.HELP_SUBMENU,
        label: "Help",
        enabled: false
    });
}

export default function helpSubmenu(menuViewType: MenuViewType | null, {
    appUpdaterState,
    pluginUpdaterState,
    installUpdatesAutomatically
}: {
    appUpdaterState: AppUpdaterState;
    pluginUpdaterState: PluginUpdaterState;
    installUpdatesAutomatically: boolean;
}): MenuItem {
    switch (menuViewType) {
        case MenuViewType.WORKSPACE_PROJECTS:
        case MenuViewType.WORKSPACE_STYLEGUIDES:
        case MenuViewType.ACTIVITIES:
        case MenuViewType.PROJECT_DASHBOARD:
        case MenuViewType.PROJECT_FLOWS:
        case MenuViewType.PROJECT_STYLEGUIDE:
        case MenuViewType.STYLEGUIDE:
        case MenuViewType.SCREEN:
        case MenuViewType.LOGIN:
            return enabledHelpMenu({
                menuViewType,
                appUpdaterState,
                pluginUpdaterState,
                installUpdatesAutomatically
            });
        default:
            return disabledHelpMenu();
    }
}
