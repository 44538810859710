/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import BarrelActionTypes from "./BarrelActionTypes";
import StyleguideActionTypes from "../styleguide/StyleguideActionTypes";
import { AllPayloads } from "../payloads";

interface State {
    selectedColorIds: string[];
}

class ColorSelectionStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            selectedColorIds: []
        };
    }

    setSelectedColors(state: State, {
        selectedColorIds
    }: {
        selectedColorIds: string[];
    }): State {
        const selectedColorSet = new Set(selectedColorIds);

        return {
            ...state,
            selectedColorIds: Array.from(selectedColorSet)
        };
    }

    clearColorSelection(state: State): State {
        return {
            ...state,
            selectedColorIds: []
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.SET_SELECTED_COLORS:
                return this.setSelectedColors(state, action);
            case BarrelActionTypes.CLEAR_COLOR_SELECTION:
                return this.clearColorSelection(state);
            case BarrelActionTypes.LOAD: {
                const { selectionColorId } = action.styleguide ?? {};
                if (selectionColorId) {
                    return this.setSelectedColors(state, {
                        selectedColorIds: [selectionColorId]
                    });
                }
                return state;
            }
            case StyleguideActionTypes.UPDATE_STATE: {
                const { selectionColorId } = action.update;
                if (selectionColorId) {
                    return this.setSelectedColors(state, {
                        selectedColorIds: [selectionColorId]
                    });
                }

                return state;
            }
            case StyleguideActionTypes.COLLAPSE_ALL_SECTIONS:
                return this.clearColorSelection(state);
            default:
                return state;
        }
    }
}

export default ColorSelectionStore;
export { State as ColorSelectionStoreState };
