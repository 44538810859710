/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { Dispatcher } from "flux";
import { Account } from "../../../foundation/api/model";
import { AllPayloads } from "../payloads";
import AccountDetail from "../../../foundation/api/model/account/AccountDetail";
import * as AppActionPayloads from "../app/AppActionPayloads";
import AppActionTypes from "../app/AppActionTypes";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import AccountAdminActionTypes from "./AccountAdminActionTypes";
import * as AccountAdminActionPayloads from "./AccountAdminActionPayloads";

interface State {
    loading: boolean;
    accounts: Account[] | undefined;
    selectedAccountId: string | undefined;
    accountDetails: BasicRecord<AccountDetail>;
    createDomainPending: boolean;
}

class AccountAdminStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            loading: true,
            accounts: [],
            selectedAccountId: undefined,
            accountDetails: {},
            createDomainPending: false
        };
    }

    hydrateEmbeddedData(state: State, {
        accounts, accountId, accountDetail
    }: AppActionPayloads.HydrateEmbeddedData): State {
        const hydratedAccountDetails = { ...state.accountDetails };
        if (accountId && accountDetail) {
            hydratedAccountDetails[accountId] = accountDetail;
        }

        return {
            ...state,
            accounts,
            selectedAccountId: accountId,
            accountDetails: hydratedAccountDetails,
            loading: !accountDetail
        };
    }

    addDomain(state: State, { aid, domain }: AccountAdminActionPayloads.AddDomain): State {
        const { accountDetails } = state;

        const accountToUpdate = accountDetails[aid];
        if (!accountToUpdate) {
            return state;
        }
        const domainToAdd = accountToUpdate.domains?.find(accountDomain => accountDomain._id === domain._id);
        if (domainToAdd) {
            return state;
        }

        const updatedDomains = accountToUpdate.domains ? [...accountToUpdate.domains, domain] : [domain];

        return {
            ...state,
            createDomainPending: false,
            accountDetails: {
                ...accountDetails,
                [aid]: {
                    ...accountToUpdate,
                    domains: updatedDomains
                }
            }
        };
    }

    updateDomainConfigurationSettings(
        state: State,
        { aid, doid, captureSettings }: AccountAdminActionPayloads.UpdateDomainConfigurationSettings): State {
        const { accountDetails } = state;

        const accountToUpdate = accountDetails[aid];
        if (!accountToUpdate) {
            return state;
        }
        const domainToUpdate = accountToUpdate.domains?.find(domain => domain._id === doid);
        if (!domainToUpdate) {
            return state;
        }

        return {
            ...state,
            accountDetails: {
                ...accountDetails,
                [aid]: {
                    ...accountToUpdate,
                    domains: accountToUpdate.domains!.map(domain => {
                        if (domain._id === doid) {
                            domain.captureSettings = captureSettings;
                        }
                        return domain;
                    })
                }
            }
        };
    }

    deleteDomain(state: State, { aid, doid }: AccountAdminActionPayloads.DeleteDomain): State {
        const { accountDetails } = state;

        const accountToUpdate = accountDetails[aid];
        if (!accountToUpdate) {
            return state;
        }
        const domainToDelete = accountToUpdate.domains?.find(domain => domain._id === doid);
        if (!domainToDelete) {
            return state;
        }
        return {
            ...state,
            accountDetails: {
                ...accountDetails,
                [aid]: {
                    ...accountToUpdate,
                    domains: accountToUpdate.domains!.filter(domain => domain._id !== doid)
                }
            }
        };
    }

    createDomain(state: State): State {
        return {
            ...state,
            createDomainPending: true
        };
    }

    createDomainFailure(state: State): State {
        return {
            ...state,
            createDomainPending: false
        };
    }

    addAdmin(state: State, { aid, member }: AccountAdminActionPayloads.AddAdmin): State {
        const { accountDetails } = state;

        const accountToUpdate = accountDetails[aid];
        if (!accountToUpdate) {
            return state;
        }

        return {
            ...state,
            accountDetails: {
                ...accountDetails,
                [aid]: {
                    ...accountToUpdate,
                    members: [...accountToUpdate.members, member]
                }
            }
        };
    }

    removeAdmin(state: State, { aid, member: { user: { _id } } }: AccountAdminActionPayloads.RemoveAdmin): State {
        const { accountDetails } = state;

        const accountToUpdate = accountDetails[aid];
        if (!accountToUpdate) {
            return state;
        }

        return {
            ...state,
            accountDetails: {
                ...accountDetails,
                [aid]: {
                    ...accountToUpdate,
                    members: accountToUpdate.members.filter(({ user: { _id: userId } }) => userId !== _id)
                }
            }
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.HYDRATE_EMBEDDED_DATA:
                return this.hydrateEmbeddedData(state, action);
            case AccountAdminActionTypes.UPDATE_DOMAIN_CONFIGURATION_SETTINGS:
                return this.updateDomainConfigurationSettings(state, action);
            case AccountAdminActionTypes.DELETE_DOMAIN:
                return this.deleteDomain(state, action);
            case AccountAdminActionTypes.ADD_DOMAIN:
                return this.addDomain(state, action);
            case AccountAdminActionTypes.CREATE_DOMAIN:
                return this.createDomain(state);
            case AccountAdminActionTypes.CREATE_DOMAIN_FAILURE:
                return this.createDomainFailure(state);
            case AccountAdminActionTypes.ADD_ADMIN:
                return this.addAdmin(state, action);
            case AccountAdminActionTypes.REMOVE_ADMIN:
                return this.removeAdmin(state, action);
            default:
                return state;
        }
    }
}

export default AccountAdminStore;
export {
    State as AccountAdminStoreState
};
