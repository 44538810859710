
import ScreenSection from "../../../foundation/api/model/barrels/ScreenSection";
import { ScreenSectionResult } from "../../../foundation/model/Section";
import { filterSortScreens } from "../../../foundation/utils/filter-sort";
import { createTemporarySectionId, TemporarySectionId } from "../../../foundation/utils/section";
import { NEW_SECTION_TITLE } from "../../containers/AppContainer/BarrelContainer/DashboardContainer/specs";

import BarrelHelpers from "../barrel/BarrelHelpers";
import ScreenHelpers from "../screen/ScreenHelpers";
import fluxRuntime from "../fluxRuntime";

import DashboardActionsNewSection from "./DashboardActionsNewSection";

function isSectionCollapsible(sections: (ScreenSection | ScreenSectionResult)[], sectionId: string): boolean {
    const section = sections.find(({ _id }) => _id === sectionId);

    if (!section) {
        return false;
    }

    const subSections = sections.filter(({ path }) => (path || []).includes(sectionId));

    return section.name !== "default" && Boolean(section.screens.length || subSections.length);
}

function getCollapsableSectionIds(): string[] {
    const barrel = BarrelHelpers.getBarrel();

    if (!barrel) {
        return [];
    }

    const { sections } = barrel;

    if (!sections) {
        return [];
    }

    return sections
        .filter((section: ScreenSection) => isSectionCollapsible(sections, section._id))
        .map((section: ScreenSection) => section._id);
}

function getCollapsedSectionIds(): string[] {
    const { collapsedSections } = fluxRuntime.DashboardStore.getState();

    const collapsableSectionIds = getCollapsableSectionIds();
    return collapsableSectionIds.filter(id => collapsedSections[id]);
}

function getExpandedSectionIds(): string[] {
    const { collapsedSections } = fluxRuntime.DashboardStore.getState();

    const collapsableSectionIds = getCollapsableSectionIds();
    return collapsableSectionIds.filter(id => !collapsedSections[id]);
}

function getScreenIdsOfSelectedScreens(selectedScreenIds: string[]) {
    const allSelectedScreenIds = new Set<string>();

    selectedScreenIds.forEach(screenId => {
        const variantGroup = BarrelHelpers.getVariantGroupOfScreen(screenId);
        if (variantGroup) {
            variantGroup.screens.forEach(({ id }) => allSelectedScreenIds.add(id));
        }

        allSelectedScreenIds.add(screenId);
    });

    return Array.from(allSelectedScreenIds);
}

function getDashboardNewSectionWithTargetIndex(
    screens: string[]
): { newSection: DashboardActionsNewSection; targetIndex: number; } | null {
    const {
        sortType, filterValue, tagFilter, jiraIssueFilter, selectedTagGroup
    } = fluxRuntime.DashboardStore.getState();
    const barrel = BarrelHelpers.getBarrel();

    if (!barrel) {
        return null;
    }

    const { sortedSections } = filterSortScreens(barrel, sortType,
        { filterValue, tagFilter, jiraIssueFilter, selectedTagGroup });

    const lastSelectedScreen = screens[screens.length - 1];
    const lastSection = ScreenHelpers.getSectionOfScreen({ pid: barrel._id, sid: lastSelectedScreen })!;
    const subSections = sortedSections.filter(({ path }) => (path || []).includes(lastSection._id));
    const lastSectionIndex = sortedSections.findIndex(sect => sect._id === lastSection._id);
    const targetIndex = lastSectionIndex < 0 ? sortedSections.length : lastSectionIndex + subSections.length + 1;
    const newSectionId = createTemporarySectionId(TemporarySectionId.NewSectionFromSelection);

    const newSection: DashboardActionsNewSection = {
        _id: newSectionId,
        name: NEW_SECTION_TITLE,
        screens
    };

    if (lastSection.parent) {
        newSection.parent = lastSection.parent;
        newSection.path = lastSection.path;
    }

    return { newSection, targetIndex };
}

function isSectionTemporary(seid: string) {
    const fromSplit = seid.startsWith(TemporarySectionId.NewSectionFromSplit);
    const fromSelection = seid.startsWith(TemporarySectionId.NewSectionFromSelection);
    const fromCreateSubsection = seid.startsWith(TemporarySectionId.NewSectionFromCreateSubsection);

    return fromSplit || fromSelection || fromCreateSubsection;
}

export default {
    isSectionCollapsible,
    getCollapsableSectionIds,
    getCollapsedSectionIds,
    getExpandedSectionIds,
    getScreenIdsOfSelectedScreens,
    getDashboardNewSectionWithTargetIndex,
    isSectionTemporary
};
