enum ActionTypes {
    GET_CONNECTED_APPS_DATA_REQUEST = "apps/getConnectedAppsDataRequest",
    GET_CONNECTED_APPS_DATA_SUCCESS = "apps/getConnectedAppsDataSuccess",
    GET_DEVELOPER_DATA_REQUEST = "apps/getDeveloperDataRequest",
    GET_DEVELOPER_DATA_SUCCESS = "apps/getDeveloperDataSuccess",
    GET_APPLICATION_REQUEST = "apps/getApplicationRequest",
    GET_APPLICATION_SUCCESS = "apps/getApplicationSuccess",
    CREATE_PERSONAL_ACCESS_TOKEN_REQUEST = "apps/createPersonalAccessTokenRequest",
    CREATE_PERSONAL_ACCESS_TOKEN_SUCCESS = "apps/createPersonalAccessTokenSuccess",
    CREATE_PERSONAL_ACCESS_TOKEN_FAILURE = "apps/createPersonalAccessTokenFailure",
    CREATE_APPLICATION_REQUEST = "apps/createApplicationRequest",
    CREATE_APPLICATION_SUCCESS = "apps/createApplicationSuccess",
    CREATE_APPLICATION_FAILURE = "apps/createApplicationFailure",
    UPDATE_APPLICATION_REQUEST = "apps/updateApplicationRequest",
    UPDATE_APPLICATION_SUCCESS = "apps/updateApplicationSuccess",
    UPDATE_APPLICATION_FAILURE = "apps/updateApplicationFailure",
    DELETE_APPLICATION_REQUEST = "apps/deleteApplicationRequest",
    DELETE_APPLICATION_SUCCESS = "apps/deleteApplicationSuccess",
    DELETE_APPLICATION_FAILURE = "apps/deleteApplicationFailure",
    DELETE_PERSONAL_ACCESS_TOKEN_REQUEST = "apps/deletePersonalAccessTokenRequest",
    DELETE_PERSONAL_ACCESS_TOKEN_SUCCESS = "apps/deletePersonalAccessTokenSuccess",
    DELETE_PERSONAL_ACCESS_TOKEN_FAILURE = "apps/deletePersonalAccessTokenFailure",
    REVOKE_AUTHORIZED_APPLICATION_REQUEST = "apps/revokeAuthorizedApplicationRequest",
    REVOKE_AUTHORIZED_APPLICATION_SUCCESS = "apps/revokeAuthorizedApplicationSuccess",
    REVOKE_AUTHORIZED_APPLICATION_FAILURE = "apps/revokeAuthorizedApplicationFailure",
    RENAME_PERSONAL_ACCESS_TOKEN = "apps/renamePersonalAccessToken",
    CONNECT_OFFICIAL_APP_REQUEST = "apps/connectOfficialAppRequest",
    CONNECT_OFFICIAL_APP_SUCCESS = "apps/connectOfficialAppSuccess",
    CONNECT_OFFICIAL_APP_FAILURE = "apps/connectOfficialAppFailure"
}

export default ActionTypes;
