enum ActionTypes {
    GET_COMPONENT_STATS = "screenComponents/getComponentStats",
    GET_COMPONENT_STATS_COMPLETE = "screenComponents/getComponentStatsComplete",
    SET_COMPONENT_STATS = "screenComponents/setComponentStats",
    GET_VIRTUAL_COMPONENT_STATS = "screenComponents/getVirtualComponentStats",
    GET_VIRTUAL_COMPONENT_STATS_COMPLETE = "screenComponents/getVirtualComponentStatsComplete",
    SET_VIRTUAL_COMPONENT_STATS = "screenComponents/setVirtualComponentStats",
    GET_VIRTUAL_COMPONENTS = "screenComponents/getVirtualComponents",
    GET_VIRTUAL_COMPONENTS_COMPLETE = "screenComponents/getVirtualComponentsComplete",
    SET_VIRTUAL_COMPONENTS = "screenComponents/setVirtualComponents",
    GET_STYLEGUIDE_COMPONENT_DETAIL = "screenComponents/getStyleguideComponentDetail",
    GET_VIRTUAL_COMPONENT_DETAIL = "screenComponents/getVirtualComponentDetail",
    GET_COMPONENT_DETAIL_COMPLETE = "screenComponents/getComponentDetailComplete",
    SET_STYLEGUIDE_COMPONENT_DETAIL = "screenComponents/setStyleguideComponentDetail",
    SET_VIRTUAL_COMPONENT_DETAIL = "screenComponents/setVirtualComponentDetail",
    UPSERT_VIRTUAL_COMPONENTS = "screenComponents/upsertVirtualComponents"
}

export default ActionTypes;
