export enum SortCommentsByDate {
    DESC, ASC
}

export enum LabelFilter {
    Unresolved,
    Unread,
    Mentioned,
    Resolved,
    Mine,
    NoReplies
}

export const LabelFilterValues: Record<LabelFilter, string> = {
    [LabelFilter.Unresolved]: "Open",
    [LabelFilter.Unread]: "Unread",
    [LabelFilter.Mentioned]: "Mentioned",
    [LabelFilter.Resolved]: "Resolved",
    [LabelFilter.Mine]: "By me",
    [LabelFilter.NoReplies]: "No replies"
};

export const LabelFilterKeys: Record<string, LabelFilter> = {
    [LabelFilterValues[LabelFilter.Unresolved]]: LabelFilter.Unresolved,
    [LabelFilterValues[LabelFilter.Unread]]: LabelFilter.Unread,
    [LabelFilterValues[LabelFilter.Mentioned]]: LabelFilter.Mentioned,
    [LabelFilterValues[LabelFilter.Resolved]]: LabelFilter.Resolved,
    [LabelFilterValues[LabelFilter.Mine]]: LabelFilter.Mine,
    [LabelFilterValues[LabelFilter.NoReplies]]: LabelFilter.NoReplies
};
