/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import InspectLayer from "../../../../foundation/model/InspectLayer";

import AppActionTypes from "../../app/AppActionTypes";
import * as BarrelActionPayloads from "../../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../../barrel/BarrelActionTypes";
import InspectableViewActionTypes from "../../inspectableView/InspectableViewActionTypes";
import { AllPayloads } from "../../payloads";

import * as Payloads from "./ComponentVariantActionPayloads";
import ComponentVariantActionTypes from "./ComponentVariantActionTypes";

interface State {
    componentVariantViewVisible: boolean;
    selectedLayer: InspectLayer | null;
    destinationLayer: InspectLayer | null;
    selectedComponentVariantId: string | null;
    sourceLayerId: string | null;
}

class ComponentVariantStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            componentVariantViewVisible: false,
            selectedLayer: null,
            destinationLayer: null,
            selectedComponentVariantId: null,
            sourceLayerId: null
        };
    }

    open(state: State, {
        coid,
        sourceLayerId
    }: Payloads.Open): State {
        return {
            ...state,
            componentVariantViewVisible: true,
            selectedComponentVariantId: coid,
            sourceLayerId
        };
    }

    close(): State {
        return this.getInitialState();
    }

    selectLayer(state: State, {
        layer
    }: Payloads.SelectLayer): State {
        return {
            ...state,
            selectedLayer: layer,
            destinationLayer: layer
        };
    }

    enterLayer(state: State, {
        layer
    }: Payloads.EnterLayer): State {
        if (state.destinationLayer === layer) {
            return state;
        }

        return {
            ...state,
            destinationLayer: layer
        };
    }

    leaveLayer(state: State): State {
        if (state.destinationLayer === null) {
            return state;
        }

        return {
            ...state,
            destinationLayer: null
        };
    }

    deselectLayer(state: State): State {
        if (!state.selectedLayer) {
            return state;
        }

        return {
            ...state,
            selectedLayer: null,
            destinationLayer: null
        };
    }

    changeComponentVariant(state: State, {
        coid
    }: Payloads.ChangeVariant): State {
        return {
            ...state,
            selectedComponentVariantId: coid,
            selectedLayer: null,
            destinationLayer: null
        };
    }

    resetComponentVariant(state: State, {
        coid
    }: BarrelActionPayloads.RemoveComponent): State {
        if (state.selectedComponentVariantId !== coid) {
            return state;
        }

        return {
            ...state,
            selectedComponentVariantId: null,
            selectedLayer: null,
            destinationLayer: null
        };
    }

    resetComponentVariantForMultipleDeletion(state: State, {
        sectionComponents
    }: BarrelActionPayloads.RemoveComponents): State {
        const currentComponentVariantFound = sectionComponents
            .find(({ coids }) => coids.find(coid => coid === state.selectedComponentVariantId));

        if (!currentComponentVariantFound) {
            return state;
        }

        return {
            ...state,
            selectedComponentVariantId: null,
            selectedLayer: null,
            destinationLayer: null
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
            case BarrelActionTypes.RESET:
            case BarrelActionTypes.LOAD:
            case BarrelActionTypes.CHANGE_SELECTED_VIEW:
                return this.getInitialState();

            case ComponentVariantActionTypes.OPEN:
                return this.open(state, action);
            case ComponentVariantActionTypes.CLOSE:
                return this.close();
            case ComponentVariantActionTypes.SELECT_LAYER:
                return this.selectLayer(state, action);
            case ComponentVariantActionTypes.ENTER_LAYER:
                return this.enterLayer(state, action);
            case ComponentVariantActionTypes.LEAVE_LAYER:
                return this.leaveLayer(state);
            case ComponentVariantActionTypes.DESELECT_LAYER:
                return this.deselectLayer(state);
            case ComponentVariantActionTypes.CHANGE_VARIANT:
                return this.changeComponentVariant(state, action);

            case BarrelActionTypes.REMOVE_COMPONENT:
                return this.resetComponentVariant(state, action);
            case BarrelActionTypes.REMOVE_COMPONENTS:
                return this.resetComponentVariantForMultipleDeletion(state, action);

            case InspectableViewActionTypes.SELECT_LAYER:
                return this.deselectLayer(state);

            default:
                return state;
        }
    }
}

export default ComponentVariantStore;
export { State as ComponentVariantStoreState };
