enum ActionTypes {
    LOAD_DECISION = "onboardingGuidance/loadDecision",
    LOAD_DATA = "onboardingGuidance/loadData",
    TRACK_ONBOARDING_GUIDANCE_EVENT = "onboardingGuidance/trackOnboardingGuidanceEvent",
    SET_NEXT_ONBOARDING_GUIDANCE_EVENT = "onboardingGuidance/setNextOnboardingGuidanceEvent",
    MARK_ONBOARDING_GUIDANCE_VIEW = "onboardingGuidance/markOnboardingGuidanceView",
    MARK_ONBOARDING_GUIDANCE_LEARN_MORE = "onboardingGuidance/markOnboardingGuidanceLearnMore",
    DISMISS_ONBOARDING_GUIDANCE_TOOLTIP = "onboardingGuidance/dismissOnboardingGuidanceTooltip",
    RESET_ONBOARDING_GUIDANCE_GROUP_EVENT = "onboardingGuidance/resetOnboardingGuidanceGroupEvent"
}

export default ActionTypes;
