import { SubscriptionPlan } from "../../../foundation/api/model";
import BarrelKind from "../../../foundation/api/model/barrels/BarrelKind";
import BarrelUser from "../../../foundation/api/model/barrels/BarrelUser";
import OrganizationMemberProfile from "../../../foundation/api/model/organizations/OrganizationMemberProfile";
import { PageType, PlanUpgradeTrigger, ProjectViewTypes } from "../../../foundation/enums";
import Barrel from "../../../foundation/model/Barrel";
import BarrelType from "../../../foundation/model/BarrelType";
import Organization from "../../../foundation/model/Organization";
import OrganizationProfiles from "../../../foundation/model/OrganizationProfiles";
import ProjectSection from "../../../foundation/model/ProjectSection";
import WorkflowStatus from "../../../foundation/model/WorkflowStatus";

import BasicRecord from "../../../foundation/utils/BasicRecord";
import { exists } from "../../../foundation/utils/object";
import { sortBy } from "../../../foundation/utils/sortBy";
import { Modify } from "../../../foundation/utils/UtilityTypes";

import fluxRuntime from "../fluxRuntime";

import OrganizationProjectPosition from "./OrganizationProjectPosition";

function getCurrentOrganizationId(): string {
    const { page } = fluxRuntime.AppStore.getState();
    const { selectedOrganizationId } = fluxRuntime.SpaceStore.getState();
    const { organizationId: barrelOrganizationId, selectedView } = fluxRuntime.BarrelStore.getState();
    const { selectedOrganizationId: activitiesOrganizationId } = fluxRuntime.ActivitiesViewStore.getState();

    let oid: string | null;

    if (page === PageType.Workspace) {
        oid = selectedOrganizationId;
    } else if (page === PageType.Activities) {
        oid = activitiesOrganizationId || selectedOrganizationId;
    } else if (selectedView === ProjectViewTypes.SCREEN) {
        oid = barrelOrganizationId || selectedOrganizationId;
    } else if (selectedView === ProjectViewTypes.COMPONENT) {
        oid = barrelOrganizationId || selectedOrganizationId;
    } else {
        oid = barrelOrganizationId || selectedOrganizationId;
    }

    return oid;
}

function getOrganization(oid?: string, { pid }: { pid?: string; } = {}): Organization | null | undefined {
    let organizationId = oid ?? fluxRuntime.BarrelStore.getState().organizationId;

    if (!organizationId && pid) {
        organizationId = findProjectOrganizationId(pid);
    }

    if (!organizationId) {
        return null;
    }

    const { organizations } = fluxRuntime.OrganizationStore.getState();

    return organizations.find(org => org._id === organizationId);
}

function getOrganizationMembers(oid?: string): BarrelUser[] | null | undefined {
    const organizationId = oid ?? fluxRuntime.BarrelStore.getState().organizationId;
    if (!organizationId) {
        return null;
    }

    const { organizations } = fluxRuntime.OrganizationStore.getState();
    return organizations.find(({ _id }) => _id === organizationId)?.members;
}

function getOrganizationMember(oid: string, uid: string): BarrelUser | null | undefined {
    const members = getOrganizationMembers(oid);

    if (!members) {
        return null;
    }

    return members.find(({ user }) => user._id === uid);
}

function findProjectOrganizationId(pid: string) {
    const { organizationProjects } = fluxRuntime.OrganizationStore.getState();

    for (const [oid, pids] of Object.entries(organizationProjects)) {
        if (pids.includes(pid)) {
            return oid;
        }
    }

    return null;
}

function getCollapsableSectionIds(oid?: string): string[] {
    const organizationId = oid ?? fluxRuntime.SpaceStore.getState().selectedOrganizationId;
    const selectedOrganization = getOrganization(organizationId);

    return selectedOrganization!.sections.filter(({ type }) => type !== "individual").map(({ _id }) => _id);
}

function getCollapsedSectionIds(oid?: string): string[] {
    const { collapsedSections } = fluxRuntime.SpaceStore.getState();
    const collapsableSectionIds = getCollapsableSectionIds(oid);

    return collapsableSectionIds.filter(id => collapsedSections[id]);
}

function getExpandedSectionIds(oid?: string): string[] {
    const { collapsedSections } = fluxRuntime.SpaceStore.getState();
    const collapsableSectionIds = getCollapsableSectionIds(oid);

    return collapsableSectionIds.filter(id => !collapsedSections[id]);
}

function getOrganizationProjectsWithLookup({
    oid,
    archivedProjectsVisible
}: { oid: string; archivedProjectsVisible: boolean; }): {
    projects: Barrel[];
    lookup: BasicRecord<Barrel>;
} | undefined {
    const { organizationProjects } = fluxRuntime.OrganizationStore.getState();
    const { projects } = fluxRuntime.ProjectsDataStore.getState();
    const organizationProjectIds = organizationProjects[oid];
    if (organizationProjectIds) {
        return organizationProjectIds.reduce((acc, projectId) => {
            if (!archivedProjectsVisible && projects[projectId].status === "archived") {
                return acc;
            }

            acc.projects.push(projects[projectId]);
            acc.lookup[projectId] = projects[projectId];
            return acc;
        }, {
            projects: [] as Barrel[],
            lookup: {} as BasicRecord<Barrel>
        });
    }
}

function getOrganizationProjects(oid: string): Barrel[] | undefined {
    const { organizationProjects } = fluxRuntime.OrganizationStore.getState();
    const { projects } = fluxRuntime.ProjectsDataStore.getState();

    const organizationProjectIds = organizationProjects[oid];
    return organizationProjectIds?.map(id => projects[id]);
}

function getOrganizationStyleguides(oid: string): Barrel[] | undefined {
    const { organizationStyleguides } = fluxRuntime.OrganizationStore.getState();
    const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();

    const organizationStyleguideIds = organizationStyleguides[oid];
    return organizationStyleguideIds?.map(id => styleguides[id]);
}

function getOrganizationWorkflowStatus(oid: string, wsid: string): WorkflowStatus | undefined {
    const { organizations } = fluxRuntime.OrganizationStore.getState();
    const { workflowStatuses } = organizations.find(({ id }) => id === oid) || {};
    const oldWorkflowStatusData = workflowStatuses?.find(({ _id }) => _id === wsid);

    return oldWorkflowStatusData;
}

function getOrganizationProfile(oid: string): OrganizationMemberProfile | undefined {
    const { organizationProfiles } = fluxRuntime.OrganizationStore.getState();

    return organizationProfiles[oid];
}

function findProjectOrganizationSection(pid: string): ProjectSection | null {
    const { organizations, organizationProjects } = fluxRuntime.OrganizationStore.getState();

    for (const [oid, pids] of Object.entries(organizationProjects)) {
        if (pids.includes(pid)) {
            const targetOrganization = organizations.find(organization => organization._id === oid);
            if (targetOrganization && targetOrganization.sections) {
                const targetSection = targetOrganization.sections.find(section => (
                    section.projects.find(projectId => projectId === pid)
                ));
                if (targetSection) {
                    return targetSection;
                }
            }
        }
    }

    return null;
}

function findProjectOrganizationSectionId(pid: string): string | null {
    const foundSection = findProjectOrganizationSection(pid);
    return foundSection?._id ?? null;
}

/**
 * Calculates the actual drop index with respect to the given visible items and actual itemIds arrays
 * Following rules are applied during the calculation:
 * When moving an item to the first position, drop it to the left of the first visible item
 * Otherwise if a visible item exists to the left of the new desired position and drop it to the right of it.
 *
 * @param params
 * @param params.itemIds List of items, including hidden ones, in a sorted manner
 * @param params.visibleItems Array of visible items, of which _id properties should be present in itemIds
 * @param params.visibleTargetIndex Target index of visible item to which the item is dropped
 * @param params.firstDroppableIndex First index of the droppable items, defaults to 0
 *
 * @returns Actual target index of the dropped item
 */
function findActualDropIndex({ itemIds, visibleItems, visibleTargetIndex, firstDroppableIndex = 0 }: {
    itemIds: string[];
    visibleItems: { _id: string; }[];
    visibleTargetIndex: number;
    firstDroppableIndex?: number;
}): number {
    let pivotItem;

    // If there are no visible items in the target, drop to the end of the actual items
    if (visibleItems.length <= firstDroppableIndex) {
        return itemIds.length;
    }

    // When moving an item to the first position, calculate the position before the first visible item
    if (visibleTargetIndex === firstDroppableIndex) {
        pivotItem = visibleItems[visibleTargetIndex];
        return itemIds.indexOf(pivotItem._id);
    }

    // Else, find the actual index of the item before the visibleTargetIndex and increment by 1
    pivotItem = visibleItems[visibleTargetIndex - 1];
    return itemIds.indexOf(pivotItem._id) + 1;
}

function findSectionIndicesOfProjects(oid: string, projectIds: string[]): OrganizationProjectPosition[] {
    const { sections } = getOrganization(oid)!;
    return projectIds.map(pid => {
        const sectionOfProject = sections.find(section => section.projects.includes(pid))!;
        const index = sectionOfProject.projects.findIndex(projectId => projectId === pid);

        return { pid, oseid: sectionOfProject._id, index };
    });
}

function isArchivedProjectsVisible(oid: string): boolean {
    const { showArchivedProjects } = fluxRuntime.SpaceStore.getState();

    if (showArchivedProjects) {
        return true;
    }

    const organization = getOrganization(oid);
    if (organization) {
        return organization.status === "suspended";
    }

    const { subscriptionStatus } = fluxRuntime.UserStore.getState();

    return subscriptionStatus === "auto_cancelled" || subscriptionStatus === "manually_cancelled";
}

function getVisibleProjectsOfSection(oid: string, oseid: string): Barrel[] {
    const archivedProjectsVisible = isArchivedProjectsVisible(oid);
    const foundSection = getOrganization(oid)!.sections.find(section => section._id === oseid)!;
    const organizationProjects = getOrganizationProjects(oid)!;
    return foundSection.projects.map(pid =>
        organizationProjects.find(project =>
            project._id === pid &&
            (archivedProjectsVisible || project.status !== "archived")
        )
    ).filter(exists);
}

function whereToDropSectionInWorkspace({ oid, index }: {
    oid: string;
    index: number;
}): number {
    const { sections, sectionIds } = getOrganization(oid)!;

    return findActualDropIndex({
        itemIds: sectionIds,
        visibleItems: sections,
        visibleTargetIndex: index,
        firstDroppableIndex: 1
    });
}

function whereToDropProjectInSection({ oid, oseid, index }: {
    oid: string;
    oseid: string;
    index?: number;
}): number {
    const targetSection = getOrganization(oid)!.sections.find(section => section._id === oseid)!;
    const visibleProjects = getVisibleProjectsOfSection(oid, oseid);
    const targetIndex = (typeof index === "undefined") ? visibleProjects.length : index;

    return findActualDropIndex({
        itemIds: targetSection.projects,
        visibleItems: visibleProjects,
        visibleTargetIndex: targetIndex
    });
}

function hasArchivedProjects(oid: string) {
    const organizationProjects = getOrganizationProjects(oid);

    if (!organizationProjects) {
        return false;
    }

    return organizationProjects.some(orgProject => orgProject.status === "archived");
}

function isOrganizationFrozen(): boolean {
    const organization = getOrganization();
    if (!organization) {
        return false;
    }

    return organization.status === "frozen";
}

function isProjectLimitExceeded(oid: string): boolean {
    const { organizations } = fluxRuntime.OrganizationStore.getState();
    const { limits:
        { projectLimit: personalProjectLimit }, userId: currentUserId } = fluxRuntime.UserStore.getState();
    const { config } = fluxRuntime.AppStore.getState();
    const { plans } = config!;

    const selectedOrganization = organizations.find(organization => organization._id === oid);

    if (selectedOrganization?.status === "suspended") {
        return false;
    }

    const currentPlan = selectedOrganization && plans.find(plan => plan.id === selectedOrganization.paymentPlan);

    const isPersonalWorkspace = oid === "user";

    const organizationProjects = getOrganizationProjects(oid) ?? [];

    let computedProjectLimit = 0;
    if (selectedOrganization) {
        computedProjectLimit = selectedOrganization.projectLimit || currentPlan!.projectLimit;
    } else {
        computedProjectLimit = personalProjectLimit;
    }

    const ownedDefaultProjects = isPersonalWorkspace
        ? organizationProjects
            .filter(project => {
                const isOwner = project.users.some(({ user: { _id }, role }) => _id === currentUserId && role === "owner");
                const isOnboardingProject = project.kind === BarrelKind.Onboarding;
                return isOwner && !isOnboardingProject;
            })
        : organizationProjects;

    const ownedActiveProjects = ownedDefaultProjects.filter(project => project.status !== "archived");

    return computedProjectLimit > 0 && ownedActiveProjects.length >= computedProjectLimit;
}

function isStyleGuideLimitExceeded(oid?: string) {
    const { userId: currentUserId, limits: { styleguideLimit } } = fluxRuntime.UserStore.getState();
    const { config } = fluxRuntime.AppStore.getState();
    const { plans } = config!;

    const selectedOrganization = getOrganization(oid);
    const currentStyleguides = getOrganizationStyleguides(oid ?? "user") ?? [];
    let computedStyleguideLimit = -1;
    if (selectedOrganization) {
        if (selectedOrganization.styleguideLimit) {
            computedStyleguideLimit = selectedOrganization.styleguideLimit;
        }

        computedStyleguideLimit = plans.find(plan => plan.id === selectedOrganization.paymentPlan)!.styleguideLimit;
    } else {
        computedStyleguideLimit = styleguideLimit;
    }

    const ownedStyleguides = selectedOrganization
        ? currentStyleguides
        : currentStyleguides.filter(({ users }) =>
            users.some(({ user: { _id }, role }) => _id === currentUserId && role === "owner"));

    const ownedActiveStyleguides = ownedStyleguides.filter(ownedStyleguide => ownedStyleguide.status !== "archived");

    return computedStyleguideLimit > 0 && ownedActiveStyleguides.length >= computedStyleguideLimit;
}

/**
 * Most recent organization profile is either:
 * - The most recently created organization's profile _(If user is owner of organization(s))_
 * - The most recently invited organization's profile _(If user doesn't own any organization)_
 */
function findMostRecentOrganizationProfile({ organizations, organizationProfiles }: {
    organizations?: Organization[];
    organizationProfiles?: OrganizationProfiles;
} = {}): Modify<OrganizationMemberProfile, { oid: string; }> | undefined {
    const {
        organizationProfiles: loadedOrganizationProfiles,
        organizations: loadedOrganizations
    } = fluxRuntime.OrganizationStore.getState();

    const organizationsToSearch = organizations ?? loadedOrganizations;
    const profilesToSearch = organizationProfiles ?? loadedOrganizationProfiles;

    // This list is sorted by oldest to newest creation date.
    const organizationProfileList = organizationsToSearch.map(({ _id }) => {
        if (!profilesToSearch[_id]) {
            return null;
        }

        return ({
            oid: _id,
            ...profilesToSearch[_id]
        });
    }).filter(exists);

    // `reverse` makes them sorted by newest to oldest creation date.
    const createdOrganizationProfiles = organizationProfileList
        .filter(org => org.role === "king")
        .reverse();

    const invitedOrganizationProfiles = organizationProfileList
        .filter(org => org.invitedAt)
        .sort((org1, org2) => sortBy.date(org1, org2, o => o.invitedAt!));

    const sortedOrganizationProfiles = createdOrganizationProfiles.concat(invitedOrganizationProfiles);

    if (!sortedOrganizationProfiles.length) {
        return;
    }

    return sortedOrganizationProfiles[0];
}

function getUpdateTriggerForBarrel(oid: string, barrelType: BarrelType): PlanUpgradeTrigger {
    if (barrelType === "project") {
        if (isProjectLimitExceeded(oid)) {
            return PlanUpgradeTrigger.PROJECT_LIMIT;
        }

        const { organizations } = fluxRuntime.OrganizationStore.getState();
        const { paymentPlan } = fluxRuntime.UserStore.getState();
        const { config } = fluxRuntime.AppStore.getState();
        const { plans } = config!;

        const selectedOrganization = organizations.find(organization => organization._id === oid);

        const currentPlan = selectedOrganization ? plans.find(plan => plan.id === selectedOrganization.paymentPlan)!
            : paymentPlan as SubscriptionPlan;

        if (currentPlan.flowDistinctProjectLimit) {
            // This is an assumption. We don't have a way to know how many flows there are in a project.
            // If there is a flow limit and the user didn't reach the project limit, we assume that the user hit the flow limit.
            return PlanUpgradeTrigger.FLOW_PROJECT_LIMIT;
        }
    }

    if (barrelType === "styleguide") {
        if (isStyleGuideLimitExceeded(oid)) {
            return PlanUpgradeTrigger.STYLEGUIDE_LIMIT;
        }
    }

    throw new Error("No upgrade trigger found");
}

function getOrganizationPlan(oid: string): SubscriptionPlan {
    const { organizations } = fluxRuntime.OrganizationStore.getState();
    const { paymentPlan } = fluxRuntime.UserStore.getState();
    const { config } = fluxRuntime.AppStore.getState();
    const { plans } = config!;

    const selectedOrganization = organizations.find(organization => organization._id === oid);

    const currentPlan = selectedOrganization ? plans.find(plan => plan.id === selectedOrganization.paymentPlan)!
        : paymentPlan as SubscriptionPlan;

    return currentPlan;
}

export default {
    getCurrentOrganizationId,
    getOrganization,
    getOrganizationMembers,
    getOrganizationMember,
    getOrganizationProjectsWithLookup,
    getOrganizationProjects,
    getOrganizationStyleguides,
    getOrganizationWorkflowStatus,
    getOrganizationProfile,
    getOrganizationPlan,
    getCollapsableSectionIds,
    getCollapsedSectionIds,
    getExpandedSectionIds,
    findSectionIndicesOfProjects,
    findProjectOrganizationSection,
    findProjectOrganizationSectionId,
    isArchivedProjectsVisible,
    getVisibleProjectsOfSection,
    whereToDropSectionInWorkspace,
    whereToDropProjectInSection,
    hasArchivedProjects,
    isOrganizationFrozen,
    isProjectLimitExceeded,
    isStyleGuideLimitExceeded,
    findMostRecentOrganizationProfile,
    getUpdateTriggerForBarrel
};
