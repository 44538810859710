enum ActionTypes {
    Select = "activities/select",
    SetDotsLoaded = "activities/setDotsLoaded",
    SelectCategory = "activities/selectCategory",
    SelectAllBarrels = "activities/selectAllBarrels",
    SelectBarrel = "activities/selectBarrel",
    SetProjectsWithUpdatesOnly = "activities/setProjectsWithUpdatesOnly",
    Load = "activities/load",
    SelectNoteStatusFilter = "activities/selectNoteStatusFilter",
    SelectColor = "activities/selectColor",
    SelectMember = "activities/selectMember",
    SelectSection = "activities/selectSection",
    RequestRelatedUsers = "activities/requestRelatedUsers",
    SetSidebarWidth = "activities/setSidebarWidth",
    SaveListScrollData = "activities/saveListScrollData",
    SelectTermsUpdate = "activities/selectTermsUpdate"
}

export default ActionTypes;
