enum ActionTypes {
    SET_SHOULD_MOUNT_IFRAME = "storybookIntegration/setShouldMountIframe",
    SET_STORIES = "storybookIntegration/setStories",
    UPDATE_URL = "storybookIntegration/updateUrl",
    UPDATE_STORY = "storybookIntegration/updateStory",
    DELETE_STORY = "storybookIntegration/deleteStory",
    UPDATE_SELECTED_STORY_PATH = "storybookIntegration/updateSelectedStoryPath",
    ADD_STORY_CONNECTED_COMPONENT = "storybookIntegration/addStoryConnectedComponent",
    REMOVE_STORY_CONNECTED_COMPONENT = "storybookIntegration/removeStoryConnectedComponent",
    UPDATE_FOR_UPDATE_CONNECTED_COMPONENTS = "storybookIntegration/updateForUpdateConnectedComponents"
}

export default ActionTypes;
