/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { MoveResourceType } from "../../../foundation/model/MoveResource";
import { AllPayloads } from "../payloads";

import * as Payloads from "./MoveResourcesActionPayloads";
import MoveResourcesActionTypes from "./MoveResourcesActionTypes";

interface State {
    open: boolean;
    targetNode: HTMLElement | null;
    resources: {
        type: MoveResourceType;
        _id: string;
    }[];
    isMovePending: boolean;
}

class MoveResourcesStore extends ReduceStore<State, AllPayloads> {
    getInitialState() {
        return {
            open: false,
            targetNode: null,
            resources: [],
            isMovePending: false
        };
    }

    open(state: State, { targetNode, resources }: Payloads.Open): State {
        return {
            ...state,
            open: true,
            targetNode,
            resources
        };
    }

    close(state: State): State {
        return {
            ...state,
            open: false,
            targetNode: null,
            resources: [],
            isMovePending: false
        };
    }

    move(state: State): State {
        return {
            ...state,
            isMovePending: true
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case MoveResourcesActionTypes.OPEN:
                return this.open(state, action);

            case MoveResourcesActionTypes.CLOSE:
                return this.close(state);

            case MoveResourcesActionTypes.MOVE:
                return this.move(state);

            default:
                return state;
        }
    }
}

export default MoveResourcesStore;
export {
    State as MoveResourcesStoreState
};
