import { ENV } from "../../../../foundation/constants";
import { EXEC_ENV } from "../../../../foundation/enums";
import MenuItem, { MenuItemDescription } from "../../../../foundation/electron/MenuItem";
import { isMacOS } from "../../../../foundation/utils/ua-utils";

import MenuItemID from "./MenuItemID";
import MenuViewType from "./MenuViewType";
import separator from "./separator";
import {
    AppUpdaterState,
    appUpdateLabels,
    PluginUpdaterState,
    pluginUpdateLabels,
    SketchPreferences
} from "./updaterStates";

const aboutMenuItem = (): MenuItemDescription => ({
    id: MenuItemID.ABOUT_ZEPLIN,
    label: "About Zeplin"
});

const updateMenuItems = ({
    appUpdaterState,
    pluginUpdaterState
}: {
    appUpdaterState: AppUpdaterState;
    pluginUpdaterState: PluginUpdaterState;
}): MenuItemDescription[] => {
    const appUpdateMenuItem = {
        id: MenuItemID.CHECK_FOR_UPDATES,
        label: appUpdateLabels[appUpdaterState],
        enabled: appUpdaterState === AppUpdaterState.Idle
    };
    if (pluginUpdaterState === PluginUpdaterState.Disabled) {
        return [appUpdateMenuItem];
    }
    return [
        appUpdateMenuItem,
        {
            id: MenuItemID.CHECK_FOR_PLUGIN_UPDATES,
            label: pluginUpdateLabels[pluginUpdaterState],
            enabled: pluginUpdaterState === PluginUpdaterState.Idle
        }
    ];
};

const sketchIntegrationMenuItem = ({
    sketchPreferences
}: {
    sketchPreferences: SketchPreferences;
}): MenuItemDescription => ({
    id: MenuItemID.SKETCH_INTEGRATION,
    label: "Sketch Integration",
    submenu: [{
        id: MenuItemID.SKETCH_KEEP_GROUPED_LAYER_NAMES,
        label: "Keep Grouped Layer Names",
        type: "checkbox",
        checked: sketchPreferences.keepGroupedLayerNames
    }, {
        id: MenuItemID.SKETCH_KEEP_GROUPED_SYMBOL_NAMES,
        label: "Keep Grouped Symbol Names",
        type: "checkbox",
        checked: sketchPreferences.keepGroupedSymbolNames
    }, {
        id: MenuItemID.SKETCH_USE_SYMBOL_INSTANCE_NAMES,
        label: "Use Symbol Instance Names",
        type: "checkbox",
        checked: sketchPreferences.useSymbolInstanceNames
    }, {
        id: MenuItemID.SKETCH_JPG_QUALITY,
        label: "JPG Quality",
        submenu: [{
            id: MenuItemID.SKETCH_JPG_QUALITY_100,
            label: "100%",
            type: "checkbox",
            checked: sketchPreferences.jpgQuality === 1.0
        }, {
            id: MenuItemID.SKETCH_JPG_QUALITY_90,
            label: "90% — Sketch Default",
            type: "checkbox",
            checked: sketchPreferences.jpgQuality === 0.9
        }, {
            id: MenuItemID.SKETCH_JPG_QUALITY_80,
            label: "80%",
            type: "checkbox",
            checked: sketchPreferences.jpgQuality === 0.8
        }, {
            id: MenuItemID.SKETCH_JPG_QUALITY_70,
            label: "70%",
            type: "checkbox",
            checked: sketchPreferences.jpgQuality === 0.7
        }, {
            id: MenuItemID.SKETCH_JPG_QUALITY_60,
            label: "60%",
            type: "checkbox",
            checked: sketchPreferences.jpgQuality === 0.6
        }, {
            id: MenuItemID.SKETCH_JPG_QUALITY_50,
            label: "50%",
            type: "checkbox",
            checked: sketchPreferences.jpgQuality === 0.5
        }]
    }]
});

const figmaIntegrationMenuItem = () => ({
    id: MenuItemID.FIGMA_INTEGRATION,
    label: "Figma Integration",
    submenu: [{
        id: MenuItemID.INSTALL_FIGMA_PLUGIN,
        label: "Install Plugin…"
    }, separator, {
        id: MenuItemID.FIGMA_PLUGIN_LEARN_MORE,
        label: "Learn More…"
    }]
});

// TODO: mac app has one more submenu item for photoshop which is `Install Plugin Automatically`
// decide whether we need it in our unified app
const adobePhotoshopIntegration = (): MenuItemDescription => ({
    id: MenuItemID.ADOBE_PHOTOSHOP_INTEGRATION,
    label: "Adobe Photoshop Integration",
    submenu: [{
        id: MenuItemID.INSTALL_PHOTOSHOP_PLUGIN,
        label: "Install Plugin…",
        enabled: false
    }]
});

const logOutMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.LOG_OUT,
    label: "Log Out",
    enabled
});

export default function applicationSubmenu(menuViewType: MenuViewType | null, {
    appUpdaterState,
    pluginUpdaterState,
    sketchPreferences
}: {
    appUpdaterState: AppUpdaterState;
    pluginUpdaterState: PluginUpdaterState;
    sketchPreferences: SketchPreferences;
}): MenuItem[] {
    if (!isMacOS()) {
        return [];
    }

    return [
        new MenuItem({
            id: MenuItemID.APPLICATION_SUBMENU,
            label: ENV === EXEC_ENV.production || ENV === EXEC_ENV.beta ? "Zeplin" : "Zeplin Test",
            submenu: [
                aboutMenuItem(),
                separator,
                ...updateMenuItems({ appUpdaterState, pluginUpdaterState }),
                separator,
                sketchIntegrationMenuItem({ sketchPreferences }),
                figmaIntegrationMenuItem(),
                adobePhotoshopIntegration(),
                separator,
                logOutMenuItem({ enabled: menuViewType !== MenuViewType.LOGIN }),
                separator,
                { role: "hide" },
                { role: "hideOthers" },
                separator,
                { role: "quit" }
            ]
        })
    ];
}
