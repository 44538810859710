import { CLI_CLIENT_ID, ZEM_CLIENT_ID } from "../../../foundation/constants";
import { imageUrl } from "../../../foundation/utils/image";

export const OFFICIAL_APPS_BASES: OfficialAppBase[] = [{
    _id: CLI_CLIENT_ID,
    name: "CLI",
    emoji: "🧩",
    description: "Connect components in Zeplin to components in code using Zeplin CLI."
}, {
    _id: ZEM_CLIENT_ID,
    name: "zem",
    logo: imageUrl("img/ntf/icExtension.svg"),
    description: "Create, test and publish Zeplin extensions with no build configuration."
}];

export enum OfficialAppStatus {
    Connected = "connected",
    Connecting = "connecting",
    Unconnected = "unconnected"
}

export interface OfficialAppBase {
    _id: string;
    name: string;
    emoji?: string;
    logo?: string;
    description: string;
}

export default interface OfficialApp extends OfficialAppBase {
    token: unknown | null;
    status: OfficialAppStatus;
}
