/* eslint-disable class-methods-use-this */
import Dispatcher from "flux/lib/Dispatcher";
import ReduceStore from "flux/lib/FluxReduceStore";

import { Annotation } from "../../../foundation/api/model";
import RemarkType from "../../../foundation/model/RemarkType";

import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import DotsAndAnnotationsReducer from "../dotsAndAnnotationsReducer/DotsAndAnnotationsReducer";
import * as ProjectsActionPayloads from "../projects/ProjectsActionPayloads";
import ProjectsActionTypes from "../projects/ProjectsActionTypes";
import { AllPayloads } from "../payloads";

import { AnnotationsRecord } from "./AnnotationsRecord";
import * as ScreenActionPayloads from "./ScreenActionPayloads";
import ScreenActionTypes from "./ScreenActionTypes";

type State = {
    annotations: AnnotationsRecord;
};

class AnnotationsDataStore extends ReduceStore<State, AllPayloads> {
    reducer: DotsAndAnnotationsReducer<State, Annotation, "annotations">;

    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
        this.reducer = new DotsAndAnnotationsReducer({ propertyName: "annotations" });
    }

    getInitialState(): State {
        return {
            annotations: {}
        };
    }

    setAnnotations(state: State, {
        pid,
        sid,
        annotations
    }: ScreenActionPayloads.SetAnnotations): State {
        return this.reducer.setDotsAndAnnotations(
            state,
            { pid, sid, data: annotations }
        );
    }

    setAnnotationsForIntermediateLoading(state: State, {
        annotationsDetails: {
            pid,
            sid,
            annotations
        }
    }: BarrelActionPayloads.LoadIntermediate): State {
        return this.reducer.setDotsAndAnnotations(
            state,
            { pid, sid, data: annotations }
        );
    }

    updateAnnotationPosition(state: State, {
        pid,
        sid,
        atid,
        x,
        y
    }: ScreenActionPayloads.UpdateAnnotationPosition): State {
        return this.reducer.updateDotAndAnnotation(
            state,
            { pid, sid, daid: atid, data: { x, y } }
        );
    }

    updateAnnotationText(state: State, {
        pid,
        sid,
        atid,
        componentSourceId,
        annotationData
    }: ScreenActionPayloads.UpdateAnnotationText): State {
        if (componentSourceId) {
            return state;
        }

        return this.reducer.updateDotAndAnnotation(state, {
            pid, sid, daid: atid, data: annotationData
        });
    }

    updateAnnotationType(state: State, {
        pid,
        sid,
        atid,
        componentSourceId,
        newType
    }: ScreenActionPayloads.UpdateAnnotationType): State {
        if (componentSourceId) {
            return state;
        }

        return this.reducer.updateDotAndAnnotation(state, {
            pid, sid, daid: atid, data: { type: newType }
        });
    }

    deleteAnnotationType(state: State, {
        pid,
        sid,
        atid,
        componentSourceId
    }: ScreenActionPayloads.DeleteAnnotationType): State {
        if (componentSourceId) {
            return state;
        }

        return this.reducer.updateDotAndAnnotation(state, {
            pid, sid, daid: atid, data: { type: undefined }
        });
    }

    removeAnnotation(state: State, {
        pid,
        sid,
        atid,
        componentSourceId
    }: ScreenActionPayloads.RemoveAnnotation): State {
        if (componentSourceId) {
            return state;
        }

        return this.reducer.removeDotAndAnnotation(state,
            { pid, sid, daid: atid });
    }

    addAnnotation(state: State, {
        pid,
        sid,
        annotation
    }: ScreenActionPayloads.AddAnnotation | ScreenActionPayloads.CreateAnnotation): State {
        if (annotation.componentSourceId) {
            return state;
        }

        return this.reducer.insertDotAndAnnotation(state,
            { pid, sid, data: annotation });
    }

    updateAnnotation(state: State, {
        pid,
        sid,
        atid: oldAtid,
        annotationData
    }: ScreenActionPayloads.UpdateAnnotation): State {
        if (annotationData.componentSourceId) {
            return state;
        }

        return this.reducer.updateDotAndAnnotation(
            state,
            { pid, sid, daid: oldAtid, data: annotationData }
        );
    }

    updateDotPopupRemarkType(state: State, {
        pid,
        sid,
        atid,
        remark,
        remarkType
    }: ScreenActionPayloads.UpdateDotPopupRemarkType): State {
        if (remarkType === RemarkType.Annotation) {
            return this.reducer.insertDotAndAnnotation(state,
                { pid, sid, data: remark! });
        }

        return this.reducer.removeDotAndAnnotation(
            state,
            { pid, sid, daid: atid! }
        );
    }

    handleUnlinkAnnotation(state: State, {
        pid,
        sid,
        newAnnotation
    }: ScreenActionPayloads.UnlinkAnnotation) {
        return this.reducer.insertDotAndAnnotation(state,
            { pid, sid, data: newAnnotation });
    }

    updateUserData(state: State, {
        bid,
        uid,
        userData
    }: BarrelActionPayloads.UpdateUserData): State {
        const projectAnnotations = state.annotations[bid];
        // eslint-disable-next-line guard-for-in
        for (const screenId in projectAnnotations) {
            const screenAnnotations: Annotation[] = state.annotations[bid][screenId];

            if (screenAnnotations && screenAnnotations.length) {
                const newScreenAnnotations: Annotation[] = screenAnnotations.map(annotation => {
                    if (annotation.creator && annotation.creator._id === uid) {
                        return { ...annotation, creator: { ...annotation.creator, ...userData } };
                    }

                    return annotation;
                });

                return this.reducer.setDotsAndAnnotations(
                    state,
                    { pid: bid, sid: screenId, data: newScreenAnnotations }
                );
            }
        }

        return state;
    }

    removeProjectEntry(state: State, {
        bid
    }: { bid: string; }): State {
        const { annotations } = state;

        const { [bid]: _, ...remainingAnnotations } = annotations;

        return {
            ...state,
            annotations: remainingAnnotations
        };
    }

    handleTransferOwnership(state: State, {
        bid,
        toOrganization,
        oid
    }: ProjectsActionPayloads.TransferOwnership| BarrelActionPayloads.OwnershipTransferred): State {
        if (toOrganization || oid !== "user") {
            const fromKnownOrg = !!oid && (oid !== "user");
            // TODO: May change in Annotations, Phase - 2
            // org to personal transfer -> do not need to delete annotations
            if (fromKnownOrg && (toOrganization === false)) {
                return state;
            }

            return this.removeProjectEntry(state, { bid });
        }

        return state;
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ScreenActionTypes.SET_ANNOTATIONS:
                return this.setAnnotations(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION_POSITION:
                return this.updateAnnotationPosition(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION_TEXT:
                return this.updateAnnotationText(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION_TYPE:
                return this.updateAnnotationType(state, action);
            case ScreenActionTypes.DELETE_ANNOTATION_TYPE:
                return this.deleteAnnotationType(state, action);
            case ScreenActionTypes.REMOVE_ANNOTATION:
                return this.removeAnnotation(state, action);
            case ScreenActionTypes.ADD_ANNOTATION:
            case ScreenActionTypes.CREATE_ANNOTATION:
                return this.addAnnotation(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION:
                return this.updateAnnotation(state, action);
            case ScreenActionTypes.UPDATE_DOTPOPUP_REMARK_TYPE:
                return this.updateDotPopupRemarkType(state, action);
            case ScreenActionTypes.UNLINK_ANNOTATION:
                return this.handleUnlinkAnnotation(state, action);

            case BarrelActionTypes.UPDATE_USER_DATA:
                return this.updateUserData(state, action);
            case ProjectsActionTypes.TRANSFER_OWNERSHIP:
            case BarrelActionTypes.OWNERSHIP_TRANSFERRED:
                return this.handleTransferOwnership(state, action);
            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.setAnnotationsForIntermediateLoading(state, action);

            case BarrelActionTypes.REMOVE:
                return this.removeProjectEntry(state, action);

            default:
                return state;
        }
    }
}

export default AnnotationsDataStore;
export {
    State as AnnotationsDataStoreState
};
