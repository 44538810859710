/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { CaptureSettingsAction } from "../../../foundation/api/model/account/CaptureSettings";
import BarrelType from "../../../foundation/model/BarrelType";
import { difference, union } from "../../../foundation/utils/array";
import { USER_MENU } from "../../containers/AppContainer/HeaderContainer/UserMenuContainer/menuSectionTypes";
import * as AppActionPayloads from "../app/AppActionPayloads";
import AppActionTypes from "../app/AppActionTypes";

import BarrelActionTypes from "../barrel/BarrelActionTypes";
import FlowActionTypes from "../flow/FlowActionTypes";

import OrganizationActionTypes from "../organization/OrganizationActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./UserActionPayloads";
import UserActionTypes from "./UserActionTypes";

import State, { Limits } from "./UserStoreState";

class UserStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            loading: true,
            userId: null,
            anonymousUserId: null,
            username: null,
            fullName: null,
            email: null,
            avatar: null,
            emotar: null,
            eligibleForFreeTrial: false,
            previouslyUsedPaidPlan: false,
            verified: null,
            registeredAt: null,
            paymentPlan: null,
            isDesktopNotificationEnabled: null,
            isEmailNotificationEnabled: null,
            notificationSettings: null,
            emailNotificationSettings: null,
            zeplinThreeZeroWhatsNewSeen: true,
            shouldPromoteSpacingSectionDescriptionEditButton: true,
            shouldDisplayStyleguideTutorial: true,
            shouldPromoteExportDesignTokens: true,
            shouldDisplayInvitedUserHintbox: false,
            shouldDisplayPersonalWorkspaceHintbox: true,
            shouldDisplayFreeTrialOnboardingHintbox: true,
            shouldDisplayFreeTrialPromotionDialog: false,
            shouldDisplayDashboardOnboardingHintbox: true,
            shouldDisplayPhotoshopInstructionsHintbox: true,
            shouldDisplayAddToFlowOnboarding: true,
            shouldDisplayAddToFlowTooltip: true,
            shouldDisplayFlowsTabTooltip: false,
            flowsTabTooltipSeen: false,
            selectedView: USER_MENU,
            acquaintancesLoading: false,
            acquaintancesError: null,
            acquaintances: null,
            offers: [],
            profession: null,
            subscriptionStatus: null,
            subscriptionCancelAtPeriodEnd: false,
            storybookIntegrationPromotionSeen: false,
            highlightComponentsPromotionSeen: false,
            linkToStyleguidePromotionSeen: false,
            colorPaletteSectionsPromotionSeen: false,
            shouldAcceptNewTerms: false,
            termsForApproval: [],
            accountCaptureInfo: undefined,
            flowOnboardingConnectorHidden: false,
            lastSeenWhatsNewDataId: null,
            whatsNewEmojiSeenCount: null,
            showSVGContent: false,
            shouldDisplayDesktopNotificationBanner: false,
            shouldDisplayEmailVerificationBanner: false,
            tierQuantity: undefined,
            limits: {
                projectLimit: 1,
                styleguideLimit: 1
            },
            createSectionPromotionCounter: 0,
            addTagPromotionCounter: 0,
            setAsVariantPromotionCounter: 0
        };
    }

    loadUser(state: State, {
        user
    }: AppActionPayloads.HydrateEmbeddedData | Payloads.LoadUser): State {
        if (!user) {
            return state;
        }

        const {
            _id: userId,
            anonymousUserId,
            username,
            fullName,
            email,
            status,
            avatar,
            emotar,
            registeredAt,
            emailNotifications: isEmailNotificationEnabled,
            notificationSettings,
            emailNotificationSettings,
            billing,
            paymentPlan,
            offers,
            profession,
            subscriptionStatus,
            subscriptionCancelAtPeriodEnd,
            tierQuantity,
            limits
        } = (user as typeof Zeplin.user & { tierQuantity?: number; limits: Limits; });

        return {
            ...state,
            anonymousUserId,
            userId,
            username,
            fullName,
            email,
            verified: status === "verified",
            avatar,
            emotar,
            registeredAt,
            isEmailNotificationEnabled,
            notificationSettings,
            emailNotificationSettings: emailNotificationSettings ? {
                ...emailNotificationSettings,
                followedProjects: new Set(emailNotificationSettings.followedProjects),
                unfollowedProjects: new Set(emailNotificationSettings.unfollowedProjects)
            } : undefined,
            billing,
            paymentPlan,
            offers,
            profession,
            subscriptionStatus,
            subscriptionCancelAtPeriodEnd,
            loading: false,
            tierQuantity,
            limits
        };
    }

    updatePaymentPlan(
        state: State,
        { plan, tierQuantity }: Payloads.UpdatePersonalSubscription
    ): State {
        const { limits: { styleguideLimit } } = state;

        return {
            ...state,
            paymentPlan: plan,
            tierQuantity,
            limits: {
                projectLimit: tierQuantity,
                styleguideLimit
            },
            subscriptionCancelAtPeriodEnd: false
        };
    }

    updateUser(state: State, {
        user
    }: Payloads.UpdateUser): State {
        return {
            ...state,
            ...user,
            loading: false
        };
    }

    updateUserAttribute(state: State, {
        name,
        value
    }: Payloads.SetUserAttribute<keyof State>): State {
        return Object.assign({}, state, {
            [name]: value
        });
    }

    setSelectedView(state: State, {
        selectedView
    }: Payloads.SetSelectedView): State {
        return Object.assign({}, state, {
            selectedView
        });
    }

    muteProjectNotifications(state: State, {
        pid
    }: Payloads.MuteProjectNotifications): State {
        const { notificationSettings } = state;
        if (!notificationSettings) {
            return state;
        }

        const mutedProjects = union(notificationSettings.mutedProjects, [pid]);

        return {
            ...state,
            notificationSettings: {
                ...notificationSettings,
                mutedProjects
            }
        };
    }

    muteStyleguideNotifications(state: State, {
        stid
    }: Payloads.MuteStyleguideNotifications): State {
        const { notificationSettings } = state;
        if (!notificationSettings) {
            return state;
        }

        const mutedStyleguides = union(notificationSettings.mutedStyleguides, [stid]);

        return {
            ...state,
            notificationSettings: {
                ...notificationSettings,
                mutedStyleguides
            }
        };
    }

    unmuteProjectNotifications(state: State, {
        pid
    }: Payloads.UnmuteProjectNotifications): State {
        const { notificationSettings } = state;
        if (!notificationSettings) {
            return state;
        }

        const mutedProjects = difference(notificationSettings.mutedProjects, [pid]);

        return {
            ...state,
            notificationSettings: {
                ...notificationSettings,
                mutedProjects
            }
        };
    }

    unmuteStyleguideNotifications(state: State, {
        stid
    }: Payloads.UnmuteStyleguideNotifications): State {
        const { notificationSettings } = state;
        if (!notificationSettings) {
            return state;
        }

        const mutedStyleguides = difference(notificationSettings.mutedStyleguides, [stid]);

        return {
            ...state,
            notificationSettings: {
                ...notificationSettings,
                mutedStyleguides
            }
        };
    }

    updateFollowedProjectNotifications(state: State, {
        followedProjectIds,
        unfollowedProjectIds
    }: Pick<Payloads.UpdateFollowedProjectNotifications, "followedProjectIds" | "unfollowedProjectIds">) {
        const { emailNotificationSettings } = state;
        if (!emailNotificationSettings) {
            return state;
        }

        const {
            followedProjects: currentFollowedProjects,
            unfollowedProjects: currentUnfollowedProjects
        } = emailNotificationSettings;

        const followedProjects = new Set(currentFollowedProjects);
        const unfollowedProjects = new Set(currentUnfollowedProjects);

        followedProjectIds?.forEach(pid => {
            followedProjects.add(pid);
            unfollowedProjects.delete(pid);
        });

        unfollowedProjectIds?.forEach(pid => {
            followedProjects.delete(pid);
            unfollowedProjects.add(pid);
        });

        return {
            ...state,
            emailNotificationSettings: {
                ...emailNotificationSettings,
                followedProjects,
                unfollowedProjects
            }
        };
    }

    setFollowedProjectNotifications(state: State, {
        followedProjectIds,
        unfollowedProjectIds
    }: Payloads.SetFollowedProjectNotifications): State {
        const { emailNotificationSettings } = state;
        if (!emailNotificationSettings) {
            return state;
        }

        return {
            ...state,
            emailNotificationSettings: {
                ...emailNotificationSettings,
                followedProjects: new Set(followedProjectIds),
                unfollowedProjects: new Set(unfollowedProjectIds)
            }
        };
    }

    setEmailNotificationPreferences(state: State, {
        preferences
    }: Payloads.SetEmailNotificationPreferences) {
        const { emailNotificationSettings } = state;
        if (!emailNotificationSettings) {
            return state;
        }

        const { emailPreferences } = emailNotificationSettings;

        return {
            ...state,
            emailNotificationSettings: {
                ...emailNotificationSettings,
                emailPreferences: {
                    ...emailPreferences,
                    ...preferences
                }
            }
        };
    }

    markZeplinThreeZeroWhatsNewAsSeen(state: State): State {
        return {
            ...state,
            zeplinThreeZeroWhatsNewSeen: true
        };
    }

    stopPromotingSpacingSectionDescriptionEditButton(state: State): State {
        return {
            ...state,
            shouldPromoteSpacingSectionDescriptionEditButton: false
        };
    }

    hideStyleguideTutorial(state: State): State {
        return {
            ...state,
            shouldDisplayStyleguideTutorial: false
        };
    }

    stopPromotingExportDesignTokens(state: State): State {
        return {
            ...state,
            shouldPromoteExportDesignTokens: false
        };
    }

    hideInvitedUserHintbox(state: State): State {
        return {
            ...state,
            shouldDisplayInvitedUserHintbox: false
        };
    }

    hidePersonalWorkspaceHintbox(state: State): State {
        return {
            ...state,
            shouldDisplayPersonalWorkspaceHintbox: false
        };
    }

    hideFreeTrialOnboardingHintbox(state: State): State {
        return {
            ...state,
            shouldDisplayFreeTrialOnboardingHintbox: false
        };
    }

    hideFreeTrialPromotionDialog(state: State): State {
        return {
            ...state,
            shouldDisplayFreeTrialPromotionDialog: false
        };
    }

    hideDashboardOnboardingHintbox(state: State): State {
        return {
            ...state,
            shouldDisplayDashboardOnboardingHintbox: false
        };
    }

    hidePhotoshopInstructionsHintbox(state: State): State {
        return {
            ...state,
            shouldDisplayPhotoshopInstructionsHintbox: false
        };
    }

    hideAddToFlowOnboarding(state: State): State {
        return {
            ...state,
            shouldDisplayAddToFlowOnboarding: false
        };
    }

    displayFlowsTabTooltip(state: State): State {
        return {
            ...state,
            shouldDisplayFlowsTabTooltip: true
        };
    }

    hideFlowsTabTooltip(state: State): State {
        return {
            ...state,
            shouldDisplayFlowsTabTooltip: false,
            flowsTabTooltipSeen: true
        };
    }

    acquaintancesRequested(state: State): State {
        return {
            ...state,
            acquaintancesLoading: true,
            acquaintancesError: null,
            acquaintances: null
        };
    }

    setAcquaintances(state: State, {
        acquaintances
    }: Payloads.SetAcquaintances): State {
        return {
            ...state,
            acquaintancesLoading: false,
            acquaintances
        };
    }

    getAcquaintancesFailed(state: State, {
        error: acquaintancesError
    }: Payloads.GetAcquaintancesFailed): State {
        return {
            ...state,
            acquaintancesLoading: false,
            acquaintancesError
        };
    }

    markStorybookIntegrationPromotionAsSeen(state: State): State {
        return {
            ...state,
            storybookIntegrationPromotionSeen: true
        };
    }

    markLinkToStyleguidePromotionAsSeen(state: State): State {
        return {
            ...state,
            linkToStyleguidePromotionSeen: true
        };
    }

    markColorPaletteSectionsPromotionAsSeen(state: State): State {
        return {
            ...state,
            colorPaletteSectionsPromotionSeen: true
        };
    }

    setTermsForApproval(state: State, action: Payloads.SetTermsForApproval): State {
        const { termsForApproval } = action;
        return {
            ...state,
            termsForApproval,
            shouldAcceptNewTerms: !!termsForApproval.length
        };
    }

    acceptTerms(state: State, action: Payloads.AcceptTerms): State {
        const { acceptedTermsId } = action;
        const remainingTermsForApproval = state.termsForApproval.filter(terms => terms._id !== acceptedTermsId);

        return {
            ...state,
            shouldAcceptNewTerms: !!remainingTermsForApproval.length,
            termsForApproval: remainingTermsForApproval
        };
    }

    removeAccountCaptureInfo(state: State, { id }: { id: string; }): State {
        const { accountCaptureInfo } = state;
        if (!accountCaptureInfo || accountCaptureInfo.action !== CaptureSettingsAction.Capture) {
            return state;
        }

        if (accountCaptureInfo.defaultOrganizationId === id) {
            return {
                ...state,
                accountCaptureInfo: undefined
            };
        }

        return state;
    }

    hideFlowOnboardingConnector(state: State): State {
        return {
            ...state,
            flowOnboardingConnectorHidden: true
        };
    }

    setLastSeenWhatsNewDataId(state: State, whatsNewId: string) {
        return {
            ...state,
            lastSeenWhatsNewDataId: whatsNewId
        };
    }

    setWhatsNewEmojiSeenCount(state: State, count: number) {
        return {
            ...state,
            whatsNewEmojiSeenCount: count
        };
    }

    showSVGContent(state: State): State {
        return {
            ...state,
            showSVGContent: true
        };
    }

    hideSVGContent(state: State): State {
        return {
            ...state,
            showSVGContent: false
        };
    }

    hideDesktopNotificationBanner(state: State): State {
        return {
            ...state,
            shouldDisplayDesktopNotificationBanner: false
        };
    }

    hideEmailVerificationBanner(state: State): State {
        return {
            ...state,
            shouldDisplayEmailVerificationBanner: false
        };
    }

    updatePlanData(state: State, {
        tierQuantity,
        limits,
        paymentPlan,
        subscriptionStatus
    }: Payloads.UpdatePlanData): State {
        return {
            ...state,
            tierQuantity,
            limits,
            paymentPlan,
            subscriptionStatus
        };
    }

    increaseCreateSectionPromotionCounter(state: State): State {
        return {
            ...state,
            createSectionPromotionCounter: state.createSectionPromotionCounter + 1
        };
    }

    increaseAddTagPromotionCounter(state: State): State {
        return {
            ...state,
            addTagPromotionCounter: state.addTagPromotionCounter + 1
        };
    }

    increaseSetAsVariantPromotionCounter(state: State): State {
        return {
            ...state,
            setAsVariantPromotionCounter: state.setAsVariantPromotionCounter + 1
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.HYDRATE_EMBEDDED_DATA:
            case UserActionTypes.LOAD_USER:
                return this.loadUser(state, action);

            case UserActionTypes.UPDATE_USER:
                return this.updateUser(state, action);
            case UserActionTypes.UPDATE_PERSONAL_SUBSCRIPTION:
                return this.updatePaymentPlan(state, action);
            case UserActionTypes.SET_USER_ATTRIBUTE:
                return this.updateUserAttribute(state, action);
            case UserActionTypes.SET_SELECTED_VIEW:
                return this.setSelectedView(state, action);
            case UserActionTypes.SET_EMAIL_NOTIFICATION_PREFERENCES:
                return this.setEmailNotificationPreferences(state, action);
            case UserActionTypes.MUTE_PROJECT_NOTIFICATIONS:
                return this.muteProjectNotifications(state, action);
            case UserActionTypes.MUTE_STYLEGUIDE_NOTIFICATIONS:
                return this.muteStyleguideNotifications(state, action);
            case UserActionTypes.UNMUTE_PROJECT_NOTIFICATIONS:
                return this.unmuteProjectNotifications(state, action);
            case UserActionTypes.UNMUTE_STYLEGUIDE_NOTIFICATIONS:
                return this.unmuteStyleguideNotifications(state, action);
            case UserActionTypes.UPDATE_FOLLOWED_PROJECT_NOTIFICATIONS:
                return this.updateFollowedProjectNotifications(state, action);
            case UserActionTypes.SET_FOLLOWED_PROJECT_NOTIFICATIONS:
                return this.setFollowedProjectNotifications(state, action);
            case UserActionTypes.MARK_ZEPLIN_THREE_ZERO_WHATS_NEW_AS_SEEN:
                return this.markZeplinThreeZeroWhatsNewAsSeen(state);
            case UserActionTypes.STOP_PROMOTING_SPACING_SECTION_DESCRIPTION_EDIT_BUTTON:
                return this.stopPromotingSpacingSectionDescriptionEditButton(state);
            case UserActionTypes.HIDE_STYLEGUIDE_TUTORIAL:
                return this.hideStyleguideTutorial(state);
            case UserActionTypes.STOP_PROMOTING_EXPORT_DESIGN_TOKENS:
                return this.stopPromotingExportDesignTokens(state);
            case UserActionTypes.HIDE_INVITED_USER_HINTBOX:
                return this.hideInvitedUserHintbox(state);
            case UserActionTypes.HIDE_PERSONAL_WORKSPACE_HINTBOX:
                return this.hidePersonalWorkspaceHintbox(state);
            case UserActionTypes.HIDE_FREE_TRIAL_ONBOARDING_HINTBOX:
                return this.hideFreeTrialOnboardingHintbox(state);
            case UserActionTypes.HIDE_FREE_TRIAL_PROMOTION_DIALOG:
                return this.hideFreeTrialPromotionDialog(state);
            case UserActionTypes.HIDE_DASHBOARD_ONBOARDING_HINTBOX:
                return this.hideDashboardOnboardingHintbox(state);
            case UserActionTypes.HIDE_PHOTOSHOP_INSTRUCTIONS_HINTBOX:
                return this.hidePhotoshopInstructionsHintbox(state);
            case UserActionTypes.HIDE_ADD_TO_FLOW_ONBOARDING:
                return this.hideAddToFlowOnboarding(state);
            case UserActionTypes.HIDE_FLOWS_TAB_TOOLTIP:
                return this.hideFlowsTabTooltip(state);
            case UserActionTypes.ACQUAINTANCES_REQUESTED:
                return this.acquaintancesRequested(state);
            case UserActionTypes.SET_ACQUAINTANCES:
                return this.setAcquaintances(state, action);
            case UserActionTypes.GET_ACQUAINTANCES_FAILED:
                return this.getAcquaintancesFailed(state, action);
            case UserActionTypes.MARK_STORYBOOK_INTEGRATION_PROMOTION_AS_SEEN:
                return this.markStorybookIntegrationPromotionAsSeen(state);
            case UserActionTypes.MARK_LINK_TO_STYLEGUIDE_PROMOTION_AS_SEEN:
                return this.markLinkToStyleguidePromotionAsSeen(state);
            case UserActionTypes.MARK_COLOR_PALETTE_SECTIONS_PROMOTION_AS_SEEN:
                return this.markColorPaletteSectionsPromotionAsSeen(state);
            case UserActionTypes.SET_TERMS_FOR_APPROVAL:
                return this.setTermsForApproval(state, action);
            case UserActionTypes.ACCEPT_TERMS:
                return this.acceptTerms(state, action);
            case OrganizationActionTypes.REMOVE_ORGANIZATION:
                return this.removeAccountCaptureInfo(state, action);
            case UserActionTypes.HIDE_FLOW_ONBOARDING_CONNECTOR:
                return this.hideFlowOnboardingConnector(state);
            case UserActionTypes.HIDE_DESKTOP_NOTIFICATION_BANNER:
                return this.hideDesktopNotificationBanner(state);

            case FlowActionTypes.ADD_NODES_TO_FLOW_REQUEST: {
                if (action.shouldTriggerFlowsTabTooltip) {
                    return this.displayFlowsTabTooltip(state);
                }

                if (action.shouldHideFlowsTabTooltip) {
                    return this.hideFlowsTabTooltip(state);
                }

                return state;
            }

            case BarrelActionTypes.JOIN:
                if (action.barrelType !== BarrelType.PROJECT) {
                    return state;
                }

                return this.updateFollowedProjectNotifications(state, {
                    followedProjectIds: [action.bid]
                });

            case BarrelActionTypes.REMOVE_MEMBER:
                if (action.barrelType !== BarrelType.PROJECT) {
                    return state;
                }

                return this.updateFollowedProjectNotifications(state, {
                    unfollowedProjectIds: [action.bid]
                });

            case UserActionTypes.SET_LAST_SEEN_WHATS_NEW_DATA_ID:
                return this.setLastSeenWhatsNewDataId(state, action.whatsNewId);
            case UserActionTypes.SET_WHATS_NEW_EMOJI_SEEN_COUNT:
                return this.setWhatsNewEmojiSeenCount(state, action.count);
            case UserActionTypes.SHOW_SVG_CONTENT:
                return this.showSVGContent(state);
            case UserActionTypes.HIDE_SVG_CONTENT:
                return this.hideSVGContent(state);
            case UserActionTypes.HIDE_EMAIL_VERIFICATION_BANNER:
                return this.hideEmailVerificationBanner(state);

            case UserActionTypes.UPDATE_PLAN_DATA:
                return this.updatePlanData(state, action);

            case UserActionTypes.INCREASE_CREATE_SECTION_PROMOTION_COUNTER:
                return this.increaseCreateSectionPromotionCounter(state);

            case UserActionTypes.INCREASE_ADD_TAG_PROMOTION_COUNTER:
                return this.increaseAddTagPromotionCounter(state);

            case UserActionTypes.INCREASE_SET_AS_VARIANT_PROMOTION_COUNTER:
                return this.increaseSetAsVariantPromotionCounter(state);

            default:
                return state;
        }
    }
}

export default UserStore;
export { State as UserStoreState };
