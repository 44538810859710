/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import BarrelActionTypes from "../BarrelActionTypes";
import AppActionTypes from "../../app/AppActionTypes";
import ComponentPropertyActionTypes from "./ComponentPropertyActionTypes";
import * as ComponentPropertyActionPayloads from "./ComponentPropertyActionPayloads";
import { AllPayloads } from "../../payloads";

interface State {
    highlightedLayers: string[];
}

class ComponentPropertyStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            highlightedLayers: []
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    highlightComponentPropertyLayers(state: State, {
        sourceIds
    }: ComponentPropertyActionPayloads.HighlightComponentPropertyLayers): State {
        return {
            ...state,
            highlightedLayers: sourceIds
        };
    }

    resetHighlightedComponentPropertyLayers(state: State): State {
        return {
            ...state,
            highlightedLayers: []
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
            case BarrelActionTypes.RESET:
                return this.reset();
            case ComponentPropertyActionTypes.HIGHLIGHT_COMPONENT_PROPERTY_LAYERS:
                return this.highlightComponentPropertyLayers(state, action);
            case ComponentPropertyActionTypes.RESET_HIGHLIGHTED_COMPONENT_PROPERTY_LAYERS:
                return this.resetHighlightedComponentPropertyLayers(state);
            default:
                return state;
        }
    }
}

export default ComponentPropertyStore;
export { State as ComponentPropertyStoreState };
