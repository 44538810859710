import * as preact from "preact";

import NamingConvention from "../../../../../foundation/model/NamingConvention";
import { getSupportedDensityScales, getOptimizedAssetsSelectedNames } from "../../../../../foundation/utils/asset";
import { getDensityScaleRepresentation, stopPropagation } from "../../../../../foundation/utils/legacy";
import noderef from "../../../../../foundation/utils/noderef";
import Menu from "../../../../../library/v2/Menu";

import "./style.css";

const NAMING_OPTIONS = [
    { content: "Default", value: NamingConvention.Default },
    { content: "camelCase", value: NamingConvention.CamelCase },
    { content: "snake_case", value: NamingConvention.SnakeCase },
    { content: "kebab-case", value: NamingConvention.KebabCase },
    { content: "Original", value: NamingConvention.Original }
];

const MIN_NUMBER_OF_ASSET_DENSITY_SCALE_PREFS = 1;

class AssetSettings extends preact.Component {
    constructor(props) {
        super(props);

        this.handlePositionMenu = this.handlePositionMenu.bind(this);
        this.handleDensityScaleOptionsChange = this.handleDensityScaleOptionsChange.bind(this);
        this.handleOptimizedAssetsOptionsChange = this.handleOptimizedAssetsOptionsChange.bind(this);
    }

    static densityScaleOptionVisible(projectType) {
        return projectType !== "base";
    }

    static namingOptionVisible(projectType) {
        return projectType !== "android";
    }

    static optimizedAssetsOptionVisible(projectType) {
        return projectType !== "base";
    }

    get optimizedAssetsSelectedOptionValues() {
        const {
            settings: {
                showOptimizedSVG,
                showOptimizedPNG,
                showOptimizedJPG
            }
        } = this.props;

        return getOptimizedAssetsSelectedNames({
            showOptimizedSVG,
            showOptimizedPNG,
            showOptimizedJPG
        });
    }

    handleDensityScaleOptionsChange(value) {
        this.props.onAssetDensityOptionChange("png", value);
    }

    handlePositionMenu(menu) {
        const targetRect = this.nodeRefs.target.getBoundingClientRect();
        const rect = menu.getBoundingClientRect();
        const offset = 12;

        const menuTop = targetRect.top;
        const menuLeft = targetRect.left - rect.width;
        let xTranslation;
        let yTranslation;

        if (menuTop < offset) {
            yTranslation = offset;
        } else if (menuTop + rect.height + offset > window.innerHeight) {
            yTranslation = window.innerHeight - rect.height - offset;
        } else {
            yTranslation = menuTop;
        }

        if (menuLeft + rect.width + offset > window.innerWidth) {
            xTranslation = window.innerWidth - rect.width - offset;
        } else {
            xTranslation = menuLeft;
        }

        menu.style.transform = `translate3d(${xTranslation}px, ${yTranslation}px, 0)`;
    }

    handleOptimizedAssetsOptionsChange(value) {
        const { onShowOptimizedSVGChange, onShowOptimizedPNGChange, onShowOptimizedJPGChange } = this.props;
        let [changedOption] = value.filter(item => !this.optimizedAssetsSelectedOptionValues.includes(item));
        let newValue = true;

        if (!changedOption) {
            [changedOption] = this.optimizedAssetsSelectedOptionValues.filter(item => !value.includes(item));
            newValue = false;
        }

        if (changedOption === "SVG") {
            onShowOptimizedSVGChange(newValue);
        } else if (changedOption === "PNG") {
            onShowOptimizedPNGChange(newValue);
        } else if (changedOption === "JPG") {
            onShowOptimizedJPGChange(newValue);
        }
    }

    render() {
        const {
            settings: { assetDensityScalePrefs, namingConvention },
            projectType,
            open,
            target,
            onClose
        } = this.props;

        const settings = [];

        if (AssetSettings.densityScaleOptionVisible(projectType)) {
            settings.push(
                <Menu.MultiSelectSubmenu
                    content="Bitmap scales"
                    checkedOptionValues={assetDensityScalePrefs.png}
                    minNumberOfCheckedOptions={MIN_NUMBER_OF_ASSET_DENSITY_SCALE_PREFS}
                    options={
                        getSupportedDensityScales(projectType).map(densityScale => ({
                            value: densityScale,
                            content: getDensityScaleRepresentation(projectType, densityScale)
                        }))
                    }
                    onChange={this.handleDensityScaleOptionsChange}/>
            );
        }

        if (AssetSettings.namingOptionVisible(projectType)) {
            settings.push(
                <Menu.SingleSelectSubmenu
                    content="Naming"
                    checkedValue={namingConvention}
                    options={NAMING_OPTIONS}
                    onChange={this.props.onNamingConventionChange}/>
            );
        }

        if (AssetSettings.optimizedAssetsOptionVisible(projectType)) {
            settings.push(
                <Menu.MultiSelectSubmenu
                    content="Optimize assets"
                    checkedOptionValues={this.optimizedAssetsSelectedOptionValues}
                    options={[{
                        content: "PNG",
                        value: "PNG"
                    }, {
                        content: "JPG",
                        value: "JPG"
                    }, {
                        content: "SVG",
                        value: "SVG"
                    }]}
                    footer={(
                        <>
                            <Menu.Divider/>
                            <Menu.Link href="https://zpl.io/optimized-assets">About optimized assets</Menu.Link>
                        </>
                    )}
                    onChange={this.handleOptimizedAssetsOptionsChange}/>
            );
        }

        return (
            <div class="assetSettings">
                <div class="target" ref={noderef(this, "target")}>
                    {target}
                </div>
                {open && (
                    <Menu.Overlay
                        class="assetSettingsMenu"
                        overlayDivProps={{
                            onClick: stopPropagation
                        }}
                        onClose={onClose}
                        onPositionMenu={this.handlePositionMenu}>
                        {settings}
                    </Menu.Overlay>
                )}
            </div>
        );
    }
}

export default AssetSettings;
