/* eslint-disable class-methods-use-this */
import { Dot, Annotation } from "../../../foundation/api/model";
import CreateDotParameters from "../../../foundation/api/model/dots/CreateDotParameters";
import Notification from "../../../foundation/api/model/notifications/Notification";

import DotComment from "../../../foundation/model/DotComment";
import DraftAnnotation from "../../../foundation/model/DraftAnnotation";
import DraftDot from "../../../foundation/model/DraftDot";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import ActivityType from "../activities/ActivityType";

const DOT_RELATED_NOTIFICATIONS = [
    ActivityType.CreateDot,
    ActivityType.CreateDotMention,
    ActivityType.CreateDotCommentMention,
    ActivityType.CreateDotComment,
    ActivityType.ResolveDot
];

type DotType = Dot | DraftDot | (CreateDotParameters & { _id: string; });
type AnnotationType = Annotation | DraftAnnotation;

interface ScreenDotsAndAnnotations<T> {
    [pid: string]: {
        [sid: string]: T[];
    };
}

interface DotsAndAnnotationsPayload {
    pid: string;
    sid: string;
    daid?: string;
}

interface ProjectDotsAndAnnotationsPayload {
    pid: string;
}

class DotsAndAnnotationsReducer<
    State extends { [k in propertyName]: ScreenDotsAndAnnotations<Type>; },
    Type extends DotType | AnnotationType,
    propertyName extends ("dots" | "annotations")
> {
    propertyName: propertyName;

    constructor(options: { propertyName: propertyName; }) {
        this.propertyName = options.propertyName;
    }

    private getDotsAndAnnotations(
        state: State,
        { pid, sid }: DotsAndAnnotationsPayload
    ): Type[] | undefined {
        if (!pid || !sid) {
            return;
        }

        return state[this.propertyName]?.[pid]?.[sid];
    }

    private getSingleDotAndAnnotation(
        state: State,
        { pid, sid, daid }: DotsAndAnnotationsPayload
    ): Type | undefined {
        if (!pid || !sid || !daid) {
            return;
        }

        const correspondingDotsAndAnnotations = this.getDotsAndAnnotations(state, { pid, sid });
        return correspondingDotsAndAnnotations?.find(({ _id }) => daid === _id);
    }

    setDotsAndAnnotations(
        state: State,
        { pid, sid, data }: DotsAndAnnotationsPayload & { data: Type[]; }
    ): State {
        if (!pid || !sid) {
            return state;
        }

        return {
            ...state,
            [this.propertyName]: {
                ...state[this.propertyName],
                [pid]: {
                    ...state[this.propertyName][pid],
                    [sid]: this.propertyName === "dots"
                        ? data.map(dotsAndAnnotationsData => ({
                            ...dotsAndAnnotationsData,
                            screenId: sid
                        })) : data
                }
            }
        };
    }

    setProjectDotsAndAnnotations(
        state: State,
        { pid, data }: ProjectDotsAndAnnotationsPayload & { data: Record<string, Dot[]>; }
    ): State {
        if (!pid) {
            return state;
        }

        return {
            ...state,
            [this.propertyName]: {
                ...state[this.propertyName],
                [pid]: {
                    ...state[this.propertyName][pid],
                    ...data
                }
            }
        };
    }

    insertDotAndAnnotation(
        state: State,
        { pid, sid, daid, data }: DotsAndAnnotationsPayload & { data: Partial<Type>; }
    ): State {
        if (!pid || !sid) {
            return state;
        }

        const correspondingDotsAndAnnotations = this.getDotsAndAnnotations(state, { pid, sid });

        if (!correspondingDotsAndAnnotations) {
            return state;
        }

        const existingDotAndAnnotation = this.getSingleDotAndAnnotation(state, { pid, sid, daid: daid ?? data._id });

        if (existingDotAndAnnotation) {
            return state;
        }

        const insertData = [...correspondingDotsAndAnnotations, (data as Type)];

        return this.setDotsAndAnnotations(state, { pid, sid, data: insertData });
    }

    updateDotAndAnnotation(
        state: State,
        { pid, sid, daid, data }: DotsAndAnnotationsPayload & { data: Partial<Type>; }
    ): State {
        if (!pid || !sid) {
            return state;
        }

        const correspondingDotsAndAnnotations = this.getDotsAndAnnotations(state, { pid, sid });

        if (!correspondingDotsAndAnnotations) {
            return state;
        }

        const existingDotAndAnnotation = this.getSingleDotAndAnnotation(state, { pid, sid, daid: daid ?? data._id });

        if (!existingDotAndAnnotation) {
            return state;
        }

        const updateData = correspondingDotsAndAnnotations.map(dotAndAnnotation => (
            dotAndAnnotation._id === daid
                ? { ...dotAndAnnotation, ...data }
                : dotAndAnnotation
        ));

        return this.setDotsAndAnnotations(state, { pid, sid, data: updateData });
    }

    removeDotAndAnnotation(
        state: State,
        { pid, sid, daid }: { pid: string; sid: string; daid: string; }
    ): State {
        if (!pid || !sid) {
            return state;
        }

        const correspondingDotsAndAnnotations = this.getDotsAndAnnotations(state, { pid, sid });

        if (!correspondingDotsAndAnnotations) {
            return state;
        }

        const removeData = correspondingDotsAndAnnotations.filter(({ _id }) => _id !== daid);

        return this.setDotsAndAnnotations(state, { pid, sid, data: removeData });
    }

    insertDotComment(state: State,
        { pid, sid, daid, comment }: { pid: string; sid: string; daid: string; comment: DotComment; }): State {
        const correspondingDot = this.getSingleDotAndAnnotation(state, { pid, sid, daid });

        if (!correspondingDot) {
            return state;
        }

        const existingComment = (correspondingDot as Dot)?.comments?.find(cm => cm._id === comment._id);

        if (existingComment) {
            return state;
        }

        const correspondingComments = (correspondingDot as Dot)?.comments ?? [];
        const updateData = { comments: [...correspondingComments, comment] } as unknown as Partial<Type>;

        return this.updateDotAndAnnotation(state, { pid, sid, daid, data: updateData });
    }

    updateDotComment(state: State, {
        pid, sid, daid, cmid, commentData
    }: { pid: string; sid: string; daid: string; cmid: string; commentData: Partial<DotComment>; }): State {
        const correspondingDot = this.getSingleDotAndAnnotation(state, { pid, sid, daid });

        if (!correspondingDot) {
            return state;
        }

        const updateData = {
            comments: (correspondingDot as Dot)?.comments?.map(comment => {
                if (comment._id !== cmid) {
                    return comment;
                }

                return { ...comment, ...commentData };
            })
        } as unknown as Partial<Type>;

        return this.updateDotAndAnnotation(state, { pid, sid, daid, data: updateData });
    }

    updateCommentReadStatuses(state: State, {
        pid, read, dots
    }: {
        pid: string;
        read: boolean;
        dots: {
            did: string;
            sid: string;
        }[]; }): State {
        const dotData = { ...state[this.propertyName][pid] };

        dots.forEach(dot => {
            const correspondingDot = this.getSingleDotAndAnnotation(state, { pid, sid: dot.sid, daid: dot.did });

            if (!correspondingDot) {
                return state;
            }

            dotData[dot.sid] = dotData[dot.sid].map(dotWithComment => {
                if (dotWithComment._id === dot.did) {
                    return {
                        ...dotWithComment,
                        comments: (dotWithComment as Dot)?.comments?.map(comment => ({ ...comment, isUnread: !read }))
                    };
                }

                return dotWithComment;
            });
        });

        return {
            ...state,
            [this.propertyName]: {
                ...state[this.propertyName],
                [pid]: {
                    ...dotData
                }
            }
        };
    }

    updateNotificationCommentReadStatuses(state: State, {
        notification,
        eventIds,
        markedAsRead
    }: {
        notification: Notification;
        eventIds: string[];
        markedAsRead: boolean;
    }): State {
        const dotMap: BasicRecord<BasicRecord<Type[]>> = {};
        eventIds.forEach(eventId => {
            const notificationEvent = notification.events.find(event => event._id === eventId);

            if (
                notificationEvent?.project?._id &&
                notificationEvent?.screen?._id &&
                notificationEvent?.dot?._id &&
                DOT_RELATED_NOTIFICATIONS.includes(notification.actionName)
            ) {
                if (!state[this.propertyName][notificationEvent.project._id]?.[notificationEvent.screen._id]) {
                    return state;
                }

                dotMap[notificationEvent.project._id] = state[this.propertyName][notificationEvent.project._id];
                dotMap[notificationEvent.project._id][notificationEvent.screen._id] =
                state[this.propertyName][notificationEvent.project._id]?.[notificationEvent.screen._id].map(dot => {
                    if (dot._id === notificationEvent.dot?._id) {
                        if (!notificationEvent.comment) {
                            return {
                                ...dot,
                                comments: (dot as Dot)?.comments?.map(comment =>
                                    ({ ...comment, isUnread: !markedAsRead }))
                            };
                        }

                        return {
                            ...dot,
                            comments: (dot as Dot)?.comments?.map(comment => {
                                if (comment._id === notificationEvent.comment?._id) {
                                    return ({ ...comment, isUnread: !markedAsRead });
                                }

                                return comment;
                            })
                        };
                    }

                    return dot;
                });
            }
        });

        return {
            ...state,
            [this.propertyName]: {
                ...state[this.propertyName],
                ...dotMap
            }
        };
    }

    removeDotComment(state: State, {
        pid, sid, daid, cmid
    }: { pid: string; sid: string; daid: string; cmid: string; }): State {
        const correspondingDot = this.getSingleDotAndAnnotation(state, { pid, sid, daid });

        if (!correspondingDot) {
            return state;
        }

        const updateData = {
            comments: (correspondingDot as Dot)?.comments?.filter(cm => cm._id !== cmid)
        } as unknown as Partial<Type>;
        return this.updateDotAndAnnotation(state, { pid, sid, daid, data: updateData });
    }
}

export default DotsAndAnnotationsReducer;
