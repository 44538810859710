import { h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

import electron from "../../../../../foundation/electron";
import { electronAppSupports } from "../../../../../foundation/electron/electronAppSupports";
import { checkUsernameAvailable, getUserStatus, loginUser } from "../../../../../foundation/api";
import ClientError from "../../../../../foundation/errors/clientError";
import ServerError from "../../../../../foundation/errors/serverError";
import { handleError } from "../../../../../foundation/errors/errors";
import Mailcheck from "../../../../../foundation/mailcheck";
import { urlResolve } from "../../../../../foundation/utils/url";
import { EXTENSIONS_WEB_URL } from "../../../../../foundation/constants";
import Cookies from "../../../../../foundation/cookies";
import * as dialog from "../../../../../foundation/dialog";

import LoginParams from "../../types/LoginParams";
import FormFieldStatus from "../../types/FormFieldStatus";
import MailcheckSuggestion from "../../types/MailcheckSuggestion";

import LoadingOverlay from "../../../../../library/LoadingOverlay";
import TextFormField from "../../../../../library/FormField/TextFormField";
import Button from "../../../../../library/Button";
import TipText from "../TipText/TipText";
import ToggleShowPassword from "../../ToggleShowPassword";

import "./style.css";

const MIN_LENGTH_FOR_HANDLE = 3;

interface Props {
    loginParams?: LoginParams;
    onHandleChange: (value: string) => void;
}

function LoginForm({
    loginParams,
    onHandleChange
}: Props) {
    const [handle, setHandle] = useState(loginParams?.email || loginParams?.username || "");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [handleStatus, setHandleStatus] = useState<FormFieldStatus>({ type: "empty" });
    const [passwordStatus, setPasswordStatus] = useState<FormFieldStatus>({ type: "empty" });
    const [loading, setLoading] = useState<boolean>(false);
    const handleElement = useRef<HTMLInputElement>(null);
    const passwordElement = useRef<HTMLInputElement>(null);
    const handleTimeoutRef = useRef<number>();

    // Since we need to check password after email changes (to reset incorrect password message)
    // We need to keep the ref to the password, and update its value whenever the value in the state changes
    // Why? Because React function components don't like setTimeout and does not give the actual state value inside setTimeout
    const passwordRef = useRef<string>("");
    useEffect(() => {
        passwordRef.current = password;
    }, [password]);

    useEffect(() => {
        onHandleChange(handle);
    }, [handle]);

    function handleHandleChange(event: Event) {
        const { value } = event.target as HTMLInputElement;
        setHandle(value);
        clearTimeout(handleTimeoutRef.current);
        handleTimeoutRef.current = window.setTimeout(() => checkHandleStatus(value), 1000);
    }

    function checkHandleStatus(value: string) {
        if (!value) {
            setHandleStatus({ type: "empty" });
            return;
        }

        /@/.test(value) ? checkEmail(value) : checkUsername(value);
    }

    async function checkUsername(value: string) {
        if (value.length > MIN_LENGTH_FOR_HANDLE) {
            try {
                await checkUsernameAvailable(encodeURIComponent(value));
                setHandleStatus({ type: "success" });
                checkPassword(passwordRef.current);
            } catch (error) {
                handleError(error, {
                    serverErrorHandler() {
                        setHandleStatus({ type: "error", explanation: "Something is wrong :(" });
                    },
                    clientErrorHandler: {
                        410() {
                            setHandleStatus({ type: "success" });
                            checkPassword(passwordRef.current);
                        }
                    }
                });
            }
        }
    }

    async function checkEmail(value: string) {
        const signupQueryParams = { source: loginParams?.source, email: value };

        try {
            const { status: userStatus } = await getUserStatus(value);
            if (userStatus === "verified" || userStatus === "registered") {
                setHandleStatus({ type: "success" });
                checkPassword(passwordRef.current);
            } else {
                setHandleStatus({
                    type: "error",
                    explanation: (
                        <span>
                            There is no user with this email.<br/>
                            You can <a href={urlResolve("signup", { query: signupQueryParams })} tabIndex={-1}>Register</a> right away.
                        </span>
                    )
                });
            }
        } catch (error) {
            handleError(error, {
                serverErrorHandler() { /* No-op */ },
                clientErrorHandler() {
                    let suggestedEmail = "";
                    Mailcheck.run({
                        email: handle,
                        suggested: (suggestion: MailcheckSuggestion) => {
                            suggestedEmail = suggestion.full;
                        }
                    });

                    setHandleStatus({
                        type: "error",
                        explanation: (
                            <span>
                                There is no user with this email.<br/>
                                You can <a href={urlResolve("signup", { query: signupQueryParams })} tabIndex={-1}>Register</a> right away.
                                {suggestedEmail && (
                                    <span>
                                        &nbsp;Or did you mean&nbsp;
                                        {/* eslint-disable-next-line react/jsx-no-bind */}
                                        <a href="#" onClick={ev => handleEmailSuggestionClick(ev, suggestedEmail)}>
                                            {suggestedEmail}
                                        </a>?
                                    </span>
                                )}
                            </span>
                        )
                    });
                }
            });
        }
    }

    function handleIncorrectHandlePasswordCombination() {
        let suggestedEmail = "";
        if (/@/.test(handle)) {
            Mailcheck.run({
                email: handle,
                suggested: (suggestion: MailcheckSuggestion) => {
                    suggestedEmail = suggestion.full;
                }
            });
        }

        setPasswordStatus({
            type: "error",
            explanation: (
                <span>
                    Your email or password is not correct.
                    {suggestedEmail && (
                        <span>
                            &nbsp;Did you mean&nbsp;
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <a href="#" onClick={ev => handleEmailSuggestionClick(ev, suggestedEmail)}>
                                {suggestedEmail}
                            </a>?
                        </span>
                    )}
                    <br/>
                    <a href={urlResolve("forgotPassword")} tabIndex={-1}>Forgot password?</a>
                </span>
            )
        });
    }

    interface MFAError {
        extra?: {
            token?: string;
            mfaSetupRequired?: string;
        };
    }

    function handleLoginError(error: MFAError | ClientError) {
        if (error.extra?.token) {
            if (electron && !electronAppSupports.mfaLogin) {
                dialog.create({
                    title: "Multi-factor Authentication isn't supported in this version",
                    message: "Your organization requires you to set up and use two-step verification while logging into your account. Please update your application to login with Multi-factor Authentication.",
                    done: {
                        name: "Got it"
                    }
                });
            } else {
                Cookies.set("mfaToken", error.extra!.token, {});
                if (error.extra!.mfaSetupRequired) {
                    location.assign(urlResolve("multi-factor-authentication-setup"));
                } else {
                    location.assign(urlResolve("multi-factor-authentication-login"));
                }
            }
        } else {
            handleIncorrectHandlePasswordCombination();
        }
    }

    function checkPassword(value: string) {
        if (value.length < 6) {
            setPasswordStatus({ type: "tip", explanation: "Type 6 characters or more" });
            return;
        }

        setPasswordStatus({ type: "success" });
    }

    function handlePasswordChange(event: Event) {
        const { value } = event.target as HTMLInputElement;
        setPassword(value);
        checkPassword(value);
    }

    function handleEmailSuggestionClick(event: Event, emailSuggestion: string) {
        event.preventDefault();
        setHandle(emailSuggestion);
        checkHandleStatus(emailSuggestion);
    }

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    function handleBlur() {
        checkHandleStatus(handle);
    }

    function handleClickLoginWithSSO(event: Event) {
        if (!electron) {
            return;
        }

        if (electronAppSupports.newSSOFlow) {
            setLoading(true);
            electron.actions.onceSsoFlowInitiated(() => {
                setLoading(false);
            });
        } else {
            event.preventDefault();
            dialog.create({
                title: "Login with SSO isn't supported in this version anymore",
                message: "Please update your application to login with SSO.",
                done: {
                    name: "Got it"
                }
            });
        }
    }

    async function handleLogin(event: Event) {
        event.preventDefault();

        if (!isFormValid()) {
            return;
        }

        setLoading(true);

        try {
            const response = await loginUser(handle, password);
            Cookies.set("userToken", response.token, loginParams?.expireAfterSession ? { expires: 1 } : {});
            delete loginParams?.expireAfterSession;
            delete response.token;
            localStorage.setItem("user", JSON.stringify(response));

            if (document.body.dataset["client"] === "extensions") {
                window.parent.postMessage({ user: response }, EXTENSIONS_WEB_URL);
                return;
            }

            sessionStorage.removeItem("mxShouldAlias");
            Cookies.remove("mxShouldAlias");

            sessionStorage.setItem("mxMasterEvent", "Logged in");

            Zeplin.redirectBack();
        } catch (error) {
            const zplError = error as (ClientError | ServerError);

            handleError(zplError, {
                clientErrorHandler: {
                    403: handleLoginError,
                    412: handleIncorrectHandlePasswordCombination,
                    423() {
                        const { title, message } = zplError as ClientError;
                        dialog.create({ title, message });
                    },
                    428() {
                        if (electron && electronAppSupports.newSSOFlow) {
                            electron.actions.onceSsoFlowInitiated(() => {
                                setLoading(false);
                            });
                        } else if (electron && !electronAppSupports.newSSOFlow) {
                            dialog.create({
                                title: "Your organization requires SSO for login",
                                message: "Please update your application to login with SSO.",
                                done: {
                                    name: "Got it"
                                }
                            });
                            return;
                        }
                        const params = { query: { source: loginParams?.source } };
                        if (/@/.test(handle)) {
                            Object.assign(params.query, { email: handle });
                        }

                        location.assign(urlResolve("sso-required", params));
                    }
                }
            });

            if (zplError.status === 428 && electron && electronAppSupports.newSSOFlow) {
                // here means we'll pass the job to the windows app it'll fetch redeemerId, we need the loading ripple
                return;
            }

            setLoading(false);
        }
    }

    function isFormValid() {
        const statusTypesBlockingSubmit = ["error", "warning", "empty", "tip"];
        if (statusTypesBlockingSubmit.includes(handleStatus.type) ||
            statusTypesBlockingSubmit.includes(passwordStatus.type)) {
            return false;
        }

        return true;
    }

    return (
        <form class="loginForm" onSubmit={handleLogin}>
            {loading && <LoadingOverlay/>}
            <TextFormField
                class="loginFormField"
                name="Handle"
                value={handle}
                placeholder="Work email"
                nativeProps={{
                    class: "loginFormInput",
                    autocapitalize: "off",
                    autocomplete: "email",
                    required: true,
                    ref: handleElement,
                    onInput: handleHandleChange
                }}
                maxLength={254}
                onBlur={handleBlur}
                shouldDisplayCountDown={false}
                shouldAlwaysDisplayTipText={true}
                tipText={<TipText type={handleStatus.type} explanation={handleStatus.explanation}/>}
                autoFocus/>
            <TextFormField
                class="loginFormField"
                name="Password"
                value={password}
                placeholder="Password"
                nativeProps={{
                    class: "loginFormInput",
                    type: showPassword ? "text" : "password",
                    pattern: ".{6,100}",
                    required: true,
                    minlength: 6,
                    autocomplete: "password",
                    ref: passwordElement,
                    onInput: handlePasswordChange
                }}
                adornment={!!password && (
                    <ToggleShowPassword
                        showPassword={showPassword}
                        onClick={toggleShowPassword}/>
                )}
                maxLength={100}
                shouldDisplayCountDown={false}
                shouldAlwaysDisplayTipText={true}
                tipText={<TipText type={passwordStatus.type} explanation={passwordStatus.explanation}/>}/>
            <Button
                disabled={!isFormValid()}
                class="loginFormButton"
                type="submit"
                primary>
                Login
            </Button>
            <div class="loginWithSSOContainer">
                <a
                    href={urlResolve("login-sso", { query: { source: loginParams?.source } })}
                    onClick={handleClickLoginWithSSO}>
                    Login with SSO
                </a>
            </div>
        </form>
    );
}

export default LoginForm;
