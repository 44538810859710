/* eslint-disable class-methods-use-this */
import { CreateFlowDotParameters } from "../../../../foundation/api/model";
import FlowDot, { FlowDotComment, FlowDraftDot } from "../../../../foundation/api/model/flow/FlowDot";

type DotType = FlowDot | FlowDraftDot | (CreateFlowDotParameters & { _id: string; });

interface FlowDots<T> {
    [pid: string]: {
        [flid: string]: T[];
    };
}

interface DotsPayload {
    pid: string;
    flid: string;
    did?: string;
}

class DotsReducer<
    State extends { [k in "dots"]: FlowDots<Type>; },
    Type extends DotType
> {
    private getDots(
        state: State,
        { pid, flid }: DotsPayload
    ): Type[] | undefined {
        if (!pid || !flid) {
            return;
        }

        return state.dots?.[pid]?.[flid];
    }

    private getSingleDot(
        state: State,
        { pid, flid, did }: DotsPayload
    ): Type | undefined {
        if (!pid || !flid || !did) {
            return;
        }

        const correspondingDots = this.getDots(state, { pid, flid });
        return correspondingDots?.find(({ _id }) => did === _id);
    }

    setDots(
        state: State,
        { pid, flid, dots }: DotsPayload & { dots: DotType[]; }
    ): State {
        if (!pid || !flid) {
            return state;
        }

        return {
            ...state,
            dots: {
                ...state.dots,
                [pid]: {
                    ...state.dots[pid],
                    [flid]: dots
                }
            }
        };
    }

    insertDot(
        state: State,
        { pid, flid, did, dot }: DotsPayload & { dot: Partial<DotType>; }
    ): State {
        if (!pid || !flid) {
            return state;
        }
        const correspondingDots = this.getDots(state, { pid, flid }) || [];
        const existingDot = this.getSingleDot(state, { pid, flid, did: did ?? dot._id });

        if (existingDot) {
            return state;
        }

        const insertData = [...correspondingDots, dot] as FlowDot[];
        return this.setDots(state, { pid, flid, dots: insertData });
    }

    updateDot(
        state: State,
        { pid, flid, did, dot }: DotsPayload & { dot: Partial<DotType>; }
    ): State {
        if (!pid || !flid) {
            return state;
        }

        const correspondingDots = this.getDots(state, { pid, flid });

        if (!correspondingDots) {
            return state;
        }

        const existingDot = this.getSingleDot(state, { pid, flid, did: did ?? dot._id });

        if (!existingDot) {
            return state;
        }

        const updateData = correspondingDots.map(cur => (
            cur._id === did
                ? { ...cur, ...dot }
                : cur
        ));

        return this.setDots(state, { pid, flid, dots: updateData });
    }

    removeDot(
        state: State,
        { pid, flid, did }: { pid: string; flid: string; did: string; }
    ): State {
        if (!pid || !flid) {
            return state;
        }

        const correspondingDots = this.getDots(state, { pid, flid });

        if (!correspondingDots) {
            return state;
        }

        const removeData = correspondingDots.filter(({ _id }) => _id !== did) as FlowDot[];

        return this.setDots(state, { pid, flid, dots: removeData });
    }

    insertDotComment(state: State,
        { pid, flid, did, comment }: { pid: string; flid: string; did: string; comment: FlowDotComment; }): State {
        const correspondingDot = this.getSingleDot(state, { pid, flid, did });

        if (!correspondingDot) {
            return state;
        }

        const existingComment = (correspondingDot as FlowDot)?.comments?.find(cm => cm._id === comment._id);

        if (existingComment) {
            return state;
        }

        const correspondingComments = (correspondingDot as FlowDot)?.comments ?? [];
        const updateData = { comments: [...correspondingComments, comment] } as unknown as Partial<DotType>;

        return this.updateDot(state, { pid, flid, did, dot: updateData });
    }

    updateDotComment(state: State, {
        pid, flid, did, cmid, commentData
    }: { pid: string; flid: string; did: string; cmid: string; commentData: Partial<FlowDotComment>; }): State {
        const correspondingDot = this.getSingleDot(state, { pid, flid, did });

        if (!correspondingDot) {
            return state;
        }

        const updateData = {
            comments: (correspondingDot as FlowDot)?.comments?.map(comment => {
                if (comment._id !== cmid) {
                    return comment;
                }
                return { ...comment, ...commentData };
            })
        } as unknown as Partial<FlowDot>;

        return this.updateDot(state, { pid, flid, did, dot: updateData });
    }

    removeDotComment(state: State, {
        pid, flid, did, cmid
    }: { pid: string; flid: string; did: string; cmid: string; }): State {
        const correspondingDot = this.getSingleDot(state, { pid, flid, did });

        if (!correspondingDot) {
            return state;
        }

        const updateData = {
            comments: (correspondingDot as FlowDot)?.comments?.filter(cm => cm._id !== cmid)
        } as unknown as Partial<FlowDot>;
        return this.updateDot(state, { pid, flid, did, dot: updateData });
    }
}

export default DotsReducer;
