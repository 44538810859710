enum HelpLinkIDs {
    QUICK_START = "helpLink/quickStart",
    WHAT_IS_ZEPLIN = "helpLink/whatIsZeplin",
    GETTING_STARTED_DESIGNERS = "helpLink/gettingStartedDesigners",
    GETTING_STARTED_DEVELOPERS = "helpLink/gettingStartedDevelopers",
    COLLABORATING_WITH_YOUR_TEAM = "helpLink/collaboratingWithYourTeam",
    DOWNLOADING_MAC_AND_WINDOWS = "helpLink/downloadingMacAndWindows",

    ACCOUNT_AND_PROFILE = "helpLink/accountAndProfile",
    CREATING_AN_ACCOUNT = "helpLink/creatingAnAccount",
    GOOGLE_SSO_WITH_ZEPLIN = "helpLink/googleSsoWithZeplin",
    RESEND_VERIFICATION_EMAIL = "helpLink/resendVerificationEmail",
    UPDATING_EMAIL_AND_USERNAME = "helpLink/updatingEmailAndUsername",
    SETTING_YOUR_PROFILE_PICTURE = "helpLink/settingYourProfilePicture",
    FORGOT_PASSWORD = "helpLink/forgotPassword",

    PRICING_AND_PLANS = "helpLink/pricingAndPlans",
    CHOOSING_SUBSCRIPTION_PLAN = "helpLink/choosingSubscriptionPlan",
    TEAM_PLAN = "helpLink/teamPlan",
    FAQ_TEAM_PLAN = "helpLink/faqTeamPlan",
    ORGANIZATION_PLAN = "helpLink/organizationPlan",
    FAQ_ORGANIZATION_PLAN = "helpLink/faqOrganizationPlan",
    FAQ_STARTER_AND_GROWING = "helpLink/faqStarterAndGrowing",
    PURCHASING_ZEPLIN_AS_RESELLER = "helpLink/purchasingZeplinAsReseller",
    VENDOR_DETAILS = "helpLink/vendorDetails",
    EDUCATION_NGO_OPEN_SOURCE = "helpLink/educationNgoOpenSource",
    COMPONENT_LIMITS_BY_PLAN = "helpLink/componentLimitsByPlan",
    VERSION_LIMITS_BY_PLAN = "helpLink/versionLimitsByPlan",
    SCREEN_VARIANT_LIMITS_BY_PLAN = "helpLink/screenVariantLimitsByPlan",
    STYLEGUIDE_LIMITS_BY_PLAN = "helpLink/styleguideLimitsByPlan",

    WORKSPACE_AND_SUBSCRIPTION_MANAGEMENT = "helpLink/workspaceAndSubscriptionManagement",
    WORKSPACE_SETTINGS = "helpLink/workspaceSettings",
    ACCESS_WORKSPACE_BILLING = "helpLink/accessWorkspaceBilling",
    ADDING_SEATS = "helpLink/addingSeats",
    REMOVING_SEATS = "helpLink/removingSeats",
    INVITING_MEMBERS = "helpLink/invitingMembers",
    REMOVING_MEMBERS = "helpLink/removingMembers",
    MANAGING_MEMBERS_GRACE_PERIOD = "helpLink/managingMembersGracePeriod",
    ASSIGNING_ROLES = "helpLink/assigningRoles",
    MANAGING_ACCESS_LEVELS = "helpLink/managingAccessLevels",
    GROUPING_MEMBERS_BY_TAGS = "helpLink/groupingMembersByTags",
    GENERATE_REPORT = "helpLink/generateReport",
    TRANSFERRING_WORKSPACE = "helpLink/transferringWorkspace",
    LEAVING_WORKSPACE = "helpLink/leavingWorkspace",
    ASSIGNING_ROLES_ON_PLANS = "helpLink/assigningRolesOnPlans",
    BILLING_FAQ = "helpLink/billingFaq",
    UPDATING_INVOICE_AND_BILLING = "helpLink/updatingInvoiceAndBilling",
    UPDATING_CREDIT_CARD = "helpLink/updatingCreditCard",
    DOWNLOADING_SENDING_INVOICES = "helpLink/downloadingSendingInvoices",
    CHANGE_SUBSCRIPTION_BILLING_CYCLE = "helpLink/changeSubscriptionBillingCycle",
    UPGRADE_SUBSCRIPTION = "helpLink/upgradeSubscription",
    DOWNGRADE_SUBSCRIPTION = "helpLink/downgradeSubscription",
    HOW_PRORATION_WORKS = "helpLink/howProrationWorks",
    CANCELLING_SUBSCRIPTION = "helpLink/cancellingSubscription",
    TRANSFERRING_DEPRECATED_PLAN = "helpLink/transferringDeprecatedPlan",
    FIX_PAYMENT_ISSUES = "helpLink/fixPaymentIssues",

    FIGMA_INTEGRATION = "helpLink/figmaIntegration",
    GETTING_STARTED_FIGMA_PLUGIN = "helpLink/gettingStartedFigmaPlugin",
    SET_UP_FIGMA_PLUGIN = "helpLink/setUpFigmaPlugin",
    PUBLISH_DESIGNS_FROM_FIGMA = "helpLink/publishDesignsFromFigma",
    EXPORTING_ASSETS_FROM_FIGMA = "helpLink/exportingAssetsFromFigma",
    EXPORTING_COMPONENTS_FROM_FIGMA = "helpLink/exportingComponentsFromFigma",
    EXPORTING_COLORS_FROM_FIGMA = "helpLink/exportingColorsFromFigma",
    EXPORTING_TEXT_STYLES_FROM_FIGMA = "helpLink/exportingTextStylesFromFigma",
    REEXPORTING = "helpLink/reexporting",
    FIGMA_PLUGIN_TROUBLESHOOT = "helpLink/figmaPluginTroubleshoot",

    SKETCH_INTEGRATION = "helpLink/sketchIntegration",
    PUBLISH_DESIGNS_FROM_SKETCH = "helpLink/publishDesignsFromSketch",
    EXPORTING_COMPONENTS_FROM_SKETCH = "helpLink/exportingComponentsFromSketch",
    EXPORTING_ASSETS_FROM_SKETCH = "helpLink/exportingAssetsFromSketch",
    EXPORTING_ASSETS_FROM_SYMBOLS_IN_SKETCH = "helpLink/exportingAssetsFromSymbolsInSketch",
    EXPORTING_COLORS_FROM_SKETCH = "helpLink/exportingColorsFromSketch",
    EXPORTING_TEXT_STYLES_FROM_SKETCH = "helpLink/exportingTextStylesFromSketch",
    KEEPING_GROUPED_LAYER_NAMES = "helpLink/keepingGroupedLayerNames",
    IT_TAKES_TOO_LONG_SKETCH = "helpLink/itTakesTooLongSketch",
    FIX_SKETCH_ISSUES = "helpLink/fixSketchIssues",
    INSTALL_SKETCH_PLUGIN = "helpLink/installSketchPlugin",

    ADOBE_XD_INTEGRATION = "helpLink/adobeXdIntegration",
    GETTING_STARTED_XD_INTEGRATION = "helpLink/gettingStartedXdIntegration",
    PUBLISH_DESINGS_FROM_XD = "helpLink/publishDesingsFromXd",
    EXPORTING_ASSETS_FROM_XD = "helpLink/exportingAssetsFromXd",
    EXPORTING_COMPONENTS_FROM_XD = "helpLink/exportingComponentsFromXd",
    EXPORTING_COLORS_FROM_XD = "helpLink/exportingColorsFromXd",
    EXPORTING_TEXT_STYLES_FROM_XD = "helpLink/exportingTextStylesFromXd",
    LIMITATIONS_XD_PLUGIN = "helpLink/limitationsXdPlugin",
    FIX_XD_ISSUES = "helpLink/fixXdIssues",

    PHOTOSHOP_INTEGRATION = "helpLink/photoshopIntegration",
    PUBLISH_DESIGNS_FROM_PHOTOSHOP = "helpLink/publishDesignsFromPhotoshop",
    EXPORTING_ASSETS_FROM_PHOTOSHOP = "helpLink/exportingAssetsFromPhotoshop",
    WORKING_WITH_ARTBOARDS_PHOTOSHOP = "helpLink/workingWithArtboardsPhotoshop",
    KEEPING_GROUPED_TOGETHER_PHOTOSHOP = "helpLink/keepingGroupedTogetherPhotoshop",
    FIX_NO_USER_SLICES_ISSUE = "helpLink/fixNoUserSlicesIssue",
    SET_UP_PROXY_PHOTOSHOP_MAC = "helpLink/setUpProxyPhotoshopMac",
    SET_UP_PROXY_PHOTOSHOP_WINDOWS = "helpLink/setUpProxyPhotoshopWindows",
    INSTALL_PHOTOSHOP_MAC = "helpLink/installPhotoshopMac",
    INSTALL_PHOTOSHOP_WINDOWS = "helpLink/installPhotoshopWindows",

    PROJECTS_AND_SCREENS = "helpLink/projectsAndScreens",
    CREATING_PROJECT = "helpLink/creatingProject",
    CHOOSING_CORRECT_TYPE_AND_DENSITY = "helpLink/choosingCorrectTypeAndDensity",
    TRACK_PROGRESS_WITH_PROJECT_STATUS = "helpLink/trackProgressWithProjectStatus",
    PIN_PROJECTS = "helpLink/pinProjects",
    ORGANIZING_PROJECTS_WITH_SECTIONS = "helpLink/organizingProjectsWithSections",
    ADD_DESCRIPTION_STYLEGUIDE_LINKS_TO_SECTIONS = "helpLink/addDescriptionStyleguideLinksToSections",
    ADD_USER_TO_PROJECT = "helpLink/addUserToProject",
    REMOVE_USER_FROM_PROJECT = "helpLink/removeUserFromProject",
    CHANGE_PROJECT_THUMBNAIL = "helpLink/changeProjectThumbnail",
    RENAME_PROJECT = "helpLink/renameProject",
    MAKING_COPY_OF_PROJECT = "helpLink/makingCopyOfProject",
    TRANSFERRING_PROJECT = "helpLink/transferringProject",
    ARCHIVING_PROJECT = "helpLink/archivingProject",
    ACTIVATING_PROJECT = "helpLink/activatingProject",
    LEAVING_PROJECT = "helpLink/leavingProject",
    DELETING_PROJECT = "helpLink/deletingProject",
    UPDATING_SCREEN = "helpLink/updatingScreen",
    RENAMING_SCREEN = "helpLink/renamingScreen",
    STORING_SCREEN_VERSIONS = "helpLink/storingScreenVersions",
    IMPORTING_IMAGES_AS_SCREENS = "helpLink/importingImagesAsScreens",
    GETTING_STARTED_WITH_SCREEN_VARIANTS = "helpLink/gettingStartedWithScreenVariants",
    MOVING_SCREENS_BETWEEN_PROJECTS = "helpLink/movingScreensBetweenProjects",
    ORGANIZING_SCREENS_WITH_SECTIONS = "helpLink/organizingScreensWithSections",
    USING_SECTIONS_AND_TAGS = "helpLink/usingSectionsAndTags",
    FILTERING_SCREENS_USING_TAGS = "helpLink/filteringScreensUsingTags",
    DOWNLOADING_SCREENS_AS_IMAGES = "helpLink/downloadingScreensAsImages",
    DELETING_SCREEN = "helpLink/deletingScreen",

    COLLABORATION = "helpLink/collaboration",
    SHARINGS_DESIGNS_AND_STYLEGUDE_PUBLICLY = "helpLink/sharingsDesignsAndStylegudePublicly",
    SHARING_APP_URIS = "helpLink/sharingAppUris",
    STAGE_MODE = "helpLink/stageMode",
    HOTSPOTS_IN_STAGE_MODE = "helpLink/hotspotsInStageMode",
    FLOW_LINKS_IN_STAGE_MODE = "helpLink/flowLinksInStageMode",
    DESKTOP_AND_EMAIL_NOTIFICATIONS = "helpLink/desktopAndEmailNotifications",
    NOTIFICATIONS = "helpLink/notifications",
    GETTING_STARTED_WITH_COMMENTS = "helpLink/gettingStartedWithComments",
    GROUPING_COMMENTS_BY_COLORS = "helpLink/groupingCommentsByColors",
    VIEW_AND_FILTER_COMMENTS_BY_STATUS_AND_COLORS = "helpLink/viewAndFilterCommentsByStatusAndColors",
    GETTING_STARTED_WITH_ANNOTATIONS = "helpLink/gettingStartedWithAnnotations",
    ADDING_ANNOTATIONS_TO_SCREEN = "helpLink/addingAnnotationsToScreen",
    LINKING_ANNOTATIONS_TO_COMPONENTS = "helpLink/linkingAnnotationsToComponents",
    FILTERING_ANNOTATIONS_ON_SCREEN = "helpLink/filteringAnnotationsOnScreen",
    DELETING_ANNOTATION = "helpLink/deletingAnnotation",

    DEVELOPING_WITH_ZEPLIN = "helpLink/developingWithZeplin",
    DEVELOPING_IOS_PROJECTS = "helpLink/developingIosProjects",
    DEVELOPING_WEB_PROJECTS = "helpLink/developingWebProjects",
    DEVELOPING_ANDROID_PROJECTS = "helpLink/developingAndroidProjects",
    DOWNLOADING_ASSETS = "helpLink/downloadingAssets",
    CHANGE_DOWNLOAD_PATH_WINDOWS = "helpLink/changeDownloadPathWindows",
    AUTO_EXPORTED_ASSETS = "helpLink/autoExportedAssets",
    OPTIMIZED_ASSETS_IN_ZEPLIN = "helpLink/optimizedAssetsInZeplin",
    RENAMING_ASSETS = "helpLink/renamingAssets",
    EXPORT_DESIGN_TOKENS = "helpLink/exportDesignTokens",
    COMPARE_USING_POP_OUT = "helpLink/compareUsingPopOut",
    WORKING_WITH_PERCENTAGE_VALUES = "helpLink/workingWithPercentageValues",
    GETTING_STARTED_WITH_LAYOUT_SPECS = "helpLink/gettingStartedWithLayoutSpecs",
    INSPECT_AUTO_LAYOUT_PROPERTIES = "helpLink/inspectAutoLayoutProperties",
    INSPECT_CONSTRAINTS = "helpLink/inspectConstraints",
    ADDING_EXTENSION_TO_PROJECT = "helpLink/addingExtensionToProject",
    ADDING_EXTENSION_TO_STYLEGUIDE = "helpLink/addingExtensionToStyleguide",
    EXPORTING_CSS_AND_HTML = "helpLink/exportingCssAndHtml",
    EXPORTING_REACT_NATIVE_SNIPPETS = "helpLink/exportingReactNativeSnippets",
    BUILDING_AND_PUBLISHING_ZEPLIN_EXTENSION = "helpLink/buildingAndPublishingZeplinExtension",

    GLOBAL_STYLEGUIDES = "helpLink/globalStyleguides",
    ADVANTAGE_OF_STYLEGUIDES = "helpLink/advantageOfStyleguides",
    CREATING_STYLEGUIDE = "helpLink/creatingStyleguide",
    CHOOSING_STYLEGUIDE_TYPE = "helpLink/choosingStyleguideType",
    DEFINING_PARENT_AND_CHILD_STYLEGUIDES = "helpLink/definingParentAndChildStyleguides",
    STYLEGUIDE_THUMBNAIL = "helpLink/styleguideThumbnail",
    LINK_PROJECT_TO_STYLEGUIDE = "helpLink/linkProjectToStyleguide",
    EXPORTING_TO_STYLEGUIDE = "helpLink/exportingToStyleguide",
    CREATING_SPACING_SYSTEM = "helpLink/creatingSpacingSystem",
    ORGANIZE_COMPONENTS_USING_SECTIONS_AND_GROUPS = "helpLink/organizeComponentsUsingSectionsAndGroups",
    ORGANIZE_STYLEGUIDE_COLORS = "helpLink/organizeStyleguideColors",
    MODIFYING_STYLEGUIDE_TEXT_STYLES = "helpLink/modifyingStyleguideTextStyles",
    ENABLE_REM_UNIT = "helpLink/enableRemUnit",
    ADD_USER_TO_STYLEGUIDE = "helpLink/addUserToStyleguide",
    REMOVE_USER_FROM_STYLEGUIDE = "helpLink/removeUserFromStyleguide",
    MOVE_COLOR_TEXTSTYLES_BETWEEN_STYLEGUIDES = "helpLink/moveColorTextstylesBetweenStyleguides",
    EXPORT_SEMANTIC_COLORS = "helpLink/exportSemanticColors",
    RENAME_STYLEGUIDE = "helpLink/renameStyleguide",
    MAKING_COPY_OF_STYLEGUIDE = "helpLink/makingCopyOfStyleguide",
    TRANSFERRING_STYLEGUIDE = "helpLink/transferringStyleguide",
    ARCHIVING_STYLEGUIDE = "helpLink/archivingStyleguide",
    ACTIVATING_STYLEGUIDE = "helpLink/activatingStyleguide",
    DELETING_STYLEGUIDE = "helpLink/deletingStyleguide",

    COMPONENTS_IN_ZEPLIN = "helpLink/componentsInZeplin",
    INSPECTING_COMPONENT_IN_SCREEN = "helpLink/inspectingComponentInScreen",
    DISCOVERING_REUSABLE_COMPONENTS_IN_SCREEN = "helpLink/discoveringReusableComponentsInScreen",
    HIGHLIGHTING_COMPONENTS_IN_SCREENS = "helpLink/highlightingComponentsInScreens",
    COMPONENT_VARIANTS = "helpLink/componentVariants",
    COMPONENT_VARIANTS_DOCUMENT = "helpLink/componentVariantsDocument",
    SETTING_UP_COMPONENT_VARIANTS_FIGMA = "helpLink/settingUpComponentVariantsFigma",
    SETTING_UP_COMPONENT_VARIANTS_SKETCH = "helpLink/settingUpComponentVariantsSketch",
    SETTING_UP_COMPONENT_VARIANTS_XD = "helpLink/settingUpComponentVariantsXd",
    SETTING_UP_COMPONENT_VARIANTS_MANUALLY = "helpLink/settingUpComponentVariantsManually",
    VIEWING_COMPONENT_VARIANTS_WITHIN_DESINGS = "helpLink/viewingComponentVariantsWithinDesings",
    CREATING_GROUPS_WITHIN_VARIANT_SECTIONS = "helpLink/creatingGroupsWithinVariantSections",
    FILTERING_VARIANTS_BY_PROPERTIES = "helpLink/filteringVariantsByProperties",
    GENERATING_CSS_SNIPPETS_FROM_COMPONENT_VARIANTS = "helpLink/generatingCssSnippetsFromComponentVariants",
    CONNECTING_COMPONENTS = "helpLink/connectingComponents",
    ADDING_STORYBOOK_LINKS_TO_COMPONENTS = "helpLink/addingStorybookLinksToComponents",
    ADDING_STYLEGUIDIST_LINKS_TO_COMPONENTS = "helpLink/addingStyleguidistLinksToComponents",
    ADDING_CUSTOM_LINKS_TO_COMPONENTS = "helpLink/addingCustomLinksToComponents",
    ADDING_REPOSITORY_LINKS_TO_COMPONENTS = "helpLink/addingRepositoryLinksToComponents",
    ENABLING_LOCAL_CONFIGURATION = "helpLink/enablingLocalConfiguration",
    BUILDING_PLUGIN = "helpLink/buildingPlugin",
    GETTING_STARTED_WITH_STORYBOOK_INTEGRATION = "helpLink/gettingStartedWithStorybookIntegration",
    CONNECTING_STORYBOOK_INSTANCE_WITH_ZEPLIN = "helpLink/connectingStorybookInstanceWithZeplin",
    LINK_COMPONENTS_IN_ZEPLIN_WITH_STORIES = "helpLink/linkComponentsInZeplinWithStories",
    UNLINK_COMPONENTS_FROM_STORIES = "helpLink/unlinkComponentsFromStories",
    REMOVE_STORYBOOK_INTEGRATION_FROM_PROJECTS_AND_STYLEGUIDES = "helpLink/removeStorybookIntegrationFromProjectsAndStyleguides",
    TROUBLESHOOTING_TIPS_FOR_STORYBOOK_INTEGRATION = "helpLink/troubleshootingTipsForStorybookIntegration",
    WHY_STORYBOOK_INTEGRATION_DOESNT_REQUIRE_CLI = "helpLink/whyStorybookIntegrationDoesntRequireCli",

    FLOWS = "helpLink/flows",
    GETTING_STARTED_WITH_FLOWS = "helpLink/gettingStartedWithFlows",
    ADD_SCREENS_TO_FLOW = "helpLink/addScreensToFlow",
    ADD_SCREEN_VARIANTS_TO_FLOW = "helpLink/addScreenVariantsToFlow",
    ADD_MULTIPLE_COPIES_TO_FLOW = "helpLink/addMultipleCopiesToFlow",
    REMOVE_SCREENS_FROM_FLOW = "helpLink/removeScreensFromFlow",
    CONNECTING_SCREENS_WITHIN_FLOW = "helpLink/connectingScreensWithinFlow",
    DOCUMENTING_IN_A_FLOW_VIA_LABELS = "helpLink/documentingInAFlowViaLabels",
    ADD_COMMENTS_ON_FLOWS = "helpLink/addCommentsOnFlows",
    UPDATING_CONNECTOR_STYLES = "helpLink/updatingConnectorStyles",
    CREATING_GROUPS_WITHIN_FLOW = "helpLink/creatingGroupsWithinFlow",
    CREATING_BOARDS_WITHIN_FLOWS = "helpLink/creatingBoardsWithinFlows",
    LAYING_OUT_SCREENS_WITHIN_FLOW = "helpLink/layingOutScreensWithinFlow",
    NAVIGATING_BETWEEN_SCREENS_IN_FLOW = "helpLink/navigatingBetweenScreensInFlow",
    DOWNLOADING_FLOW_AS_PDF = "helpLink/downloadingFlowAsPdf",

    APPS_AND_WORKFLOW_INTEGRATIONS = "helpLink/appsAndWorkflowIntegrations",
    WORKFLOW_INTEGRATION = "helpLink/workflowIntegration",
    USING_THIRD_PARTY_APPS = "helpLink/usingThirdPartyApps",
    THIRD_PARTY_APP_PERMISSIONS = "helpLink/thirdPartyAppPermissions",
    INSTALLING_ZEPLIN_FOR_JIRA_APP = "helpLink/installingZeplinForJiraApp",
    CONNECTIONG_JIRA_TO_ZEPLIN_ACCOUNT = "helpLink/connectiongJiraToZeplinAccount",
    ENABLING_ZEPLIN_FOR_SPECIFIC_JIRA_PROJECTS = "helpLink/enablingZeplinForSpecificJiraProjects",
    ATTACHING_DESIGNS_TO_JIRA_ISSUES = "helpLink/attachingDesignsToJiraIssues",
    VIEWING_JIRA_ISSUES_IN_ZEPLIN = "helpLink/viewingJiraIssuesInZeplin",
    FILTERING_DESIGNS_BY_JIRA_ISSUES = "helpLink/filteringDesignsByJiraIssues",
    REMOVING_DESINGS_FROM_JIRA_ISSUES = "helpLink/removingDesingsFromJiraIssues",
    SLACK_INTEGRATION = "helpLink/slackIntegration",
    ADD_PROJECT_TO_SLACK = "helpLink/addProjectToSlack",
    ADD_STYLEGUIDE_TO_SLACK = "helpLink/addStyleguideToSlack",
    SLACK_PREFERENCES = "helpLink/slackPreferences",
    INSTALLING_ZEPLIN_FOR_MICROSOFT_TEAMS = "helpLink/installingZeplinForMicrosoftTeams",
    CONNECTING_PROJECT_STYLGUIDE_TO_MICROSOFT_TEAMS = "helpLink/connectingProjectStylguideToMicrosoftTeams",
    ADJUST_CHANNEL_NOTIFICATIONS = "helpLink/adjustChannelNotifications",
    CREATING_FIRST_WEBHOOK = "helpLink/creatingFirstWebhook",
    ALLOWLISTING_WEBHOOK_REQUESTS = "helpLink/allowlistingWebhookRequests",
    REMOVING_WEBHOOKS_CREATED_BY_ZEPLIN_APPS = "helpLink/removingWebhooksCreatedByZeplinApps",
    VISUAL_STUDIO_CODE_EXTENSION = "helpLink/visualStudioCodeExtension",
    ENABLING_ZEPLIN_POWER_UP_ON_TRELLO = "helpLink/enablingZeplinPowerUpOnTrello",

    TIPS_AND_TRICKS = "helpLink/tipsAndTricks",
    GETTING_STARTED_WITH_GLOBAL_SEARCH = "helpLink/gettingStartedWithGlobalSearch",
    PUBLISHING_DESIGNS_IN_ORDER = "helpLink/publishingDesignsInOrder",
    EXPORTING_DESIGNS_WITH_PROTOTYPING_LINKS = "helpLink/exportingDesignsWithPrototypingLinks",
    USING_MARKDOWN_IN_COMMENTS_AND_SECTIONS = "helpLink/usingMarkdownInCommentsAndSections",
    USING_MARKDOWN_IN_STYLEGUIDE_AND_SPACING_SYSTEM_DESCRIPTIONS = "helpLink/usingMarkdownInStyleguideAndSpacingSystemDescriptions",
    USE_QUICK_LOOK_TO_PREVIEW_THUMBNAILS = "helpLink/useQuickLookToPreviewThumbnails",
    CHANGING_COLOR_FORMATS = "helpLink/changingColorFormats",
    EMBED_SCREENS_ON_A_CONFLUENCE_PAGE_OR_JIRA_TICKET = "helpLink/embedScreensOnAConfluencePageOrJiraTicket",
    EMBED_ZEPLIN_DESIGNS_IN_NOTION = "helpLink/embedZeplinDesignsInNotion",
    KEYBOARD_SHORTCUTS_WINDOWS_WEB = "helpLink/keyboardShortcutsWindowsWeb",
    KEYBOARD_SHORTCUTS_MAC = "helpLink/keyboardShortcutsMac",
    EXPORTING_GRID_LAYOUTS = "helpLink/exportingGridLayouts",

    FAQ = "helpLink/faq",
    SUPPORTED_VERSIONS = "helpLink/supportedVersions",
    USING_ZEPLIN_IN_LINUX = "helpLink/usingZeplinInLinux",
    ENABLING_DARK_MODE = "helpLink/enablingDarkMode",
    CHANGING_PROJECT_TYPES = "helpLink/changingProjectTypes",
    ALL_THE_MEASUREMENTS_ARE_WRONG = "helpLink/allTheMeasurementsAreWrong",
    CHANGING_UNITS = "helpLink/changingUnits",
    THIRD_PARTY_SOFTWARE_LICENCES = "helpLink/thirdPartySoftwareLicences",
    EXPORTING_COMPONENTS_AS_SCREENS = "helpLink/exportingComponentsAsScreens",
    UNINSTALL_MAC_AND_WINDOWS = "helpLink/uninstallMacAndWindows",
    BUSINESS_CONTINUITY_PLAN_COVID_19 = "helpLink/businessContinuityPlanCovid19",

    ENTERPRISE_PLAN = "helpLink/enterprisePlan",
    ENTERPRISE_SSO = "helpLink/enterpriseSso",
    MULTIPLE_WORKSPACES_WITH_SSO_AND_SCIM = "helpLink/multipleWorkspacesWithSsoAndScim",
    USER_PROVISIONING_WITH_SCIM = "helpLink/userProvisioningWithScim",
    GENERIC_IDENTITY_PROVIDER_AND_SAML = "helpLink/genericIdentityProviderAndSaml",
    OKTA_SAML_SCIM = "helpLink/oktaSamlScim",
    AZURE_AD_SAML_SCIM = "helpLink/azureAdSamlScim",
    CENTRIFY_SAML = "helpLink/centrifySaml",
    GSUITE_SAML = "helpLink/gsuiteSaml",
    IBM_CLOUD_IDENTITY_AND_SAML = "helpLink/ibmCloudIdentityAndSaml",
    JUMPCLOUD_SAML_AND_SCIM = "helpLink/jumpcloudSamlAndScim",
    KEYCLOAK_SAML = "helpLink/keycloakSaml",
    MICROSOFT_ADFS_SAML = "helpLink/microsoftAdfsSaml",
    NETIQ_SAML = "helpLink/netiqSaml",
    ONELOGIN_SAML_SCIM = "helpLink/oneloginSamlScim",
    PING_IDENTITY_SAML = "helpLink/pingIdentitySaml",
    GETTING_STARTED_WITH_DOMAIN_CAPTURE = "helpLink/gettingStartedWithDomainCapture",
    SETTING_UP_ACCOUNT_ADMIN = "helpLink/settingUpAccountAdmin",
    SETTING_UP_NEW_USER_ACTIONS_FOR_DOMAINS = "helpLink/settingUpNewUserActionsForDomains",
    MANAGING_DOMAINS = "helpLink/managingDomains",

    ACCESSING_ZEPLIN = "helpLink/accessingZeplin",
    FIXING_YOUR_SESSION_EXPIRED = "helpLink/fixingYourSessionExpired",
    FIXING_CONNECTIVITY = "helpLink/fixingConnectivity",
    TROUBLESHOOTING_EMAIL_ISSUES = "helpLink/troubleshootingEmailIssues",
    FIXING_FAILED_TO_EXTRACT_INSTALLER = "helpLink/fixingFailedToExtractInstaller",
    FIXING_REQUEST_FAILED_FORBIDDEN = "helpLink/fixingRequestFailedForbidden",
    FIXING_REQUEST_TIMEOUT = "helpLink/fixingRequestTimeout",

    PRIVACY_AND_SECURITY = "PRIVACY_AndSecurity",
    CODE_OF_CONDUCT_AND_ETHICS = "helpLink/codeOfConductAndEthics",
    SECURITY_WHITEPAPER = "helpLink/securityWhitepaper",
    REGULATORY_COMPLIANCE = "helpLink/regulatoryCompliance",
    GDPR = "helpLink/gdpr",
    DATA_PROCESSING_ADDENDUM = "helpLink/dataProcessingAddendum",
    TYPES_OF_PERSONAL_INFORMATION = "helpLink/typesOfPersonalInformation",
    HOW_ZEPLIN_USES_PERSONAL_INFORMATION = "helpLink/howZeplinUsesPersonalInformation",
    OPT_OUT = "helpLink/optOut",
    DELETING_YOUR_ACCOUNT = "helpLink/deletingYourAccount",
    LOG4J_VULNERABILITY = "helpLink/log4jVulnerability",
    SECURITY_PENETRATION_TEST = "helpLink/securityPenetrationTest",
    RESPONSIBLE_DISCLOSURE = "helpLink/responsibleDisclosure",
    DATA_ENCRYPTION_AT_REST_AND_IN_TRANSIT = "helpLink/dataEncryptionAtRestAndInTransit",

    RELEASE_NOTES = "helpLink/releaseNotes",
    ZEPLIN_HIGHLIGHTS = "helpLink/zeplinHighlights",
    RELEASE_NOTES_MAC = "helpLink/releaseNotesMac",
    RELEASE_NOTES_WEB_WINDOWS = "helpLink/releaseNotesWebWindows"
}

export default HelpLinkIDs;
