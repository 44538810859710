/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import ApiColor from "../../../foundation/api/model/colors/Color";
import TextStyle from "../../../foundation/api/model/textStyles/TextStyle";
import Color from "../../../foundation/model/color";
import ColorDefinition from "../../../foundation/model/ColorDefinition";
import Font from "../../../foundation/model/font";
import TextStyleDefinition from "../../../foundation/model/TextStyleDefinition";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { getRandomString } from "../../../foundation/utils/string";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import StyleguideActionTypes from "../styleguide/StyleguideActionTypes";
import { AllPayloads } from "../payloads";

import SuggestedResources, { SuggestedResourcesDefinition } from "./SuggestedResources";
import * as Payloads from "./VersionImportsActionPayloads";
import VersionImportsActionTypes from "./VersionImportsActionTypes";

interface State {
    componentRejections: BasicRecord<unknown>;
    suggestedResourcesByBarrel: BasicRecord<SuggestedResources>;
    versionImports: unknown[];
    indicatorVisible: boolean;
    listVisible: boolean;
    multipleColorsSupportedHintBoxVisible: boolean;
    exportToStyleguideHintBoxVisible: boolean;
    xdPluginReleasedMessageVisible: boolean;
}

function getFoundationColors(colors: ColorDefinition[]): Color[] {
    return colors.map(color => new Color({ _id: getRandomString(), ...color }));
}

function getFoundationTextStyles(textStyles: TextStyleDefinition[]): Font[] {
    return textStyles.map(textStyle => new Font({ _id: getRandomString(), ...textStyle }));
}

function transformSuggestedResourcesByBarrel(suggestedResourcesByBarrel: BasicRecord<SuggestedResourcesDefinition>):
    BasicRecord<SuggestedResources> {
    return Object.keys(suggestedResourcesByBarrel).reduce((prev, barrelId) => {
        const {
            colors = [],
            textStyles = []
        } = suggestedResourcesByBarrel[barrelId];

        return {
            ...prev,
            [barrelId]: {
                colors: getFoundationColors(colors),
                textStyles: getFoundationTextStyles(textStyles)
            }
        };
    }, {});
}

class VersionImportsStore extends ReduceStore<State, AllPayloads> {
    getInitialState() {
        return {
            // TODO-tech-debt: Check usage of `componentRejections` on Windows App and remove this and its actions
            componentRejections: {},
            suggestedResourcesByBarrel: {
                // barrel1: {
                //     colors: [1] as unknown[] as Color[],
                //     textStyles: [2] as unknown[] as Font[]
                // }
            },
            versionImports: [],
            indicatorVisible: false,
            listVisible: false,
            // USE THIS TO DEBUG; REMOVE JUST BEFORE MERGE
            // versionImports: [{
            //     _id: "import1",
            //     barrel: {
            //         _id: "barrel1",
            //         name: "Stack overflowed",
            //         type: "android",
            //         barrelType: "project"
            //     },
            //     versionCollection: {
            //         _id: "col1",
            //         name: "very long screen name bla bla bla bla bla bla bla bla bla bla bla bla bla",
            //         type: "screen"
            //     },
            //     progress: {
            //         countOfItemsToUpload: 10,
            //         countOfUploadedItems: 2,
            //         isApiOperationFailed: false,
            //         update: false
            //     },
            //     source: "Sketch"
            // }
            // , {
            //     _id: "import2",
            //     barrel: {
            //         _id: "barrel1",
            //         name: "Stack overflowed",
            //         type: "android",
            //         barrelType: "project"
            //     },
            //     versionCollection: {
            //         _id: "col2",
            //         name: "Trips",
            //         type: "screen"
            //     },
            //     progress: {
            //         countOfItemsToUpload: 10,
            //         countOfUploadedItems: 3,
            //         isApiOperationFailed: false,
            //         update: false
            //     },
            //     source: "Sketch"
            // }
            // , {
            //     _id: "import3",
            //     barrel: {
            //         _id: "barrel1",
            //         name: "Stack overflowed",
            //         type: "android",
            //         barrelType: "project"
            //     },
            //     styleguideItemsOperation: {
            //         ids: ["col2", "col3", "col4"],
            //         type: "color"
            //     },
            //     progress: {
            //         countOfItemsToUpload: 3,
            //         countOfUploadedItems: 2,
            //         isApiOperationFailed: false,
            //         update: false
            //     },
            //     source: "Sketch"
            // }
            // , {
            //     _id: "import4",
            //     barrel: {
            //         _id: "barrel1",
            //         name: "Stack overflowed",
            //         type: "android",
            //         barrelType: "project"
            //     },
            //     styleguideItemsOperation: {
            //         ids: ["col2", "col3", "col4"],
            //         type: "color"
            //     },
            //     progress: {
            //         countOfItemsToUpload: 3,
            //         countOfUploadedItems: 3,
            //         isApiOperationFailed: false,
            //         update: false
            //     },
            //     source: "Sketch"
            // }
            // ],
            // indicatorVisible: true,
            // listVisible: true,
            multipleColorsSupportedHintBoxVisible: false,
            exportToStyleguideHintBoxVisible: false,
            xdPluginReleasedMessageVisible: false
        };
    }

    hideImportsIndicator(state: State): State {
        return Object.assign({}, state, {
            indicatorVisible: false
        });
    }

    showImportsList(state: State): State {
        return Object.assign({}, state, {
            indicatorVisible: true,
            listVisible: true
        });
    }

    hideImportsList(state: State): State {
        return Object.assign({}, state, {
            listVisible: false
        });
    }

    removeColorFromSuggestions(state: State, {
        color: data,
        bid
    }: Pick<BarrelActionPayloads.AddColor, "bid" | "color">): State {
        const {
            suggestedResourcesByBarrel
        } = state;

        if (!suggestedResourcesByBarrel[bid]) {
            return state;
        }

        const { [bid]: { colors, textStyles } } = suggestedResourcesByBarrel;

        if (!colors.length) {
            return state;
        }

        const color = new Color(data);
        return {
            ...state,
            suggestedResourcesByBarrel: {
                ...suggestedResourcesByBarrel,
                [bid]: {
                    textStyles,
                    colors: colors.filter(suggestedColor => !color.equals(suggestedColor))
                }
            }
        };
    }

    removeColorsFromSuggestions(state: State, {
        colors: colorsToRemove,
        bid
    }: BarrelActionPayloads.AddMultipleColors): State {
        const {
            suggestedResourcesByBarrel
        } = state;

        if (!suggestedResourcesByBarrel[bid]) {
            return state;
        }

        const { [bid]: { colors } } = suggestedResourcesByBarrel;

        if (!colors.length) {
            return state;
        }

        return colorsToRemove.reduce(
            (prevState, color) => this.removeColorFromSuggestions(prevState, { color, bid }), state
        );
    }

    removeTextStyleFromSuggestions(state: State, {
        textStyle: data,
        bid
    }: Pick<BarrelActionPayloads.AddTextStyle, "textStyle" | "bid">): State {
        const {
            suggestedResourcesByBarrel
        } = state;

        if (!suggestedResourcesByBarrel[bid]) {
            return state;
        }

        const { [bid]: { colors, textStyles } } = suggestedResourcesByBarrel;

        if (!textStyles.length) {
            return state;
        }

        const textStyle = new Font(data);
        return {
            ...state,
            suggestedResourcesByBarrel: {
                ...suggestedResourcesByBarrel,
                [bid]: {
                    colors,
                    textStyles: textStyles.filter(suggestedTextStyle => !textStyle.equals(suggestedTextStyle))
                }
            }
        };
    }

    removeTextStylesFromSuggestions(state: State, {
        textStyles: textStylesToRemove,
        bid
    }: BarrelActionPayloads.AddMultipleTextStyles): State {
        const {
            suggestedResourcesByBarrel
        } = state;

        if (!suggestedResourcesByBarrel[bid]) {
            return state;
        }

        const { [bid]: { textStyles } } = suggestedResourcesByBarrel;

        if (!textStyles.length) {
            return state;
        }

        return textStylesToRemove.reduce(
            (prevState, textStyle) => this.removeTextStyleFromSuggestions(prevState, { textStyle, bid }), state
        );
    }

    changeHintBoxVisibilities(state: State, {
        colorsToAdd,
        hasLinkedStyleguideColor
    }: BarrelActionPayloads.MigrateColors): State {
        const showMultipleColorsSupportedHintBox = colorsToAdd.length > 0;

        return {
            ...state,
            multipleColorsSupportedHintBoxVisible: showMultipleColorsSupportedHintBox,
            exportToStyleguideHintBoxVisible: hasLinkedStyleguideColor
        };
    }

    hideMultipleColorsSupportedHintBox(state: State): State {
        return {
            ...state,
            multipleColorsSupportedHintBoxVisible: false
        };
    }

    hideExportToStyleguideHintBox(state: State): State {
        return {
            ...state,
            exportToStyleguideHintBoxVisible: false
        };
    }

    setSuggestedColors(state: State, bid: string, colors: ApiColor[]): State {
        const {
            suggestedResourcesByBarrel,
            suggestedResourcesByBarrel: {
                [bid]: suggestedBarrelResources
            }
        } = state;

        return {
            ...state,
            suggestedResourcesByBarrel: {
                ...suggestedResourcesByBarrel,
                [bid]: {
                    ...suggestedBarrelResources,
                    colors: getFoundationColors(colors)
                }
            }
        };
    }

    setSuggestedTextStyles(state: State, bid: string, textStyles: TextStyle[]): State {
        const {
            suggestedResourcesByBarrel,
            suggestedResourcesByBarrel: {
                [bid]: suggestedBarrelResources
            }
        } = state;

        return {
            ...state,
            suggestedResourcesByBarrel: {
                ...suggestedResourcesByBarrel,
                [bid]: {
                    ...suggestedBarrelResources,
                    textStyles: getFoundationTextStyles(textStyles)
                }
            }
        };
    }

    showXdPluginReleasedMessage(state: State): State {
        return {
            ...state,
            xdPluginReleasedMessageVisible: true
        };
    }

    hideXdPluginReleasedMessage(state: State): State {
        return {
            ...state,
            xdPluginReleasedMessageVisible: false
        };
    }

    setSuggestedResources(state: State, {
        suggestedResourcesByBarrel
    }: Payloads.SetSuggestedLocalResources): State {
        return {
            ...state,
            suggestedResourcesByBarrel: transformSuggestedResourcesByBarrel(suggestedResourcesByBarrel)
        };
    }

    handleImports(state: State, {
        imports: {
            componentImportRejections: componentRejections,
            versionCollectionImports: versionImports
        }
    }: Payloads.HandleImports): State {
        return Object.assign({}, state, {
            componentRejections,
            versionImports,
            indicatorVisible: versionImports.length > 0,
            listVisible: versionImports.length === 0 ? false : state.listVisible
        });
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ScreenActionTypes.OPEN_SCREEN:
            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.hideImportsList(state);

            case BarrelActionTypes.ADD_MULTIPLE_COLORS:
                return this.removeColorsFromSuggestions(state, action);
            case BarrelActionTypes.ADD_COLOR:
                return this.removeColorFromSuggestions(state, action);
            case BarrelActionTypes.ADD_TEXT_STYLE:
                return this.removeTextStyleFromSuggestions(state, action);
            case BarrelActionTypes.ADD_MULTIPLE_TEXT_STYLES:
                return this.removeTextStylesFromSuggestions(state, action);
            case BarrelActionTypes.MIGRATE_COLORS:
                return this.changeHintBoxVisibilities(state, action);

            case StyleguideActionTypes.HIDE_SUGGESTED_COLORS:
                return this.setSuggestedColors(state, action.barrelId, []);
            case StyleguideActionTypes.HIDE_SUGGESTED_TEXT_STYLES:
                return this.setSuggestedTextStyles(state, action.barrelId, []);

            case VersionImportsActionTypes.HIDE_IMPORTS_INDICATOR:
                return this.hideImportsIndicator(state);
            case VersionImportsActionTypes.SHOW_IMPORTS_LIST:
                return this.showImportsList(state);
            case VersionImportsActionTypes.HIDE_IMPORTS_LIST:
                return this.hideImportsList(state);
            case VersionImportsActionTypes.SET_SUGGESTED_LOCAL_RESOURCES:
                return this.setSuggestedResources(state, action);
            case VersionImportsActionTypes.SUGGESTED_REMOTE_COLORS_RECEIVED:
                return this.setSuggestedColors(state, action.bid, action.colors);
            case VersionImportsActionTypes.SUGGESTED_REMOTE_TEXT_STYLES_RECEIVED:
                return this.setSuggestedTextStyles(state, action.bid, action.textStyles);
            case VersionImportsActionTypes.HANDLE_IMPORTS:
                return this.handleImports(state, action);
            case VersionImportsActionTypes.GO_TO_IMPORTED_VERSION:
                return this.hideImportsList(state);
            case VersionImportsActionTypes.HIDE_MULTIPLE_COLORS_SUPPORTED_HINT_BOX:
                return this.hideMultipleColorsSupportedHintBox(state);
            case VersionImportsActionTypes.HIDE_EXPORT_TO_STYLEGUIDE_HINT_BOX:
                return this.hideExportToStyleguideHintBox(state);
            case VersionImportsActionTypes.SHOW_XD_PLUGIN_RELEASED_MESSAGE:
                return this.showXdPluginReleasedMessage(state);
            case VersionImportsActionTypes.HIDE_XD_PLUGIN_RELEASED_MESSAGE:
                return this.hideXdPluginReleasedMessage(state);
            default:
                return state;
        }
    }
}

export default VersionImportsStore;
export {
    State as VersionImportsStoreState
};
