/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { AllPayloads } from "../payloads";

import NPSSurveyActionTypes from "./NPSSurveyActionTypes";
import * as Payloads from "./NPSSurveyActionPayloads";

interface State {
    isOpen: boolean;
    canSurveyBeShown: boolean;
    step: "Rate" | "Feedback";
    rate: number | null;
    triggeringEvent?: string;
}

class NPSSurveyStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            step: "Rate",
            isOpen: false,
            canSurveyBeShown: false,
            rate: null
        };
    }

    handleDismissSurvey(state: State): State {
        return {
            ...state,
            isOpen: false,
            canSurveyBeShown: false
        };
    }

    handleShowSurvey(state: State, { triggeringEvent }: Payloads.ShowSurvey): State {
        if (state.isOpen) {
            return state;
        }
        return {
            ...state,
            isOpen: true,
            step: "Rate",
            rate: null,
            triggeringEvent
        };
    }

    handleRateSurvey(state: State, { rating }: Payloads.RateSurvey): State {
        return {
            ...state,
            rate: rating,
            step: "Feedback"
        };
    }

    handleSubmitFeedback(state: State): State {
        return {
            ...state,
            step: "Rate",
            canSurveyBeShown: false,
            isOpen: false
        };
    }

    setCanShowSurvey(state: State, { canSurveyBeShown }: Payloads.SetCanShowSurvey): State {
        if (state.isOpen && state.canSurveyBeShown) {
            return state;
        }
        return {
            ...state,
            canSurveyBeShown
        };
    }

    reduce(state: State, action: AllPayloads) {
        switch (action.type) {
            case NPSSurveyActionTypes.DISMISS_SURVEY:
                return this.handleDismissSurvey(state);

            case NPSSurveyActionTypes.RATE_SURVEY:
                return this.handleRateSurvey(state, action);

            case NPSSurveyActionTypes.SUBMIT_FEEDBACK:
                return this.handleSubmitFeedback(state);

            case NPSSurveyActionTypes.SHOW_SURVEY:
                return this.handleShowSurvey(state, action);

            case NPSSurveyActionTypes.SET_CAN_SHOW_SURVEY:
                return this.setCanShowSurvey(state, action);

            default:
                return state;
        }
    }
}

export default NPSSurveyStore;
export {
    State as NPSSurveyStoreState
};
