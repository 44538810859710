/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { Dispatcher } from "flux";

import StorybookIntegrationActionTypes from "./StorybookIntegrationActionTypes";
import * as Payloads from "./StorybookIntegrationActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import AppActionTypes from "../app/AppActionTypes";
import { Story } from "./StorybookIntegrationStoreTypes";
import { AllPayloads } from "../payloads";
import { calculateStoryStatus } from "../../../foundation/utils/story";
import { transformStories } from "../../../foundation/utils/transform";
import { StoryStatus } from "../../../foundation/enums";

interface State {
    shouldMountIframe: boolean;
    url: string;
    stories: Story[];
    selectedStoryPath: string;
    loading: boolean;
}

class StorybookIntegrationStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            shouldMountIframe: true,
            url: "",
            stories: [],
            selectedStoryPath: "",
            loading: true
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    setShouldMountIframe(state: State, {
        shouldMountIframe
    }: Payloads.SetShouldMountIframe): State {
        return {
            ...state,
            shouldMountIframe
        };
    }

    setStories(state: State, {
        stories
    }: Payloads.SetStories): State {
        let updatedStories = stories;

        // Prevent unnecessary re-rendering
        if (state.stories.length === 0 && updatedStories.length === 0) {
            updatedStories = state.stories;
        }

        return {
            ...state,
            loading: false,
            stories: updatedStories
        };
    }

    updateUrl(state: State, {
        url
    }: Payloads.UpdateUrl): State {
        let computedUrl = url;

        if (computedUrl.endsWith("/")) {
            computedUrl = computedUrl.slice(0, -1);
        }

        return {
            ...state,
            url: computedUrl
        };
    }

    updateStory(state: State, {
        storyId,
        styleguidesData,
        payload
    }: Payloads.UpdateStory): State {
        return {
            ...state,
            stories: state.stories.map(story => {
                if (story.id === storyId) {
                    const computedStory = {
                        ...story,
                        ...payload
                    };

                    computedStory.status = calculateStoryStatus(computedStory.connectedComponentIds, styleguidesData);

                    return computedStory;
                }

                return story;
            })
        };
    }

    deleteStory(state: State, {
        storyId
    }: Payloads.DeleteStory): State {
        return {
            ...state,
            stories: state.stories.filter(story => story.id !== storyId)
        };
    }

    updateSelectedStoryPath(state: State, {
        selectedStoryPath
    }: Payloads.UpdateSelectedStoryPath): State {
        return {
            ...state,
            selectedStoryPath
        };
    }

    addStoryConnectedComponent(state: State, {
        storyPath,
        styleguidesData,
        connectedComponent
    }: Payloads.AddStoryConnectedComponent): State {
        return {
            ...state,
            stories: state.stories.map(story => {
                if (`${story.kind}/${story.name}` === storyPath) {
                    const computedStory = { ...story };

                    if (!computedStory.connectedComponentItemIds.includes(connectedComponent._id)) {
                        computedStory.connectedComponentItemIds.push(connectedComponent._id);
                    }

                    if (
                        connectedComponent.componentId &&
                        !computedStory.connectedComponentIds.includes(connectedComponent.componentId)
                    ) {
                        computedStory.connectedComponentIds.push(connectedComponent.componentId);
                    }

                    computedStory.status = calculateStoryStatus(computedStory.connectedComponentIds, styleguidesData);

                    return computedStory;
                }

                return story;
            })
        };
    }

    removeStoryConnectedComponent(state: State, {
        storyPath,
        styleguidesData,
        connectedComponent
    }: Payloads.RemoveStoryConnectedComponent): State {
        return {
            ...state,
            stories: state.stories.map(story => {
                if (`${story.kind}/${story.name}` === storyPath) {
                    const computedStory = { ...story };

                    computedStory.connectedComponentItemIds = computedStory.connectedComponentItemIds
                        .filter(id => connectedComponent._id !== id);
                    computedStory.connectedComponentIds = computedStory.connectedComponentIds
                        .filter(id => connectedComponent.componentId !== id);
                    computedStory.status = calculateStoryStatus(computedStory.connectedComponentIds, styleguidesData);

                    return computedStory;
                }

                return story;
            })
        };
    }

    updateForRemoveComponent(state: State, {
        coid
    }: Pick<BarrelActionPayloads.RemoveComponent, "coid">): State {
        return {
            ...state,
            stories: state.stories.map(story => {
                if (story.connectedComponentIds.includes(coid)) {
                    return {
                        ...story,
                        status: StoryStatus.HasIssue
                    };
                }

                return story;
            })
        };
    }

    updateForRemoveComponents(state: State, { sectionComponents }: BarrelActionPayloads.RemoveComponents): State {
        let newState = state;

        const allCoids = sectionComponents.reduce((acc, { coids }) => {
            return [...acc, ...coids];
        }, [] as string[]);

        allCoids.forEach(coid => {
            newState = this.updateForRemoveComponent(newState, { coid });
        });
        return newState;
    }

    updateForUpdateConnectedComponents(state: State, {
        styleguidesData
    }: Payloads.UpdateForUpdateConnectedComponents): State {
        const { url: storybookUrl, stories } = state;

        return {
            ...state,
            stories: transformStories({ storybookUrl, stories, styleguidesData })
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
            case BarrelActionTypes.RESET:
                return this.reset();

            case StorybookIntegrationActionTypes.SET_SHOULD_MOUNT_IFRAME:
                return this.setShouldMountIframe(state, action);

            case StorybookIntegrationActionTypes.SET_STORIES:
                return this.setStories(state, action);

            case StorybookIntegrationActionTypes.UPDATE_URL:
                return this.updateUrl(state, action);

            case StorybookIntegrationActionTypes.UPDATE_STORY:
                return this.updateStory(state, action);

            case StorybookIntegrationActionTypes.DELETE_STORY:
                return this.deleteStory(state, action);

            case StorybookIntegrationActionTypes.UPDATE_SELECTED_STORY_PATH:
                return this.updateSelectedStoryPath(state, action);

            case StorybookIntegrationActionTypes.ADD_STORY_CONNECTED_COMPONENT:
                return this.addStoryConnectedComponent(state, action);

            case StorybookIntegrationActionTypes.REMOVE_STORY_CONNECTED_COMPONENT:
                return this.removeStoryConnectedComponent(state, action);

            case StorybookIntegrationActionTypes.UPDATE_FOR_UPDATE_CONNECTED_COMPONENTS:
                return this.updateForUpdateConnectedComponents(state, action);

            case BarrelActionTypes.REMOVE_COMPONENT:
                return this.updateForRemoveComponent(state, action);
            case BarrelActionTypes.REMOVE_COMPONENTS:
                return this.updateForRemoveComponents(state, action);

            default:
                return state;
        }
    }
}

export default StorybookIntegrationStore;
export { State as StorybookIntegrationStoreState };
