/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import WorkspaceSettingsActionTypes from "./WorkspaceSettingsActionTypes";
import GetOrganizationSCIMSettingsResponse from "../../../foundation/api/model/organizations/GetOrganizationSCIMSettingsResponse";
import GetSCIMTokensResponse from "../../../foundation/api/model/organizations/GetSCIMTokensResponse";
import SCIMToken from "../../../foundation/api/model/organizations/SCIMToken";
import PartialSCIMToken from "../../../foundation/api/model/organizations/PartialSCIMToken";

import { Dispatcher } from "flux";
import { AllPayloads } from "../payloads";

interface State {
    isSCIMSettingsPending: boolean;
    isSCIMTokensPending: boolean;
    isSCIMTokenCreatePending: boolean;
    isSCIMTokenDeletePending: boolean;
    scimSettingsData: GetOrganizationSCIMSettingsResponse;
    scimTokens: PartialSCIMToken[];
    newSCIMTokenGenerated: boolean;
}

const defaultSetting = { enabled: true, role: "pawn", restricted: true };
class WorkspaceSettingsStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            isSCIMSettingsPending: true,
            isSCIMTokensPending: true,
            isSCIMTokenCreatePending: false,
            isSCIMTokenDeletePending: false,
            scimSettingsData: defaultSetting,
            scimTokens: [],
            newSCIMTokenGenerated: false
        };
    }

    getSCIMSettingsSuccess(state: State, SCIMSettings: GetOrganizationSCIMSettingsResponse) {
        return {
            ...state,
            scimSettingsData: SCIMSettings,
            isSCIMSettingsPending: false
        };
    }

    getSCIMSettingsFailure(state: State) {
        return {
            ...state,
            isSCIMSettingsPending: false
        };
    }

    getSCIMTokensSuccess(state: State, scimTokens: GetSCIMTokensResponse[]) {
        return {
            ...state,
            scimTokens,
            isSCIMTokensPending: false
        };
    }

    getSCIMTokensFailure(state: State) {
        return {
            ...state,
            isSCIMTokensPending: false
        };
    }

    createSCIMTokenRequest(state: State) {
        return {
            ...state,
            newSCIMTokenGenerated: false,
            isSCIMTokenCreatePending: true
        };
    }

    createSCIMTokenSuccess(state: State, newToken: SCIMToken) {
        return {
            ...state,
            newSCIMTokenGenerated: true,
            isSCIMTokenCreatePending: false,
            scimTokens: [newToken, ...state.scimTokens]
        };
    }

    createSCIMTokenFailure(state: State) {
        return {
            ...state,
            isSCIMTokenCreatePending: false,
            newSCIMTokenGenerated: false
        };
    }

    deleteSCIMTokenRequest(state: State) {
        return {
            ...state,
            isSCIMTokenDeletePending: true
        };
    }

    deleteSCIMTokenSuccess(state: State, id: string) {
        return {
            ...state,
            scimTokens: state.scimTokens.filter(({ accessTokenId }) => accessTokenId !== id),
            scimSettingsData: defaultSetting,
            isSCIMTokenDeletePending: false
        };
    }

    deleteSCIMTokenFailure(state: State) {
        return {
            ...state,
            isSCIMTokenDeletePending: false
        };
    }

    updateSCIMRole(state: State, role: string) {
        const newSettings = Object.assign({}, state.scimSettingsData, { role });
        return {
            ...state,
            scimSettingsData: newSettings
        };
    }

    updateSCIMAccessibility(state: State, restricted: boolean) {
        const newSettings = Object.assign({}, state.scimSettingsData, { restricted });
        return {
            ...state,
            scimSettingsData: newSettings
        };
    }

    updateSCIMStatus(state: State, enabled: boolean) {
        const newSettings = Object.assign({}, state.scimSettingsData, { enabled });
        return {
            ...state,
            scimSettingsData: newSettings
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case WorkspaceSettingsActionTypes.GET_SCIM_SETTINGS_SUCCESS:
                return this.getSCIMSettingsSuccess(state, action.SCIMSettings);
            case WorkspaceSettingsActionTypes.GET_SCIM_SETTINGS_FAILURE:
                return this.getSCIMSettingsFailure(state);
            case WorkspaceSettingsActionTypes.GET_SCIM_TOKENS_SUCCESS:
                return this.getSCIMTokensSuccess(state, action.scimTokens);
            case WorkspaceSettingsActionTypes.GET_SCIM_TOKENS_FAILURE:
                return this.getSCIMTokensFailure(state);
            case WorkspaceSettingsActionTypes.CREATE_SCIM_TOKEN_REQUEST:
                return this.createSCIMTokenRequest(state);
            case WorkspaceSettingsActionTypes.CREATE_SCIM_TOKEN_SUCCESS:
                return this.createSCIMTokenSuccess(state, action.newToken);
            case WorkspaceSettingsActionTypes.CREATE_SCIM_TOKEN_FAILURE:
                return this.createSCIMTokenFailure(state);
            case WorkspaceSettingsActionTypes.DELETE_SCIM_TOKEN_REQUEST:
                return this.deleteSCIMTokenRequest(state);
            case WorkspaceSettingsActionTypes.DELETE_SCIM_TOKEN_SUCCESS:
                return this.deleteSCIMTokenSuccess(state, action.accessTokenId);
            case WorkspaceSettingsActionTypes.UPDATE_SCIM_ROLE:
                return this.updateSCIMRole(state, action.role);
            case WorkspaceSettingsActionTypes.UPDATE_SCIM_ACCESSIBILITY:
                return this.updateSCIMAccessibility(state, action.restricted);
            case WorkspaceSettingsActionTypes.UPDATE_SCIM_STATUS:
                return this.updateSCIMStatus(state, action.enabled);

            default:
                return state;
        }
    }
}

export default WorkspaceSettingsStore;
export {
    State as WorkspaceSettingsStoreState
};
