enum ActivityCategory {
    All = "All",
    Note = "Note",
    Screen = "Screen",
    Styleguide = "Styleguide",
    Tag = "Tag",
    Other = "Other"
}

export default ActivityCategory;
