enum ActionTypes {
    UPDATE_PAGE_STATE = "space/updatePageState",
    CHANGE_SELECTED_TAB = "space/changeSelectedTab",
    CHANGE_SELECTED_ORGANIZATION = "space/changeSelectedOrganization",
    CHANGE_FILTER_VALUE = "space/changeFilterValue",
    CHANGE_PROJECT_SELECTED_SORT_TYPE = "space/changeProjectSelectedSortType",
    TOGGLE_ARCHIVED_PROJECTS = "space/toggleArchivedProjects",
    CHANGE_WORKFLOW_STATUS_FILTER = "space/changeWorkflowStatusFilter",
    RESET_FILTERS = "space/resetFilters",
    UNHIGHLIGHT_BARREL = "space/unhighlightBarrel",
    HIGHLIGHT_SECTION = "space/highlightSection",
    UNHIGHLIGHT_SECTION = "space/unhighlightSection",
    SET_SELECTED_SECTION = "space/setSelectedSection",
    EXPAND_SECTIONS = "space/expandSections",
    COLLAPSE_SECTIONS = "space/collapseSections",
    OPEN_DIALOG = "space/openDialog",
    CLOSE_DIALOG = "space/closeDialog",
    HIDE_LOADING = "space/hideLoading",
    TRACK_BARREL_CLICK = "space/trackBarrelClick",
    SET_SELECTED_PROJECTS = "space/setSelectedProjects",
    CLEAR_PROJECT_SELECTION = "space/clearProjectSelection",
    POSTPONE_OFFER_REMINDER = "space/postponeOfferReminder",
    SET_BARREL_TO_SCROLL = "space/setBarrelToScroll",
    SCROLLED_TO_BARREL = "space/scrolledToBarrel",
    CHANGE_PROJECTS_SCROLLED_STATE = "space/changeProjectsScrolledState"
}

export default ActionTypes;
