enum ActionTypes {
    SET_APPROVALS = "approvals/setApprovals",
    GET_APPROVALS = "approvals/getApprovals",
    CREATE_APPROVAL = "approvals/createApproval",
    ADD_APPROVAL = "approvals/addApproval",
    SET_PROJECT_APPROVALS = "approvals/setProjectApprovals",
    GET_PROJECT_APPROVALS = "approvals/getProjectApprovals",
    GET_WORKSPACE_APPROVALS = "approvals/getWorkspaceApprovals",
    SET_WORKSPACE_APPROVALS = "approvals/setWorkspaceApprovals",
    UPDATE_APPROVAL = "approvals/updateApproval",
    SET_APPROVAL_STATUS = "approvals/setApprovalStatus",
    DELETE_APPROVAL = "approvals/deleteApproval",
    REQUEST_CHANGES = "approvals/requestChanges",
    ADD_WORKSPACE_APPROVAL = "approvals/addWorkspaceApproval",
    UPDATE_WORKSPACE_APPROVAL_STATUS = "approvals/updateWorkspaceApprovalStatus",
    DELETE_WORKSPACE_APPROVAL = "approvals/deleteWorkspaceApproval",
    SET_SCREEN_APPROVAL_HINT_SEEN = "approvals/setScreenApprovalHintSeen",
    SET_SCREEN_APPROVAL_HINT_CLOSED = "approvals/setScreenApprovalHintClosed",
    SET_REQUEST_APPROVAL_POPUP_SEEN = "approvals/setRequestApprovalPopupSeen",
    SET_APPROVALS_REMINDER_DISMISS_COUNT = "approvals/setApprovalsReminderDismissCount"
}

export default ActionTypes;
