import {
    Flow, FlowConnector, FlowGroup, FlowNode, FlowScreenNode
} from "../../../foundation/api/model";
import FlowDot, { FlowDotComment } from "../../../foundation/api/model/flow/FlowDot";
import { FlowShapeNode } from "../../../foundation/api/model/flow/FlowNode";
import FlowNodeType from "../../../foundation/api/model/flow/FlowNodeType";
import { checkFlowDotFilter } from "../../../foundation/utils/dot";
import { exists } from "../../../foundation/utils/object";
import { HIGHLIGHT_OFFSET } from "../../containers/AppContainer/BarrelContainer/FlowContainer/Konva/note/constants";
import BarrelHelpers from "../barrel/BarrelHelpers";
import fluxRuntime from "../fluxRuntime";

function getFirstFlowId(pid: string | null): string | null {
    if (!pid) {
        return null;
    }

    const { flows: { [pid]: projectFlows = {} } } = fluxRuntime.FlowStore.getState();

    const [firstFlowId] = Object.keys(projectFlows);

    return firstFlowId;
}

function getFirstFlow(pid: string): Flow | null {
    const firstFlowId = getFirstFlowId(pid);
    if (!firstFlowId) {
        return null;
    }

    const { flows: { [pid]: projectFlows } } = fluxRuntime.FlowStore.getState();
    return projectFlows[firstFlowId];
}

function getFlows(pid?: string): Flow[] {
    let projectId: string | undefined | null = pid;
    if (!projectId) {
        ({ barrelId: projectId } = fluxRuntime.BarrelStore.getState());
    }

    const { flows: { [projectId!]: projectFlows } } = fluxRuntime.FlowStore.getState();

    if (!projectFlows) {
        return [];
    }

    return Object.values(projectFlows);
}

function getFlow(pid?: string, flid?: string | null): Flow | null {
    let projectId: string | undefined | null = pid;
    if (!projectId) {
        ({ barrelId: projectId } = fluxRuntime.BarrelStore.getState());
    }

    if (!projectId) {
        return null;
    }

    let flowId: string | null | undefined = flid;
    if (!flowId) {
        ({ currentFlowId: flowId } = fluxRuntime.FlowStore.getState());
    }

    if (!flowId) {
        return null;
    }

    const { flows: { [projectId]: projectFlows } } = fluxRuntime.FlowStore.getState();

    if (!projectFlows) {
        return null;
    }

    return projectFlows[flowId];
}

function getConnectors(flid?: string): FlowConnector[] {
    const { barrelId: pid } = fluxRuntime.BarrelStore.getState();
    if (!pid) {
        return [];
    }

    let flowId: string | null | undefined = flid;
    if (!flowId) {
        ({ currentFlowId: flowId } = fluxRuntime.FlowStore.getState());
    }

    const flow = getFlow(pid, flowId);

    return flow?.connectors ?? [];
}

function isFlowFetched(flid: string): boolean {
    const { fetchedFlowIds } = fluxRuntime.FlowStore.getState();

    return fetchedFlowIds.has(flid);
}

function getFlowNodes(noids: string[]) {
    const project = BarrelHelpers.getProject();

    if (!project) {
        return null;
    }

    const flow = getFlow(project.id);
    if (!flow) {
        return null;
    }

    return flow.nodes.filter(node => noids.includes(node._id)).filter(Boolean);
}

function getFlowNodesInSection(seid: string) {
    const project = BarrelHelpers.getProject();

    if (!project?.sections) {
        return null;
    }

    const flow = getFlow(project.id);
    if (!flow) {
        return null;
    }

    const sectionScreens = project.sections
        .filter(({ _id, path }) => _id === seid || (path || []).includes(seid))
        .flatMap(({ screens }) => screens);

    return flow.nodes.filter(node => {
        if (node.type === FlowNodeType.Screen) {
            return sectionScreens.includes(node.screen._id);
        } else if (node.type === FlowNodeType.VariantGroup) {
            return sectionScreens.includes(node.defaultVariant);
        }

        return false;
    });
}

function getFlowScreenIds(flow: Flow): string[] {
    return flow.nodes
        .map(node => {
            if (node.type === FlowNodeType.VariantGroup) {
                return node.defaultVariant;
            } else if (node.type === FlowNodeType.Screen) {
                return node.screen._id;
            }
            return undefined;
        }).filter(exists);
}

function getFlowScreenNodeCount(flow: Pick<Flow, "nodes">) {
    return flow?.nodes.filter(({ type }) =>
        [FlowNodeType.Screen, FlowNodeType.VariantGroup].includes(type)).length ?? 0;
}

function getDots({ pid, flid }: { pid?: string; flid?: string; } = {}): FlowDot[] | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const flowId = flid ?? fluxRuntime.FlowStore.getState().currentFlowId;

    if (!projectId || !flowId) {
        return null;
    }

    const { dots } = fluxRuntime.FlowDotsStore.getState();
    return dots?.[projectId]?.[flowId] ?? null;
}

function getFilteredDots({ pid, flid }: { pid?: string; flid?: string; } = {}): FlowDot[] | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const flowId = flid ?? fluxRuntime.FlowStore.getState().currentFlowId;
    const barrelUser = BarrelHelpers.findUser();

    if (!projectId || !flowId || !barrelUser) {
        return null;
    }

    const { statusFilter, colorFilter } = fluxRuntime.FlowStore.getState();
    const { dots } = fluxRuntime.FlowDotsStore.getState();
    const currentDots = dots?.[projectId]?.[flowId] ?? null;
    return currentDots?.filter(dot => checkFlowDotFilter(
        dot,
        statusFilter,
        colorFilter,
        barrelUser!.user
    )) ?? null;
}

function getDot({ pid, flid, did }: {
    pid?: string;
    flid?: string;
    did?: string;
} = {}): FlowDot | null | undefined {
    const dots = getDots({ pid, flid });
    if (!dots) {
        return null;
    }

    let dotId: string | null | undefined = did;
    if (!dotId) {
        ({ dotId } = fluxRuntime.FlowStore.getState());
    }

    if (!dotId) {
        return null;
    }

    return dots.find(d => d._id === dotId);
}

function getComment({ pid, flid, did, cmid }: {
    pid?: string;
    flid?: string;
    did?: string;
    cmid?: string;
} = {}): FlowDotComment | null {
    const dot = getDot({ pid, flid, did });

    return dot?.comments?.find(cm => cm._id === cmid) ?? null;
}

// Comment Navigation
function getNodeDimension(node: FlowNode) {
    let width = 0;
    let height = 0;
    if (node.type === FlowNodeType.VariantGroup) {
        const { variantGroup: { screens }, defaultVariant } = node;
        const screen = screens.find(({ _id }) => defaultVariant === _id)!;
        const snapshot = screen?.latestVersion.snapshot;
        ({ width, height } = snapshot!);
    } else if (node.type === FlowNodeType.Screen) {
        ({ width, height } = (node as FlowScreenNode).screen.latestVersion.snapshot);
    } else if ([FlowNodeType.Shape, FlowNodeType.Placeholder].includes(node.type)) {
        ({ width, height } = (node as FlowShapeNode));
    }

    return { width, height };
}

function getNodeOfDot(dot: FlowDot, flow: Flow): FlowNode | null {
    const nodes = flow.nodes.filter(({ type }) => type !== FlowNodeType.TextLabel);
    for (const node of nodes) {
        const { position: { x: nodeX, y: nodeY } } = node;
        const { width, height } = getNodeDimension(node);
        if (dot.x >= nodeX - HIGHLIGHT_OFFSET && dot.x <= nodeX + width + HIGHLIGHT_OFFSET &&
                dot.y >= nodeY - HIGHLIGHT_OFFSET && dot.y <= nodeY + height + HIGHLIGHT_OFFSET) {
            return node;
        }
    }

    return null;
}

function getDotsInNode(node: FlowNode, dots: FlowDot[]) {
    const res = [];
    const { position: { x: nodeX, y: nodeY } } = node;

    for (const dot of dots) {
        const { width, height } = getNodeDimension(node);

        if (dot.x >= nodeX - HIGHLIGHT_OFFSET && dot.x <= nodeX + width + HIGHLIGHT_OFFSET &&
            dot.y >= nodeY - HIGHLIGHT_OFFSET && dot.y <= nodeY + height + HIGHLIGHT_OFFSET) {
            res.push(dot);
        }
    }

    // Sort by x position
    res.sort((a, b) => a.x - b.x);

    return res;
}

function findBounds(nodes: FlowNode[]) {
    let left = Infinity;
    let right = -Infinity;
    let top = Infinity;
    let bottom = -Infinity;

    for (const node of nodes) {
        const { x: nodeLeft, y: nodeTop } = node.position;
        const { width, height } = getNodeDimension(node);

        const nodeRight = nodeLeft + width;
        const nodeBottom = nodeTop + height;

        if (nodeLeft < left) {
            left = nodeLeft;
        }

        if (nodeRight > right) {
            right = nodeRight;
        }

        if (nodeTop < top) {
            top = nodeTop;
        }

        if (nodeBottom > bottom) {
            bottom = nodeBottom;
        }
    }

    if (
        !Number.isFinite(top) ||
        !Number.isFinite(right) ||
        !Number.isFinite(bottom) ||
        !Number.isFinite(left)
    ) {
        return {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        };
    }

    return { top, right, bottom, left };
}

function getGroupOfDot(dot: FlowDot, flow: Flow): FlowGroup | null {
    const groups = flow.groups || [];
    for (const group of groups) {
        const groupNodes = group.nodes
            .map(nodeId => flow.nodes.find(({ _id }) => nodeId === _id)!)
            .filter(({ type }) => type !== FlowNodeType.TextLabel);
        const bounds = findBounds(groupNodes);

        if (dot.x >= bounds.left && dot.x <= bounds.right &&
            dot.y >= bounds.top && dot.y <= bounds.bottom) {
            return group;
        }
    }

    return null;
}

function getDotsInGroup(group: FlowGroup, dots: FlowDot[], flow: Flow) {
    const result = [];
    const groupNodes = group.nodes.map(nodeId => flow.nodes.find(({ _id }) => nodeId === _id)!);
    const bounds = findBounds(groupNodes);

    for (const dot of dots) {
        if (dot.x >= bounds.left && dot.x <= bounds.right &&
            dot.y >= bounds.top && dot.y <= bounds.bottom) {
            result.push(dot);
        }
    }

    // Sort by x position
    result.sort((a, b) => a.x - b.x);

    return result;
}

function getSortedDotsByIdForNavigation(): FlowDot[] {
    const dots = getFilteredDots() || [];
    const flow = getFlow()!;
    const result = [];

    // Sort by x position
    dots.sort((a, b) => a.x - b.x);

    const added = new Set();
    for (const dot of dots) {
        if (added.has(dot._id)) {
            continue;
        }

        result.push(dot);
        added.add(dot._id);

        const node = getNodeOfDot(dot, flow);
        if (node) {
            const screenDots = getDotsInNode(node, dots);
            for (const screenDot of screenDots) {
                if (added.has(screenDot._id)) {
                    continue;
                }
                result.push(screenDot);
                added.add(screenDot._id);
            }
        }

        const group = getGroupOfDot(dot, flow);
        if (group) {
            const groupDots = getDotsInGroup(group, dots, flow);
            for (const groupDot of groupDots) {
                if (added.has(groupDot._id)) {
                    continue;
                }
                result.push(groupDot);
                added.add(groupDot._id);
            }
        }
    }

    return result;
}

function getNextDotToNavigate(currentDotId: string) {
    const sortedDots = getSortedDotsByIdForNavigation();
    const currentDotIndex = sortedDots.findIndex(({ _id }) => currentDotId === _id);

    return sortedDots[currentDotIndex + 1];
}

function getPrevDotToNavigate(currentDotId: string) {
    const sortedDots = getSortedDotsByIdForNavigation();
    const currentDotIndex = sortedDots.findIndex(({ _id }) => currentDotId === _id);

    return sortedDots[currentDotIndex - 1];
}
// END - Comment Navigation

export default {
    getFirstFlowId,
    getFirstFlow,
    getFlow,
    getFlows,
    getConnectors,
    isFlowFetched,
    getFlowNodes,
    getFlowNodesInSection,
    getFlowScreenIds,
    getFlowScreenNodeCount,
    getDots,
    getFilteredDots,
    getDot,
    getComment,
    getNextDotToNavigate,
    getPrevDotToNavigate,
    getNodeOfDot,
    getDotsInNode
};
