/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { AllPayloads } from "../payloads";
import Dispatcher from "flux/lib/Dispatcher";
import ApprovalsActionTypes from "./ApprovalsActionTypes";
import * as ApprovalsActionPayloads from "./ApprovalsActionPayloads";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";

import Approval from "../../../foundation/api/model/approvals/Approval";
import ProjectApprovalScreen from "../../../foundation/api/model/approvals/ProjectApprovalScreen";
import { ApprovalStatus, ApprovalStatusText } from "../../containers/AppContainer/ApprovalsContainer/ApprovalsStatus";
import BarrelActionTypes from "../barrel/BarrelActionTypes";

type State = {
    requestApprovalPopupSeen: boolean;
    approvalsReminderDismissCount: number | null;
    approvalsReminderDismissUntil: Date | null;
    approvals: {
        [pid: string]: {
            [sid: string]: Approval[];
        };
    };
};

class ApprovalsScreenDataStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            requestApprovalPopupSeen: false,
            approvalsReminderDismissCount: null,
            approvalsReminderDismissUntil: null,
            approvals: {}
        };
    }

    getAddedProjectScreens(sid: string, screens: ProjectApprovalScreen[], approval: Approval) {
        if (approval.requestedFrom?._id === Zeplin.user._id) {
            const projectScreens = [...screens];
            const projectScreenIndex = projectScreens.findIndex(({ _id }) => _id === sid);

            if (projectScreenIndex > -1) {
                projectScreens[projectScreenIndex].approvals.push(approval);
            } else {
                projectScreens.push({
                    _id: sid,
                    approvals: [approval]
                });
            }

            return projectScreens;
        }

        return screens;
    }

    setApprovals(state: State, {
        pid,
        sid,
        approvals
    }: ApprovalsActionPayloads.SetApprovals): State {
        return {
            ...state,
            approvals: {
                [pid]: {
                    [sid]: approvals
                }
            }
        };
    }

    addApproval(state: State, {
        pid,
        sid,
        approval
    }: ApprovalsActionPayloads.AddApproval): State {
        return {
            ...state,
            approvals: {
                [pid]: {
                    ...(state.approvals[pid] || {}),
                    [sid]: [...(state.approvals[pid]?.[sid] || []), approval]
                }
            }
        };
    }

    updateApproval(state: State, {
        pid,
        sid,
        approval
    }: ApprovalsActionPayloads.UpdateApproval): State {
        const screenApprovalList = state.approvals[pid]?.[sid]?.map(screenApproval => {
            if (screenApproval._id === approval._id) {
                return approval;
            }

            return screenApproval;
        });

        if (!screenApprovalList) {
            return state;
        }

        return {
            ...state,
            approvals: {
                [pid]: {
                    ...(state.approvals[pid] || {}),
                    [sid]: screenApprovalList
                }
            }
        };
    }

    requestChanges(state: State, {
        pid,
        sid,
        apid
    }: ApprovalsActionPayloads.RequestChanges): State {
        const screenApprovalList = state.approvals[pid][sid]?.map(screenApproval => {
            if (screenApproval._id === apid) {
                return {
                    ...screenApproval,
                    status: ApprovalStatusText[ApprovalStatus.CHANGES_REQUESTED]
                };
            }

            return screenApproval;
        });

        return {
            ...state,
            approvals: {
                [pid]: {
                    ...(state.approvals[pid] || {}),
                    [sid]: screenApprovalList
                }
            }
        };
    }

    deleteApproval(state: State, {
        pid,
        sid,
        apid
    }: ApprovalsActionPayloads.DeleteApproval): State {
        const screenApprovalList = state.approvals[pid]?.[sid]?.filter(screenApproval => {
            return screenApproval._id !== apid;
        });

        if (!screenApprovalList) {
            return state;
        }

        return {
            ...state,
            approvals: {
                [pid]: {
                    ...(state.approvals[pid] || {}),
                    [sid]: screenApprovalList
                }
            }
        };
    }

    setRequestApprovalPopupSeen(state: State): State {
        return {
            ...state,
            requestApprovalPopupSeen: true
        };
    }

    loadBarrel(state: State, {
        approvals: {
            requestApprovalPopupSeen,
            approvalsReminderDismissCount,
            approvalsReminderDismissUntil
        } = {
            requestApprovalPopupSeen: false,
            approvalsReminderDismissCount: 0,
            approvalsReminderDismissUntil: null
        }
    }: BarrelActionPayloads.Load): State {
        return {
            ...state,
            requestApprovalPopupSeen,
            approvalsReminderDismissCount,
            approvalsReminderDismissUntil
        };
    }

    setApprovalsDismissCount(state: State, { dismissDaysLimit, dismissCount }: {
        dismissDaysLimit?: Date;
        dismissCount: number;
    }): State {
        if (!dismissDaysLimit) {
            return {
                ...state,
                approvalsReminderDismissCount: dismissCount
            };
        }

        return {
            ...state,
            approvalsReminderDismissCount: dismissCount,
            approvalsReminderDismissUntil: dismissDaysLimit
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ApprovalsActionTypes.SET_APPROVALS:
                return this.setApprovals(state, action);

            case ApprovalsActionTypes.ADD_APPROVAL:
                return this.addApproval(state, action);

            case ApprovalsActionTypes.UPDATE_APPROVAL:
                return this.updateApproval(state, action);

            case ApprovalsActionTypes.DELETE_APPROVAL:
                return this.deleteApproval(state, action);

            case ApprovalsActionTypes.REQUEST_CHANGES:
                return this.requestChanges(state, action);

            case BarrelActionTypes.LOAD:
                return this.loadBarrel(state, action);

            case ApprovalsActionTypes.SET_REQUEST_APPROVAL_POPUP_SEEN:
                return this.setRequestApprovalPopupSeen(state);

            case ApprovalsActionTypes.SET_APPROVALS_REMINDER_DISMISS_COUNT:
                return this.setApprovalsDismissCount(state, action);
            default:
                return state;
        }
    }
}

export default ApprovalsScreenDataStore;
export {
    State as ApprovalsScreenDataStoreState
};
