import { h } from "preact";

import classname from "../../../../../foundation/utils/classname";
import IconCross from "../../../../../library/Icons/IconCross";
import IconTick from "../../../../../library/Icons/IconTick";
import IconWarning from "../../../../../library/Icons/IconWarning";
import FormFieldStatus from "../../types/FormFieldStatus";

import "./style.css";

function TipText({ type, explanation, class: cssClass } : FormFieldStatus & { class?: string; }) {
    if (!explanation) {
        return null;
    }

    return (
        <div class={classname("tipTextWrapper", cssClass)}>
            <div class="tipText">
                {type === "error" && <IconCross width={9}/>}
                {(type === "warning" || type === "suggestion") && <IconWarning/>}
                {type === "success" && <IconTick width={12}/>}
                <p className={classname({ [type]: true })}>
                    {explanation}
                </p>
            </div>
        </div>
    );
}

export default TipText;
