import { Dispatcher } from "flux";
import FluxReduceStore from "flux/lib/FluxReduceStore";
import { Store } from "redux";

import BasicRecord from "../../foundation/utils/BasicRecord";

import AccountAdminStore from "./accountAdmin/AccountAdminStore";
import ActivitiesViewStore from "./activities/ActivitiesViewStore";
import AppStore from "./app/AppStore";
import ApprovalsProjectDataStore from "./approvals/ApprovalsProjectDataStore";
import ApprovalsScreenDataStore from "./approvals/ApprovalsScreenDataStore";
import ApprovalsWorkspaceDataStore from "./approvals/ApprovalsWorkspaceDataStore";
import AppsStore from "./apps/AppsStore";
import AssetsStore from "./assets/AssetsStore";
import BarrelStore from "./barrel/BarrelStore";
import ColorSelectionStore from "./barrel/ColorSelectionStore";
import ComponentPropertyStore from "./barrel/componentProperty/ComponentPropertyStore";
import ComponentSelectionStore from "./barrel/ComponentSelectionStore";
import DashboardStore from "./dashboard/DashboardStore";
import ScreenSelectionStore from "./dashboard/ScreenSelectionStore";
import DialogStore from "./dialog/DialogStore";
import DotStatesStore from "./dotStates/DotStatesStore";
import DragDropStore from "./dragDrop/DragDropStore";
import ElectronStore from "./electron/ElectronStore";
import EmojisStore from "./emojis/EmojisStore";
import ExperimentationStore from "./experimentation/ExperimentationStore";
import ExtensionsStore from "./extensions/ExtensionsStore";
import FigmaLibrarySyncDataStore from "./figmaLibrarySync/FigmaLibrarySyncDataStore";
import FlowDotsStore from "./flow/dots/FlowDotsStore";
import FlowStore from "./flow/FlowStore";
import GlobalSearchStore from "./globalSearch/GlobalSearchStore";
import InspectableViewStore from "./inspectableView/InspectableViewStore";
import JiraIntegrationStore from "./jiraIntegration/JiraIntegrationStore";
import MoveResourcesStore from "./moveResources/MoveResourcesStore";
import MultiFactorAuthenticationStore from "./multiFactorAuthentication/MultiFactorAuthenticationStore";
import NotificationsStore from "./notifications/NotificationsStore";
import NPSSurveyStore from "./npsSurvey/NPSSurveyStore";
import OnboardingGuidanceStore from "./onboarding/OnboardingGuidanceStore";
import OrganizationStore from "./organization/OrganizationStore";
import OrganizationBillingStore from "./organizationBilling/OrganizationBillingStore";
import { AllPayloads } from "./payloads";
import ProjectsDataStore from "./projects/ProjectsDataStore";
import RecentlyLoadedBarrelsStore from "./recentlyLoadedBarrels/RecentlyLoadedBarrelsStore";
import AnnotationsDataStore from "./screen/AnnotationsDataStore";
import AnnotationsStore from "./screen/AnnotationsStore";
import ComponentVariantStore from "./screen/componentVariant/ComponentVariantStore";
import DotsDataStore from "./screen/DotsDataStore";
import DotsStore from "./screen/DotsStore";
import ScreenComponentsDataStore from "./screenComponents/ScreenComponentsDataStore";
import SlackPreferencesStore from "./slackPreferences/SlackPreferencesStore";
import ProjectSelectionStore from "./space/ProjectSelectionStore";
import SpaceStore from "./space/SpaceStore";
import WorkspaceScrollStore from "./space/WorkspaceScrollStore";
import StorybookIntegrationStore from "./storybookIntegration/StorybookIntegrationStore";
import StyleguideStore from "./styleguide/StyleguideStore";
import StyleguidesDataStore from "./styleguides/StyleguidesDataStore";
import SupportButtonStore from "./supportButton/SupportButtonStore";
import TeamsIntegrationStore from "./teamsIntegration/TeamsIntegrationStore";
import ThemeStore from "./theme/ThemeStore";
import UserStore from "./user/UserStore";
import VersionImportsStore from "./versionImports/VersionImportsStore";
import WebhooksDataStore from "./webhooks/WebhooksDataStore";
import WorkspaceStore from "./workspace/WorkspaceStore";
import WorkspaceSettingsStore from "./workspaceSettings/WorkspaceSettingsStore";

class FluxRuntime {
    dispatcher: Dispatcher<AllPayloads> = new Dispatcher<AllPayloads>();
    stores: BasicRecord<FluxReduceStore<unknown, AllPayloads>> = {};
    storeNames: BasicRecord<string> = {};

    AccountAdminStore!: AccountAdminStore;
    ActivitiesViewStore!: ActivitiesViewStore;
    AppStore!: AppStore;
    AppsStore!: AppsStore;
    BarrelStore!: BarrelStore;
    DashboardStore!: DashboardStore;
    DotStatesStore!: DotStatesStore;
    EmojisStore!: EmojisStore;
    ExtensionsStore!: ExtensionsStore;
    MoveResourcesStore!: MoveResourcesStore;
    NotificationsStore!: NotificationsStore;
    OrganizationStore!: OrganizationStore;
    OrganizationBillingStore!: OrganizationBillingStore;
    ProjectsDataStore!: ProjectsDataStore;
    RecentlyLoadedBarrelsStore!: RecentlyLoadedBarrelsStore;
    ComponentVariantStore!: ComponentVariantStore;
    DotsDataStore!: DotsDataStore;
    DotsStore!: DotsStore;
    AnnotationsStore!: AnnotationsStore;
    AnnotationsDataStore!: AnnotationsDataStore;
    WorkspaceScrollStore!: WorkspaceScrollStore;
    InspectableViewStore!: InspectableViewStore;
    ScreenComponentsDataStore!: ScreenComponentsDataStore;
    SlackPreferencesStore!: SlackPreferencesStore;
    StyleguideStore!: StyleguideStore;
    StyleguidesDataStore!: StyleguidesDataStore;
    SpaceStore!: SpaceStore;
    WorkspaceSettingsStore!: WorkspaceSettingsStore;
    ProjectSelectionStore!: ProjectSelectionStore;
    SupportButtonStore!: SupportButtonStore;
    TeamsIntegrationStore!: TeamsIntegrationStore;
    UserStore!: UserStore;
    VersionImportsStore!: VersionImportsStore;
    WebhooksDataStore!: WebhooksDataStore;
    AssetsStore!: AssetsStore;
    StorybookIntegrationStore!: StorybookIntegrationStore;
    FlowStore!: FlowStore;
    FlowDotsStore!: FlowDotsStore;
    ScreenSelectionStore!: ScreenSelectionStore;
    NPSSurveyStore!: NPSSurveyStore;
    GlobalSearchStore!: GlobalSearchStore;
    DragDropStore!: DragDropStore;
    ComponentSelectionStore!: ComponentSelectionStore;
    ColorSelectionStore!: ColorSelectionStore;
    JiraIntegrationStore!: JiraIntegrationStore;
    ComponentPropertyStore!: ComponentPropertyStore;
    ExperimentationStore!: ExperimentationStore;
    OnboardingGuidanceStore!: OnboardingGuidanceStore;
    MultiFactorAuthenticationStore!: MultiFactorAuthenticationStore;
    ApprovalsScreenDataStore!: ApprovalsScreenDataStore;
    ApprovalsProjectDataStore!: ApprovalsProjectDataStore;
    ApprovalsWorkspaceDataStore!: ApprovalsWorkspaceDataStore;
    ElectronStore!: ElectronStore;
    FigmaLibrarySyncDataStore!: FigmaLibrarySyncDataStore;
    WorkspaceStore!: WorkspaceStore;
    ThemeStore!: ThemeStore;
    DialogStore!: DialogStore;

    generateStoreNames() {
        for (const [key, value] of Object.entries(this)) {
            if (value.getDispatchToken) {
                this.storeNames[value.getDispatchToken()] = key;
            }
        }
    }

    createStore<T extends FluxReduceStore<unknown, AllPayloads>>(
        StoreInitializer: new (dispatcher: Dispatcher<AllPayloads>) => T
    ): T {
        const store = new StoreInitializer(this.dispatcher);
        const storeId = store.getDispatchToken();
        this.stores[storeId] = store;

        return store;
    }

    shadowFluxStore(store: FluxReduceStore<unknown, AllPayloads>, reduxStore: Store) {
        const storeId = store.getDispatchToken();

        // Related Store is already shadowed.
        if (!this.stores[storeId]) {
            return;
        }

        const storeName = this.storeNames[storeId];

        this.dispatcher.unregister(storeId);
        delete this.stores[storeId];
        delete this[(storeName as keyof FluxRuntime)];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[storeName] = {
            getState: () => reduxStore.getState()[storeName],
            getDispatchToken: () => "Shadowed_ID",
            addListener: (cb: () => void) => reduxStore.subscribe(cb)
        };
    }

    initializeStores(initFn?: (f: FluxRuntime) => void | undefined) {
        if (initFn) {
            initFn(this);
            this.generateStoreNames();
            return;
        }

        // create stores. this makes them hook into the dispatcher, we can reorder if we want;
        this.ActivitiesViewStore = this.createStore(ActivitiesViewStore);
        this.AppStore = this.createStore(AppStore);
        this.AppsStore = this.createStore(AppsStore);
        this.BarrelStore = this.createStore(BarrelStore);
        this.DashboardStore = this.createStore(DashboardStore);
        this.DotStatesStore = this.createStore(DotStatesStore);
        this.EmojisStore = this.createStore(EmojisStore);
        this.ExtensionsStore = this.createStore(ExtensionsStore);
        this.MoveResourcesStore = this.createStore(MoveResourcesStore);
        this.NotificationsStore = this.createStore(NotificationsStore);
        this.OrganizationStore = this.createStore(OrganizationStore);
        this.OrganizationBillingStore = this.createStore(OrganizationBillingStore);
        this.ProjectsDataStore = this.createStore(ProjectsDataStore);
        this.RecentlyLoadedBarrelsStore = this.createStore(RecentlyLoadedBarrelsStore);
        this.ComponentVariantStore = this.createStore(ComponentVariantStore);
        this.DotsDataStore = this.createStore(DotsDataStore);
        this.DotsStore = this.createStore(DotsStore);
        this.AnnotationsStore = this.createStore(AnnotationsStore);
        this.InspectableViewStore = this.createStore(InspectableViewStore);
        this.ScreenComponentsDataStore = this.createStore(ScreenComponentsDataStore);
        this.SlackPreferencesStore = this.createStore(SlackPreferencesStore);
        this.StyleguideStore = this.createStore(StyleguideStore);
        this.StyleguidesDataStore = this.createStore(StyleguidesDataStore);
        this.SpaceStore = this.createStore(SpaceStore);
        this.WorkspaceScrollStore = this.createStore(WorkspaceScrollStore);
        this.ProjectSelectionStore = this.createStore(ProjectSelectionStore);
        this.SupportButtonStore = this.createStore(SupportButtonStore);
        this.TeamsIntegrationStore = this.createStore(TeamsIntegrationStore);
        this.UserStore = this.createStore(UserStore);
        this.VersionImportsStore = this.createStore(VersionImportsStore);
        this.WebhooksDataStore = this.createStore(WebhooksDataStore);
        this.AssetsStore = this.createStore(AssetsStore);
        this.StorybookIntegrationStore = this.createStore(StorybookIntegrationStore);
        this.WorkspaceSettingsStore = this.createStore(WorkspaceSettingsStore);
        this.FlowStore = this.createStore(FlowStore);
        this.FlowDotsStore = this.createStore(FlowDotsStore);
        this.AnnotationsDataStore = this.createStore(AnnotationsDataStore);
        this.ScreenSelectionStore = this.createStore(ScreenSelectionStore);
        this.NPSSurveyStore = this.createStore(NPSSurveyStore);
        this.AccountAdminStore = this.createStore(AccountAdminStore);
        this.GlobalSearchStore = this.createStore(GlobalSearchStore);
        this.DragDropStore = this.createStore(DragDropStore);
        this.ComponentSelectionStore = this.createStore(ComponentSelectionStore);
        this.ColorSelectionStore = this.createStore(ColorSelectionStore);
        this.JiraIntegrationStore = this.createStore(JiraIntegrationStore);
        this.ComponentPropertyStore = this.createStore(ComponentPropertyStore);
        this.ExperimentationStore = this.createStore(ExperimentationStore);
        this.OnboardingGuidanceStore = this.createStore(OnboardingGuidanceStore);
        this.MultiFactorAuthenticationStore = this.createStore(MultiFactorAuthenticationStore);
        this.ApprovalsScreenDataStore = this.createStore(ApprovalsScreenDataStore);
        this.ApprovalsProjectDataStore = this.createStore(ApprovalsProjectDataStore);
        this.ApprovalsWorkspaceDataStore = this.createStore(ApprovalsWorkspaceDataStore);
        this.ElectronStore = this.createStore(ElectronStore);
        this.FigmaLibrarySyncDataStore = this.createStore(FigmaLibrarySyncDataStore);
        this.WorkspaceStore = this.createStore(WorkspaceStore);
        this.ThemeStore = this.createStore(ThemeStore);
        this.DialogStore = this.createStore(DialogStore);

        this.generateStoreNames();
    }
}

export default new FluxRuntime();
