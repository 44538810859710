import { Account, AccountDetail } from "../../../foundation/api/model";
import GetBarrelOrganizationResponse from "../../../foundation/api/model/barrels/GetBarrelOrganizationResponse";
import { Project, ProjectDetails } from "../../../foundation/api/model/barrels/Project";
import { Styleguide, StyleguideDetails, CompleteStyleguide } from "../../../foundation/api/model/barrels/Styleguide";
import ApiConfig from "../../../foundation/api/model/config/ApiConfig";
import FocusedBarrel from "../../../foundation/api/model/organizations/FocusedBarrel";
import ApiOrganization from "../../../foundation/api/model/organizations/Organization";
import FoundationOrganization from "../../../foundation/model/Organization";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import fluxRuntime from "../fluxRuntime";
import AppActionTypes from "./AppActionTypes";
import EntryProjectData from "../../entries/app/model/EntryProjectData";
import EntryStyleguideData from "../../entries/app/model/EntryStyleguideData";
import EntryOrganizationData from "../../entries/app/model/EntryOrganizationData";
import OrganizationMemberProfile from "../../../foundation/api/model/organizations/OrganizationMemberProfile";
import { turnMinutesIntoHumanReadableInterval } from "../../../foundation/utils/date";

import UpdateNotificationBannerDetails from "./UpdateNotificationBannerDetails";

const Actions = {
    loadWorkspaceData({
        organizations, organizationProfiles, organization, focusedBarrels, projects, styleguides, dialogDismissParams,
        oid, workspaceMainDataLoading, allProjectsLoaded, archivedProjectIds, restrictedMemberProjectIds,
        freeTrialExpirationHintboxSeen, freeTrialEndedDialogSeen, transferProjectTooltipSeen,
        projectLimitTeyzeSeenForCount
    }: {
        organizations: FoundationOrganization[] | ApiOrganization[];
        organizationProfiles: BasicRecord<OrganizationMemberProfile> | undefined;
        organization: EntryOrganizationData | null;
        focusedBarrels: FocusedBarrel[] | null;
        projects: EntryProjectData[];
        styleguides: EntryStyleguideData[];
        dialogDismissParams: BasicRecord | null;
        oid: string;
        workspaceMainDataLoading?: boolean;
        allProjectsLoaded?: BasicRecord<boolean>;
        archivedProjectIds?: string[];
        restrictedMemberProjectIds?: string[];
        freeTrialExpirationHintboxSeen: boolean | null;
        freeTrialEndedDialogSeen: boolean | null;
        transferProjectTooltipSeen: boolean | null;
        projectLimitTeyzeSeenForCount: number | null;
    }) {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.LOAD_WORKSPACE_DATA,
            organizations,
            organizationProfiles,
            organization,
            focusedBarrels,
            projects,
            styleguides,
            dialogDismissParams,
            oid,
            workspaceMainDataLoading,
            allProjectsLoaded,
            archivedProjectIds,
            restrictedMemberProjectIds,
            freeTrialExpirationHintboxSeen,
            freeTrialEndedDialogSeen,
            transferProjectTooltipSeen,
            projectLimitTeyzeSeenForCount
        });
    },
    reset() {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.RESET
        });
    },
    hydrateEmbeddedData({
        projects, styleguides, focusedBarrels, oid, organizations, organizationProfiles, styleguide, project,
        linkedStyleguides, ancestors, organization, user, config, accounts, accountId, accountDetail
    }: {
        projects?: Project[] | null;
        styleguides?: Styleguide[] | null;
        focusedBarrels?: FocusedBarrel[];
        oid?: string;
        organizations?: ApiOrganization[];
        organizationProfiles?: BasicRecord<OrganizationMemberProfile> | null;
        styleguide?: StyleguideDetails;
        project?: ProjectDetails;
        linkedStyleguides?: CompleteStyleguide[];
        ancestors?: CompleteStyleguide[];
        organization?: Partial<GetBarrelOrganizationResponse>;
        user: typeof Zeplin.user;
        config: ApiConfig;
        accounts?: Account[];
        accountId?: string | undefined;
        accountDetail?: AccountDetail | undefined;
    }) {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.HYDRATE_EMBEDDED_DATA,
            projects,
            styleguides,
            focusedBarrels,
            oid,
            organizations,
            organizationProfiles,
            styleguide,
            project,
            linkedStyleguides,
            ancestors,
            organization,
            user,
            config,
            accounts,
            accountId,
            accountDetail
        });
    },
    initRecentlyLoadedBarrels({ projects, styleguides }: {
        projects: {
            _id: string;
            name: string;
        }[];
        styleguides: {
            _id: string;
            name: string;
        }[];
    }) {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.INIT_RECENTLY_LOADED_BARRELS,
            projects,
            styleguides
        });
    },
    popBackStack() {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.POP_BACK_STACK
        });
    },
    pushToBackStack(label: string, link: string) {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.PUSH_TO_BACK_STACK,
            label,
            link
        });
    },
    setPage(page: string, nextViewHint: string | undefined = undefined) {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.SET_PAGE,
            page,
            nextViewHint
        });
    },
    sessionTokenExpired(sessionDurationInMinutes: number) {
        const { organizations } = fluxRuntime.OrganizationStore.getState();

        if (!organizations) {
            Zeplin.logout();
            return;
        }

        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.SESSION_TOKEN_EXPIRED,
            frequency: turnMinutesIntoHumanReadableInterval(sessionDurationInMinutes)
        });
    },
    unsetSessionTokenExpired() {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.UNSET_SESSION_TOKEN_EXPIRED
        });
    },
    logout() {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.LOG_OUT
        });
    },
    setNotificationSettingsDialogVisibility(visible: boolean) {
        const { showNotificationSettingsDialog } = fluxRuntime.AppStore.getState();
        if (showNotificationSettingsDialog === visible) {
            return;
        }

        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.SET_NOTIFICATION_SETTINGS_DIALOG_VISIBILITY,
            visible
        });
    },
    setWhatsNewModalVisibility(visible: boolean) {
        const { whatsNewModalOpen } = fluxRuntime.AppStore.getState();
        if (whatsNewModalOpen === visible) {
            return;
        }

        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.SET_WHATS_NEW_MODAL_VISIBILITY,
            visible
        });
    },
    setWhatsNewPopupVisibility(visible: boolean) {
        const { whatsNewPopupOpen } = fluxRuntime.AppStore.getState();
        if (whatsNewPopupOpen === visible) {
            return;
        }

        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.SET_WHATS_NEW_POPUP_VISIBILITY,
            visible
        });
    },
    setUpdateNotificationBannerDetails(updateNotificationBannerDetails: UpdateNotificationBannerDetails) {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.SET_UPDATE_NOTIFICATION_BANNER_DETAILS,
            updateNotificationBannerDetails
        });
    },
    resetUpdateNotificationBannerDetails() {
        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.RESET_UPDATE_NOTIFICATION_BANNER_DETAILS
        });
    },
    setDisplaySocketConnectionIssueBanner(display: boolean, bannerDismissed?: boolean) {
        const { shouldDisplaySocketConnectionIssueBanner } = fluxRuntime.AppStore.getState();
        if (shouldDisplaySocketConnectionIssueBanner === display ||
            sessionStorage.getItem("socketErrorBannerStatus") === "dismissed") {
            return;
        }

        fluxRuntime.dispatcher.dispatch({
            type: AppActionTypes.SET_SHOULD_DISPLAY_SOCKET_CONNECTION_ISSUE_BANNER,
            display,
            bannerDismissed
        });
    }
};

export default Actions;
