enum ActionTypes {
    SET_VERSION_ASSETS = "assets/setVersionAssets",
    SET_COMPONENT_ASSETS = "assets/setComponentAssets",
    SET_MULTIPLE_COMPONENT_ASSETS = "assets/setMultipleComponentAssets",
    UPDATE_VERSION_ASSET_OPTIMIZED_CONTENTS = "assets/updateVersionAssetOptimizedContents",
    PROCESS_VERSION_ASSET_CONTENT_REQUEST = "assets/processVersionAssetContentRequest",
    PROCESS_VERSION_ASSET_CONTENT_FAILURE = "assets/processVersionAssetContentFailure",
    UPDATE_COMPONENT_VERSION_ASSET_OPTIMIZED_CONTENTS = "assets/updateComponentVersionAssetOptimizedContents",
    PROCESS_COMPONENT_VERSION_ASSET_CONTENT_REQUEST = "assets/processComponentVersionAssetContentRequest",
    PROCESS_COMPONENT_VERSION_ASSET_CONTENT_FAILURE = "assets/processComponentVersionAssetContentFailure"
}

export default ActionTypes;
