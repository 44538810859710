/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import OrganizationBillingActionTypes from "./OrganizationBillingActionTypes";
import * as Payloads from "./OrganizationBillingActionPayloads";
import OrganizationScheduledSeatsUpdateResponse from "../../../foundation/api/model/organizations/OrganizationScheduledSeatsUpdateResponse";
import { Dispatcher } from "flux";
import { AllPayloads } from "../payloads";

interface State {
    newReservedSeatCount: number;
    scheduledSeatsUpdate: OrganizationScheduledSeatsUpdateResponse | null;
    isUpdateSeatsPending: boolean;
    isCancelScheduledSeatsUpdatePending: boolean;
}

class OrganizationBillingStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            newReservedSeatCount: 0,
            scheduledSeatsUpdate: null,
            isUpdateSeatsPending: false,
            isCancelScheduledSeatsUpdatePending: false
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    updateSeatsRequest(state: State): State {
        return {
            ...state,
            isUpdateSeatsPending: true
        };
    }

    updateSeatsSuccess(state: State, {
        reservedSeatCount: newReservedSeatCount
    }: Payloads.UpdateSeatsSuccess): State {
        return {
            ...state,
            newReservedSeatCount,
            scheduledSeatsUpdate: null,
            isUpdateSeatsPending: false
        };
    }

    updateSeatsFailure(state: State): State {
        return {
            ...state,
            isUpdateSeatsPending: false
        };
    }

    getScheduledSeatsUpdateSuccess(state: State, {
        scheduledSeatsUpdate
    }: Payloads.GetScheduledSeatsUpdateSuccess): State {
        return {
            ...state,
            scheduledSeatsUpdate
        };
    }

    cancelScheduledSeatsUpdateRequest(state: State): State {
        return {
            ...state,
            scheduledSeatsUpdate: null,
            isCancelScheduledSeatsUpdatePending: true
        };
    }

    cancelScheduledSeatsUpdateSuccess(state: State): State {
        return {
            ...state,
            isCancelScheduledSeatsUpdatePending: false
        };
    }

    cancelScheduledSeatsUpdateFailure(state: State, {
        cancelledScheduledSeatsUpdate
    }: Payloads.CancelScheduledSeatsUpdateFailure): State {
        return {
            ...state,
            scheduledSeatsUpdate: cancelledScheduledSeatsUpdate,
            isCancelScheduledSeatsUpdatePending: false
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case OrganizationBillingActionTypes.RESET:
                return this.reset();

            case OrganizationBillingActionTypes.UPDATE_SEATS_REQUEST:
                return this.updateSeatsRequest(state);
            case OrganizationBillingActionTypes.UPDATE_SEATS_SUCCESS:
                return this.updateSeatsSuccess(state, action);
            case OrganizationBillingActionTypes.UPDATE_SEATS_FAILURE:
                return this.updateSeatsFailure(state);

            case OrganizationBillingActionTypes.GET_SCHEDULED_SEATS_UPDATE_SUCCESS:
                return this.getScheduledSeatsUpdateSuccess(state, action);

            case OrganizationBillingActionTypes.CANCEL_SCHEDULED_SEATS_UPDATE_REQUEST:
                return this.cancelScheduledSeatsUpdateRequest(state);
            case OrganizationBillingActionTypes.CANCEL_SCHEDULED_SEATS_UPDATE_SUCCESS:
                return this.cancelScheduledSeatsUpdateSuccess(state);
            case OrganizationBillingActionTypes.CANCEL_SCHEDULED_SEATS_UPDATE_FAILURE:
                return this.cancelScheduledSeatsUpdateFailure(state, action);

            default:
                return state;
        }
    }
}

export default OrganizationBillingStore;
export { State as OrganizationBillingStoreState };
