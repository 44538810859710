import { electronAppSupports } from "../../../../foundation/electron/electronAppSupports";
import MenuItem, { MenuItemDescription } from "../../../../foundation/electron/MenuItem";

import { PLATFORM } from "../../../../foundation/enums";
import BarrelType from "../../../../foundation/model/BarrelType";
import NamingConvention from "../../../../foundation/model/NamingConvention";
import { getSupportedDensityScales, getOptimizedAssetsSelectedNames } from "../../../../foundation/utils/asset";
import BasicRecord from "../../../../foundation/utils/BasicRecord";
import { getDensityScaleRepresentation } from "../../../../foundation/utils/legacy";
import { isMacOS } from "../../../../foundation/utils/ua-utils";

import AssetSettings from "../../../containers/AppContainer/BarrelContainer/AssetSettings";

import BarrelHelpers from "../../../data/barrel/BarrelHelpers";
import fluxRuntime from "../../../data/fluxRuntime";
import SpaceHelpers from "../../../data/space/SpaceHelpers";

import MenuItemID from "./MenuItemID";
import MenuViewType from "./MenuViewType";
import separator from "./separator";

const newWindowMenuItem = electronAppSupports.multiWindow && isMacOS() ? [{
    label: "New Window",
    id: MenuItemID.NEW_WINDOW,
    accelerator: "Cmd+Shift+N"
}] : [];

const newBarrelMenuItem = ({ type, enabled }: { type: BarrelType; enabled: boolean; }): MenuItemDescription => ({
    label: type === BarrelType.PROJECT ? "New Project…" : "New Styleguide…",
    id: type === BarrelType.PROJECT ? MenuItemID.NEW_PROJECT : MenuItemID.NEW_STYLEGUIDE,
    enabled,
    accelerator: "CmdOrCtrl+N"
});

const openRecentBarrelSubmenu = (
    { type, enabled = true }: { type: BarrelType; enabled?: boolean; }
): MenuItemDescription => {
    if (!enabled) {
        return {
            label: "Open Recent",
            id: MenuItemID.OPEN_RECENT_PROJECT_SUBMENU,
            enabled: false
        };
    }

    const {
        [`${type}s` as "projects" | "styleguides"]: recentBarrels
    } = fluxRuntime.RecentlyLoadedBarrelsStore.getState();

    const submenuId = type === BarrelType.PROJECT
        ? MenuItemID.OPEN_RECENT_PROJECT_SUBMENU
        : MenuItemID.OPEN_RECENT_STYLEGUIDE_SUBMENU;
    const menuIdPrefix = type === BarrelType.PROJECT
        ? MenuItemID.OPEN_RECENT_PROJECT
        : MenuItemID.OPEN_RECENT_STYLEGUIDE;

    const submenu: MenuItem[] = recentBarrels.map(({ _id, name }) => ({
        id: `${menuIdPrefix}.${_id}`,
        label: name
    }));

    if (recentBarrels.length) {
        submenu.push(
            separator,
            {
                id: type === BarrelType.PROJECT
                    ? MenuItemID.CLEAR_RECENT_PROJECTS
                    : MenuItemID.CLEAR_RECENT_STYLEGUIDES,
                label: "Clear Menu"
            }
        );
    }

    return {
        label: "Open Recent",
        id: submenuId,
        enabled: recentBarrels.length > 0,
        submenu
    };
};

const getNamingConventionOptions = (assetNamingConvention: NamingConvention): MenuItemDescription[] => (
    [
        {
            id: MenuItemID.NAMING_CONVENTION_DEFAULT,
            type: "radio",
            label: "Recommended",
            checked: assetNamingConvention === NamingConvention.Default
        },
        {
            id: MenuItemID.NAMING_CONVENTION_CAMEL_CASE,
            type: "radio",
            label: "camelCase",
            checked: assetNamingConvention === NamingConvention.CamelCase
        },
        {
            id: MenuItemID.NAMING_CONVENTION_SNAKE_CASE,
            type: "radio",
            label: "snake_case",
            checked: assetNamingConvention === NamingConvention.SnakeCase
        },
        {
            id: MenuItemID.NAMING_CONVENTION_KEBAB_CASE,
            type: "radio",
            label: "kebab-case",
            checked: assetNamingConvention === NamingConvention.KebabCase
        },
        {
            id: MenuItemID.NAMING_CONVENTION_ORIGINAL,
            type: "radio",
            label: "Original",
            checked: assetNamingConvention === NamingConvention.Original
        }
    ]
);

const getBitmapScalesOptions = (
    projectType: PLATFORM | undefined,
    assetDensityScalePrefs: BasicRecord<number[]> | null
): MenuItemDescription[] => {
    if (!projectType) {
        return [];
    }

    return getSupportedDensityScales(projectType as PLATFORM).map(densityScale => ({
        id: `${MenuItemID.PROJECT_DENSITY_SCALE}.${densityScale}`,
        type: "checkbox",
        label: getDensityScaleRepresentation(projectType, densityScale),
        checked: Boolean(
            assetDensityScalePrefs &&
            assetDensityScalePrefs["png"] &&
            assetDensityScalePrefs["png"].includes(densityScale)
        )
    }));
};

const getOptimizeAssetsOptions = (selectedOptimizedAssets: string[]): MenuItemDescription[] => (
    [
        {
            id: MenuItemID.OPTIMIZE_ASSETS_PNG,
            type: "checkbox",
            label: "PNG",
            checked: selectedOptimizedAssets.includes("PNG")
        },
        {
            id: MenuItemID.OPTIMIZE_ASSETS_JPG,
            type: "checkbox",
            label: "JPG",
            checked: selectedOptimizedAssets.includes("JPG")
        },
        {
            id: MenuItemID.OPTIMIZE_ASSETS_SVG,
            type: "checkbox",
            label: "SVG",
            checked: selectedOptimizedAssets.includes("SVG")
        },
        separator,
        {
            id: MenuItemID.OPTIMIZE_ASSETS_ABOUT,
            label: "About Optimized Assets"
        }
    ]
);

function assetsSubMenu(): MenuItemDescription {
    const {
        assetNamingConvention, assetDensityScalePrefs, showOptimizedPNG, showOptimizedJPG, showOptimizedSVG
    } = fluxRuntime.BarrelStore.getState();
    const { type: projectType } = BarrelHelpers.getBarrel() ?? {};

    const selectedOptimizedAssets = getOptimizedAssetsSelectedNames({
        showOptimizedSVG,
        showOptimizedJPG,
        showOptimizedPNG
    });

    const submenu = [];

    if (AssetSettings.namingOptionVisible(projectType)) {
        submenu.push({
            id: MenuItemID.NAMING_CONVENTION,
            label: "Naming Convention",
            submenu: getNamingConventionOptions(assetNamingConvention)
        });
    }

    submenu.push({
        id: MenuItemID.BITMAP_SCALES,
        label: "Bitmap Scales",
        submenu: getBitmapScalesOptions(projectType as PLATFORM, assetDensityScalePrefs)
    });

    submenu.push({
        id: MenuItemID.OPTIMIZE_ASSETS,
        label: "Optimize Assets",
        submenu: getOptimizeAssetsOptions(selectedOptimizedAssets)
    });

    return {
        label: "Assets",
        id: MenuItemID.ASSETS,
        enabled: true,
        submenu
    };
}

function workspaceProjectsMenu(): MenuItem {
    // TODO: New Window, Color Represantation, Export Colors as
    const canCreateProject = SpaceHelpers.canCreateProject();

    return new MenuItem({
        id: MenuItemID.PROJECT_SUBMENU,
        label: "Project",
        submenu: [
            newBarrelMenuItem({ type: BarrelType.PROJECT, enabled: !!canCreateProject }),
            ...newWindowMenuItem,
            openRecentBarrelSubmenu({ type: BarrelType.PROJECT })
        ]
    });
}

function styleguideMenu(): MenuItem {
    // TODO: New Window, Color Represantation, Export Colors as
    const canCreateStyleguide = SpaceHelpers.canCreateStyleguide();

    return new MenuItem({
        id: MenuItemID.STYLEGUIDE_SUBMENU,
        label: "Styleguide",
        submenu: [
            newBarrelMenuItem({ type: BarrelType.STYLEGUIDE, enabled: !!canCreateStyleguide }),
            ...newWindowMenuItem,
            openRecentBarrelSubmenu({ type: BarrelType.STYLEGUIDE })
        ]
    });
}

function projectMenu(): MenuItem {
    // TODO: New Window, Color Represantation, Export Colors as
    const canCreateProject = SpaceHelpers.canCreateProject();

    return new MenuItem({
        id: MenuItemID.PROJECT_SUBMENU,
        label: "Project",
        submenu: [
            newBarrelMenuItem({ type: BarrelType.PROJECT, enabled: !!canCreateProject }),
            ...newWindowMenuItem,
            openRecentBarrelSubmenu({ type: BarrelType.PROJECT }),
            separator,
            assetsSubMenu()
        ]
    });
}

function loginMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.PROJECT_SUBMENU,
        label: "Project",
        submenu: [
            newBarrelMenuItem({ type: BarrelType.PROJECT, enabled: false }),
            openRecentBarrelSubmenu({ type: BarrelType.PROJECT, enabled: false })
        ]
    });
}

function disabledBarrelMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.PROJECT_SUBMENU,
        label: "Project",
        enabled: false
    });
}

export default function barrelSubmenu(menuViewType: MenuViewType | null) {
    switch (menuViewType) {
        case MenuViewType.WORKSPACE_PROJECTS:
        case MenuViewType.ACTIVITIES:
            return workspaceProjectsMenu();
        case MenuViewType.WORKSPACE_STYLEGUIDES:
        case MenuViewType.STYLEGUIDE:
            return styleguideMenu();
        case MenuViewType.PROJECT_DASHBOARD:
        case MenuViewType.PROJECT_FLOWS:
        case MenuViewType.PROJECT_STYLEGUIDE:
        case MenuViewType.SCREEN:
            return projectMenu();
        case MenuViewType.LOGIN:
            return loginMenu();
        default:
            return disabledBarrelMenu();
    }
}
