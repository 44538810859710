/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import SpaceActionTypes from "./SpaceActionTypes";
import * as SpaceActionPayloads from "./SpaceActionPayloads";
import { AllPayloads } from "../payloads";
import { SpaceScrollStoreState as State } from "./types";

class WorkspaceScrollStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            scrollToHighlightedBarrel: false,
            barrelToScroll: null
        };
    }

    setBarrelToScroll(state: State, {
        barrelId
    }: SpaceActionPayloads.SetBarrelToScroll): State {
        return {
            ...state,
            barrelToScroll: barrelId,
            scrollToHighlightedBarrel: false
        };
    }

    scrolledToBarrel(state: State): State {
        return {
            ...state,
            barrelToScroll: null,
            scrollToHighlightedBarrel: false
        };
    }

    scrollToHighlightedBarrel(state: State, {
        updateState: {
            scrollToHighlightedBarrel
        }
    }: SpaceActionPayloads.UpdatePageState): State {
        return {
            ...state,
            barrelToScroll: null,
            scrollToHighlightedBarrel: !!scrollToHighlightedBarrel
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case SpaceActionTypes.SET_BARREL_TO_SCROLL:
                return this.setBarrelToScroll(state, action);
            case SpaceActionTypes.SCROLLED_TO_BARREL:
                return this.scrolledToBarrel(state);
            case SpaceActionTypes.UPDATE_PAGE_STATE:
                return this.scrollToHighlightedBarrel(state, action);
            default:
                return state;
        }
    }
}

export default WorkspaceScrollStore;
