enum ActionTypes {
    OPEN_SCREEN = "screen/openScreen",
    ENABLE_PERCENTAGE_MODE = "screen/enablePercentageMode",
    DISABLE_PERCENTAGE_MODE = "screen/disablePercentageMode",
    ADD_SCREEN = "screen/addScreen",
    ADD_SCREEN_TILES = "screen/addScreenTiles",
    UPDATE_NAME = "screen/updateName",
    REMOVE_SCREEN = "screen/removeScreen",
    OPEN_TAG_MANAGER = "screen/openTagManager",
    CLOSE_TAG_MANAGER = "screen/closeTagManager",
    HIDE_COMPONENT_VARIANTS_ONBOARDING_HINTBOX = "screen/hideComponentVariantsOnboardingHintbox",
    HIDE_IMAGE_PROCESSING_HINTBOX = "screen/hideImageProcessingHintbox",
    NOTIFY_ASSETS_TAB_OPENED = "screen/notifyAssetsTabOpened",
    SET_ONBOARDING_STEP = "screen/setOnboardingStep",
    COMPLETE_ONBOARDING_STEP = "screen/completeOnboardingStep",
    SET_ONBOARDING_INFO_VISIBILITY = "screen/setOnboardingInfoVisibility",
    HIDE_ONBOARDING_FLOW_HINTBOX = "screen/hideFlowHintbox",
    ENTER_STAGE_MODE = "screen/enterStageMode",
    LEAVE_STAGE_MODE = "screen/leaveStageMode",
    SHOW_STAGE_COMMENTS = "screen/showStageComments",
    HIDE_STAGE_COMMENTS = "screen/hideStageComments",
    HIDE_STAGE_MODE_FIRST_TIME_LANDING = "screen/hideStageModeFirstTimeLanding",
    SHARE_STAGE_MODE_SCREEN_URL = "screen/shareStageModeScreenUrl",
    CLOSE_STAGE_MODE_SIDEBAR = "screen/closeStageModeSidebar",
    APPLY_STAGE_MODE_HISTORY_OPERATION = "screen/applyStageModeHistoryOperation",
    FLASH_STAGE_MODE_HOTSPOTS = "screen/flashStageModeHotspots",
    FLASH_STAGE_MODE_HOTSPOTS_DONE = "screen/flashStageModeHotspotsDone",

    // MARK = Dots action types
    SET_DOTS = "screen/setDots",
    SELECT_DOT = "screen/selectDot",
    SAVE_NOTE = "screen/saveNote",
    CLOSE_DOT_POPUP = "screen/closeDotPopup",
    CHANGE_DOT_FILTER = "screen/changeDotFilter",
    DOTS_FLASHED = "screen/dotsFlashed",
    UNHIGHLIGHT_COMMENT = "screen/unhighlightComment",
    ADD_DOT = "screen/addDot",
    UPDATE_DOT = "screen/updateDot",
    UPDATE_DOT_COLOR = "screen/updateDotColor",
    UPDATE_DOT_POSITION = "screen/updateDotPosition",
    UPDATE_DOT_STATUS = "screen/updateDotStatus",
    REMOVE_DOT = "screen/removeDot",
    CREATE_COMMENT = "screen/createComment",
    ADD_COMMENT = "screen/addComment",
    UPDATE_COMMENT = "screen/updateComment",
    REMOVE_COMMENT = "screen/removeComment",
    OPEN_DOT_MENU = "screen/openDotMenu",
    CLOSE_DOT_MENU = "screen/closeDotMenu",

    // MARK = Annotations action types
    SET_ANNOTATIONS = "screen/setAnnotations",
    SELECT_ANNOTATION = "screen/selectAnnotation",
    UPDATE_ANNOTATION_POSITION = "screen/updateAnnotationPosition",
    UPDATE_ANNOTATION_TEXT = "screen/updateAnnotationText",
    UPDATE_ANNOTATION_TYPE = "screen/updateAnnotationType",
    DELETE_ANNOTATION_TYPE = "screen/deleteAnnotationType",
    REMOVE_ANNOTATION = "screen/removeAnnotation",
    ADD_ANNOTATION = "screen/addAnnotation",
    CREATE_ANNOTATION = "screen/createAnnotation",
    UPDATE_ANNOTATION = "screen/updateAnnotation",
    CHANGE_ANNOTATION_FILTER = "screen/changeAnnotationFilter",
    SAVE_ANNOTATION_TEXT = "screen/saveAnnotationText",
    HIDE_ANNOTATION_LANDING = "screen/hideAnnotationLanding",
    CHANGE_ANNOTATION_CONFIG_FILTER = "screen/changeAnnotationConfigFilter",
    UNLINK_ANNOTATION = "screen/unlinkAnnotation",

    // MARK = Note action types
    ENABLE_NOTE_MODE = "screen/enableNoteMode",
    DISABLE_NOTE_MODE = "screen/disableNoteMode",
    CREATE_NOTE = "screen/createNote",
    SHOW_NOTES = "screen/showNotes",
    HIDE_NOTES = "screen/hideNotes",
    CHANGE_NOTE_STATUS_FILTER = "screen/changeNoteStatusFilter",
    CHANGE_NOTE_COLOR_FILTER = "screen/changeNoteColorFilter",

    // MARK = Flow action types
    GET_FLOWS = "screen/getFlows",

    // MARK = Flow links action types
    SHOW_FLOW_LINKS = "screen/showFlowLinks",
    HIDE_FLOW_LINKS = "screen/hideFlowLinks",

    // Mark = Versions action types
    GET_VERSIONS = "screen/getVersions",
    SET_VERSIONS = "screen/setVersions",
    SET_SNAPSHOT = "screen/setSnapshot",
    GET_ASSET_STATS = "screen/getAssetStats",

    ADD_VERSION = "screen/addVersion",
    UPDATE_COMMIT_COLOR = "screen/updateCommitColor",
    UPDATE_COMMIT_MESSAGE = "screen/updateCommitMessage",
    REMOVE_VERSION = "screen/removeVersion",
    SET_SOURCE_FILE = "screen/setSourceFile",
    SET_VERSION_DIFFS = "screen/setVersionDiffs",
    SHOW_TOO_MANY_DIFFS = "screen/showTooManyDiffs",
    HIDE_DIFF_POPUP = "screen/hideDiffPopup",
    SHOW_VERSION_DIFF_ANNOUNCEMENT_POPUP = "screen/showVersionDiffAnnouncementPopup",
    HIDE_VERSION_DIFF_ANNOUNCEMENT_POPUP = "screen/hideVersionDiffAnnouncementPopup",

    // Mark = Variant action types
    REMOVE_SCREEN_VARIANT = "screen/removeScreenVariant",
    ADD_SCREEN_VARIANT = "screen/addScreenVariant",
    RENAME_SCREEN_VARIANT = "screen/renameScreenVariant",
    REORDER_SCREEN_VARIANT = "screen/reorderScreenVariant",
    SELECT_SCREEN_VARIANT = "screen/selectScreenVariant",

    CHANGE_COMPONENTS_FILTER = "screen/changeComponentsFilter",
    SET_LAST_OPEN_DATES = "screen/setLastOpenDates",
    UPDATE_DOTPOPUP_REMARK_TYPE = "screen/updateDotPopupRemarkType",
    SET_ACTIVE_REMARK_TYPE_TAB = "screen/setActiveRemarkTypeTab"
}

export default ActionTypes;
