import electron from "../../../../foundation/electron";
import { WorkspaceTabs, ProjectViewTypes } from "../../../../foundation/enums";
import BarrelType from "../../../../foundation/model/BarrelType";

import MenuViewType from "./MenuViewType";
import applicationSubmenu from "./applicationSubmenu";
import barrelSubmenu from "./barrelSubmenu";
import viewSubmenu from "./viewSubmenu";
import editSubmenu from "./editSubmenu";
import developSubmenu from "./developSubmenu";
import windowSubmenu from "./windowSubmenu";
import helpSubmenu from "./helpSubmenu";
import MenuContext from "./MenuContext";
import UpdateAppMenuParams from "./UpdateAppMenuParams";

let lastMenuViewType: MenuViewType | null = null;

function createMenuTemplate({
    showBadge,
    appUpdaterState,
    pluginUpdaterState,
    installUpdatesAutomatically,
    withResetAppZoomFactor = true,
    themePreference,
    sketchPreferences
}: MenuContext,
menuViewType: MenuViewType | null) {
    return [
        ...applicationSubmenu(menuViewType, { appUpdaterState, pluginUpdaterState, sketchPreferences }),
        barrelSubmenu(menuViewType),
        editSubmenu(menuViewType),
        viewSubmenu(menuViewType, { showBadge, withResetAppZoomFactor, themePreference }),
        developSubmenu(menuViewType),
        windowSubmenu(menuViewType),
        helpSubmenu(menuViewType, { appUpdaterState, pluginUpdaterState, installUpdatesAutomatically })
    ];
}

function forceReloadAppMenu(menuViewType?: MenuViewType) {
    update(menuViewType || lastMenuViewType);
}

function update(menuViewType: MenuViewType | null) {
    electron!.actions.unregisterGetMenuTemplateListeners();

    electron!.actions.onGetMenuTemplate(
        (_: unknown, { context }: { context: MenuContext; }) => {
            electron!.actions.sendMenuTemplateV3({
                menuTemplate: createMenuTemplate(context, menuViewType)
            });
        }
    );

    electron!.actions.sendGetAppMenuParameters();
}

function getMenuViewType(params: Partial<UpdateAppMenuParams>): MenuViewType | null {
    const { page, selectedTab, barrelType, selectedView } = params;

    if (page === "workspace") {
        return selectedTab === WorkspaceTabs.PROJECTS
            ? MenuViewType.WORKSPACE_PROJECTS
            : MenuViewType.WORKSPACE_STYLEGUIDES;
    }

    if (page === "activities") {
        return MenuViewType.ACTIVITIES;
    }

    if (barrelType === BarrelType.PROJECT) {
        if (selectedView === ProjectViewTypes.DASHBOARD) {
            return MenuViewType.PROJECT_DASHBOARD;
        }

        if (selectedView === ProjectViewTypes.FLOW) {
            return MenuViewType.PROJECT_FLOWS;
        }

        if (selectedView === ProjectViewTypes.STYLEGUIDE) {
            return MenuViewType.PROJECT_STYLEGUIDE;
        }

        if (selectedView === ProjectViewTypes.SCREEN) {
            return MenuViewType.SCREEN;
        }
    }

    if (barrelType === BarrelType.STYLEGUIDE) {
        return MenuViewType.STYLEGUIDE;
    }

    return null;
}

function updateAppMenu(params: UpdateAppMenuParams) {
    const menuViewType = getMenuViewType(params);
    lastMenuViewType = menuViewType;
    update(menuViewType);
}

export { createMenuTemplate, updateAppMenu, forceReloadAppMenu, getMenuViewType };
