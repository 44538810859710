/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import NotificationsActionTypes from "./NotificationsActionTypes";
import * as Payloads from "./NotificationsActionPayloads";
import Notification from "../../../foundation/api/model/notifications/Notification";
import ActivitiesActionTypes from "../activities/ActivitiesActionTypes";
import * as ActivitiesActionPayloads from "../activities/ActivitiesActionPayloads";
import { Dispatcher } from "flux";
import { AllPayloads } from "../payloads";

interface State {
    notifications: Notification[];
    loading: boolean | null;
    error: unknown;
}

class NotificationsStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            notifications: [],
            loading: null,
            error: null
        };
    }

    getNotificationsRequested(state: State): State {
        return Object.assign({}, state, {
            loading: true
        });
    }

    getNotificationsSuccess(state: State, {
        notifications
    }: Payloads.GetSuccess): State {
        return Object.assign({}, state, {
            notifications,
            loading: false
        });
    }

    getNotificationsFailed(state: State, {
        error
    }: Payloads.GetFailed): State {
        return Object.assign({}, state, {
            loading: false,
            error
        });
    }

    /**
     * If a new event is added to an old notification:
     * - Update old data,
     * - Add new data's event(s) to head of old one's.
     * - Move resulting notification to the head of notification list
     * Otherwise (A new notification is created)
     * - Add new notification to the head of notification list
     */
    addNotification(state: State, {
        notification: newNotification
    }: Payloads.Add): State {
        const updatedNotification = state.notifications.find(({ _id }) => _id === newNotification._id);
        const resultNotification = {
            ...newNotification,
            events: [...newNotification.events, ...updatedNotification?.events ?? []]
        };
        const notifications = [
            resultNotification,
            ...state.notifications.filter(({ _id }) => _id !== newNotification._id)
        ];

        return {
            ...state,
            notifications
        };
    }

    setReadStatus(state: State, notificationsToUpdate: Notification[], markedAsRead: boolean): State {
        let newState = state;
        for (const { _id, events } of notificationsToUpdate) {
            newState = this.setEventReadStatus(newState, _id, events.map(event => event._id), markedAsRead);
        }
        return newState;
    }

    setReadStatusById(state: State, {
        nids,
        markedAsRead
    }: Payloads.SetReadStatusOffline): State {
        const notifications = state.notifications.map(notification => (
            (!nids.includes(notification._id)
                ? notification
                : {
                    ...notification,
                    events: notification.events.map(event => ({
                        ...event,
                        markedAsRead
                    }))
                })
        ));
        return {
            ...state,
            notifications
        };
    }

    restoreReadStatus(state: State, {
        notifications: restoredNotifications
    }: Payloads.RestoreReadStatus): State {
        const notifications = state.notifications.map(notification => {
            const restoredNotification = restoredNotifications.find(({ _id }) => _id === notification._id);
            return !restoredNotification
                ? notification
                : {
                    ...notification,
                    events: notification.events.map(event => {
                        const restoredEvent = restoredNotification.events.find(({ _id }) => _id === event._id);
                        return !restoredEvent
                            ? event
                            : {
                                ...event,
                                markedAsRead: restoredEvent.markedAsRead
                            };
                    })
                };
        });

        return {
            ...state,
            notifications
        };
    }

    setEventReadStatus(state: State, nid: string, eventIds: string[], markedAsRead: boolean): State {
        const notifications = state.notifications.map(notification => {
            if (nid !== notification._id) {
                return notification;
            }

            const events = notification.events.map(event => (
                !eventIds.includes(event._id)
                    ? event
                    : {
                        ...event,
                        markedAsRead
                    }
            ));

            return {
                ...notification,
                events
            };
        });

        return {
            ...state,
            notifications
        };
    }

    deleteEvent(state: State, {
        nid,
        eventId
    }: Payloads.DeleteEvent): State {
        const notifications = state.notifications.map(notification => {
            if (nid !== notification._id) {
                return notification;
            }

            const events = notification.events.filter(({ _id }) => _id !== eventId);

            return {
                ...notification,
                events
            };
        }).filter(({ events: { length } }) => length);

        return {
            ...state,
            notifications
        };
    }

    selectNotification(state: State, {
        activity: notification,
        eventIds,
        options: {
            autoSelect
        }
    }: ActivitiesActionPayloads.Select): State {
        if (autoSelect) {
            return state;
        }

        return this.setEventReadStatus(state, notification._id, eventIds, true);
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case NotificationsActionTypes.GetNotificationsRequested:
                return this.getNotificationsRequested(state);
            case NotificationsActionTypes.GetNotificationsSuccess:
                return this.getNotificationsSuccess(state, action);
            case NotificationsActionTypes.GetNotificationsFailed:
                return this.getNotificationsFailed(state, action);
            case NotificationsActionTypes.AddNotification:
                return this.addNotification(state, action);
            case NotificationsActionTypes.MarkAllAsRead:
                return this.setReadStatus(state, action.notifications, true);
            case NotificationsActionTypes.SetReadStatusOfflineByNotifications:
                return this.setReadStatus(state, action.notifications, action.markedAsRead);
            case NotificationsActionTypes.RestoreReadStatus:
                return this.restoreReadStatus(state, action);
            case NotificationsActionTypes.SetReadStatusOffline:
                return this.setReadStatusById(state, action);
            case NotificationsActionTypes.SetEventReadStatus:
                return this.setEventReadStatus(state, action.notification._id, action.eventIds, action.markedAsRead);
            case NotificationsActionTypes.SetEventReadStatusOffline:
                return this.setEventReadStatus(state, action.nid, action.eventIds, action.markedAsRead);
            case NotificationsActionTypes.DeleteEvent:
                return this.deleteEvent(state, action);
            case ActivitiesActionTypes.Select:
                return this.selectNotification(state, action);
            default:
                return state;
        }
    }
}

export default NotificationsStore;
export { State as NotificationsStoreState };
