import { isMacOS } from "../../../../foundation/utils/ua-utils";
import permissions from "../../../../foundation/utils/permissions";
import BarrelType from "../../../../foundation/model/BarrelType";
import Barrel from "../../../../foundation/model/Barrel";
import BarrelUser from "../../../../foundation/model/BarrelUser";
import Screen from "../../../../foundation/model/Screen";
import FlowNodeType from "../../../../foundation/api/model/flow/FlowNodeType";
import OrganizationMember from "../../../../foundation/api/model/organizations/OrganizationMember";
import MenuItem, { MenuItemDescription } from "../../../../foundation/electron/MenuItem";
import { isStyleguideComponent } from "../../../../foundation/utils/component";
import fluxRuntime from "../../../data/fluxRuntime";
import BarrelHelpers from "../../../data/barrel/BarrelHelpers";
import ScreenHelpers from "../../../data/screen/ScreenHelpers";
import FlowHelpers from "../../../data/flow/FlowHelpers";

import MenuViewType from "./MenuViewType";
import MenuItemID from "./MenuItemID";
import separator from "./separator";
import { electronAppSupports } from "../../../../foundation/electron/electronAppSupports";

type UserLike = BarrelUser | OrganizationMember | null | undefined;

const leaveBarrelMenuItem = (
    barrel: Barrel | null, barrelType: BarrelType, barrelUser: UserLike
): MenuItemDescription => {
    const menuItemId = barrelType === BarrelType.PROJECT ? MenuItemID.LEAVE_PROJECT : MenuItemID.LEAVE_STYLEGUIDE;
    const menuLabel = barrelType === BarrelType.PROJECT ? "Leave Project…" : "Leave Styleguide…";

    if (!barrel) {
        return {
            id: menuItemId,
            label: menuLabel,
            enabled: false
        };
    }

    return {
        id: menuItemId,
        label: menuLabel,
        enabled: barrelType === BarrelType.PROJECT
            ? permissions.canLeaveProject(barrelUser)
            : permissions.canLeaveStyleguide(barrelUser)
    };
};

const deleteBarrelMenuItem = (
    barrel: Barrel | null, barrelType: BarrelType, barrelUser: UserLike
): MenuItemDescription => {
    const menuItemId = barrelType === BarrelType.PROJECT ? MenuItemID.DELETE_PROJECT : MenuItemID.DELETE_STYLEGUIDE;
    const menuLabel = barrelType === BarrelType.PROJECT ? "Delete Project…" : "Delete Styleguide…";

    if (!barrel) {
        return {
            id: menuItemId,
            label: menuLabel,
            enabled: false
        };
    }

    return {
        id: menuItemId,
        label: menuLabel,
        enabled: barrelType === BarrelType.PROJECT
            ? permissions.canDeleteProject(barrelUser)
            : permissions.canDeleteStyleguide(barrelUser)
    };
};

const renameScreenMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.RENAME_SCREEN,
    label: "Rename Screen",
    enabled,
    accelerator: "Alt+R"
});

const deleteScreenMenuItem = ({ enabled, multiple }:
    { enabled: boolean; multiple?: boolean; }
): MenuItemDescription => ({
    id: MenuItemID.DELETE_SCREEN,
    label: multiple ? "Delete Screens" : "Delete Screen",
    enabled,
    accelerator: "CmdOrCtrl+Backspace"
});

const setAsThumbnailMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.SET_AS_THUMBNAIL,
    label: "Set as Thumbnail",
    enabled
});

const addOrManageTagMenuItem = (
    { enabled, hasTag }: { enabled: boolean; hasTag: boolean; }
): MenuItemDescription => ({
    id: MenuItemID.ADD_OR_MANAGE_TAG,
    label: hasTag ? "Manage tags…" : "Add Tag…",
    enabled,
    accelerator: "CmdOrCtrl+T"
});

const newSectionFromSelectionMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.NEW_SECTION_FROM_SELECTION,
    label: "New Section from Selection",
    enabled,
    accelerator: isMacOS() ? "Cmd+Ctrl+N" : "Ctrl+Shift+N"
});

const importPNGsMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.IMPORT_PNGS,
    label: "Import PNGs…",
    enabled,
    accelerator: "CmdOrCtrl+Shift+P"
});

const addAnotherCopyMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.ADD_ANOTHER_COPY,
    label: "Add Another Copy",
    enabled,
    accelerator: "CmdOrCtrl+D"
});

const groupMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.GROUP,
    label: "Group",
    enabled,
    accelerator: "CmdOrCtrl+G"
});

function getTagMenuDetails(
    barrel: Barrel | null,
    screen: Screen | null | undefined,
    barrelType: BarrelType,
    selectedScreens: string[]
) {
    if (barrel && screen) {
        return { enabled: true, hasTag: !!screen.tags?.length };
    }

    if (barrel && barrelType === BarrelType.PROJECT && selectedScreens.length) {
        const hasTag = barrel.screens!
            .filter(({ _id: screenId }) => selectedScreens.includes(screenId))
            .some(({ tags }) => tags && tags.length);
        return { enabled: true, hasTag };
    }

    return { enabled: false, hasTag: false };
}

function shouldEnableNewSectionFromSelection({
    selectedScreens,
    selectedComponentIds,
    barrel,
    barrelUser
}: {
    selectedScreens: string[];
    selectedComponentIds: string[];
    barrel: Barrel | null;
    barrelUser: BarrelUser | OrganizationMember | null | undefined;
}) {
    if (!barrel) {
        return false;
    }

    if (selectedScreens.length) {
        return permissions.canManageScreens(barrelUser);
    }

    if (selectedComponentIds.length) {
        return (
            permissions.canManageComponents(barrelUser) &&
            selectedComponentIds.every(selectedComponent => isStyleguideComponent(selectedComponent, barrel))
        );
    }

    return false;
}

function shouldEnableAddAnotherCopy({
    isFlowView,
    pid,
    flid,
    selectedNodes
}: {
    isFlowView: boolean;
    pid?: string;
    flid: string | null;
    selectedNodes: string[];
}): boolean {
    if (!isFlowView || !pid || !flid || !selectedNodes.length) {
        return false;
    }

    const { nodes } = FlowHelpers.getFlow(pid, flid) || {};

    if (!nodes) {
        return false;
    }

    const copiableNodes = nodes.filter(
        node => node.type === FlowNodeType.Screen && FlowNodeType.Shape && FlowNodeType.VariantGroup
    );

    return copiableNodes.length > 0;
}

function shouldEnableGroup({
    selectedNodes,
    barrelUser
}: {
    selectedNodes: string[];
    barrelUser: BarrelUser | OrganizationMember | null | undefined;
}) {
    if (!barrelUser || !permissions.canManageScreens(barrelUser)) {
        return false;
    }

    const { groups } = FlowHelpers.getFlow() || {};
    if (!groups) {
        return false;
    }

    const groupNodes = groups.flatMap(({ nodes }) => nodes);
    const selectedNodesWithNoGroup = selectedNodes.filter(
        selectedNode => !groupNodes.includes(selectedNode)
    );

    return selectedNodesWithNoGroup.length > 1;
}

function editMenu(menuViewType: MenuViewType): MenuItem {
    const { barrelType } = fluxRuntime.BarrelStore.getState();
    const { selectedScreens } = fluxRuntime.ScreenSelectionStore.getState();
    const { selectedComponentIds } = fluxRuntime.ComponentSelectionStore.getState();
    const { selectedNodes, currentFlowId } = fluxRuntime.FlowStore.getState();
    const barrel = BarrelHelpers.getBarrel();
    const barrelUser = BarrelHelpers.findUser();
    const screen = ScreenHelpers.getScreen();

    const singleScreenMenuItemEnabled = Boolean(
        barrel &&
        permissions.canManageScreens(barrelUser) &&
        (screen || selectedScreens.length === 1)
    );

    const multipleScreensMenuItemEnabled = Boolean(
        barrel &&
        permissions.canManageScreens(barrelUser) &&
        (screen || selectedScreens.length >= 1)
    );

    const isFlowView = menuViewType === MenuViewType.PROJECT_FLOWS;

    return new MenuItem({
        id: MenuItemID.EDIT_SUBMENU,
        label: "Edit",
        submenu: [
            { role: "undo" },
            { role: "redo" },
            separator,
            leaveBarrelMenuItem(barrel, barrelType, barrelUser),
            deleteBarrelMenuItem(barrel, barrelType, barrelUser),
            separator,
            renameScreenMenuItem({ enabled: singleScreenMenuItemEnabled }),
            deleteScreenMenuItem({ enabled: multipleScreensMenuItemEnabled, multiple: selectedScreens.length > 1 }),
            separator,
            setAsThumbnailMenuItem({ enabled: singleScreenMenuItemEnabled }),
            separator,
            addOrManageTagMenuItem(getTagMenuDetails(barrel, screen, barrelType, selectedScreens)),
            separator,
            newSectionFromSelectionMenuItem({
                enabled: shouldEnableNewSectionFromSelection({
                    selectedScreens, selectedComponentIds, barrel, barrelUser
                })
            }),
            separator,
            importPNGsMenuItem({
                enabled: !!barrel && barrelType === BarrelType.PROJECT && electronAppSupports.importPNGs
            }),
            separator,
            { role: "cut" },
            { role: "copy" },
            { role: "paste" },
            { role: "pasteAndMatchStyle" },
            addAnotherCopyMenuItem({
                enabled: shouldEnableAddAnotherCopy({
                    isFlowView, pid: barrel?._id, flid: currentFlowId, selectedNodes
                })
            }),
            groupMenuItem({ enabled: shouldEnableGroup({ selectedNodes, barrelUser }) }),
            { role: "delete" },
            { role: "selectAll" }
        ]
    });
}

function loginEditMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.EDIT_SUBMENU,
        label: "Edit",
        submenu: [
            { role: "undo" },
            { role: "redo" },
            separator,
            leaveBarrelMenuItem(null, BarrelType.PROJECT, null),
            deleteBarrelMenuItem(null, BarrelType.PROJECT, null),
            separator,
            renameScreenMenuItem({ enabled: false }),
            deleteScreenMenuItem({ enabled: false }),
            separator,
            setAsThumbnailMenuItem({ enabled: false }),
            separator,
            addOrManageTagMenuItem({ enabled: false, hasTag: false }),
            separator,
            newSectionFromSelectionMenuItem({ enabled: false }),
            separator,
            importPNGsMenuItem({ enabled: false }), // TODO: discuss if we should still have this?
            separator,
            { role: "cut" },
            { role: "copy" },
            { role: "paste" },
            { role: "pasteAndMatchStyle" },
            addAnotherCopyMenuItem({ enabled: false }),
            groupMenuItem({ enabled: false }),
            { role: "delete" },
            { role: "selectAll" }
        ]
    });
}

function disabledEditMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.EDIT_SUBMENU,
        label: "Edit",
        enabled: false
    });
}

export default function editSubmenu(menuViewType: MenuViewType | null) {
    switch (menuViewType) {
        case MenuViewType.WORKSPACE_PROJECTS:
        case MenuViewType.WORKSPACE_STYLEGUIDES:
        case MenuViewType.ACTIVITIES:
        case MenuViewType.PROJECT_DASHBOARD:
        case MenuViewType.PROJECT_FLOWS:
        case MenuViewType.PROJECT_STYLEGUIDE:
        case MenuViewType.STYLEGUIDE:
        case MenuViewType.SCREEN:
            return editMenu(menuViewType);
        case MenuViewType.LOGIN:
            return loginEditMenu();
        default:
            return disabledEditMenu();
    }
}
