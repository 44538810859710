import { ComponentChildren, Fragment, h } from "preact";
import { useEffect, useState } from "preact/hooks";

import classname from "../../../../../../../foundation/utils/classname";

import DragDropContainer from "../../../../../../../library/DragDropContainer";
import Button, { ButtonVariant } from "../../../../../../../library/v2/Button";
import IconLink from "../../../../../../../library/v2/Icons/IconLink";
import IconMenu from "../../../../../../../library/v2/Icons/IconMenu";
import IconPlusInCircle from "../../../../../../../library/v2/Icons/IconPlusInCircle";
import Menu from "../../../../../../../library/v2/Menu";
import { OverlayMenuHorizontalAligment } from "../../../../../../../library/v2/Menu/OverlayMenu";
import OnlyIconButton, { OnlyIconButtonVariant } from "../../../../../../../library/v2/OnlyIconButton";

import { BoardTabBarItemProps, BoardTabBarItemReorderPosition } from "./BoardTabBarItem";
import DragDropBoardTabBarItem from "./DragDropBoardTabBarItem";

import styles from "./BoardTabBar.module.css";

const HEIGHT = 32;
const ITEM_CONTEXT_MENU_OFFSET_X = 4;
const ITEM_CONTEXT_MENU_OFFSET_Y = 4;
const BUTTON_MENU_OFFSET_Y = 8;
const MENU_OVERLAY_OFFSET = 8;

enum MenuSource {
    Item = "item",
    AccessoryButton = "button"
}

interface Props {
    tabs: BoardTabBarItemProps["tab"][];
    selectedTabId: string;
    creatingTabId?: string;
    accessoryContent?: ComponentChildren;
    canManageBoards: boolean;
    onSelectTab?: (tabId: string) => void;
    onRenameBoard?: (tabId: string, name: string) => void;
    onCopyBoardLink?: (tabId: string) => void;
    onExportBoard?: () => void;
    onDeleteBoard?: (tabId: string) => void;
    onReorderBoard?: (tabId: string, fromIndex: number, toIndex: number) => void;
    onCreateBoard?: () => void;
    onSubmitCreateBoard?: (tabId: string, name?: string) => void;
}

export default function BoardTabBar({
    tabs,
    selectedTabId,
    creatingTabId,
    accessoryContent,
    canManageBoards,
    onSelectTab,
    onRenameBoard,
    onCopyBoardLink,
    onExportBoard,
    onDeleteBoard,
    onReorderBoard,
    onCreateBoard,
    onSubmitCreateBoard
}: Props) {
    const [renamingTabId, setRenamingTabId] = useState<string | undefined>(undefined);
    const [menu, setMenu] = useState<{
        tabId: string;
        origin: { x: number; y: number; };
        source: MenuSource;
    } | undefined>(undefined);
    const [reorderingTabIds, setReorderingTabIds] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        if (creatingTabId === undefined) {
            return;
        }

        setRenamingTabId(creatingTabId);
    }, [creatingTabId]);

    function handleClickShareButton() {
        onCopyBoardLink?.(selectedTabId);
    }

    function handleClickMenuButton(event: MouseEvent) {
        const target = event.currentTarget as HTMLDivElement;
        const rect = target.getBoundingClientRect();

        setMenu({
            tabId: selectedTabId,
            origin: {
                x: rect.right,
                y: rect.bottom + BUTTON_MENU_OFFSET_Y
            },
            source: MenuSource.AccessoryButton
        });
    }

    function handleCloseMenu() {
        setMenu(undefined);
    }

    function handleRenameBoard() {
        if (!menu) {
            return;
        }

        setRenamingTabId(menu.tabId);
    }

    function handleCopyBoardLink() {
        if (!menu) {
            return;
        }

        onCopyBoardLink?.(menu.tabId);
    }

    function handleDeleteBoard() {
        if (!menu) {
            return;
        }

        onDeleteBoard?.(menu.tabId);
    }

    function handleStartDrag() {
        setReorderingTabIds(tabs.map(tab => tab.id));
    }

    function handleEndDrag() {
        setReorderingTabIds(undefined);
    }

    function renderMenu() {
        if (!menu) {
            return null;
        }

        const horizontalAlignment = menu.source === MenuSource.Item
            ? OverlayMenuHorizontalAligment.Left
            : OverlayMenuHorizontalAligment.Right;

        return (
            <Menu.Overlay
                horizontalAlignment={horizontalAlignment}
                clickOrigin={menu.origin}
                offset={MENU_OVERLAY_OFFSET}
                onClose={handleCloseMenu}>
                {canManageBoards && (
                    <>
                        <Menu.Button onClick={handleRenameBoard}>Rename board</Menu.Button>
                        <Menu.Divider/>
                    </>
                )}
                <Menu.Button onClick={handleCopyBoardLink}>Copy board link</Menu.Button>
                {menu.source === MenuSource.AccessoryButton && (
                    <Menu.Button onClick={onExportBoard}>Export board as PDF</Menu.Button>
                )}
                {canManageBoards && (
                    <>
                        <Menu.Divider/>
                        <Menu.Button warning onClick={handleDeleteBoard}>Delete board</Menu.Button>
                    </>
                )}
            </Menu.Overlay>
        );
    }

    return (
        <div class={styles.boardTabBar}>
            <DragDropContainer
                class={styles.itemsWrapper}
                itemsClass={styles.items}
                disabled={!canManageBoards || creatingTabId !== undefined}
                onStartDrag={handleStartDrag}
                onEndDrag={handleEndDrag}>
                {tabs.map((tab, index) => {
                    const isSelected = tab.id === selectedTabId;
                    const isRenaming = tab.id === renamingTabId;
                    const isCreating = tab.id === creatingTabId;

                    const canSelect = !isSelected && !isCreating;

                    function handleSelect() {
                        if (!canSelect) {
                            return;
                        }

                        onSelectTab?.(tab.id);
                    }

                    function handleContextMenu(event: MouseEvent) {
                        if (isCreating) {
                            return;
                        }

                        event.preventDefault();

                        const target = event.currentTarget as HTMLDivElement;
                        const rect = target.getBoundingClientRect();

                        setMenu({
                            tabId: tab.id,
                            origin: {
                                x: rect.left + ITEM_CONTEXT_MENU_OFFSET_X,
                                y: rect.bottom + ITEM_CONTEXT_MENU_OFFSET_Y
                            },
                            source: MenuSource.Item
                        });
                    }

                    function handleSubmitRenameBoard(newName?: string) {
                        if (tab.id.startsWith("newBoard")) {
                            onSubmitCreateBoard?.(tab.id, newName);

                            setRenamingTabId(undefined);

                            return;
                        }

                        if (newName && newName !== tab.name) {
                            onRenameBoard?.(tab.id, newName);
                        }

                        setRenamingTabId(undefined);
                    }

                    function handleReorderHoverBoard(fromTabId: string, position: BoardTabBarItemReorderPosition) {
                        const fromIndex = tabs.findIndex(someTab => someTab.id === fromTabId);

                        let toIndex = index;
                        if (index > fromIndex) {
                            toIndex = position === BoardTabBarItemReorderPosition.Left ? index - 1 : index;
                        } else if (index < fromIndex) {
                            toIndex = position === BoardTabBarItemReorderPosition.Right ? index + 1 : index;
                        }

                        const reorderingTabs = [...tabs];
                        reorderingTabs.splice(toIndex, 0, reorderingTabs.splice(fromIndex, 1)[0]);
                        const newReorderingTabIds = reorderingTabs.map(someTab => someTab.id);

                        setReorderingTabIds(newReorderingTabIds);
                    }

                    function handleReorderDropBoard(fromTabId: string) {
                        if (!reorderingTabIds) {
                            return;
                        }

                        const fromIndex = tabs.findIndex(someTab => someTab.id === fromTabId);
                        const toIndex = reorderingTabIds.indexOf(fromTabId);
                        if (fromIndex === toIndex) {
                            return;
                        }

                        onReorderBoard?.(fromTabId, fromIndex, toIndex);
                    }

                    let reorderingIndexDelta: number | undefined;
                    if (reorderingTabIds) {
                        const reorderingIndex = reorderingTabIds.indexOf(tab.id);
                        reorderingIndexDelta = reorderingIndex - index;
                    }

                    return (
                        <DragDropBoardTabBarItem
                            key={tab.id}
                            tab={tab}
                            isSelected={isSelected}
                            isRenaming={isRenaming}
                            isContextMenuOpen={menu && menu.source === MenuSource.Item && menu.tabId === tab.id}
                            reorderProps={reorderingIndexDelta !== undefined
                                ? { indexDelta: reorderingIndexDelta }
                                : undefined}
                            onSelect={handleSelect}
                            onContextMenu={handleContextMenu}
                            onSubmitRename={handleSubmitRenameBoard}
                            onReorderHover={handleReorderHoverBoard}
                            onReorderDrop={handleReorderDropBoard}/>
                    );
                })}
                {canManageBoards && (
                    <OnlyIconButton
                        class={styles.createButton}
                        icon={IconPlusInCircle}
                        variant={OnlyIconButtonVariant.Ninja}
                        disabled={creatingTabId !== undefined}
                        onClick={onCreateBoard}/>
                )}
            </DragDropContainer>
            <div class={styles.accessoryContentContainer}>
                <Button
                    class={styles.shareButton}
                    variant={ButtonVariant.Ninja}
                    icon={IconLink}
                    onClick={handleClickShareButton}>
                    Share
                </Button>
                {accessoryContent}
                {canManageBoards && (
                    <OnlyIconButton
                        class={classname(styles.menuButton, {
                            [styles.isOpen]: menu && menu.source === MenuSource.AccessoryButton
                        })}
                        icon={IconMenu}
                        variant={OnlyIconButtonVariant.Ninja}
                        onClick={handleClickMenuButton}/>
                )}
            </div>
            {renderMenu()}
        </div>
    );
}

export { HEIGHT as BOARD_TAB_BAR_HEIGHT };
