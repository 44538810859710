enum ActionTypes {
    INIT = "slackPreferences/init",
    LOAD = "slackPreferences/load",
    OPEN = "slackPreferences/open",
    CLOSE = "slackPreferences/close",
    UPDATE_EVENT_GROUP = "slackPreferences/updateEventGroup",
    EVENT_GROUP_UPDATED = "slackPreferences/eventGroupUpdated",
    EVENT_GROUP_UPDATE_FAILED = "slackPreferences/eventGroupUpdateFailed",
    EVENT_UPDATED = "slackPreferences/eventUpdated"
}

export default ActionTypes;
