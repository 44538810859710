/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { AllPayloads } from "../payloads";
import * as Payloads from "./OnboardingGuidanceActionPayloads";

import OnboardingGuidanceActionTypes from "./OnboardingGuidanceActionTypes";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import FlowActionTypes from "../flow/FlowActionTypes";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import InspectableViewActionTypes from "../inspectableView/InspectableViewActionTypes";

import BasicRecord from "../../../foundation/utils/BasicRecord";
import { GuidanceDismissType, GuidanceEvent, GuidanceEventGroup, GuidancePriorities } from "./types";

interface GuidanceEventGroupState {
    currentEvent: GuidanceEvent | null;
    lastGuidanceDisplayedAt: number;
    eventQueue: GuidanceEvent[];
}

interface State {
    [GuidanceEventGroup.Dashboard]: GuidanceEventGroupState;
    [GuidanceEventGroup.Screen]: GuidanceEventGroupState;
    [GuidanceEventGroup.Styleguide]: GuidanceEventGroupState;
    events: BasicRecord<boolean> | null;
    dismissedTooltips: BasicRecord<boolean> | null;
    guidanceDelay: number; // In seconds
    guidancePriorities: GuidancePriorities | null;
    isEnabled: boolean;
    isDecisionLoaded: boolean;
}

class OnboardingGuidanceStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        const guidanceEventGroupState: GuidanceEventGroupState = {
            currentEvent: null,
            lastGuidanceDisplayedAt: 0,
            eventQueue: []
        };

        return {
            [GuidanceEventGroup.Dashboard]: { ...guidanceEventGroupState },
            [GuidanceEventGroup.Screen]: { ...guidanceEventGroupState },
            [GuidanceEventGroup.Styleguide]: { ...guidanceEventGroupState },
            events: null,
            dismissedTooltips: null,
            guidanceDelay: Infinity,
            guidancePriorities: null,
            isEnabled: false,
            isDecisionLoaded: false
        };
    }

    loadDecision(state: State, { isEnabled, guidanceDelay, guidancePriorities }: Payloads.LoadDecision): State {
        return {
            ...state,
            isEnabled,
            guidanceDelay,
            guidancePriorities,
            isDecisionLoaded: true
        };
    }

    loadData(state: State, {
        events,
        dismissedTooltips,
        guidanceLastDisplayDates
    }: Payloads.LoadData): State {
        const updatedState = Object.values(GuidanceEventGroup).reduce((acc, eventGroup) => {
            const lastGuidanceDisplayedAt = guidanceLastDisplayDates[eventGroup] ?? 0;

            return {
                ...acc,
                [eventGroup]: {
                    ...acc[eventGroup],
                    lastGuidanceDisplayedAt
                }
            };
        }, { ...state });

        return {
            ...updatedState,
            events,
            dismissedTooltips
        };
    }

    trackOnboardingGuidanceEvent(state: State, eventName: GuidanceEvent) {
        return {
            ...state,
            events: {
                ...state.events,
                [eventName]: true
            }
        };
    }

    resetCurrentEventOfEventGroup(state: State, eventGroup: GuidanceEventGroup): State {
        return {
            ...state,
            [eventGroup]: {
                ...state[eventGroup],
                currentEvent: null
            }
        };
    }

    updateLastDisplayDate(state: State, eventGroup: GuidanceEventGroup) {
        const guidanceEventGroupState = state[eventGroup];

        return {
            ...state,
            [eventGroup]: {
                ...guidanceEventGroupState,
                lastGuidanceDisplayedAt: Date.now()
            }
        };
    }

    dismissOnboardingGuidanceTooltip(state: State, action: Payloads.DismissOnboardingGuidanceTooltip) {
        const { eventName, eventGroup } = action;

        let updatedState = this.resetCurrentEventOfEventGroup(state, eventGroup);
        updatedState = this.updateLastDisplayDate(updatedState, eventGroup);
        updatedState = this.removeEventFromEventQueue(updatedState, action);
        updatedState = this.addRelatedEventToEventQueue(updatedState, action);

        return {
            ...updatedState,
            dismissedTooltips: {
                ...state.dismissedTooltips,
                [eventName]: true
            }
        };
    }

    setNextOnboardingGuidanceEvent(state: State, {
        eventGroup,
        eventName
    }: Payloads.SetNextOnboardingGuidanceEvent): State {
        const guidanceEventGroupState = state[eventGroup];

        return {
            ...state,
            [eventGroup]: {
                ...guidanceEventGroupState,
                currentEvent: eventName
            }
        };
    }

    addRelatedEventToEventQueue(state: State, {
        eventName,
        eventGroup,
        dismissType
    }: Payloads.DismissOnboardingGuidanceTooltip): State {
        if (dismissType !== GuidanceDismissType.ClickedToAction) {
            return state;
        }

        // This is implemented as a switch statement to make it easier to add new events in the future.
        // If this pattern repeats itself many times, this switch statement should be refactored to a map.
        switch (eventName) {
            case GuidanceEvent.CreateAnnotation:
                return this.addEventToEventQueue(state, {
                    eventName: GuidanceEvent.SelectAnnotationType,
                    eventGroup
                });
            default:
                return state;
        }
    }

    addEventToEventQueue(state: State, { eventName, eventGroup }: {
        eventName: GuidanceEvent;
        eventGroup: GuidanceEventGroup;
    }) {
        const { eventQueue } = state[eventGroup];

        return {
            ...state,
            [eventGroup]: {
                ...state[eventGroup],
                eventQueue: [...eventQueue, eventName]
            }
        };
    }

    removeEventFromEventQueue(state: State, { eventName, eventGroup }: {
        eventName: GuidanceEvent;
        eventGroup: GuidanceEventGroup;
    }) {
        const { eventQueue } = state[eventGroup];

        return {
            ...state,
            [eventGroup]: {
                ...state[eventGroup],
                eventQueue: eventQueue.filter(event => event !== eventName)
            }
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case OnboardingGuidanceActionTypes.LOAD_DECISION:
                return this.loadDecision(state, action);
            case OnboardingGuidanceActionTypes.LOAD_DATA:
                return this.loadData(state, action);
            case OnboardingGuidanceActionTypes.TRACK_ONBOARDING_GUIDANCE_EVENT:
                return this.trackOnboardingGuidanceEvent(state, action.eventName);
            case OnboardingGuidanceActionTypes.DISMISS_ONBOARDING_GUIDANCE_TOOLTIP:
                return this.dismissOnboardingGuidanceTooltip(state, action);
            case OnboardingGuidanceActionTypes.SET_NEXT_ONBOARDING_GUIDANCE_EVENT:
                return this.setNextOnboardingGuidanceEvent(state, action);
            case OnboardingGuidanceActionTypes.RESET_ONBOARDING_GUIDANCE_GROUP_EVENT:
                return this.resetCurrentEventOfEventGroup(state, action.eventGroup);

            case BarrelActionTypes.DOWNLOAD_ASSETS:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.DownloadAssets);
            case BarrelActionTypes.ADD_MEMBERS: {
                if (action.withInvite) {
                    return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.InviteUser);
                }

                return state;
            }

            case DashboardActionTypes.CREATE_SECTION: {
                const { section: { path } } = action;
                const isSubsection = path && path.length > 0;

                if (isSubsection) {
                    return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.CreateSubSection);
                }

                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.CreateSection);
            }

            case FlowActionTypes.ADD_NODES_TO_FLOW_REQUEST:
            case FlowActionTypes.CREATE_FLOW_REQUEST:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.CreateFlow);

            case ScreenActionTypes.ADD_DOT: {
                if (action.syncWithApi) {
                    return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.CreateComment);
                }
                return state;
            }
            case ScreenActionTypes.ADD_ANNOTATION: {
                if (action.syncWithApi) {
                    return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.CreateAnnotation);
                }
                return state;
            }
            case ScreenActionTypes.SELECT_DOT:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.ViewComment);
            case ScreenActionTypes.SELECT_ANNOTATION:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.ViewAnnotation);
            case ScreenActionTypes.SELECT_SCREEN_VARIANT:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.ViewScreenVariant);
            case InspectableViewActionTypes.SET_VERSION_ID:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.ViewOldVersion);
            case InspectableViewActionTypes.SELECT_LAYER:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.InspectLayer);
            case InspectableViewActionTypes.SHOW_VERSION_DIFFS:
                return this.trackOnboardingGuidanceEvent(state, GuidanceEvent.ViewVersionDiff);

            default:
                return state;
        }
    }
}

export default OnboardingGuidanceStore;
export { State as OnboardingGuidanceStoreState };
