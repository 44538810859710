enum ActionTypes {
    RESET = "barrel/reset",
    LOAD = "barrel/load",
    LOAD_INTERMEDIATE = "barrel/loadIntermediate",
    PRELOAD_PROJECT_DASHBOARD = "barrel/prelaodProjectDashboard",
    SET_ORGANIZATION_ID = "barrel/setOrganizationId",
    CHANGE_SELECTED_VIEW = "barrel/changeSelectedView",
    ADD_TO_SLACK = "barrel/addToSlack",
    AUTHENTICATE_SLACK_INTEGRATION = "barrel/authSlack",
    AUTHENTICATE_SLACK_INTEGRATION_FAILED = "barrel/authSlackFailed",
    AUTHENTICATED_SLACK_INTEGRATION = "barrel/authenticatedSlackIntegration",
    // Data actions
    ADD = "barrel/add",
    ACTIVATE = "barrel/activate",
    ARCHIVE = "barrel/archive",
    REMOVE = "barrel/remove",
    COPY = "barrel/copy",
    UPDATE_NAME = "barrel/updateName",
    UPDATE_DESCRIPTION = "barrel/updateDescription",
    SET_EDITING_DESCRIPTION = "barrel/setEditingDescription",
    UPDATE_DENSITY_SCALE = "barrel/updateDensityScale",
    UPDATE_THUMBNAIL = "barrel/updateThumbnail",
    ADD_INTEGRATION = "barrel/addIntegration",
    REMOVE_INTEGRATION = "barrel/removeIntegration",
    ADD_MEMBERS = "barrel/addMembers",
    UPDATE_USER_ROLE = "barrel/updateUserRole",
    UPDATE_USER_DATA = "barrel/updateUserData",
    TRANSFER_OWNERSHIP = "barrel/transferOwnership",
    OWNERSHIP_TRANSFERRED = "barrel/ownershipTransferred",
    REMOVE_MEMBER = "barrel/removeMember",
    CLEAR_RECENTLY_LOADED_BARRELS = "barrel/clearRecentlyLoadedBarrels",
    UPDATE_VARIABLE_COLLECTION_DESCRIPTION = "barrel/updateVariableCollectionDescription",
    ADD_VARIABLE_COLLECTION = "barrel/addVariableCollection",
    DELETE_VARIABLE_COLLECTION = "barrel/deleteVariableCollection",
    UPDATE_VARIABLE_COLLECTION_GROUP_DESCRIPTION = "barrel/updateVariableCollectionGroupDescription",
    UPDATE_VARIABLE_ORDER = "barrel/updateVariableOrder",
    SET_SELECTED_SIDEBAR_TAB = "barrel/setSelectedSidebarTab",

    // Styleguide actions
    ADD_COLOR = "barrel/addColor",
    ADD_MULTIPLE_COLORS = "barrel/addMultipleColors",
    UPDATE_COLOR = "barrel/updateColor",
    UPDATE_COLORS_NAME = "barrel/updateColorsName",
    REMOVE_COLOR = "barrel/removeColor",
    REMOVE_COLORS = "barrel/removeColors",
    MIGRATE_COLORS = "barrel/migrateColors",
    UPDATE_COLORS_ORDER = "barrel/updateColorsOrder",
    ADD_TEXT_STYLE = "barrel/addTextStyle",
    ADD_MULTIPLE_TEXT_STYLES = "barrel/addMultipleTextStyles",
    UPDATE_TEXT_STYLE = "barrel/updateTextStyle",
    REMOVE_TEXT_STYLE = "barrel/removeTextStyle",
    REMOVE_ALL_TEXT_STYLES = "barrel/removeAllTextStyles",
    ADD_SPACING_SECTION = "barrel/addSpacingSection",
    UPDATE_SPACING_SECTION = "barrel/updateSpacingSection",
    REMOVE_SPACING_SECTION = "barrel/removeSpacingSection",
    ADD_SPACING_TOKEN = "barrel/addSpacingToken",
    UPDATE_SPACING_TOKEN = "barrel/updateSpacingToken",
    REMOVE_SPACING_TOKEN = "barrel/removeSpacingToken",
    UPDATE_REM_PREFERENCES = "barrel/updateRemPreferences",
    ADD_COMPONENT = "barrel/addComponent",
    UPDATE_COMPONENT = "barrel/updateComponent",
    REMOVE_COMPONENT = "barrel/removeComponent",
    REMOVE_COMPONENTS = "barrel/removeComponents",
    SET_COMPONENT_SNAPSHOT = "barrel/setComponentSnapshot",
    SET_MULTIPLE_COMPONENT_SNAPSHOTS= "barrel/setMultipleComponentSnapshots",
    ADD_COMPONENT_VERSION = "barrel/addComponentVersion",
    UPDATE_COMPONENTS_ORDER = "barrel/updateComponentsOrder",
    ADD_COMPONENT_SECTION = "barrel/addComponentSection",
    UPDATE_COMPONENT_SECTION = "barrel/updateComponentSection",
    MERGE_COMPONENT_SECTION = "barrel/mergeComponentSection",
    REMOVE_COMPONENT_SECTION = "barrel/removeComponentSection",
    REMOVE_COMPONENT_SECTIONS = "barrel/removeComponentSections",
    UPDATE_COMPONENT_SECTIONS_ORDER = "barrel/updateComponentSectionsOrder",
    COPY_COMPONENT_SECTION_WEB_LINK = "barrel/copyComponentSectionWebLink",
    COPY_COMPONENT_SECTION_APP_URI = "barrel/copyComponentSectionAppURI",
    FETCH_COMPONENT_SECTION_SHORT_LINK = "barrel/fetchComponentSectionShortLink",
    SCROLLED_TO_COMPONENT = "barrel/scrolledToComponent",

    GO_TO_DASHBOARD = "barrel/goToDashboard",
    GO_TO_STYLEGUIDE = "barrel/goToStyleguide",
    GO_TO_FLOW = "barrel/goToFlow",
    CHANGE_ASSET_NAME = "barrel/changeAssetName",
    CHANGE_ASSET_DENSITY_SCALE_PREF = "barrel/changeAssetDensityScalePref",
    CHANGE_ASSET_NAMING_CONVENTION = "barrel/changeAssetNamingConvention",
    TOGGLE_SVG_OPTIMIZATION = "barrel/toggleSVGOptimization",
    TOGGLE_PNG_OPTIMIZATION = "barrel/togglePNGOptimization",
    TOGGLE_JPG_OPTIMIZATION = "barrel/toggleJPGOptimization",
    CHANGE_COLOR_FORMAT_PREFERENCE = "barrel/changeColorFormatPreference",
    FETCH_ASSET_DOWNLOAD_TARGETS = "barrel/fetchAssetDownloadTargets",
    SET_ASSET_DOWNLOAD_TARGETS = "barrel/setAssetDownloadTargets",
    OPEN_ASSET_DOWNLOAD_TARGET = "barrel/openAssetDownloadTarget",
    DELETE_ASSET_DOWNLOAD_TARGET = "barrel/deleteAssetDownloadTarget",
    DOWNLOAD_ASSETS = "barrel/downloadAssets",
    EXPORT_CODE = "barrel/exportCode",
    COPY_CODE = "barrel/copyCode",
    HIDE_NO_VECTOR_ASSET_INFO = "barrel/hideNoVectorAssetInfo",
    HIDE_DENSITY_MISMATCH_WARNING = "barrel/hideDensityMismatchWarning",
    HIDE_SOURCE_FILE_INFO = "barrel/hideSourceFileInfo",
    DISMISS_FLOW_LINK_INFO = "barrel/dismissFlowLinkInfo",
    DISMISS_NO_RESOURCE_INFO = "barrel/dismissNoResourceInfo",
    HIDE_SLACK_RECONNECTION_AUNTIE = "barrel/hideSlaclReconnectionAuntie",
    TOGGLE_SCENE = "barrel/toggleScene",
    INVITE_USER = "barrel/inviteUser",
    INVITE_USER_BY_TAG = "barrel/inviteUserByTag",
    REINVITE_USER = "barrel/reinviteUser",
    JOIN = "barrel/join",
    STOP_JOIN_LOADING = "barrel/stopLoadingJoin",
    MOVE_RESOURCES_REQUEST = "barrel/moveResourcesRequest",
    MOVE_RESOURCES_SUCCESS = "barrel/moveResourcesSuccess",
    MOVE_RESOURCES_FAIL = "barrel/movesResourcesFail",
    ATTACH_JIRA_ISSUE = "barrel/attachJiraIssue",
    REMOVE_JIRA_ISSUE_ATTACHMENT = "barrel/removeJiraIssueAttachment",
    UPDATE_JIRA_ISSUE = "barrel/updateJiraIssue",
    ENABLE_DEV_MODE = "barrel/enableDevMode",
    DISABLE_DEV_MODE = "barrel/disableDevMode",
    SET_LOCAL_CONNECTED_COMPONENTS = "barrel/setLocalConnectedComponents",
    RELOAD_LOCAL_CONNECTED_COMPONENTS = "barrel/reloadLocalConnectedComponents",
    UPDATE_CONNECTED_COMPONENTS = "barrel/updateConnectedComponents",
    DELETE_CONNECTED_COMPONENTS = "barrel/deleteConnectedComponents",
    CREATE_CONNECTED_COMPONENT_ITEM = "barrel/createConnectedComponentItem",
    UPDATE_CONNECTED_COMPONENT_ITEM = "barrel/updateConnectedComponentItem",
    DELETE_CONNECTED_COMPONENT_ITEM = "barrel/deleteConnectedComponentItem",
    SET_CONNECTED_COMPONENTS_LOCAL_WORKSPACE = "barrel/setConnectedComponentsLocalWorkspace",
    GET_CONNECTED_COMPONENTS_EDITOR_INFO = "barrel/getConnectedComponentsEditorInfo",
    SET_CONNECTED_COMPONENTS_EDITOR_INFO = "barrel/setConnectedComponentsEditorInfo",
    SET_CONNECTED_COMPONENTS_EDITOR_PREFERENCE = "barrel/setConnectedComponentsEditorPreference",
    SELECT_CONNECTED_COMPONENTS_WORKSPACE_FOLDER = "barrel/selectConnectedComponentsWorkspaceFolder",
    OPEN_COMPONENT_FILE_IN_EDITOR = "barrel/openComponentFileInEditor",
    SET_CONNECTED_COMPONENTS_WORKSPACE_PATH = "barrel/setConnectedComponentsWorkspacePath",
    UPDATE_WORKFLOW_STATUS = "barrel/updateWorkflowStatus",
    UPDATE_WORKFLOW_STATUSES = "barrel/updateWorkflowStatuses",
    DELETE_WORKFLOW_STATUS = "barrel/deleteWorkflowStatus",
    DELETE_WORKFLOW_STATUSES = "barrel/deleteWorkflowStatuses",
    MARK_REVIEWER_HINTBOX_AS_SEEN = "barrel/markReviewerHintboxAsSeen",
    SET_SELECTED_COMPONENTS = "barrel/setSelectedComponents",
    CLEAR_COMPONENT_SELECTION = "barrel/clearComponentSelection",
    SET_SELECTED_COLORS = "barrel/setSelectedColors",
    CLEAR_COLOR_SELECTION = "barrel/clearColorSelection",
    MARK_COMPONENT_VERSIONS_NEW_BADGE_MENU_AS_SEEN = "barrel/markComponentVersionsNewBadgeMenuAsSeen",
    MARK_ANDROID_RES_FOLDER_REMINDER_AS_SEEN = "barrel/markAndroidResFolderReminderAsSeen",
    MARK_SUGGESTED_STATUS_TAGS_AS_AUTO_CREATED = "barrel/markSuggestedStatusTagsAsAutoCreated"
}

export default ActionTypes;
