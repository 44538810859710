/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { AllPayloads } from "../payloads";
import Dispatcher from "flux/lib/Dispatcher";
import ApprovalsActionTypes from "./ApprovalsActionTypes";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import * as ApprovalsActionPayloads from "./ApprovalsActionPayloads";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import * as DashboardActionPayloads from "../dashboard/DashboardActionPayloads";

import Approval from "../../../foundation/api/model/approvals/Approval";
import ProjectApprovalScreen from "../../../foundation/api/model/approvals/ProjectApprovalScreen";

type State = {
    [pid: string]: ProjectApprovalScreen[];
};

class ApprovalsProjectDataStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {};
    }

    getAddedProjectScreens(sid: string, screens: ProjectApprovalScreen[], approval: Approval) {
        if (approval.requestedFrom?._id === Zeplin.user._id) {
            const projectScreens = [...(screens || [])];
            const projectScreenIndex = projectScreens.findIndex(({ _id }) => _id === sid);

            if (projectScreenIndex > -1) {
                projectScreens[projectScreenIndex].approvals.push(approval);
            } else {
                projectScreens.push({
                    _id: sid,
                    approvals: [approval]
                });
            }

            return projectScreens;
        }

        return screens;
    }

    getDeletedProjectState(state: State, pid: string) {
        const updatedProjects = { ...state };
        delete updatedProjects[pid];
        return updatedProjects;
    }

    addApproval(state: State, {
        pid,
        sid,
        approval
    }: ApprovalsActionPayloads.AddApproval): State {
        const updatedProjectScreens = this.getAddedProjectScreens(
            sid,
            state[pid],
            approval
        );

        return {
            ...state,
            [pid]: updatedProjectScreens
        };
    }

    updateApproval(state: State, {
        pid,
        sid,
        approval
    }: ApprovalsActionPayloads.UpdateApproval): State {
        const projectApprovalList = state[pid]?.map(screen => {
            return screen._id !== sid
                ? screen
                : {
                    ...screen,
                    approvals: screen.approvals.map(
                        screenApproval => (screenApproval._id !== approval._id ? screenApproval : approval)
                    )
                };
        });

        return projectApprovalList ? {
            ...state,
            [pid]: projectApprovalList
        } : state;
    }

    setProjectApprovals(state: State, {
        pid,
        approvals
    }: ApprovalsActionPayloads.SetProjectApprovals): State {
        return {
            ...state,
            [pid]: approvals
        };
    }

    deleteApproval(state: State, {
        pid,
        sid,
        apid
    }: ApprovalsActionPayloads.DeleteApproval): State {
        const projectApprovalList = state[pid]?.map(screen => {
            return screen._id !== sid
                ? screen
                : { ...screen, approvals: screen.approvals.filter(approval => approval._id !== apid) };
        });

        return projectApprovalList ? {
            ...state,
            [pid]: projectApprovalList
        } : state;
    }

    deleteProjectApprovals(state: State, {
        bid
    }: BarrelActionPayloads.Remove): State {
        return this.getDeletedProjectState(state, bid);
    }

    deleteProjectScreens(state: State, {
        pid,
        items
    }: DashboardActionPayloads.DeleteScreens) {
        const deletedScreens = items.filter(item => item.type === "screen").map(({ _id }) => _id);
        const projectApprovalList = state[pid]?.filter(screen => !deletedScreens.includes(screen._id));

        if (!projectApprovalList) {
            return this.getDeletedProjectState(state, pid);
        }

        return {
            ...state,
            [pid]: projectApprovalList
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ApprovalsActionTypes.ADD_APPROVAL:
                return this.addApproval(state, action);

            case ApprovalsActionTypes.SET_PROJECT_APPROVALS:
                return this.setProjectApprovals(state, action);

            case ApprovalsActionTypes.UPDATE_APPROVAL:
                return this.updateApproval(state, action);

            case ApprovalsActionTypes.DELETE_APPROVAL:
                return this.deleteApproval(state, action);

            case BarrelActionTypes.REMOVE:
                return this.deleteProjectApprovals(state, action);

            case DashboardActionTypes.DELETE_SCREENS:
                return this.deleteProjectScreens(state, action);

            default:
                return state;
        }
    }
}

export default ApprovalsProjectDataStore;
export {
    State as ApprovalsProjectDataStoreState
};
