const NEW_SECTION_TITLE = "Untitled Section";

const SCREEN_FIXED_CROP_HEIGHT = 192;
const SCREEN_HORIZONTAL_SPACING = 32;
const SCREEN_VERTICAL_SPACING = 32;
const SECTION_HEADER_MINIMUM_HEIGHT = 50;
const NESTED_SECTION_HEADER_MINIMUM_HEIGHT = 48;
const SPACING_BETWEEN_SECTIONS = 48;
const SPACING_BEFORE_NESTED_SECTION = 32;
const SPACING_AFTER_COLLAPSED_SECTION = 24;
const SECTION_LINE_LEFT_SPACING = 32;
const SPACING_BETWEEN_SECTION_LINES = 8;
const SCREEN_DESCRIPTION_HEIGHT = 28;
const SECTION_DESCRIPTION_HEIGHT = 24;
const DEFAULT_SECTION_HEADER_HEIGHT = 0;
const JIRA_ATTACHMENT_HEIGHT = 34;
const MORE_JIRA_ATTACHMENT_HEIGHT = 24;
const NUMBER_OF_JIRA_ATTACHMENTS_SHOWN_IN_HEADER = 2;
// Bottom margin of onboarding elements + bottom margin&padding of .projectOverviewTags
const ONBOARDING_VERTICAL_MARGINS = 56;
const SIDEBAR_MIN_WIDTH = 260;
const SIDEBAR_COLLAPSED_WIDTH = 20;
const SCROLLBAR_DEFAULT_WIDTH = 15;
const TAGS_HEIGHT = 48;

export {
    SCREEN_FIXED_CROP_HEIGHT,
    NEW_SECTION_TITLE,
    SCREEN_HORIZONTAL_SPACING,
    SCREEN_VERTICAL_SPACING,
    SECTION_HEADER_MINIMUM_HEIGHT,
    NESTED_SECTION_HEADER_MINIMUM_HEIGHT,
    SPACING_BETWEEN_SECTIONS,
    SPACING_BEFORE_NESTED_SECTION,
    SPACING_AFTER_COLLAPSED_SECTION,
    SECTION_LINE_LEFT_SPACING,
    SPACING_BETWEEN_SECTION_LINES,
    SCREEN_DESCRIPTION_HEIGHT,
    ONBOARDING_VERTICAL_MARGINS,
    DEFAULT_SECTION_HEADER_HEIGHT,
    SECTION_DESCRIPTION_HEIGHT,
    SIDEBAR_MIN_WIDTH,
    SIDEBAR_COLLAPSED_WIDTH,
    JIRA_ATTACHMENT_HEIGHT,
    MORE_JIRA_ATTACHMENT_HEIGHT,
    NUMBER_OF_JIRA_ATTACHMENTS_SHOWN_IN_HEADER,
    SCROLLBAR_DEFAULT_WIDTH,
    TAGS_HEIGHT
};
