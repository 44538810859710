/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import AppActionTypes from "../app/AppActionTypes";
import GlobalSearchActionTypes from "./GlobalSearchActionTypes";
import { AllPayloads } from "../payloads";
import * as GlobalSearchActionPayloads from "./GlobalSearchActionPayloads";
import { SearchResultEntity } from "../../containers/AppContainer/GlobalSearchContainer/GlobalSearchListItemContent";
import SpaceActionTypes from "../space/SpaceActionTypes";
import GlobalSearchSortType from "../../../foundation/api/model/globalSearch/GlobalSearchSortType";
import { GLOBAL_SEARCH_FILTERS, GlobalSearchFilterElement } from "../../containers/AppContainer/GlobalSearchContainer/GlobalSearchFilter/GlobalSearchFilterTypes";

interface State {
    globalSearchPopupOpen: boolean;
    lastSearchedTerm: string;
    lastResultEntity: SearchResultEntity;
    lastOrganizationId: string;
    lastTotalNumberOfResults: number;
    lastSelectedItemIndex: number;
    lastSelectedSortType: GlobalSearchSortType;
    lastSelectedFilterType: GlobalSearchFilterElement;
}

class GlobalSearchStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            globalSearchPopupOpen: false,
            lastOrganizationId: "user",
            lastResultEntity: {
                results: [],
                resultBatchId: null
            },
            lastSearchedTerm: "",
            lastTotalNumberOfResults: 0,
            lastSelectedItemIndex: 0,
            lastSelectedSortType: GlobalSearchSortType.Relevance,
            lastSelectedFilterType: GLOBAL_SEARCH_FILTERS[0]
        };
    }

    reset() {
        return this.getInitialState();
    }

    openGlobalSearchPopup(state: State): State {
        return {
            ...state,
            globalSearchPopupOpen: true
        };
    }

    closeGlobalSearchPopup(state: State): State {
        return {
            ...state,
            globalSearchPopupOpen: false
        };
    }

    saveSearchData(state: State, {
        term, resultEntity, organizationId, totalNumberOfResults, selectedItemIndex, sortType, filterType
    }: GlobalSearchActionPayloads.SaveSearchData): State {
        return {
            ...state,
            lastSearchedTerm: term,
            lastResultEntity: resultEntity,
            lastOrganizationId: organizationId,
            lastTotalNumberOfResults: totalNumberOfResults,
            lastSelectedItemIndex: selectedItemIndex,
            lastSelectedSortType: sortType,
            lastSelectedFilterType: filterType
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case GlobalSearchActionTypes.RESET:
            case SpaceActionTypes.CHANGE_SELECTED_ORGANIZATION:
            case AppActionTypes.RESET:
                return this.reset();
            case GlobalSearchActionTypes.OPEN_GLOBAL_SEARCH_POPUP:
                return this.openGlobalSearchPopup(state);
            case GlobalSearchActionTypes.CLOSE_GLOBAL_SEARCH_POPUP:
                return this.closeGlobalSearchPopup(state);
            case GlobalSearchActionTypes.SAVE_SEARCH_DATA:
                return this.saveSearchData(state, action);

            default:
                return state;
        }
    }
}

export default GlobalSearchStore;
export { State as GlobalSearchStoreState };
