import { ComponentType, h, RefObject } from "preact";
import { useRef } from "preact/hooks";

import { pipe } from "../../../../../../../../foundation/utils/legacy";

import { DragPosition, DragSource, DropTarget } from "../../../../../../../../library/DragDropContainer";

import BoardTabBarItem, { BoardTabBarItemProps, BoardTabBarItemReorderPosition } from "../BoardTabBarItem";

const DRAG_DROP_ITEM_TYPE = "BoardTabBarItem";

type DragDropProps = BoardTabBarItemProps & {
    dragSourceBaseRef: RefObject<HTMLElement>;
    onHover: (sourceTabId: string, mirrorPosition: DragPosition) => void;
    onDrop: (sourceTabId: string, mirrorPosition: DragPosition) => void;
};
type DragData = BoardTabBarItemProps;

const RawDragDropBoardTabBarItem: ComponentType<DragDropProps> = pipe(
    DragSource<DragDropProps, DragData>({
        itemType: DRAG_DROP_ITEM_TYPE,
        allowY: false,
        mirrorComponent: props => {
            const { width } = props.dragSourceBaseRef.current!.getBoundingClientRect();

            return <BoardTabBarItem {...props} isSelected mirrorProps={{ width }}/>;
        },
        provideDragData: props => props
    }),
    DropTarget<DragDropProps, DragData>({
        acceptsItemType: DRAG_DROP_ITEM_TYPE,
        onDrop: (props, dragState) => props.onDrop(dragState.dragData!.tab.id, dragState.mirrorPosition),
        onHover: (props, dragState) => props.onHover(dragState.dragData!.tab.id, dragState.mirrorPosition),
        // TODO-electron-post-release-berk: Update DropTarget to use class instead.
        style: {
            flexBasis: "216px",
            flexShrink: 1,
            minWidth: "64px"
        }
    })
)(BoardTabBarItem);

export default function DragDropBoardTabBarItem(props: BoardTabBarItemProps) {
    const {
        tab,
        onReorderHover,
        onReorderDrop
    } = props;

    const dragSourceBaseRef = useRef<HTMLElement>(null);

    function getReorderPosition(mirrorPosition: DragPosition) {
        const rect = dragSourceBaseRef.current!.getBoundingClientRect();

        // eslint-disable-next-line no-magic-numbers
        return mirrorPosition.left > rect.left + rect.width / 2
            ? BoardTabBarItemReorderPosition.Right
            : BoardTabBarItemReorderPosition.Left;
    }

    function handleDragBaseRef(element: HTMLElement | null) {
        dragSourceBaseRef.current = element!;
    }

    function handleHover(sourceTabId: string, mirrorPosition: DragPosition) {
        const position = getReorderPosition(mirrorPosition);

        onReorderHover?.(sourceTabId, position);
    }

    function handleDrop(sourceTabId: string, mirrorPosition: DragPosition) {
        if (sourceTabId === tab.id) {
            return;
        }

        const position = getReorderPosition(mirrorPosition);

        onReorderDrop?.(sourceTabId, position);
    }

    return (
        <RawDragDropBoardTabBarItem
            {...props}
            // DragSource props
            dragBaseRef={handleDragBaseRef}
            // Injected props
            dragSourceBaseRef={dragSourceBaseRef}
            onHover={handleHover}
            onDrop={handleDrop}/>
    );
}
