/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import ScreenComponentsActionTypes from "./ScreenComponentsActionTypes";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import AppActionTypes from "../app/AppActionTypes";
import * as Payloads from "./ScreenComponentsActionPayloads";

import { AllPayloads } from "../payloads";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import ComponentStats from "../../../foundation/api/model/components/ComponentStats";
import VirtualComponentStats from "../../../foundation/api/model/components/VirtualComponentStats";
import ComponentDetail from "../../../foundation/api/model/components/ComponentDetail";
import VirtualComponentDetail from "../../../foundation/api/model/components/VirtualComponentDetail";
import VirtualComponent from "../../../foundation/api/model/components/VirtualComponent";

interface State {
    fetchingComponentsStats: boolean;
    fetchingVirtualComponentsStats: boolean;
    componentsStats: BasicRecord<ComponentStats>;
    virtualComponentsStats: BasicRecord<VirtualComponentStats>;
    virtualComponents: BasicRecord<VirtualComponent>;
    fetchingComponentDetail: boolean;
    componentsDetails: BasicRecord<ComponentDetail>;
    virtualComponentsDetails: BasicRecord<VirtualComponentDetail>;
    // fetchedScreens are collection of screens that we fetched component stats of. Since we always fetch
    // component stats and virtual component stats of the screens at the same time, we only added
    // screenId of fetched component stats to the set. If later on there happens a possibility to separate fetch
    // logics from each other, screenId in GetVirtualComponentStats should also be added to fetchedScreens set.
    fetchedScreens: Set<string>;
}

class ScreenComponentsDataStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            fetchingComponentsStats: false,
            fetchingVirtualComponentsStats: false,
            componentsStats: {},
            virtualComponentsStats: {},
            virtualComponents: {},
            fetchingComponentDetail: false,
            componentsDetails: {},
            virtualComponentsDetails: {},
            fetchedScreens: new Set()
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    setFetchedScreens(state: State, { screenId }: Payloads.GetVirtualComponentsComplete) : State {
        if (state.fetchedScreens.has(screenId)) {
            return state;
        }

        return { ...state,
            fetchedScreens: new Set([...state.fetchedScreens, screenId]) };
    }

    getComponentStats(state: State): State {
        if (state.fetchingComponentsStats) {
            return state;
        }
        return {
            ...state,
            fetchingComponentsStats: true
        };
    }

    getComponentStatsComplete(state: State): State {
        if (!state.fetchingComponentsStats) {
            return state;
        }
        return {
            ...state,
            fetchingComponentsStats: false
        };
    }

    setComponentStats(state: State, { componentsStats }: Payloads.SetComponentStats): State {
        const computedComponentsStats = { ...state.componentsStats };

        for (const componentStats of componentsStats) {
            if (!componentStats.usedIn.screenCount) {
                continue;
            }

            computedComponentsStats[componentStats._id] = { ...componentStats };
        }

        return {
            ...state,
            componentsStats: computedComponentsStats
        };
    }

    getVirtualComponentStats(state: State): State {
        if (state.fetchingVirtualComponentsStats) {
            return state;
        }

        return {
            ...state,
            fetchingVirtualComponentsStats: true
        };
    }

    getVirtualComponentStatsComplete(state: State): State {
        if (!state.fetchingVirtualComponentsStats) {
            return state;
        }

        return {
            ...state,
            fetchingVirtualComponentsStats: false
        };
    }

    setVirtualComponentStats(state: State, { virtualComponentsStats }: Payloads.SetVirtualComponentStats): State {
        const computedVirtualComponentsStats = { ...state.virtualComponentsStats };

        for (const virtualComponentStats of virtualComponentsStats) {
            if (!virtualComponentStats.usedIn.screenCount) {
                continue;
            }

            computedVirtualComponentsStats[virtualComponentStats.sourceId] = { ...virtualComponentStats };
        }

        return {
            ...state,
            virtualComponentsStats: computedVirtualComponentsStats
        };
    }

    setVirtualComponents(state: State, { virtualComponents }: Payloads.SetVirtualComponents): State {
        const computedVirtualComponents = { ...state.virtualComponents };

        for (const virtualComponent of virtualComponents) {
            computedVirtualComponents[virtualComponent.sourceId] = { ...virtualComponent };
        }

        return {
            ...state,
            virtualComponents: computedVirtualComponents
        };
    }

    getComponentDetail(state: State): State {
        return {
            ...state,
            fetchingComponentDetail: true
        };
    }

    getComponentDetailComplete(state: State): State {
        return {
            ...state,
            fetchingComponentDetail: false
        };
    }

    setStyleguideComponentDetail(state: State, { componentDetail }: { componentDetail: ComponentDetail; }): State {
        const computedComponentsDetails = { ...state.componentsDetails };

        const { sourceId } = componentDetail;
        computedComponentsDetails[sourceId] = { ...componentDetail };

        return {
            ...state,
            componentsDetails: computedComponentsDetails
        };
    }

    setVirtualComponentDetail(
        state: State, { virtualComponentDetail }: { virtualComponentDetail: VirtualComponentDetail; }
    ): State {
        const computedVirtualComponentsDetails = { ...state.virtualComponentsDetails };

        const { sourceId } = virtualComponentDetail;
        computedVirtualComponentsDetails[sourceId] = { ...virtualComponentDetail };

        return {
            ...state,
            virtualComponentsDetails: computedVirtualComponentsDetails
        };
    }

    upsertVirtualComponents(
        state: State,
        { virtualComponents }: Payloads.UpsertVirtualComponents
    ) {
        const computedVirtualComponentsDetails = { ...state.virtualComponentsDetails };

        virtualComponents.forEach(virtualComponent => {
            const { sourceId, variant } = virtualComponent;
            delete computedVirtualComponentsDetails[sourceId];

            if (variant) {
                Object.values(computedVirtualComponentsDetails).forEach(virtualComponentDetail => {
                    if (virtualComponentDetail.variant) {
                        const { components } = virtualComponentDetail.variant;
                        for (const component of components) {
                            if (component.sourceId === sourceId) {
                                component.variant.values = variant.values;
                            }
                        }
                    }
                });
            }
        });

        return {
            ...state,
            virtualComponentDetails: computedVirtualComponentsDetails
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
            case BarrelActionTypes.RESET:
                return this.reset();

            case ScreenComponentsActionTypes.GET_COMPONENT_STATS:
                return this.getComponentStats(state);

            case ScreenComponentsActionTypes.GET_COMPONENT_STATS_COMPLETE:
                return this.getComponentStatsComplete(state);

            case ScreenComponentsActionTypes.SET_COMPONENT_STATS:
                return this.setComponentStats(state, action);

            case ScreenComponentsActionTypes.GET_VIRTUAL_COMPONENT_STATS:
                return this.getVirtualComponentStats(state);

            case ScreenComponentsActionTypes.GET_VIRTUAL_COMPONENT_STATS_COMPLETE:
                return this.getVirtualComponentStatsComplete(state);

            case ScreenComponentsActionTypes.SET_VIRTUAL_COMPONENT_STATS:
                return this.setVirtualComponentStats(state, action);

            case ScreenComponentsActionTypes.GET_VIRTUAL_COMPONENTS_COMPLETE:
                return this.setFetchedScreens(state, action);

            case ScreenComponentsActionTypes.SET_VIRTUAL_COMPONENTS:
                return this.setVirtualComponents(state, action);

            case ScreenComponentsActionTypes.GET_STYLEGUIDE_COMPONENT_DETAIL:
            case ScreenComponentsActionTypes.GET_VIRTUAL_COMPONENT_DETAIL:
                return this.getComponentDetail(state);

            case ScreenComponentsActionTypes.GET_COMPONENT_DETAIL_COMPLETE:
                return this.getComponentDetailComplete(state);

            case ScreenComponentsActionTypes.SET_STYLEGUIDE_COMPONENT_DETAIL:
                return this.setStyleguideComponentDetail(state, action);

            case ScreenComponentsActionTypes.SET_VIRTUAL_COMPONENT_DETAIL:
                return this.setVirtualComponentDetail(state, action);

            case ScreenComponentsActionTypes.UPSERT_VIRTUAL_COMPONENTS:
                return this.upsertVirtualComponents(state, action);

            default:
                return state;
        }
    }
}

export default ScreenComponentsDataStore;
export {
    State as ScreenComponentsDataStoreState
};
