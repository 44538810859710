enum ActionTypes {
    GET_WEBHOOKS_REQUEST = "webhooks/getWebhooksRequest",
    GET_WEBHOOKS_SUCCESS = "webhooks/getWebhooksSuccess",
    GET_WEBHOOK_REQUEST = "webhooks/getWebhookRequest",
    GET_WEBHOOK_SUCCESS = "webhooks/getWebhookSuccess",
    GET_WEBHOOK_FAILURE = "webhooks/getWebhookFailure",
    CREATE_WEBHOOK_REQUEST = "webhooks/createWebhookRequest",
    CREATE_WEBHOOK_SUCCESS = "webhooks/createWebhookSuccess",
    CREATE_WEBHOOK_FAILURE = "webhooks/createWebhookFailure",
    UPDATE_WEBHOOK_REQUEST = "webhooks/updateWebhookRequest",
    UPDATE_WEBHOOK_SUCCESS = "webhooks/updateWebhookSuccess",
    UPDATE_WEBHOOK_FAILURE = "webhooks/updateWebhookFailure",
    DELETE_WEBHOOK_REQUEST = "webhooks/deleteWebhookRequest",
    DELETE_WEBHOOK_SUCCESS = "webhooks/deleteWebhookSuccess",
    DELETE_WEBHOOK_FAILURE = "webhooks/deleteWebhookFailure"
}

export default ActionTypes;
