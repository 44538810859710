import MenuItem from "../../../../foundation/electron/MenuItem";

import MenuViewType from "./MenuViewType";
import MenuItemID from "./MenuItemID";
import separator from "./separator";

function enabledWindowMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.WINDOW_SUBMENU,
        label: "Window",
        submenu: [
            { role: "close" },
            { role: "minimize" },
            separator,
            { role: "front" }]
    });
}

function disabledWindowMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.WINDOW_SUBMENU,
        label: "Window",
        enabled: false
    });
}

export default function windowSubmenu(menuViewType: MenuViewType | null): MenuItem {
    switch (menuViewType) {
        case MenuViewType.WORKSPACE_PROJECTS:
        case MenuViewType.WORKSPACE_STYLEGUIDES:
        case MenuViewType.ACTIVITIES:
        case MenuViewType.PROJECT_DASHBOARD:
        case MenuViewType.PROJECT_FLOWS:
        case MenuViewType.PROJECT_STYLEGUIDE:
        case MenuViewType.STYLEGUIDE:
        case MenuViewType.SCREEN:
        case MenuViewType.LOGIN:
            return enabledWindowMenu();
        default:
            return disabledWindowMenu();
    }
}
