enum ActionTypes {
    LOAD_WORKSPACE_DATA = "app/loadWorkspaceData",
    RESET = "app/reset",
    HYDRATE_EMBEDDED_DATA = "app/hydrateEmbeddedData",
    INIT_RECENTLY_LOADED_BARRELS = "app/initRecentlyLoadedBarrels",
    POP_BACK_STACK = "app/popBackStack",
    PUSH_TO_BACK_STACK = "app/pushToBackStack",
    SET_SHORTLINK = "app/setShortlink",
    SET_PAGE = "app/setPage",
    SESSION_TOKEN_EXPIRED = "app/sessionTokenExpired",
    UNSET_SESSION_TOKEN_EXPIRED = "app/unsetSessionTokenExpired",
    LOGIN_SSO = "app/loginSSO",
    SET_NOTIFICATION_SETTINGS_DIALOG_VISIBILITY = "app/setNotificationSettingsDialogVisibility",
    SET_WHATS_NEW_MODAL_VISIBILITY = "app/whatsNewModalVisibility",
    SET_WHATS_NEW_POPUP_VISIBILITY = "app/whatsNewPopupVisibility",
    LOG_OUT ="app/logout",
    SET_UPDATE_NOTIFICATION_BANNER_DETAILS = "app/setUpdateNotificationBannerDetails",
    RESET_UPDATE_NOTIFICATION_BANNER_DETAILS = "app/resetUpdateNotificationBannerDetails",
    SET_SHOULD_DISPLAY_SOCKET_CONNECTION_ISSUE_BANNER = "app/setShouldDisplaySocketConnectionIssueBanner"
}

export default ActionTypes;
