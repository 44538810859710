// TODO: Check OrganizationData casts in this file, The casted data does not match this type. Either OrganizationData
// Or the types of data casted to OrganizationData should be updated.
/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import OrganizationActionTypes from "./OrganizationActionTypes";
import * as Payloads from "./OrganizationActionPayloads";
import ProjectsActionTypes from "../projects/ProjectsActionTypes";
import StyleguidesActionTypes from "../styleguides/StyleguidesActionTypes";
import SpaceActionTypes from "../space/SpaceActionTypes";
import * as SpaceActionPayloads from "../space/SpaceActionPayloads";
import AppActionTypes from "../app/AppActionTypes";
import * as AppActionPayloads from "../app/AppActionPayloads";
/**
 * Using `import { BarrelActionTypes } from "../barrel"` early initializes BarrelHandlers which causes `api` import to fail in there.
 *
 * DO NOT USE `import { BarrelActionTypes } from "../barrel"`
 */
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import Organization, { OrganizationData } from "../../../foundation/model/Organization";
import WorkflowStatus, { WorkflowStatusData } from "../../../foundation/model/WorkflowStatus";
import BarrelType from "../../../foundation/model/BarrelType";
import { arrayRemove } from "../../../foundation/utils/array";
import ProjectSection from "../../../foundation/model/ProjectSection";
import { TemporarySectionId } from "../../../foundation/utils/section";
import FoundationFocusedBarrel from "../../../foundation/model/FocusedBarrel";
import ApiFocusedBarrel from "../../../foundation/api/model/organizations/FocusedBarrel";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import OrganizationProfiles from "../../../foundation/model/OrganizationProfiles";
import { AllPayloads } from "../payloads";
import OrganizationMemberProfile from "../../../foundation/api/model/organizations/OrganizationMemberProfile";
import { exists } from "../../../foundation/utils/object";
import { OrganizationMember } from "../../../foundation/api/model";

let newProjectSectionCount = 0;

type State = {
    loading: boolean;
    loadingProfiles: boolean;
    loadingBarrels: boolean;
    loadingOrganization: boolean;
    error: unknown;
    organizations: Organization[];
    organizationProfiles: OrganizationProfiles;
    organizationProjects: BasicRecord<string[]>;
    organizationStyleguides: BasicRecord<string[]>;
    focusedBarrels: BasicRecord<FoundationFocusedBarrel[]>;
    dialogDismissStates: BasicRecord<BasicRecord>;
    allProjectsLoaded?: BasicRecord<boolean>;
    initialArchivedProjects?: BasicRecord<boolean>; // This field is only used in workspaceDataLoading stage to filter archived projects
    restrictedMemberProjectIds?: BasicRecord<boolean>; // This field is only used in workspaceDataLoading stage to filter unjoined projects for restricted members
    freeTrialExpirationHintboxSeen: boolean | null;
    freeTrialEndedDialogSeen: boolean | null;
    transferProjectTooltipSeen: boolean | null;
    projectLimitTeyzeSeenForCount: number | null;
};

class OrganizationStore extends ReduceStore<State, AllPayloads> {
    /**
     * @state {Object} `organizationProjects/organizationStyleguides` Stores project/styleguide ids of an organization
     * {
     *   "oid1": ["id1", "id2"],
     *   "oid2": ["id3", "id4"]
     * }
     *
     */
    getInitialState(): State {
        return {
            loading: true,
            loadingProfiles: true,
            loadingBarrels: true,
            loadingOrganization: true,
            error: null,
            organizations: [],
            organizationProfiles: {},
            organizationProjects: {},
            organizationStyleguides: {},
            focusedBarrels: {},
            dialogDismissStates: {},
            allProjectsLoaded: {},
            initialArchivedProjects: {},
            restrictedMemberProjectIds: undefined,
            freeTrialExpirationHintboxSeen: null,
            freeTrialEndedDialogSeen: null,
            transferProjectTooltipSeen: null,
            projectLimitTeyzeSeenForCount: null
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    load(state: State, {
        data: {
            organization,
            organizations,
            organizationProfiles,
            organizationProjects,
            organizationStyleguides
        },
        barrel: {
            organizationId: oid
        }
    }: BarrelActionPayloads.Load): State {
        let newState = {
            ...state,
            loading: false
        };

        if (organizations) {
            newState = this.getOrganizationsSuccess(newState, organizations as OrganizationData[]);
        }

        if (organization) {
            newState = this.addOrganization(newState, organization as OrganizationData);
        }

        if (organizationProfiles) {
            newState = this.getOrganizationMemberProfilesSuccess(newState, organizationProfiles);
        }

        if (organizationProjects) {
            newState = this.loadOrganizationProjects(newState, {
                projects: organizationProjects, oid
            });
        }

        if (organizationStyleguides) {
            newState = this.loadOrganizationStyleguides(newState, {
                styleguides: organizationStyleguides, oid
            });
        }

        return newState;
    }

    addOrganization(state: State, organizationData: OrganizationData): State {
        const existingOrganization = state.organizations.find(({ _id }) => _id === organizationData._id);

        const org = existingOrganization
            ? new Organization({ ...existingOrganization, ...organizationData })
            : new Organization(organizationData);

        return {
            ...state,
            organizations: state.organizations
                .filter(({ _id }) => _id !== organizationData._id)
                .concat(org),
            loadingOrganization: false
        };
    }

    addOrganizationWorkflowStatus(state: State, {
        oid,
        workflowStatusData
    }: Payloads.AddOrganizationWorkflowStatus): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid)!;
        const pendingWorkflowStatus: WorkflowStatusData = selectedOrganization.workflowStatuses
            .find(({ _id }) => _id === workflowStatusData._id && _id.startsWith("newWorkflowStatusId")) ?? {};
        const workflowStatuses = selectedOrganization.workflowStatuses.map(workflowStatus => (
            workflowStatus._id === pendingWorkflowStatus._id ? new WorkflowStatus(workflowStatusData) : workflowStatus
        ));

        if (!pendingWorkflowStatus._id) {
            workflowStatuses.push(new WorkflowStatus(workflowStatusData));
        }

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        workflowStatuses
                    })
                    : organization
            ))
        };
    }

    updateOrganization(state: State, id: string, organizationPatch: Partial<OrganizationData>): State {
        return Object.assign({}, state, {
            organizations: state.organizations.map(organization => {
                if (organization._id === id) {
                    return new Organization({ ...organization, ...organizationPatch });
                }
                return organization;
            }),
            loadingOrganization: false
        });
    }

    updateOrganizationProfile(state: State, {
        id,
        organizationProfile: organizationProfilePatch
    }: {
        id: string;
        organizationProfile: Partial<OrganizationMemberProfile>;
    }): State {
        const organizationProfile = state.organizationProfiles[id];

        if (!organizationProfile) {
            return state;
        }

        const updatedOrganizationProfile = Object.assign({}, organizationProfile, organizationProfilePatch);

        return Object.assign({}, state, {
            organizationProfiles: Object.assign({}, state.organizationProfiles, { [id]: updatedOrganizationProfile })
        });
    }

    updateOrganizationSubscription(state: State, {
        oid,
        plan,
        projectLimit
    }: Payloads.UpdateOrganizationSubscription): State {
        const organization = state.organizations.find(({ _id }) => _id === oid);

        if (!organization) {
            return state;
        }

        return this.updateOrganization(state, organization._id, { paymentPlan: plan, projectLimit });
    }

    updateOrganizationWorkflowStatus(state: State, {
        oid,
        workflowStatusId,
        workflowStatusData,
        oldWorkflowStatusData
    }: Payloads.UpdateOrganizationWorkflowStatus): State {
        const updatedWorkflowStatus = new WorkflowStatus({
            ...oldWorkflowStatusData,
            ...workflowStatusData
        });

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        workflowStatuses: organization.workflowStatuses.map(workflowStatus => (
                            workflowStatus._id === workflowStatusId ? updatedWorkflowStatus : workflowStatus
                        ))
                    })
                    : organization
            ))
        };
    }

    updateOrganizationMemberRole(state: State, {
        oid,
        mid,
        uid,
        role
    }: Payloads.UpdateOrganizationMemberRole): State {
        const organization = state.organizations.find(org => org._id === oid);

        if (!organization) {
            return state;
        }

        const updatedMembers = organization.members?.map(member => {
            if (member.user._id !== mid) {
                return member;
            }

            return {
                ...member,
                role
            };
        });

        let newState = this.updateOrganization(state, oid, { members: updatedMembers });

        if (mid === uid) {
            newState = this.updateOrganizationProfile(newState, { id: oid, organizationProfile: { role } });
        }

        return newState;
    }

    removeOrganization(state: State, {
        id
    }: Payloads.RemoveOrganization): State {
        return Object.assign({}, state, {
            organizations: state.organizations.filter(organization => organization._id !== id)
        });
    }

    deleteOrganizationWorkflowStatus(state: State, {
        oid,
        workflowStatusId
    }: Payloads.DeleteOrganizationWorkflowStatus): State {
        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        workflowStatuses: organization.workflowStatuses.filter(({ _id }) => _id !== workflowStatusId)
                    })
                    : organization
            ))
        };
    }

    getOrganizationsSuccess(state: State, organizations: OrganizationData[]): State {
        return {
            ...state,
            organizations: organizations.map(organization => new Organization(organization)),
            loading: false
        };
    }

    getOrganizationSuccess(state: State, {
        organization
    }: AppActionPayloads.LoadWorkspaceData): State {
        if (!organization) {
            return {
                ...state,
                loadingOrganization: false
            };
        }
        const existingOrganization = state.organizations.find(({ _id }) => _id === organization._id);
        return existingOrganization
            ? this.updateOrganization(state, organization._id, organization as OrganizationData)
            : this.addOrganization(state, organization as OrganizationData);
    }

    getOrganizationSectionsSuccess(state: State, {
        sections,
        oid
    }: Payloads.GetOrganizationSectionsSuccess): State {
        const organization = state.organizations.find(({ _id }) => _id === oid);

        if (!organization) {
            return state;
        }

        return this.updateOrganization(state, organization._id, { sections });
    }

    organizationMembersRequested(state: State, {
        oid
    }: Payloads.OrganizationMembersRequested): State {
        const organization = state.organizations.find(({ _id }) => _id === oid);

        if (!organization) {
            return state;
        }

        return this.updateOrganization(state, organization._id, {
            membersLoading: true,
            membersError: null,
            members: null
        });
    }

    getOrganizationMembersSuccess(state: State, {
        organizationMembers
    }: Payloads.GetOrganizationMembersSuccess): State {
        const { _id: organizationId, members } = organizationMembers || {};
        const organization = state.organizations.find(({ _id }) => _id === organizationId);

        if (!organization) {
            return state;
        }

        return this.updateOrganization(state, organization._id, {
            membersLoading: false,
            members
        });
    }

    getOrganizationMembersFailed(state: State, {
        oid,
        error
    }: Payloads.GetOrganizationMembersFailed): State {
        const organization = state.organizations.find(({ _id }) => _id === oid);

        if (!organization) {
            return state;
        }

        return this.updateOrganization(state, organization._id, {
            membersLoading: false,
            membersError: error
        });
    }

    getOrganizationMemberProfilesSuccess(
        state: State,
        organizationProfiles: BasicRecord<OrganizationMemberProfile>
    ): State {
        return Object.assign({}, state, {
            organizationProfiles,
            loadingProfiles: false
        });
    }

    addOrganizationMemberProfile(state: State, {
        organizationProfile,
        oid
    }: Payloads.AddOrganizationProfile): State {
        return {
            ...state,
            organizationProfiles: {
                ...state.organizationProfiles,
                [oid]: organizationProfile
            }
        };
    }

    addProjectToOrganizationSection(state: State, { pid, oid, oseid, index }: {
        pid: string;
        oid: string;
        oseid: string | null | undefined;
        index: number | undefined;
    }): State {
        const targetOrganization = state.organizations.find(({ _id }) => _id === oid);
        if (!targetOrganization?.sections) {
            return state;
        }

        const targetSection = oseid
            ? targetOrganization.sections.find(section => section._id === oseid)
            : targetOrganization.sections[0];

        if (!targetSection || targetSection.projects.includes(pid)) {
            return state;
        }

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        sections: organization.sections.map(section => {
                            if (section._id === targetSection._id) {
                                const newProjects = [...section.projects];
                                if (index) {
                                    newProjects.splice(index, 0, pid);
                                } else {
                                    newProjects.push(pid);
                                }

                                return new ProjectSection({
                                    ...section,
                                    projects: newProjects
                                });
                            }

                            return section;
                        })
                    })
                    : organization
            ))
        };
    }

    addBarrel(state: State, {
        barrel: { _id: bid }, oid, oseid, index, barrelType
    }: {
        barrel: {
            _id: string;
        };
        oid: string | null;
        oseid?: string | null;
        index?: number;
        barrelType: BarrelType;
    }): State {
        if (!oid) {
            return state;
        }

        const { organizationProjects, organizationStyleguides } = state;

        let newState = state;

        if (barrelType === BarrelType.PROJECT) {
            newState = this.addProjectToOrganizationSection(state, {
                pid: bid,
                oid,
                oseid,
                index
            });

            const projectIds = organizationProjects[oid];

            if (projectIds && !projectIds.includes(bid)) {
                return {
                    ...newState,
                    organizationProjects: {
                        ...organizationProjects,
                        [oid]: [...projectIds, bid]
                    }
                };
            }
        } else {
            const styleguideIds = organizationStyleguides[oid];
            if (styleguideIds && !styleguideIds.includes(bid)) {
                const newStyleguideIds = [...styleguideIds];
                if (index) {
                    newStyleguideIds.splice(index, 0, bid);
                } else {
                    newStyleguideIds.push(bid);
                }

                return {
                    ...state,
                    organizationStyleguides: {
                        ...organizationStyleguides,
                        [oid]: newStyleguideIds
                    }
                };
            }
        }

        return newState;
    }

    removeProjectFromOrganizationSection(state: State, { pid, oid, oseid }: {
        pid: string;
        oid: string | null;
        oseid: string;
    }): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid);
        const { sectionIds, sections } = { ...selectedOrganization } || {};

        if (!sections) {
            return state;
        }

        const targetSection = sections.find(section => section._id === oseid);
        if (!targetSection) {
            return state;
        }

        arrayRemove(targetSection.projects, id => id === pid);

        return this.sortAndPurgeEmptySections(state, { oid: oid!, sections, sectionIds: sectionIds! });
    }

    removeBarrel(state: State, { bid, oid, oseid }: {
        bid: string;
        oid: string | null;
        oseid?: string | null;
    }): State {
        if (!oid) {
            return state;
        }

        const { organizationProjects, organizationStyleguides } = state;
        let newState = state;
        if (oseid) {
            newState = this.removeProjectFromOrganizationSection(state, {
                oid,
                pid: bid,
                oseid
            });
        }

        const projectIds = organizationProjects[oid];
        if (projectIds?.includes(bid)) {
            return {
                ...newState,
                organizationProjects: {
                    ...organizationProjects,
                    [oid]: projectIds.filter(id => id !== bid)
                }
            };
        }

        const styleguideIds = organizationStyleguides[oid];
        if (styleguideIds?.includes(bid)) {
            return {
                ...newState,
                organizationStyleguides: {
                    ...organizationStyleguides,
                    [oid]: styleguideIds.filter(id => id !== bid)
                }
            };
        }

        return newState;
    }

    loadOrganizationProjects(state: State, { projects, oid }: {
        projects: {
            _id: string;
        }[];
        oid: string | null | undefined;
    }): State {
        return {
            ...state,
            organizationProjects: {
                ...state.organizationProjects,
                /* @ts-ignore */ // An unharmful suppression because empty oid works, but ts compiler gives error
                [oid]: projects?.map(project => project._id)
            },
            loadingBarrels: false,
            allProjectsLoaded: {
                ...state.allProjectsLoaded,
                /* @ts-ignore */ // An unharmful suppression because empty oid works, but ts compiler gives error
                [oid]: true
            }
        };
    }

    transferOrganizationProject(state: State, {
        bid,
        targetId,
        toOrganization,
        oid,
        oseid
    }: {
        bid: string;
        targetId: string;
        toOrganization: boolean | undefined;
        oid: string;
        oseid?: string | null;
    }): State {
        if (!toOrganization && oid === "user") {
            return state;
        }

        // Move project to target organization
        const deletedState = this.removeBarrel(state, { bid, oid, oseid });
        const removedFromFocusState = oid === "user"
            ? deletedState
            : this.removeBarrelFromFocus(deletedState, { bid, oid });

        return this.addBarrel(removedFromFocusState, {
            barrel: { _id: bid },
            oid: toOrganization ? targetId : "user",
            barrelType: BarrelType.PROJECT
        });
    }

    loadOrganizationStyleguides(state: State, {
        styleguides,
        oid
    }: {
        styleguides: {
            _id: string;
        }[];
        oid: string | null | undefined;
    }): State {
        return {
            ...state,
            organizationStyleguides: {
                ...state.organizationStyleguides,
                /* @ts-ignore */ // An unharmful suppression because empty oid works, but ts compiler gives error
                [oid]: styleguides.map(styleguide => styleguide._id)
            },
            loadingBarrels: false
        };
    }

    transferOrganizationStyleguide(state: State, { affectedIds, targetId, toOrganization, oid }: {
        affectedIds?: string[]; // This is always filled when called, but made optional to be able to called easier
        targetId: string;
        toOrganization: boolean | undefined;
        oid: string;
    }): State {
        if (!toOrganization && oid === "user") {
            return state;
        }

        // Move styleguide and its children to target organization
        const { organizationStyleguides } = state;
        const currentStyleguideIds = organizationStyleguides[oid];
        const targetOrganizationId = toOrganization ? targetId : "user";
        const targetStyleguideIds = organizationStyleguides[targetOrganizationId];

        return {
            ...state,
            organizationStyleguides: {
                [oid]: currentStyleguideIds.filter(stid => !affectedIds!.includes(stid)),
                [targetOrganizationId]: targetStyleguideIds && [...targetStyleguideIds, ...affectedIds!]
            }
        };
    }

    setWorkspaceLoading(state: State, {
        updateState: {
            selectedOrganizationId: oid
        }
    }: SpaceActionPayloads.UpdatePageState): State {
        if (!oid) {
            return state;
        }
        const { organizationProjects, organizationStyleguides } = state;
        const loadingBarrels = !organizationProjects[oid] || !organizationStyleguides[oid];

        let loadingOrganization = false;
        if (oid !== "user") {
            const existingOrganization = state.organizations.find(({ _id }) => _id === oid);
            loadingOrganization = !existingOrganization || !existingOrganization.complete;
        }

        return {
            ...state,
            loadingBarrels,
            loadingOrganization
        };
    }

    getFocusedBarrelsSuccess(state: State, { focusedBarrels, oid }: {
        focusedBarrels: ApiFocusedBarrel[] | null;
        oid: string;
    }): State {
        if (!focusedBarrels) {
            return state;
        }
        return {
            ...state,
            focusedBarrels: {
                ...state.focusedBarrels,
                [oid]: focusedBarrels
            }
        };
    }

    addBarrelToFocus(state: State, {
        oid,
        bid,
        barrelType,
        shouldPopOldestBarrel
    }: Payloads.AddBarrelToFocus): State {
        const focusedBarrelsOfOid = state.focusedBarrels[oid];

        if (!focusedBarrelsOfOid) {
            return state;
        }

        const filteredFocusedBarrelsOfOid = focusedBarrelsOfOid.filter(({ unpinned }) => !unpinned);

        if (filteredFocusedBarrelsOfOid.some(({ barrelId }) => barrelId === bid)) {
            return state;
        }

        if (shouldPopOldestBarrel) {
            filteredFocusedBarrelsOfOid.shift();
        }

        return {
            ...state,
            focusedBarrels: {
                ...state.focusedBarrels,
                [oid]: [
                    ...filteredFocusedBarrelsOfOid,
                    {
                        barrelType,
                        barrelId: bid
                    }
                ]
            }
        };
    }

    removeBarrelFromFocus(state: State, { oid, bid, poppedBarrel }: {
        oid: string;
        bid: string;
        poppedBarrel?: ApiFocusedBarrel;
    }): State {
        const focusedBarrelsOfOid = state.focusedBarrels[oid];

        if (!focusedBarrelsOfOid) {
            return state;
        }

        const focusedBarrels = focusedBarrelsOfOid.slice();

        // If poppedBarrel exists, we need to put this barrel to the first position
        if (poppedBarrel) {
            focusedBarrels.unshift(poppedBarrel);
        }

        return {
            ...state,
            focusedBarrels: {
                ...state.focusedBarrels,
                [oid]: focusedBarrels.filter(({ barrelId }) => barrelId !== bid)
            }
        };
    }

    unpinBarrelFromFocus(state: State, {
        oid,
        bid
    }: Payloads.UnpinBarrelFromFocus): State {
        const focusedBarrelsOfOid = state.focusedBarrels[oid];

        return {
            ...state,
            focusedBarrels: {
                ...state.focusedBarrels,
                [oid]: focusedBarrelsOfOid.map(barrel => {
                    if (barrel.barrelId === bid) {
                        return { ...barrel, unpinned: true };
                    }
                    return barrel;
                })
            }
        };
    }

    removeArchivedBarrelFromFocus(state: State, {
        bid
    }: BarrelActionPayloads.Archive): State {
        return Object.keys(state.focusedBarrels).reduce(
            (prevState, oid) => this.removeBarrelFromFocus(prevState, { oid, bid }),
            state
        );
    }

    repinBarrelToFocus(state: State, {
        oid,
        bid
    }: Payloads.RepinBarrelToFocus): State {
        const focusedBarrelsOfOid = state.focusedBarrels[oid];

        return {
            ...state,
            focusedBarrels: {
                [oid]: focusedBarrelsOfOid.map(barrel => {
                    if (barrel.barrelId === bid) {
                        return {
                            ...barrel,
                            unpinned: false
                        };
                    }
                    return barrel;
                })
            }
        };
    }

    removeUnjoinedBarrels(state: State, {
        oid,
        remainingProjects,
        remainingStyleguides
    }: Payloads.RemoveUnjoinedBarrels): State {
        const newState = this.updateMemberAccessibility(state, { oid, restricted: true });

        return {
            ...newState,
            organizationProjects: {
                ...newState.organizationProjects,
                [oid]: remainingProjects
            },
            organizationStyleguides: {
                ...newState.organizationStyleguides,
                [oid]: remainingStyleguides
            }
        };
    }

    updateMemberAccessibility(state: State, { oid, restricted = false }: {
        oid: string;
        restricted?: boolean;
    }): State {
        return {
            ...state,
            organizationProfiles: {
                ...state.organizationProfiles,
                [oid]: {
                    ...state.organizationProfiles[oid],
                    restricted
                }
            }
        };
    }

    updateMemberTags(state: State, {
        oid,
        mid,
        operation,
        tags
    }: Payloads.UpdateMemberTags): State {
        const org = state.organizations.find(({ _id }) => _id === oid);
        if (!org) {
            return state;
        }

        const orgMember = org.members?.find(({ user: { _id } }) => _id === mid);
        if (!orgMember) {
            return state;
        }

        let computedTags: string[] = [];
        if (operation === "add") {
            computedTags = orgMember.tags.concat(tags);
        } else if (operation === "remove") {
            computedTags = orgMember.tags.filter(tag => !tags.includes(tag));
        }

        return {
            ...state,
            organizations: state.organizations.map(organization => {
                if (organization._id !== oid) {
                    return organization;
                }

                const orgMembers = organization.members || [];
                const members = orgMembers.map(member => {
                    if (member.user._id !== mid) {
                        return member;
                    }

                    return { ...member, tags: computedTags };
                });

                return new Organization({ ...organization, members });
            })
        };
    }

    updateMemberData(state: State, {
        uid,
        userData
    }: BarrelActionPayloads.UpdateUserData): State {
        return {
            ...state,
            organizations: state.organizations.map(organization => {
                if (!organization.members) {
                    return organization;
                }

                const members = organization.members.map(member => {
                    if (member.user._id !== uid) {
                        return member;
                    }

                    return {
                        ...member,
                        user: {
                            ...member.user,
                            ...userData
                        }
                    };
                });

                return new Organization({ ...organization, members });
            })
        };
    }

    sortAndPurgeEmptySections(state: State, { oid, sections, sectionIds }: {
        oid: string;
        sections: ProjectSection[];
        sectionIds: string[];
    }): State {
        const organizationProjects = state.organizationProjects[oid];
        const organizationProfile = state.organizationProfiles[oid];

        const newSectionsData = sectionIds.map((id, index) => {
            const foundSection = sections.find(section => section._id === id);
            const shouldRemoveSection = foundSection &&
                organizationProfile?.restricted &&
                index > 0 &&
                !foundSection.projects.some(project => organizationProjects.includes(project));
            return shouldRemoveSection ? null : foundSection;
        }).filter(exists);

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        sections: newSectionsData,
                        sectionIds
                    })
                    : organization
            ))
        };
    }

    createProjectSection(state: State, {
        oid,
        sectionData
    }: Payloads.CreateProjectSection): State {
        const { _id: oseid, name } = sectionData;

        return this.updateProjectSection(state, {
            oid,
            oseid: oseid!,
            sectionData: {
                name
            }
        });
    }

    addProjectSection(state: State, {
        oid,
        index,
        sectionData,
        dialogDismissParams
    }: Payloads.AddProjectSection): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid);
        const { sectionIds, sections } = { ...selectedOrganization } || {};

        if (!sectionIds) {
            return state;
        }

        sectionData.projects?.forEach(pid => {
            const sourceSection = sections!.find(section => section.projects.includes(pid));
            if (sourceSection) {
                arrayRemove(sourceSection.projects, id => id === pid);
            }
        });

        const newSection = new ProjectSection(sectionData);

        if (newSection._id === TemporarySectionId.NewProjectSection) {
            newSection.newSection = Object.prototype.hasOwnProperty.call(sectionData, "newSection")
                ? sectionData.newSection!
                : true;
            newSection.key = `${TemporarySectionId.NewProjectSection}-${newProjectSectionCount++}`;
        }

        if (newSection._id === TemporarySectionId.NewProjectSection || !sectionIds.includes(newSection._id)) {
            sectionIds.splice(index, 0, newSection._id);
        }

        sections!.push(newSection);

        const newState = this.updateDialogDismissStates(state, { oid, dialogDismissParams });
        return this.sortAndPurgeEmptySections(newState, { oid, sections: sections!, sectionIds });
    }

    updateProjectSection(state: State, {
        oid,
        oseid,
        sectionData
    }: Pick<Payloads.UpdateProjectSection, "oid" | "oseid" | "sectionData">): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid);
        const { sections, sectionIds } = { ...selectedOrganization } || {};

        if (!sections) {
            return state;
        }

        const oldSectionData = { ...sections.find(section => section._id === oseid) };
        if (!oldSectionData) {
            return state;
        }

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        sections: sections.map(section => {
                            if (section._id === oseid) {
                                return new ProjectSection({ ...section, ...sectionData });
                            }
                            return section;
                        }),
                        sectionIds: sectionIds!.map(sectionId => {
                            if (sectionId === oseid && sectionData._id && sectionData._id !== sectionId) {
                                return sectionData._id;
                            }
                            return sectionId;
                        })
                    })
                    : organization
            ))
        };
    }

    removeProjectSection(state: State, {
        oid,
        oseid
    }: Payloads.RemoveProjectSection): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid);
        const { sectionIds, sections } = { ...selectedOrganization } || {};

        if (!sectionIds) {
            return state;
        }

        const defaultSection = { ...sections![0] };
        const deletedSection = sections!.find(({ _id }) => _id === oseid);

        if (deletedSection) {
            defaultSection.projects.push(...deletedSection.projects);
        }

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        sections: sections!.map((section, index) => {
                            if (index === 0) {
                                return defaultSection;
                            }
                            if (section._id === oseid) {
                                return null;
                            }
                            return section;
                        }).filter(exists),
                        sectionIds: sectionIds.filter(id => id !== oseid)
                    })
                    : organization
            ))
        };
    }

    updateProjectSectionOrder(state: State, {
        oid,
        oseid,
        index,
        dialogDismissParams
    }: Payloads.UpdateProjectSectionOrder): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid);
        const { sectionIds, sections } = { ...selectedOrganization };

        if (!sectionIds) {
            return state;
        }

        const oldIndex = sectionIds.indexOf(oseid);

        if (oldIndex < 0) {
            return state;
        }

        sectionIds.splice(oldIndex, 1);
        sectionIds.splice(index, 0, oseid);

        const newState = this.updateDialogDismissStates(state, { oid, dialogDismissParams });

        return this.sortAndPurgeEmptySections(newState, { oid, sections: sections!, sectionIds });
    }

    updateProjectOrder(state: State, {
        oid,
        oseid,
        index,
        projects,
        dialogDismissParams
    }: Payloads.UpdateProjectOrder): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid);
        const organizationProjects = state.organizationProjects[oid];
        const organizationProfile = state.organizationProfiles[oid];
        const { sections, sectionIds } = { ...selectedOrganization };

        if (!organizationProjects || !organizationProfile || !sections) {
            return state;
        }

        projects.forEach(pid => {
            const sourceSection = sections.find(section => section.projects.includes(pid));

            if (sourceSection) {
                arrayRemove(sourceSection.projects, id => id === pid);
            }
        });

        const targetSection = sections.find(se => se._id === oseid);

        if (!targetSection) {
            return state;
        }

        targetSection.projects.splice(index, 0, ...projects);

        const newState = this.updateDialogDismissStates(state, { oid, dialogDismissParams });

        return this.sortAndPurgeEmptySections(newState, { oid, sections, sectionIds: sectionIds! });
    }

    addSectionLink(state: State, {
        oid,
        oseid,
        linkData
    }: Payloads.AddSectionLink): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid)!;
        if (!selectedOrganization.sections) {
            return state;
        }

        const targetSection = selectedOrganization.sections.find(({ _id }) => _id === oseid);
        if (!targetSection) {
            return state;
        }

        const updatedSection = new ProjectSection({ ...targetSection, links: [...targetSection.links!, linkData] });
        const sections =
            selectedOrganization.sections.map(section => (section._id === oseid ? updatedSection : section));

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        sections
                    })
                    : organization
            ))
        };
    }

    updateSectionLink(state: State, {
        oid,
        oseid,
        oselid,
        linkData
    }: Payloads.UpdateSectionLink): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid)!;
        if (!selectedOrganization.sections) {
            return state;
        }

        const targetSection = selectedOrganization.sections.find(({ _id }) => _id === oseid);
        if (!targetSection) {
            return state;
        }

        const updatedLinks = targetSection.links!.map(link => (link._id === oselid ? linkData : link));
        const updatedSection = new ProjectSection({ ...targetSection, links: updatedLinks });
        const sections =
            selectedOrganization.sections.map(section => (section._id === oseid ? updatedSection : section));

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        sections
                    })
                    : organization
            ))
        };
    }

    removeSectionLink(state: State, {
        oid,
        oseid,
        oselid
    }: Payloads.RemoveSectionLink): State {
        const selectedOrganization = state.organizations.find(({ _id }) => _id === oid)!;
        if (!selectedOrganization.sections) {
            return state;
        }

        const targetSection = selectedOrganization.sections.find(({ _id }) => _id === oseid);
        if (!targetSection) {
            return state;
        }

        const updatedLinks = targetSection.links!.filter(link => link._id !== oselid);
        const updatedSection = new ProjectSection({ ...targetSection, links: updatedLinks });
        const sections =
            selectedOrganization.sections.map(section => (section._id === oseid ? updatedSection : section));

        return {
            ...state,
            organizations: state.organizations.map(organization => (
                organization._id === oid
                    ? new Organization({
                        ...organization,
                        sections
                    })
                    : organization
            ))
        };
    }

    updateFreeTrialExpirationHintboxState(state: State, { freeTrialExpirationHintboxSeen }: {
        freeTrialExpirationHintboxSeen: boolean | null;
    }): State {
        return {
            ...state,
            freeTrialExpirationHintboxSeen
        };
    }

    updateFreeTrialEndedDialogState(state: State, { freeTrialEndedDialogSeen }: {
        freeTrialEndedDialogSeen: boolean | null;
    }): State {
        return {
            ...state,
            freeTrialEndedDialogSeen
        };
    }

    updateTransferProjectTooltipState(state: State, { transferProjectTooltipSeen }: {
        transferProjectTooltipSeen: boolean | null;
    }): State {
        return {
            ...state,
            transferProjectTooltipSeen
        };
    }

    updateProjectLimitTeyzeState(state: State, { projectLimitTeyzeSeenForCount }: {
        projectLimitTeyzeSeenForCount: number | null;
    }): State {
        return {
            ...state,
            projectLimitTeyzeSeenForCount
        };
    }

    updateDialogDismissStates(state: State, { oid, dialogDismissParams }: {
        oid: string;
        dialogDismissParams: BasicRecord | null | undefined;
    }): State {
        if (!dialogDismissParams) {
            return state;
        }

        return {
            ...state,
            dialogDismissStates: {
                ...state.dialogDismissStates,
                [oid]: {
                    ...state.dialogDismissStates[oid],
                    ...dialogDismissParams
                }
            }
        };
    }

    updateAllProjectsLoadedState(state: State, { allProjectsLoaded }: {
        allProjectsLoaded?: BasicRecord<boolean>;
    }) {
        return {
            ...state,
            allProjectsLoaded: {
                ...state.allProjectsLoaded,
                ...allProjectsLoaded
            }
        };
    }

    setInitialArchivedProjects(state: State, { archivedProjectIds }: {
        archivedProjectIds?: string[];
    }) {
        const initialArchivedProjects = archivedProjectIds?.reduce((archivedProjectObject, archivedProjectId) =>
            ({ ...archivedProjectObject, [archivedProjectId]: true }), {});

        return {
            ...state,
            initialArchivedProjects: {
                ...(initialArchivedProjects || {})
            }
        };
    }

    setRestrictedMemberProjects(state: State, { restrictedMemberProjectIds }: {
        restrictedMemberProjectIds?: string[];
    }) {
        const restrictedProjectIdObject = restrictedMemberProjectIds?.reduce(
            (restrictedProjectsObject, restrictedProjectId) =>
                ({ ...restrictedProjectsObject, [restrictedProjectId]: true }), {});

        return {
            ...state,
            restrictedMemberProjectIds: restrictedProjectIdObject && {
                ...restrictedProjectIdObject
            }
        };
    }

    addMembers(state: State, { oid, members }: {
        oid: string;
        members: OrganizationMember[];
    }) {
        return {
            ...state,
            organizations: state.organizations.map(organization => {
                if (organization._id !== oid) {
                    return organization;
                }

                const organizationMembers = [...(organization.members || []), ...members];

                return new Organization({ ...organization, members: organizationMembers });
            })
        };
    }

    removeMember(state: State, { oid, mid }: {
        oid: string;
        mid: string;
    }) {
        return {
            ...state,
            organizations: state.organizations.map(organization => {
                if (organization._id !== oid) {
                    return organization;
                }

                const organizationMembers = [...(organization.members || [])];
                const filteredOrganizationMembers = organizationMembers.filter(member => member.user._id !== mid);
                return new Organization({ ...organization, members: filteredOrganizationMembers });
            })
        };
    }

    loadWorkspaceData(state: State, action: AppActionPayloads.LoadWorkspaceData): State {
        const getOrganizationsSuccessState =
            this.getOrganizationsSuccess(state, action.organizations as OrganizationData[]);
        const getOrganizationProfilesSucessState = action.organizationProfiles
            ? this.getOrganizationMemberProfilesSuccess(getOrganizationsSuccessState, action.organizationProfiles)
            : getOrganizationsSuccessState;
        const getOrganizationSuccessState = this.getOrganizationSuccess(getOrganizationProfilesSucessState, action);
        const getFocusedBarrelsState = this.getFocusedBarrelsSuccess(getOrganizationSuccessState, action);
        const loadOrganizationProjectsState = this.loadOrganizationProjects(getFocusedBarrelsState, action);
        const loadDialogDismissParamsState = this.updateDialogDismissStates(loadOrganizationProjectsState, action);
        const loadOrganizationStyleguidesState = this.loadOrganizationStyleguides(loadDialogDismissParamsState, action);
        const loadAllProjectsLoadedState = this.updateAllProjectsLoadedState(loadOrganizationStyleguidesState, action);
        const setInitialArchivedProjectsState = this.setInitialArchivedProjects(loadAllProjectsLoadedState, action);
        const loadFreeTrialExpirationHintboxState =
            this.updateFreeTrialExpirationHintboxState(setInitialArchivedProjectsState, action);
        const loadFreeTrialEndedDialogAsSeenState =
            this.updateFreeTrialEndedDialogState(loadFreeTrialExpirationHintboxState, action);
        const loadTransferProjectTooltipState =
            this.updateTransferProjectTooltipState(loadFreeTrialEndedDialogAsSeenState, action);
        const loadProjectLimitTeyzeState =
            this.updateProjectLimitTeyzeState(loadTransferProjectTooltipState, action);
        return this.setRestrictedMemberProjects(loadProjectLimitTeyzeState, action);
    }

    hydrateEmbeddedData(state: State, {
        organization,
        organizations,
        projects,
        oid,
        styleguides,
        focusedBarrels,
        organizationProfiles
    }: AppActionPayloads.HydrateEmbeddedData): State {
        let newState = state;

        if (organizations) {
            newState = organizations.reduce(
                (prevState, organizationToAdd) =>
                    this.addOrganization(prevState, organizationToAdd as OrganizationData),
                newState
            );
        }

        if (organization) {
            newState = this.addOrganization(state, organization as OrganizationData);
        }

        if (projects && oid) {
            newState = this.loadOrganizationProjects(newState, { projects, oid });
        }

        if (styleguides && oid) {
            newState = this.loadOrganizationStyleguides(newState, { styleguides, oid });
        }

        if (focusedBarrels && oid) {
            newState = this.getFocusedBarrelsSuccess(newState, { focusedBarrels, oid });
        }

        if (organizationProfiles) {
            newState = this.getOrganizationMemberProfilesSuccess(newState, organizationProfiles);
        }

        return newState;
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            // #region Space
            case SpaceActionTypes.UPDATE_PAGE_STATE:
                return this.setWorkspaceLoading(state, action);

            // #region Barrel
            case BarrelActionTypes.LOAD:
                return this.load(state, action);
            case BarrelActionTypes.ADD:
                return this.addBarrel(state, action);
            case BarrelActionTypes.REMOVE:
                return this.removeBarrel(state, action);
            case BarrelActionTypes.REMOVE_MEMBER:
                if (action.shouldRemoveBarrel) {
                    return this.removeBarrel(state, action);
                }
                return state;
            case BarrelActionTypes.ARCHIVE:
                return this.removeArchivedBarrelFromFocus(state, action);

            // #region Projects
            case ProjectsActionTypes.GET_PROJECTS_SUCCESS:
                return this.loadOrganizationProjects(state, action);

            // #region Styleguides
            case StyleguidesActionTypes.GET_STYLEGUIDES_SUCCESS:
                return this.loadOrganizationStyleguides(state, action);

            case ProjectsActionTypes.TRANSFER_OWNERSHIP:
            case StyleguidesActionTypes.TRANSFER_OWNERSHIP:
            case BarrelActionTypes.OWNERSHIP_TRANSFERRED:
                if ((action as { affectedIds?: string[]; }).affectedIds) {
                    return this.transferOrganizationStyleguide(state, action);
                }
                return this.transferOrganizationProject(state, action);

            // #region Organization
            case OrganizationActionTypes.ADD_ORGANIZATION:
                return this.addOrganization(state, action.organization);
            case OrganizationActionTypes.ADD_ORGANIZATION_WORKFLOW_STATUS:
                return this.addOrganizationWorkflowStatus(state, action);
            case OrganizationActionTypes.UPDATE_ORGANIZATION:
                return this.updateOrganization(state, action.id, action.organization);
            case OrganizationActionTypes.UPDATE_ORGANIZATION_SUBSCRIPTION:
                return this.updateOrganizationSubscription(state, action);
            case OrganizationActionTypes.UPDATE_ORGANIZATION_WORKFLOW_STATUS:
                return this.updateOrganizationWorkflowStatus(state, action);
            case OrganizationActionTypes.UPDATE_ORGANIZATION_MEMBER_ROLE:
                return this.updateOrganizationMemberRole(state, action);
            case OrganizationActionTypes.REMOVE_ORGANIZATION:
                return this.removeOrganization(state, action);
            case OrganizationActionTypes.DELETE_ORGANIZATION_WORKFLOW_STATUS:
                return this.deleteOrganizationWorkflowStatus(state, action);
            case OrganizationActionTypes.GET_ORGANIZATION_SECTIONS_SUCCESS:
                return this.getOrganizationSectionsSuccess(state, action);
            case OrganizationActionTypes.ORGANIZATION_MEMBERS_REQUESTED:
                return this.organizationMembersRequested(state, action);
            case OrganizationActionTypes.GET_ORGANIZATION_MEMBERS_SUCCESS:
                return this.getOrganizationMembersSuccess(state, action);
            case OrganizationActionTypes.GET_ORGANIZATION_MEMBERS_FAILED:
                return this.getOrganizationMembersFailed(state, action);
            case OrganizationActionTypes.ADD_ORGANIZATION_MEMBER_PROFILE:
                return this.addOrganizationMemberProfile(state, action);
            case OrganizationActionTypes.ADD_BARREL_TO_FOCUS:
                return this.addBarrelToFocus(state, action);
            case OrganizationActionTypes.UNPIN_BARREL_FROM_FOCUS:
                return this.unpinBarrelFromFocus(state, action);
            case OrganizationActionTypes.REMOVE_BARREL_FROM_FOCUS:
                return this.removeBarrelFromFocus(state, action);
            case OrganizationActionTypes.REPIN_BARREL_TO_FOCUS:
                return this.repinBarrelToFocus(state, action);
            case OrganizationActionTypes.REMOVE_UNJOINED_BARRELS:
                return this.removeUnjoinedBarrels(state, action);
            case OrganizationActionTypes.GET_BARRELS:
                return this.updateMemberAccessibility(state, action);
            case OrganizationActionTypes.UPDATE_MEMBER_TAGS:
                return this.updateMemberTags(state, action);
            case BarrelActionTypes.UPDATE_USER_DATA:
                return this.updateMemberData(state, action);
            case OrganizationActionTypes.ADD_MEMBERS:
                return this.addMembers(state, action);
            case OrganizationActionTypes.REMOVE_MEMBER:
                return this.removeMember(state, action);
            case OrganizationActionTypes.CREATE_PROJECT_SECTION:
                return this.createProjectSection(state, action);
            case OrganizationActionTypes.ADD_PROJECT_SECTION:
                return this.addProjectSection(state, action);
            case OrganizationActionTypes.UPDATE_PROJECT_SECTION:
                return this.updateProjectSection(state, action);
            case OrganizationActionTypes.REMOVE_PROJECT_SECTION:
                return this.removeProjectSection(state, action);
            case OrganizationActionTypes.UPDATE_PROJECT_SECTION_ORDER:
                return this.updateProjectSectionOrder(state, action);
            case OrganizationActionTypes.UPDATE_PROJECT_ORDER:
                return this.updateProjectOrder(state, action);
            case OrganizationActionTypes.ADD_SECTION_LINK:
                return this.addSectionLink(state, action);
            case OrganizationActionTypes.UPDATE_SECTION_LINK:
                return this.updateSectionLink(state, action);
            case OrganizationActionTypes.REMOVE_SECTION_LINK:
                return this.removeSectionLink(state, action);
            case OrganizationActionTypes.UPDATE_FREE_TRIAL_EXPIRATION_HINTBOX_STATE:
                return this.updateFreeTrialExpirationHintboxState(state, action);
            case OrganizationActionTypes.UPDATE_FREE_TRIAL_ENDED_DIALOG_STATE:
                return this.updateFreeTrialEndedDialogState(state, action);
            case OrganizationActionTypes.UPDATE_TRANSFER_PROJECT_TOOLTIP_STATE:
                return this.updateTransferProjectTooltipState(state, action);
            case OrganizationActionTypes.UPDATE_PROJECT_LIMIT_TEYZE_STATE:
                return this.updateProjectLimitTeyzeState(state, action);

            case AppActionTypes.LOAD_WORKSPACE_DATA:
                return this.loadWorkspaceData(state, action);
            case AppActionTypes.RESET:
                return this.reset();
            case AppActionTypes.HYDRATE_EMBEDDED_DATA:
                return this.hydrateEmbeddedData(state, action);

            default:
                return state;
        }
    }
}

export default OrganizationStore;
export { State as OrganizationStoreState };
