/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { DialogParams } from "../../../foundation/dialogParams";
import { DialogTypes } from "../../../foundation/enums";

import { AllPayloads } from "../payloads";

import * as Payloads from "./DialogActionPayloads";
import DialogActionTypes from "./DialogActionTypes";

interface State {
    visibleDialogType: DialogTypes | null;
    visibleDialogParams: DialogParams;
}

class DialogStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            visibleDialogType: null,
            visibleDialogParams: {}
        };
    }

    open(state: State, {
        dialogType,
        dialogParams
    }: Payloads.Open): State {
        return {
            ...state,
            visibleDialogType: dialogType,
            visibleDialogParams: dialogParams
        };
    }

    close(state: State): State {
        return {
            ...state,
            visibleDialogType: null,
            visibleDialogParams: {}
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case DialogActionTypes.OPEN:
                return this.open(state, action);
            case DialogActionTypes.CLOSE:
                return this.close(state);

            default:
                return state;
        }
    }
}

export default DialogStore;
export { State as DialogStoreState };
