enum ActionTypes {
    CHANGE_SORT_TYPE = "dashboard/changeSortType",
    CHANGE_TAG_FILTER = "dashboard/changeTagFilter",
    TOGGLE_FIXED_THUMBNAIL_SIZE = "dashboard/toggleFixedThumbnailSize",
    CHANGE_SIDEBAR_WIDTH = "dashboard/changeSidebarWidth",
    EXPAND_SECTIONS = "dashboard/expandSections",
    COLLAPSE_SECTIONS = "dashboard/collapseSections",
    DOWNLOAD_SCREENS = "dashboard/downloadScreens",
    DOWNLOAD_SNAPSHOT_VERSION = "dashboard/downloadSnapshotVersion",
    UNHIGHLIGHT_SCREEN = "dashboard/unhighlightScreen",
    HIGHLIGHT_SCREEN = "dashboard/highlightScreen",
    HIGHLIGHT_SECTION = "dashboard/highlightSection",
    UNHIGHLIGHT_SECTION = "dashboard/unhighlightSection",

    CREATE_SECTION = "dashboard/createSection",
    ADD_SECTION = "dashboard/addSection",
    UPDATE_SECTION = "dashboard/updateSection",
    RENAME_SECTION = "dashboard/renameSection",
    UPDATE_SECTION_DESCRIPTION = "dashboard/updateSectionDescription",
    UPDATE_SECTION_ORDER = "dashboard/updateSectionOrder",
    REMOVE_SECTION = "dashboard/removeSection",

    UPDATE_SCREEN_ORDER = "dashboard/updateScreenOrder",
    UPDATE_SCREEN_ORDER_V2 = "dashboard/updateScreenOrderV2",
    CREATE_VARIANT_GROUP = "dashboard/createVariantGroup",
    ADD_SCREENS_TO_VARIANT_GROUP = "dashboard/addScreensToVariantGroup",
    UPDATE_VARIANT_GROUP = "dashboard/updateVariantGroup",
    DELETE_VARIANT_GROUP = "dashboard/deleteVariantGroup",
    RENAME_VARIANT_GROUP = "dashboard/renameVariantGroup",
    DELETE_SCREENS = "dashboard/deleteScreens",
    HIDE_NESTED_SCREEN_SECTIONS_ONBOARDING = "dashboard/hideNestedScreenSectionsOnboarding",
    HIDE_TAGS_EMPTY_ONBOARDING = "dashboard/hideTagsEmptyOnboarding",
    HIDE_TAG_GROUPS_EMPTY_ONBOARDING = "dashboard/hideTagGroupsEmptyOnboarding",
    DETACH_VARIANT_GROUP = "dashboard/detachVariantGroup",

    HIDE_ONBOARDING_INSTRUCTION = "dashboard/hideOnboardingInstruction",
    HIDE_ONBOARDING_ENDING_HINTBOX = "dashboard/hideOnboardingEndingHintbox",

    SET_SELECTED_SCREENS = "dashboard/setSelectedScreens",
    CLEAR_SCREEN_SELECTION = "dashboard/clearScreenSelection",

    SET_SELECTED_COMMENT_FILTER = "dashboard/setSelectedCommentFilter"
}

export default ActionTypes;
