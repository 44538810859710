/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";
import EmojisActionTypes from "./EmojisActionTypes";
import * as Payloads from "./EmojisActionPayloads";
import { AllPayloads } from "../payloads";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { entriesOf } from "../../../foundation/utils/object";
import defaultEmojis from "../../../foundation/utils/defaultEmojis";
import EmojiDescription from "../../../foundation/utils/EmojiDescription";
import { filterOut } from "../../../foundation/utils/legacy";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import { RemoveIntegration } from "../barrel/BarrelActionPayloads";

type DefaultEmojiAliases = {
    [defaultEmojiIndex: number]: string[];
};

interface ProjectEmojis {
    defaultEmojiAliases: DefaultEmojiAliases;
    customEmojis: EmojiDescription[];
}

interface State {
    errorProjectIds: string[];
    loadingProjectIds: string[];
    projectsEmojis: BasicRecord<ProjectEmojis>;
}

const ALIAS_PREFIX = "alias:";

class EmojisStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            errorProjectIds: [],
            loadingProjectIds: [],
            projectsEmojis: {}
        };
    }

    requestEmojis(state: State, {
        pid
    }: Pick<Payloads.RequestEmojis, "pid">): State {
        const errorProjectIds = state.errorProjectIds.filter(id => id !== pid);
        const loadingProjectIds = [...state.loadingProjectIds, pid];

        return {
            ...state,
            errorProjectIds,
            loadingProjectIds
        };
    }

    addEmojis(state: State, {
        pid,
        response
    }: Payloads.AddEmojis): State {
        const loadingProjectIds = state.loadingProjectIds.filter(id => id !== pid);

        const customAliases: BasicRecord = {};
        const customEmojis: EmojiDescription[] = [];
        entriesOf(response.emoji).forEach(([name, urlOrAliasDescription]) => {
            const alias = name.replace(/_/g, " ");
            if (urlOrAliasDescription.startsWith(ALIAS_PREFIX)) {
                const aliasOf = urlOrAliasDescription.substring(ALIAS_PREFIX.length);
                customAliases[alias] = aliasOf;
            } else if (!defaultEmojis.some(({ aliases }) => aliases.includes(alias))) {
                customEmojis.push({
                    emoji: urlOrAliasDescription,
                    aliases: [alias]
                });
            }
        });

        const defaultEmojiAliases: DefaultEmojiAliases = {};
        entriesOf(customAliases).forEach(([alias, aliasOf]) => {
            const defaultEmojiIndex = defaultEmojis.findIndex(({ aliases }) => aliases.includes(aliasOf));
            if (defaultEmojiIndex >= 0) {
                defaultEmojiAliases[defaultEmojiIndex] = [...defaultEmojiAliases[defaultEmojiIndex] ?? [], alias];
            } else {
                const customEmoji = customEmojis.find(({ aliases }) => aliases.includes(aliasOf));
                customEmoji?.aliases.push(alias);
            }
        });

        return {
            ...state,
            loadingProjectIds,
            projectsEmojis: {
                ...state.projectsEmojis,
                [pid]: {
                    defaultEmojiAliases,
                    customEmojis
                }
            }
        };
    }

    emojisError(state: State, {
        pid
    }: Payloads.EmojisError): State {
        const errorProjectIds = [...state.errorProjectIds, pid];
        const loadingProjectIds = state.loadingProjectIds.filter(id => id !== pid);

        return {
            ...state,
            errorProjectIds,
            loadingProjectIds
        };
    }

    removeIntegration(state: State, {
        bid,
        isLastSlackIntegration
    }: RemoveIntegration): State {
        if (!isLastSlackIntegration) {
            return this.requestEmojis(state, { pid: bid });
        }

        const { projectsEmojis: prevProjectsEmojis } = state;
        const projectsEmojis = filterOut(prevProjectsEmojis, bid);

        return {
            ...state,
            projectsEmojis
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case EmojisActionTypes.RequestEmojis:
                return this.requestEmojis(state, action);
            case EmojisActionTypes.AddEmojis:
                return this.addEmojis(state, action);
            case EmojisActionTypes.EmojisError:
                return this.emojisError(state, action);
            case BarrelActionTypes.REMOVE_INTEGRATION:
                return this.removeIntegration(state, action);
            default:
                return state;
        }
    }
}

export default EmojisStore;
export {
    State as EmojisStoreState
};
