/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { AllPayloads } from "../payloads";

import * as Payloads from "./WorkspaceActionPayloads";

import WorkspaceActionTypes from "./WorkspaceActionTypes";
import WorkspaceWarningDetails from "./WorkspaceWarningDetails";

interface State {
    workspaceWarningDetails: WorkspaceWarningDetails | null;
}

class WorkspaceStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            workspaceWarningDetails: null
        };
    }

    setWorkspaceWarningDetails(state: State, {
        workspaceWarningDetails
    }: Payloads.SetWorkspaceWarningDetails) {
        return {
            ...state,
            workspaceWarningDetails
        };
    }

    resetWorkspaceWarningDetails(state: State) {
        return {
            ...state,
            workspaceWarningDetails: null
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case WorkspaceActionTypes.SET_WORKSPACE_WARNING_DETAILS:
                return this.setWorkspaceWarningDetails(state, action);
            case WorkspaceActionTypes.RESET_WORKSPACE_WARNING_DETAILS:
                return this.resetWorkspaceWarningDetails(state);
            default:
                return state;
        }
    }
}

export default WorkspaceStore;
export { State as WorkspaceStoreState };
