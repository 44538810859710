/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import AppActionTypes from "../app/AppActionTypes";
import * as AppActionPayloads from "../app/AppActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelType from "../../../foundation/model/BarrelType";
import { AllPayloads } from "../payloads";

const maxBarrelCount = 10;

interface State {
    projects: {
        _id: string;
        name: string;
    }[];
    styleguides: {
        _id: string;
        name: string;
    }[];
}

class RecentlyLoadedBarrelsStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            projects: [],
            styleguides: []
        };
    }

    init({
        projects,
        styleguides
    }: AppActionPayloads.InitRecentlyLoadedBarrels): State {
        return {
            projects,
            styleguides
        };
    }

    update(state: State, {
        barrel: {
            barrelType
        },
        data
    }: BarrelActionPayloads.Load): State {
        const { _id, name } = data[barrelType]!;

        const barrelsKey = barrelType === BarrelType.PROJECT ? "projects" : "styleguides";

        const barrels = state[barrelsKey];

        const filteredBarrels = barrels.filter(({ _id: existingBid }) => existingBid !== _id);
        const updatedBarrels = [{ _id, name }, ...filteredBarrels];
        const limitedBarrels = updatedBarrels.slice(0, maxBarrelCount);

        return {
            ...state,
            [barrelsKey]: limitedBarrels
        };
    }

    clear(state: State, { barrelType }: BarrelActionPayloads.ClearRecentlyLoadedBarrels) {
        const barrelsKey = barrelType === BarrelType.PROJECT ? "projects" : "styleguides";

        return {
            ...state,
            [barrelsKey]: []
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.LOAD:
                return this.update(state, action);
            case AppActionTypes.INIT_RECENTLY_LOADED_BARRELS:
                return this.init(action);
            case BarrelActionTypes.CLEAR_RECENTLY_LOADED_BARRELS:
                return this.clear(state, action);
            default:
                return state;
        }
    }
}

export default RecentlyLoadedBarrelsStore;
export { State as RecentlyLoadedBarrelsStoreState };
