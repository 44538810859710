/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import ApiConfig from "../../../foundation/api/model/config/ApiConfig";
import { AppRoutes, PageType } from "../../../foundation/enums";
import { getAppRouteFromPathname } from "../../../foundation/utils/url";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as DashboardActionPayloads from "../dashboard/DashboardActionPayloads";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import * as FlowActionPayloads from "../flow/FlowActionPayloads";
import FlowActionTypes from "../flow/FlowActionTypes";
import * as ScreenActionPayloads from "../screen/ScreenActionPayloads";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./AppActionPayloads";

import AppActionTypes from "./AppActionTypes";
import UpdateNotificationBannerDetails from "./UpdateNotificationBannerDetails";

interface BackStackElement {
    label: string;
    link: string;
}
interface State {
    config: ApiConfig | null;
    backStack: BackStackElement[];
    page: PageType;
    sessionTokenExpired: string;
    isLoggedOut: boolean;
    showNotificationSettingsDialog: boolean;
    whatsNewModalOpen: boolean;
    whatsNewPopupOpen: boolean;
    updateNotificationBannerDetails: UpdateNotificationBannerDetails | null;
    shouldDisplaySocketConnectionIssueBanner: boolean;
}

function getInitialPage() {
    const appRoute = getAppRouteFromPathname(window.location.pathname);

    if (appRoute === AppRoutes.Workspace) {
        return PageType.Workspace;
    }

    if (appRoute === AppRoutes.Notifications) {
        return PageType.Activities;
    }

    return PageType.Barrel;
}

class AppStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            config: null,
            backStack: [],
            page: getInitialPage(),
            sessionTokenExpired: "",
            isLoggedOut: false,
            showNotificationSettingsDialog: false,
            whatsNewModalOpen: false,
            whatsNewPopupOpen: false,
            updateNotificationBannerDetails: null,
            shouldDisplaySocketConnectionIssueBanner: false
        };
    }

    reset(state: State): State {
        return { ...this.getInitialState(), config: state.config };
    }

    popBackStack(state: State) {
        const { backStack } = state;
        backStack.pop();
        return {
            ...state,
            backStack
        };
    }

    pushToBackStack(state: State, {
        label,
        link
    }: Payloads.PushToBackStack): State {
        return {
            ...state,
            backStack: [
                ...state.backStack, {
                    label,
                    link
                }
            ]
        };
    }

    updateForUpdateComponent(state: State, {
        coid,
        componentData
    }: BarrelActionPayloads.UpdateComponent): State {
        if (!("name" in componentData)) {
            return state;
        }

        const { backStack } = state;

        if (!backStack.length) {
            return state;
        }

        const newBackStack = backStack.map(({ link, label }) => {
            const backButtonURL = new URL(link);
            if (coid !== backButtonURL.searchParams.get("coid")) {
                return {
                    link,
                    label
                };
            }

            return {
                link,
                label: componentData.name!
            };
        });

        return {
            ...state,
            backStack: newBackStack
        };
    }

    updateForRemoveComponent(state: State, {
        coid
    }: Pick<BarrelActionPayloads.RemoveComponent, "coid">): State {
        const { backStack } = state;

        if (!backStack.length) {
            return state;
        }

        const newBackStack = backStack.filter(({ link }) => {
            const backButtonURL = new URL(link);
            return coid !== backButtonURL.searchParams.get("coid");
        });

        return {
            ...state,
            backStack: newBackStack
        };
    }

    updateForRemoveComponents(state: State, {
        sectionComponents
    }: BarrelActionPayloads.RemoveComponents): State {
        const { backStack } = state;
        if (!backStack.length) {
            return state;
        }
        let newState = state;
        sectionComponents.forEach(({ coids }) => {
            coids.forEach(coid => {
                newState = this.updateForRemoveComponent(newState, { coid });
            });
        });

        return newState;
    }

    updateForUpdateScreenName(state: State, {
        sid,
        name
    }: ScreenActionPayloads.UpdateName): State {
        const { backStack } = state;

        if (!backStack.length) {
            return state;
        }

        const newBackStack = backStack.map(({ link, label }) => {
            if (!link.endsWith(sid)) {
                return {
                    link,
                    label
                };
            }

            return {
                link,
                label: name
            };
        });

        return {
            ...state,
            backStack: newBackStack
        };
    }

    updateForRemoveScreen(state: State, {
        sid
    }: Pick<ScreenActionPayloads.RemoveScreen, "sid">): State {
        const { backStack } = state;

        if (!backStack.length) {
            return state;
        }

        const newBackStack = backStack.filter(({ link }) => !link.endsWith(sid));

        return {
            ...state,
            backStack: newBackStack
        };
    }

    updateForRemoveScreens(state: State, {
        items
    }: DashboardActionPayloads.DeleteScreens): State {
        const { backStack } = state;
        if (!backStack.length) {
            return state;
        }

        let newState = state;
        items.forEach(({ _id }) => {
            newState = this.updateForRemoveScreen(newState, { sid: _id });
        });

        return newState;
    }

    updateForFlowToScreenNavigation(state: State, { flowUrl }: FlowActionPayloads.NavigateToScreen) {
        return {
            ...state,
            backButtonLabel: "Flows",
            backButtonLink: flowUrl!
        };
    }

    setPage(state: State, {
        page
    }: Payloads.SetPage): State {
        return {
            ...state,
            page: page as PageType,
            backStack: page === PageType.Workspace ? [] : state.backStack
        };
    }

    setConfig(state: State, {
        config
    }: Payloads.HydrateEmbeddedData): State {
        return {
            ...state,
            config
        };
    }

    logOut(state: State): State {
        return {
            ...state,
            isLoggedOut: true
        };
    }

    sessionTokenExpired(state: State, {
        frequency
    }: Payloads.SessionTokenExpired): State {
        return {
            ...state,
            sessionTokenExpired: frequency
        };
    }

    unsetSessionTokenExpired(state: State): State {
        return {
            ...state,
            sessionTokenExpired: ""
        };
    }

    loadBarrel(state: State, action: BarrelActionPayloads.Load): State {
        const { app } = action;

        if (app && app.showNotificationSettingsDialog) {
            return {
                ...state,
                showNotificationSettingsDialog: true
            };
        }

        return state;
    }

    setNotificationSettingsDialogVisibility(state: State, {
        visible
    }: Payloads.SetNotificationSettingsDialogVisibility): State {
        return {
            ...state,
            showNotificationSettingsDialog: visible
        };
    }

    setWhatsNewModalVisibility(state: State, {
        visible
    }: Payloads.SetWhatsNewModalVisibility): State {
        return {
            ...state,
            whatsNewModalOpen: visible
        };
    }

    setWhatsNewPopupVisibility(state: State, {
        visible
    }: Payloads.SetWhatsNewPopupVisibility): State {
        return {
            ...state,
            whatsNewPopupOpen: visible
        };
    }

    setUpdateNotificationBannerDetails(state: State, {
        updateNotificationBannerDetails
    }: Payloads.SetUpdateNotificationBannerDetails) {
        return {
            ...state,
            updateNotificationBannerDetails
        };
    }

    resetUpdateNotificationBannerDetails(state: State) {
        return {
            ...state,
            updateNotificationBannerDetails: null
        };
    }

    setShouldDisplaySocketConnectionIssueBanner(state: State, {
        display
    }: Payloads.SetShouldDisplayPusherConnectionIssueBanner): State {
        return {
            ...state,
            shouldDisplaySocketConnectionIssueBanner: display
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.HYDRATE_EMBEDDED_DATA:
                return this.setConfig(state, action);
            case AppActionTypes.RESET:
                return this.reset(state);
            case AppActionTypes.POP_BACK_STACK:
                return this.popBackStack(state);
            case AppActionTypes.PUSH_TO_BACK_STACK:
                return this.pushToBackStack(state, action);
            case AppActionTypes.SET_PAGE:
                return this.setPage(state, action);
            case AppActionTypes.SESSION_TOKEN_EXPIRED:
                return this.sessionTokenExpired(state, action);
            case AppActionTypes.UNSET_SESSION_TOKEN_EXPIRED:
                return this.unsetSessionTokenExpired(state);
            case AppActionTypes.SET_NOTIFICATION_SETTINGS_DIALOG_VISIBILITY:
                return this.setNotificationSettingsDialogVisibility(state, action);
            case AppActionTypes.SET_WHATS_NEW_MODAL_VISIBILITY:
                return this.setWhatsNewModalVisibility(state, action);
            case AppActionTypes.SET_WHATS_NEW_POPUP_VISIBILITY:
                return this.setWhatsNewPopupVisibility(state, action);
            case AppActionTypes.LOG_OUT:
                return this.logOut(state);
            case AppActionTypes.SET_UPDATE_NOTIFICATION_BANNER_DETAILS:
                return this.setUpdateNotificationBannerDetails(state, action);
            case AppActionTypes.RESET_UPDATE_NOTIFICATION_BANNER_DETAILS:
                return this.resetUpdateNotificationBannerDetails(state);
            case AppActionTypes.SET_SHOULD_DISPLAY_SOCKET_CONNECTION_ISSUE_BANNER:
                return this.setShouldDisplaySocketConnectionIssueBanner(state, action);

            case BarrelActionTypes.UPDATE_COMPONENT:
                return this.updateForUpdateComponent(state, action);
            case BarrelActionTypes.REMOVE_COMPONENT:
                return this.updateForRemoveComponent(state, action);
            case BarrelActionTypes.LOAD:
                return this.loadBarrel(state, action);
            case BarrelActionTypes.REMOVE_COMPONENTS:
                return this.updateForRemoveComponents(state, action);

            case DashboardActionTypes.DELETE_SCREENS:
                return this.updateForRemoveScreens(state, action);

            case ScreenActionTypes.UPDATE_NAME:
                return this.updateForUpdateScreenName(state, action);
            case ScreenActionTypes.REMOVE_SCREEN:
                return this.updateForRemoveScreen(state, action);

            case FlowActionTypes.NAVIGATE_TO_SCREEN:
                return this.updateForFlowToScreenNavigation(state, action);

            default:
                return state;
        }
    }
}

export default AppStore;
export { State as AppStoreState };
