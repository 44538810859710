/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import BarrelActionTypes from "./BarrelActionTypes";
import StyleguideActionTypes from "../styleguide/StyleguideActionTypes";
import { AllPayloads } from "../payloads";

interface State {
    selectedComponentIds: string[];
    componentToScroll: string | null;
}

class ComponentSelectionStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            selectedComponentIds: [],
            componentToScroll: null
        };
    }

    setSelectedComponents(state: State, {
        selectedComponentIds,
        componentToScroll
    }: {
        selectedComponentIds: string[];
        componentToScroll?: string;
    }): State {
        const selectedComponentSet = new Set(selectedComponentIds);

        return {
            ...state,
            selectedComponentIds: Array.from(selectedComponentSet),
            componentToScroll: componentToScroll ?? state.componentToScroll
        };
    }

    clearComponentSelection(state: State): State {
        if (state.selectedComponentIds.length === 0) {
            return state;
        }

        return {
            ...state,
            selectedComponentIds: []
        };
    }

    scrolledToComponent(state: State): State {
        return {
            ...state,
            componentToScroll: null
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.SET_SELECTED_COMPONENTS:
                return this.setSelectedComponents(state, action);
            case BarrelActionTypes.CLEAR_COMPONENT_SELECTION:
                return this.clearComponentSelection(state);
            case BarrelActionTypes.SCROLLED_TO_COMPONENT:
                return this.scrolledToComponent(state);

            case BarrelActionTypes.LOAD: {
                const { selectionComponentId } = action.styleguide ?? {};
                if (selectionComponentId) {
                    return this.setSelectedComponents(state, {
                        selectedComponentIds: [selectionComponentId],
                        componentToScroll: selectionComponentId
                    });
                }
                return state;
            }
            case StyleguideActionTypes.UPDATE_STATE: {
                const { selectionComponentId } = action.update;
                if (selectionComponentId) {
                    return this.setSelectedComponents(state, {
                        selectedComponentIds: [selectionComponentId],
                        componentToScroll: selectionComponentId
                    });
                }

                return state;
            }
            case StyleguideActionTypes.COLLAPSE_ALL_SECTIONS:
                return this.clearComponentSelection(state);
            default:
                return state;
        }
    }
}

export default ComponentSelectionStore;
export { State as ComponentSelectionStoreState };
