import "../../../foundation/forms";
import * as dialog from "../../../foundation/dialog";
import getRedirectUrl from "../../../foundation/utils/get-redirect-url";
import checkAuthError from "../../../foundation/utils/checkAuthError";
import * as banner from "../../../foundation/banner";
import "../electronApp/menu/loginAppMenu";
import "../electronApp/windowsAppUpdates";
import { sendGAPageView } from "../../../foundation/utils/tracking";
import PreactDOMComponent from "../../../foundation/utils/preact-dom-component";
import LoginPage from "../../containers/LoginSignup/pages/LoginPage";

const LoginPageComponent = new PreactDOMComponent(LoginPage, document.body);

sendGAPageView();

const loginParams = {};

function processUrlParams() {
    if (location.search) {
        location.search.substr(1).split("&").forEach(function (param) {
            var kv = param.split("=");
            loginParams[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
        });

        history.replaceState({}, "Zeplin - Login", location.pathname);
    }

    if (loginParams.redirect) {
        sessionStorage.setItem("redirectTo", getRedirectUrl(loginParams.redirect, "/"));
    }

    if (loginParams.sessionExpiredForAppAuthorization) {
        dialog.create({
            title: "Session expired",
            message: "You must login again to authorize the app."
        });
    }
}

function checkSessionTermination() {
    const sessionTerminatedBanner = sessionStorage.getItem("sessionTerminatedBanner");
    const parsedBanner = sessionTerminatedBanner && JSON.parse(sessionTerminatedBanner);
    if (parsedBanner) {
        sessionStorage.removeItem("sessionTerminatedBanner");
        banner.show(parsedBanner);
    }
}

function render() {
    LoginPageComponent.render({
        loginParams
    });
}

processUrlParams();
render();
checkAuthError();
checkSessionTermination();
