enum ActionTypes {
    OPEN_COMPONENT = "component/openComponent",
    SET_COMPONENT_VERSIONS = "component/setComponentVersions",
    UPDATE_COMMIT_MESSAGE = "component/updateCommitMessage",
    UPDATE_COMMIT_COLOR = "component/updateCommitColor",
    REMOVE_VERSION = "component/removeVersion",
    SET_VERSION_DIFFS = "component/setVersionDiffs"
}

export default ActionTypes;
