/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { SortTypes } from "../../../foundation/enums";
import BarrelType from "../../../foundation/model/BarrelType";
import { DotCommentFilter } from "../../../foundation/model/DotComment";
import isUserMemberOfBarrel from "../../../foundation/utils/barrel/isUserMemberOfBarrel";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { PartializeProps } from "../../../foundation/utils/UtilityTypes";
import { LabelFilter, LabelFilterValues, SortCommentsByDate } from "../../containers/AppContainer/BarrelContainer/BarrelSidebarContainer/BarrelSidebar/CommentsView/types";
import AppActionTypes from "../app/AppActionTypes";

import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import ProjectActionTypes from "../project/ProjectActionTypes";
import * as ScreenActionPayloads from "../screen/ScreenActionPayloads";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./DashboardActionPayloads";
import DashboardActionTypes from "./DashboardActionTypes";

import TagFilter from "./TagFilter";

type State = {
    sortType: SortTypes;
    selectedTagGroup: string | undefined;
    filterValue: string | undefined;
    fixedThumbnailSize: boolean;
    nestedScreenSectionsOnboardingSeen: boolean;
    tagsEmptyOnboardingSeen: boolean;
    tagGroupsEmptyOnboardingSeen: boolean;
    sidebarWidth: number;
    tagFilter: TagFilter;
    jiraIssueFilter: string[];
    collapsedSections: BasicRecord<boolean>;
    highlightedSection: string | null;
    highlightedScreens: string[];
    dashboardOnboardingEndingHintboxHidden: boolean;
    preventScrollToHighlightedScreen: boolean;
    screenIdToScroll?: string;
    commentsViewSortAndFilter: DotCommentFilter;
};

class DashboardStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            sortType: SortTypes.SECTIONS,
            selectedTagGroup: undefined,
            filterValue: "",
            fixedThumbnailSize: false,
            nestedScreenSectionsOnboardingSeen: false,
            tagsEmptyOnboardingSeen: false,
            tagGroupsEmptyOnboardingSeen: false,
            sidebarWidth: 280,
            tagFilter: { tids: [], untagged: false },
            jiraIssueFilter: [],
            collapsedSections: {},
            highlightedSection: null,
            highlightedScreens: [],
            dashboardOnboardingEndingHintboxHidden: false,
            preventScrollToHighlightedScreen: false,
            screenIdToScroll: undefined,
            commentsViewSortAndFilter: {
                text: "",
                color: null,
                label: LabelFilterValues[LabelFilter.Unresolved],
                sort: SortCommentsByDate.ASC
            }
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    changeSelectedView(state: State, {
        viewUpdate: {
            sids,
            seid,
            filterValue,
            tids,
            jiraIssues,
            untagged,
            sectionsToExpand
        }
    }: BarrelActionPayloads.ChangeSelectedView): State {
        let filteredState: State = {
            ...state,
            tagFilter: { tids: [], untagged: false },
            jiraIssueFilter: [],
            highlightedScreens: [],
            highlightedSection: null
        };

        // Apply filters
        if (tids) {
            filteredState = this.changeTagFilter(filteredState, { tids, untagged: false });
        }

        if (untagged) {
            filteredState = this.changeTagFilter(filteredState, { tids: [], untagged: true });
        }

        if (jiraIssues) {
            filteredState = {
                ...filteredState,
                jiraIssueFilter: jiraIssues
            };
        }

        if (filteredState.filterValue !== filterValue) {
            filteredState = {
                ...filteredState,
                filterValue
            };
        }

        // Highlight
        if (sids) {
            filteredState = {
                ...filteredState,
                preventScrollToHighlightedScreen: false,
                highlightedScreens: [...sids],
                collapsedSections: this.expandSections(filteredState, sectionsToExpand!).collapsedSections
            };
        }

        if (seid) {
            filteredState = {
                ...filteredState,
                highlightedSection: seid,
                collapsedSections: this.expandSections(filteredState, sectionsToExpand!).collapsedSections
            };
        }

        return filteredState;
    }

    changeSortType(state: State, {
        sortType,
        selectedTagGroup
    }: Payloads.ChangeSortType): State {
        return {
            ...state,
            sortType,
            selectedTagGroup,
            highlightedScreens: []
        };
    }

    changeTagFilter(state: State, tagFilter: TagFilter): State {
        return {
            ...state,
            tagFilter,
            highlightedScreens: []
        };
    }

    toggleFixedThumbnailSize(state: State): State {
        return {
            ...state,
            fixedThumbnailSize: !state.fixedThumbnailSize
        };
    }

    hideNestedScreenSectionsOnboarding(state: State): State {
        return {
            ...state,
            nestedScreenSectionsOnboardingSeen: true
        };
    }

    hideTagsEmptyOnboarding(state: State): State {
        return {
            ...state,
            tagsEmptyOnboardingSeen: true
        };
    }

    hideTagGroupsEmptyOnboarding(state: State): State {
        return {
            ...state,
            tagGroupsEmptyOnboardingSeen: true
        };
    }

    changeSidebarWidth(state: State, {
        sidebarWidth
    }: Payloads.ChangeSidebarWidth): State {
        return {
            ...state,
            sidebarWidth
        };
    }

    expandSections(state: State, seids: string[]): State {
        return {
            ...state,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(seids.map(seid => [seid, false]))
            }
        };
    }

    collapseSections(state: State, {
        seids
    }: Payloads.CollapseSections): State {
        return {
            ...state,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(seids.map(seid => [seid, true]))
            }
        };
    }

    highlightScreen(state: State, {
        sid,
        scroll
    }: Payloads.HighlightScreen): State {
        return {
            ...state,
            preventScrollToHighlightedScreen: !scroll,
            screenIdToScroll: scroll ? sid : undefined,
            highlightedScreens: [sid]
        };
    }

    unhighlightScreen(state: State, {
        sid
    }: Payloads.UnhighlightScreen): State {
        return {
            ...state,
            highlightedScreens: state.highlightedScreens.filter(id => id !== sid)
        };
    }

    highlightSection(state: State, {
        seid: highlightedSection,
        sectionsToExpand = []
    }: Payloads.HighlightSection): State {
        return {
            ...state,
            highlightedSection,
            collapsedSections: this.expandSections(state, sectionsToExpand).collapsedSections
        };
    }

    unhighlightSection(state: State): State {
        return {
            ...state,
            highlightedSection: null
        };
    }

    highlightUpdatedSection(state: State, {
        seid,
        highlight,
        sectionData
    }: Payloads.UpdateSection): State {
        if (!highlight) {
            return state;
        }

        const highlightedSection = sectionData._id || seid;

        return {
            ...state,
            highlightedSection
        };
    }

    updateForOpenScreen(state: State, {
        screenId,
        shouldResetDashboardFilters
    }: ScreenActionPayloads.OpenScreen | BarrelActionPayloads.OpenScreenData): State {
        let maybeResetFilters = {};
        if (shouldResetDashboardFilters) {
            maybeResetFilters = {
                tagFilter: { tids: [], untagged: false },
                jiraIssueFilter: []
            };
        }

        return {
            ...state,
            ...maybeResetFilters,
            highlightedScreens: [screenId]
        };
    }

    loadIntermediate(state: State, {
        barrelData: {
            dashboard,
            data: {
                project
            },
            user: {
                userId
            }
        },
        openScreenData
    }: BarrelActionPayloads.LoadIntermediate) {
        if (!isUserMemberOfBarrel(project, userId)) {
            return state;
        }

        return {
            ...this.updateForOpenScreen(state, openScreenData),
            ...dashboard
        };
    }

    load(state: State, {
        barrel: {
            barrelType
        },
        dashboard = {},
        data: {
            project
        },
        user: {
            userId
        }
    }: PartializeProps<BarrelActionPayloads.Load, "dashboard">): State {
        if (barrelType === BarrelType.STYLEGUIDE || !isUserMemberOfBarrel(project, userId)) {
            return state;
        }

        return {
            ...state,
            ...dashboard
        };
    }

    preloadProjectDashboard(state: State, {
        barrel: {
            barrelType
        },
        dashboard = {},
        data: {
            project
        },
        user: {
            userId
        }
    }: PartializeProps<BarrelActionPayloads.PreloadDashboard, "dashboard">): State {
        if (barrelType === BarrelType.STYLEGUIDE || !isUserMemberOfBarrel(project, userId)) {
            return state;
        }

        return {
            ...state,
            ...dashboard
        };
    }

    hideOnboardingEndingHintbox(state: State): State {
        return {
            ...state,
            dashboardOnboardingEndingHintboxHidden: true
        };
    }

    clearScreenSelection(state: State): State {
        if (state.highlightedScreens.length === 0) {
            return state;
        }

        return {
            ...state,
            highlightedScreens: [],
            screenIdToScroll: undefined
        };
    }

    setSelectedScreens(state: State, {
        scrollToFirstSelectedScreen,
        sids
    }: Payloads.SetSelectedScreens): State {
        if (sids.length === 1) {
            return {
                ...state,
                screenIdToScroll: scrollToFirstSelectedScreen ? sids[0] : undefined
            };
        }

        return {
            ...state,
            screenIdToScroll: scrollToFirstSelectedScreen ? sids[0] : undefined
        };
    }

    setSelectedCommentFilter(state: State, sortAndFilterData: Payloads.SetSelectedCommentFilter): State {
        return {
            ...state,
            commentsViewSortAndFilter: {
                ...state.commentsViewSortAndFilter,
                ...sortAndFilterData
            }
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.LOAD:
                return this.load(state, action);

            case BarrelActionTypes.PRELOAD_PROJECT_DASHBOARD:
                return this.preloadProjectDashboard(state, action);

            case AppActionTypes.RESET:
            case BarrelActionTypes.RESET:
                return this.reset();

            case BarrelActionTypes.CHANGE_SELECTED_VIEW:
                return this.changeSelectedView(state, action);

            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.loadIntermediate(state, action);

            case DashboardActionTypes.CHANGE_SORT_TYPE:
                return this.changeSortType(state, action);

            case DashboardActionTypes.CHANGE_TAG_FILTER:
            case ProjectActionTypes.REMOVE_TAG:
            case ProjectActionTypes.REMOVE_TAG_GROUP:
                return this.changeTagFilter(state, action.tagFilter);

            case DashboardActionTypes.TOGGLE_FIXED_THUMBNAIL_SIZE:
                return this.toggleFixedThumbnailSize(state);

            case DashboardActionTypes.HIDE_NESTED_SCREEN_SECTIONS_ONBOARDING:
                return this.hideNestedScreenSectionsOnboarding(state);

            case DashboardActionTypes.HIDE_TAGS_EMPTY_ONBOARDING:
                return this.hideTagsEmptyOnboarding(state);

            case DashboardActionTypes.HIDE_TAG_GROUPS_EMPTY_ONBOARDING:
                return this.hideTagGroupsEmptyOnboarding(state);

            case DashboardActionTypes.CHANGE_SIDEBAR_WIDTH:
                return this.changeSidebarWidth(state, action);

            case DashboardActionTypes.EXPAND_SECTIONS:
                return this.expandSections(state, action.seids);

            case DashboardActionTypes.COLLAPSE_SECTIONS:
                return this.collapseSections(state, action);

            case DashboardActionTypes.UNHIGHLIGHT_SCREEN:
                return this.unhighlightScreen(state, action);

            case DashboardActionTypes.HIGHLIGHT_SCREEN:
                return this.highlightScreen(state, action);

            case DashboardActionTypes.HIGHLIGHT_SECTION:
                return this.highlightSection(state, action);

            case DashboardActionTypes.UNHIGHLIGHT_SECTION:
                return this.unhighlightSection(state);

            case DashboardActionTypes.UPDATE_SECTION:
                return this.highlightUpdatedSection(state, action);

            case DashboardActionTypes.HIDE_ONBOARDING_ENDING_HINTBOX:
                return this.hideOnboardingEndingHintbox(state);

            case DashboardActionTypes.CLEAR_SCREEN_SELECTION:
                return this.clearScreenSelection(state);

            case DashboardActionTypes.SET_SELECTED_SCREENS:
                return this.setSelectedScreens(state, action);

            case DashboardActionTypes.SET_SELECTED_COMMENT_FILTER:
                return this.setSelectedCommentFilter(state, action);

            case ScreenActionTypes.OPEN_SCREEN:
                return this.updateForOpenScreen(state, action);

            default:
                return state;
        }
    }
}

export default DashboardStore;
export { State as DashboardStoreState };
