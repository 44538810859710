/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import AppsActionTypes from "./AppsActionTypes";
import * as Payloads from "./AppsActionPayloads";
import Application from "../../../foundation/model/Application";
import { AllPayloads } from "../payloads";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import OfficialApp, { OfficialAppStatus, OFFICIAL_APPS_BASES } from "./OfficialApp";
import PersonalAccessToken from "../../../foundation/api/model/accessTokens/PersonalAccessToken";

interface State {
    loadingConnectedAppsData: boolean;
    loadingDeveloperData: boolean;
    loadingApplication: boolean;
    revokingAuthorizedApplication: boolean;
    deletingPersonalAccessToken: boolean;
    creatingAccessToken: boolean;
    creatingApplication: boolean;
    updatingApplication: boolean;
    deletingApplication: boolean;
    authorizedApplications: Application[];
    ownedApplications: Application[];
    personalAccessTokens: PersonalAccessToken[];
    fetchedApplicationIds: string[];
    officialApps: BasicRecord<OfficialApp>;
}

class AppsStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            loadingConnectedAppsData: true,
            loadingDeveloperData: false,
            loadingApplication: false,
            revokingAuthorizedApplication: false,
            deletingPersonalAccessToken: false,
            creatingAccessToken: false,
            creatingApplication: false,
            updatingApplication: false,
            deletingApplication: false,
            authorizedApplications: [],
            ownedApplications: [],
            personalAccessTokens: [],
            fetchedApplicationIds: [],
            officialApps: OFFICIAL_APPS_BASES.reduce(
                (prev, app) => ({
                    ...prev,
                    [app._id]: {
                        ...app,
                        token: null,
                        status: OfficialAppStatus.Unconnected
                    }
                }),
                {}
            )
        };
    }

    loadConnectedAppsData(state: State, {
        authorizedApplications,
        officialApps
    }: Payloads.GetConnectedAppsDataSuccess): State {
        return {
            ...state,
            authorizedApplications: authorizedApplications.map(
                authorizedApplication => new Application(authorizedApplication)
            ),
            officialApps,
            loadingConnectedAppsData: false
        };
    }

    loadDeveloperDataRequest(state: State): State {
        return {
            ...state,
            loadingDeveloperData: true
        };
    }

    loadDeveloperData(state: State, {
        ownedApplications,
        personalAccessTokens
    }: Payloads.GetDeveloperDataSuccess): State {
        return {
            ...state,
            ownedApplications: ownedApplications.map(ownedApplication => new Application(ownedApplication)),
            personalAccessTokens,
            loadingDeveloperData: false
        };
    }

    getApplicationRequest(state: State): State {
        return {
            ...state,
            loadingApplication: true
        };
    }

    loadApplication(state: State, {
        application
    }: Payloads.GetApplicationSuccess): State {
        const { ownedApplications, fetchedApplicationIds } = state;
        return {
            ...state,
            ownedApplications: ownedApplications.map(ownedApplication => {
                if (application._id === ownedApplication._id) {
                    return new Application(application);
                }
                return ownedApplication;
            }),
            fetchedApplicationIds: [...fetchedApplicationIds, application._id],
            loadingApplication: false
        };
    }

    revokeAuthorizedApplicationSuccess(state: State, {
        authorizedApplication
    }: Payloads.RevokeAuthorizedApplicationSuccess): State {
        return {
            ...state,
            authorizedApplications: state.authorizedApplications.filter(({ _id }) => authorizedApplication._id !== _id),
            revokingAuthorizedApplication: false
        };
    }

    revokeAuthorizedApplicationFailure(state: State): State {
        return {
            ...state,
            revokingAuthorizedApplication: false
        };
    }

    deletePersonalAccessTokenRequest(state: State): State {
        return {
            ...state,
            deletingPersonalAccessToken: true
        };
    }

    deletePersonalAccessTokenSuccess(state: State, {
        personalAccessToken
    }: Payloads.DeletePersonalAccessTokenSuccess): State {
        return {
            ...state,
            personalAccessTokens: state.personalAccessTokens.filter(({ _id }) => personalAccessToken._id !== _id),
            deletingPersonalAccessToken: false
        };
    }

    deletePersonalAccessTokenFailure(state: State): State {
        return {
            ...state,
            deletingPersonalAccessToken: false
        };
    }

    createPersonalAccessTokenRequest(state: State): State {
        return {
            ...state,
            creatingAccessToken: true
        };
    }

    createPersonalAccessTokenSuccess(state: State, {
        personalAccessToken
    }: Payloads.CreatePersonalAccessTokenSuccess): State {
        return {
            ...state,
            personalAccessTokens: [personalAccessToken, ...state.personalAccessTokens],
            creatingAccessToken: false
        };
    }

    createPersonalAccessTokenFailure(state: State): State {
        return {
            ...state,
            creatingAccessToken: false
        };
    }

    createApplicationRequest(state: State): State {
        return {
            ...state,
            creatingApplication: true
        };
    }

    createApplicationSuccess(state: State, {
        application
    }: Payloads.CreateApplicationSuccess): State {
        return {
            ...state,
            /* @ts-ignore */
            // TODO: types doesn't match, response data may be typed wrong, or that type is just adequate for where
            // ownedApplications is consumed
            ownedApplications: [application, ...state.ownedApplications],
            creatingApplication: false
        };
    }

    createApplicationFailure(state: State): State {
        return {
            ...state,
            creatingApplication: false
        };
    }

    updateApplicationRequest(state: State): State {
        return {
            ...state,
            updatingApplication: true
        };
    }

    updateApplicationSuccess(state: State, {
        appId,
        application
    }: Payloads.UpdateApplicationSuccess): State {
        return {
            ...state,
            ownedApplications: state.ownedApplications.map(ownedApplication => {
                if (ownedApplication._id === appId) {
                    /* @ts-ignore */
                    // TODO: _id is overriden by application, doesn't seem reasonable, response data may be typed wrong
                    // it doesn't contain _id field, or adding _id here is redundant, or there is a bug
                    return new Application({ _id: appId, ...application });
                }
                return ownedApplication;
            }),
            updatingApplication: false
        };
    }

    updateApplicationFailure(state: State): State {
        return {
            ...state,
            updatingApplication: false
        };
    }

    deleteApplicationRequest(state: State): State {
        return {
            ...state,
            deletingApplication: true
        };
    }

    deleteApplicationSuccess({
        ownedApplications,
        ...state
    }: State, {
        application: {
            _id
        }
    }: Payloads.DeleteApplicationSuccess): State {
        return {
            ...state,
            ownedApplications: ownedApplications.filter(ownedApplication => ownedApplication._id !== _id),
            deletingApplication: false
        };
    }

    deleteApplicationFailure(state: State): State {
        return {
            ...state,
            deletingApplication: false
        };
    }

    renamePersonalAccessToken(state: State, {
        tokenId,
        name
    }: Payloads.RenamePersonalAccessToken): State {
        return {
            ...state,
            personalAccessTokens: state.personalAccessTokens.map(personalAccessToken => {
                if (personalAccessToken._id === tokenId) {
                    return {
                        ...personalAccessToken,
                        name
                    };
                }
                return personalAccessToken;
            })
        };
    }

    setRevokingAuthorizedApplication(state: State): State {
        return {
            ...state,
            revokingAuthorizedApplication: true
        };
    }

    setOfficialAppConnecting(state: State, {
        appId
    }: Payloads.ConnectOfficialApp): State {
        return {
            ...state,
            officialApps: {
                ...state.officialApps,
                [appId]: {
                    ...state.officialApps[appId],
                    token: null,
                    status: OfficialAppStatus.Connecting
                }
            }
        };
    }

    connectOfficalAppSuccess(state: State, {
        appId,
        token
    }: Payloads.ConnectOfficialAppSuccess): State {
        return {
            ...state,
            officialApps: {
                ...state.officialApps,
                [appId]: {
                    ...state.officialApps[appId],
                    token,
                    status: OfficialAppStatus.Connected
                }
            }
        };
    }

    connectOfficialAppFailure(state: State, {
        appId
    }: Payloads.ConnectOfficialAppFailure): State {
        return {
            ...state,
            officialApps: {
                ...state.officialApps,
                [appId]: {
                    ...state.officialApps[appId],
                    token: null,
                    status: OfficialAppStatus.Unconnected
                }
            }
        };
    }

    revokeOfficialApp(state: State, {
        authorizedApplication: app
    }: Payloads.RevokeAuthorizedApplicationSuccess): State {
        return {
            ...state,
            officialApps: {
                ...state.officialApps,
                [app._id]: {
                    ...state.officialApps[app._id],
                    token: null,
                    status: OfficialAppStatus.Unconnected
                }
            },
            revokingAuthorizedApplication: false
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppsActionTypes.GET_CONNECTED_APPS_DATA_SUCCESS:
                return this.loadConnectedAppsData(state, action);

            case AppsActionTypes.GET_DEVELOPER_DATA_REQUEST:
                return this.loadDeveloperDataRequest(state);

            case AppsActionTypes.GET_DEVELOPER_DATA_SUCCESS:
                return this.loadDeveloperData(state, action);

            case AppsActionTypes.GET_APPLICATION_REQUEST:
                return this.getApplicationRequest(state);

            case AppsActionTypes.GET_APPLICATION_SUCCESS:
                return this.loadApplication(state, action);

            case AppsActionTypes.REVOKE_AUTHORIZED_APPLICATION_REQUEST:
                return this.setRevokingAuthorizedApplication(state);

            case AppsActionTypes.REVOKE_AUTHORIZED_APPLICATION_SUCCESS:
                if (OFFICIAL_APPS_BASES.some(({ _id }) => action.authorizedApplication._id === _id)) {
                    return this.revokeOfficialApp(state, action);
                }
                return this.revokeAuthorizedApplicationSuccess(state, action);

            case AppsActionTypes.REVOKE_AUTHORIZED_APPLICATION_FAILURE:
                return this.revokeAuthorizedApplicationFailure(state);

            case AppsActionTypes.DELETE_PERSONAL_ACCESS_TOKEN_REQUEST:
                return this.deletePersonalAccessTokenRequest(state);

            case AppsActionTypes.DELETE_PERSONAL_ACCESS_TOKEN_SUCCESS:
                return this.deletePersonalAccessTokenSuccess(state, action);

            case AppsActionTypes.DELETE_PERSONAL_ACCESS_TOKEN_FAILURE:
                return this.deletePersonalAccessTokenFailure(state);

            case AppsActionTypes.CREATE_PERSONAL_ACCESS_TOKEN_REQUEST:
                return this.createPersonalAccessTokenRequest(state);

            case AppsActionTypes.CREATE_PERSONAL_ACCESS_TOKEN_SUCCESS:
                return this.createPersonalAccessTokenSuccess(state, action);

            case AppsActionTypes.CREATE_PERSONAL_ACCESS_TOKEN_FAILURE:
                return this.createPersonalAccessTokenFailure(state);

            case AppsActionTypes.CREATE_APPLICATION_REQUEST:
                return this.createApplicationRequest(state);

            case AppsActionTypes.CREATE_APPLICATION_SUCCESS:
                return this.createApplicationSuccess(state, action);

            case AppsActionTypes.CREATE_APPLICATION_FAILURE:
                return this.createApplicationFailure(state);

            case AppsActionTypes.UPDATE_APPLICATION_REQUEST:
                return this.updateApplicationRequest(state);

            case AppsActionTypes.UPDATE_APPLICATION_SUCCESS:
                return this.updateApplicationSuccess(state, action);

            case AppsActionTypes.UPDATE_APPLICATION_FAILURE:
                return this.updateApplicationFailure(state);

            case AppsActionTypes.DELETE_APPLICATION_REQUEST:
                return this.deleteApplicationRequest(state);

            case AppsActionTypes.DELETE_APPLICATION_SUCCESS:
                return this.deleteApplicationSuccess(state, action);

            case AppsActionTypes.DELETE_APPLICATION_FAILURE:
                return this.deleteApplicationFailure(state);

            case AppsActionTypes.RENAME_PERSONAL_ACCESS_TOKEN:
                return this.renamePersonalAccessToken(state, action);

            case AppsActionTypes.CONNECT_OFFICIAL_APP_REQUEST:
                return this.setOfficialAppConnecting(state, action);

            case AppsActionTypes.CONNECT_OFFICIAL_APP_SUCCESS:
                return this.connectOfficalAppSuccess(state, action);

            case AppsActionTypes.CONNECT_OFFICIAL_APP_FAILURE:
                return this.connectOfficialAppFailure(state, action);

            default:
                return state;
        }
    }
}

export default AppsStore;
export {
    State as AppsStoreState
};
