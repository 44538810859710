enum ActionTypes {
    RESET = "organizationBilling/reset",
    UPDATE_SEATS_REQUEST = "organizationBilling/updateSeatsRequest",
    UPDATE_SEATS_SUCCESS = "organizationBilling/updateSeatsSuccess",
    UPDATE_SEATS_FAILURE = "organizationBilling/updateSeatsFailure",
    GET_SCHEDULED_SEATS_UPDATE_REQUEST = "organizationBilling/getScheduledSeatsUpdateRequest",
    GET_SCHEDULED_SEATS_UPDATE_SUCCESS = "organizationBilling/getScheduledSeatsUpdateSuccess",
    CANCEL_SCHEDULED_SEATS_UPDATE_REQUEST = "organizationBilling/cancelScheduledSeatsUpdateRequest",
    CANCEL_SCHEDULED_SEATS_UPDATE_SUCCESS = "organizationBilling/cancelScheduledSeatsUpdateSuccess",
    CANCEL_SCHEDULED_SEATS_UPDATE_FAILURE = "organizationBilling/cancelScheduledSeatsUpdateFailure"
}

export default ActionTypes;
