import electron from "../../../../foundation/electron";

import { forceReloadAppMenu } from "./appMenu";
import MenuViewType from "./MenuViewType";

function init() {
    if (!electron) {
        return;
    }

    forceReloadAppMenu(MenuViewType.LOGIN);
}

init();
