enum ActionTypes {
    TRIGGER_SURVEY = "npsSurvey/triggerSurvey",
    SHOW_SURVEY = "npsSurvey/showSurvey",
    DISMISS_SURVEY = "npsSurvey/dismissSurvey",
    RATE_SURVEY = "npsSurvey/rateSurvey",
    SUBMIT_FEEDBACK = "npsSurvey/submitFeedback",
    SET_CAN_SHOW_SURVEY = "npsSurvey/setCanShowSurvey",
    HANDLE_VIEWED_NPS = "npsSurvey/handleViewedNPS"
}

export default ActionTypes;
