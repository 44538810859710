/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import ActivitiesActionTypes from "./ActivitiesActionTypes";
import ActivityCategory from "./ActivityCategory";
import AppActionTypes from "../app/AppActionTypes";
import NotificationsActionTypes from "../notifications/NotificationsActionTypes";
import UserActionTypes from "../user/UserActionTypes";
import State from "./ActivitiesViewStoreState";

import * as Payloads from "./ActivitiesActionPayloads";
import * as NotificationsActionPayloads from "../notifications/NotificationsActionPayloads";
import { AllPayloads } from "../payloads";
import { Dispatcher } from "flux";

class ActivitiesViewStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            initialized: false,
            loading: true,
            selectedOrganizationId: "user",
            category: ActivityCategory.All,
            termsUpdateSelected: false,
            barrelSelection: { type: "all" },
            projectsWithUpdatesOnly: true,
            dotsLoaded: false,
            selectedEventIds: [],
            autoSelect: false
        };
    }

    setLoading(state: State): State {
        return {
            ...state,
            loading: true
        };
    }

    resetSelection(state: State): State {
        return {
            ...state,
            selectedId: undefined,
            selectedEventIds: [],
            dotsLoaded: false
        };
    }

    resetSelectionAndNoteFilters(state: State): State {
        return this.resetSelection({
            ...state,
            noteStatusFilter: undefined,
            color: undefined,
            memberId: undefined,
            sectionId: undefined
        });
    }

    load(state: State, {
        data
    }: Payloads.Load): State {
        return {
            ...(data.selectedOrganizationId === state.selectedOrganizationId ? state : this.getInitialState()),
            ...data,
            loading: false,
            initialized: true
        };
    }

    select(state: State, {
        activity,
        eventIds,
        options: {
            reselect,
            autoSelect
        }
    }: Payloads.Select): State {
        if (reselect) {
            return {
                ...state,
                autoSelect
            };
        }

        return {
            ...state,
            selectedId: activity._id,
            selectedEventIds: eventIds,
            dotsLoaded: false,
            autoSelect,
            termsUpdateSelected: false
        };
    }

    setDotsLoaded(state: State, {
        activity
    }: Payloads.SetDotsLoaded): State {
        if (state.selectedId === activity._id) {
            return {
                ...state,
                dotsLoaded: true
            };
        }

        return state;
    }

    selectCategory(state: State, {
        category
    }: Payloads.SelectCategory): State {
        return this.resetSelection({
            ...state,
            termsUpdateSelected: category === ActivityCategory.All ? state.termsUpdateSelected : false,
            category
        });
    }

    selectAllBarrels(state: State): State {
        return this.resetSelectionAndNoteFilters({
            ...state,
            barrelSelection: {
                type: "all"
            }
        });
    }

    selectBarrel(state: State, {
        id,
        isPinned,
        barrelType: type
    }: Payloads.SelectBarrel): State {
        return this.resetSelectionAndNoteFilters({
            ...state,
            barrelSelection: {
                id,
                isPinned,
                type
            },
            termsUpdateSelected: false
        });
    }

    setProjectsWithUpdatesOnly(state: State, {
        projectsWithUpdatesOnly
    }: Payloads.SetProjectsWithUpdatesOnly): State {
        return {
            ...state,
            projectsWithUpdatesOnly
        };
    }

    selectNoteStatusFilter(state: State, {
        noteStatusFilter
    }: Payloads.SelectNoteStatusFilter): State {
        return this.resetSelection({
            ...state,
            noteStatusFilter
        });
    }

    selectColor(state: State, {
        color
    }: Payloads.SelectColor): State {
        return this.resetSelection({
            ...state,
            color
        });
    }

    selectMember(state: State, {
        memberId
    }: Payloads.SelectMember): State {
        return this.resetSelection({
            ...state,
            memberId
        });
    }

    selectSection(state: State, {
        sectionId
    }: Payloads.SelectSection): State {
        return this.resetSelection({
            ...state,
            sectionId
        });
    }

    setSidebarWidth(state: State, {
        sidebarWidth
    }: Payloads.SetSidebarWidth): State {
        return {
            ...state,
            sidebarWidth
        };
    }

    saveListScrollData(state: State, {
        listScrollData
    }: Payloads.SaveListScrollData): State {
        return {
            ...state,
            listScrollData
        };
    }

    checkSelectionOnEventDeleted(state: State, {
        nid,
        eventId
    }: NotificationsActionPayloads.DeleteEvent): State {
        const { selectedId, selectedEventIds } = state;
        if (selectedId === nid && selectedEventIds.includes(eventId)) {
            // On a very rare case we need to reset selection:
            // User reads a notification, but its read event hasn't reached BE and the source user reverts
            // their operation and BE deletes that notification event.
            return this.resetSelection(state);
        }

        return state;
    }

    selectTermsUpdate(state: State): State {
        return this.resetSelection({
            ...state,
            termsUpdateSelected: true
        });
    }

    deselectTermsUpdate(state: State): State {
        return this.resetSelection({
            ...state,
            termsUpdateSelected: false
        });
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.SET_PAGE:
                return this.setLoading(state);
            case ActivitiesActionTypes.Load:
                return this.load(state, action);
            case ActivitiesActionTypes.Select:
                return this.select(state, action);
            case ActivitiesActionTypes.SetDotsLoaded:
                return this.setDotsLoaded(state, action);
            case ActivitiesActionTypes.SelectCategory:
                return this.selectCategory(state, action);
            case ActivitiesActionTypes.SelectAllBarrels:
                return this.selectAllBarrels(state);
            case ActivitiesActionTypes.SelectBarrel:
                return this.selectBarrel(state, action);
            case ActivitiesActionTypes.SetProjectsWithUpdatesOnly:
                return this.setProjectsWithUpdatesOnly(state, action);
            case ActivitiesActionTypes.SelectNoteStatusFilter:
                return this.selectNoteStatusFilter(state, action);
            case ActivitiesActionTypes.SelectColor:
                return this.selectColor(state, action);
            case ActivitiesActionTypes.SelectMember:
                return this.selectMember(state, action);
            case ActivitiesActionTypes.SelectSection:
                return this.selectSection(state, action);
            case ActivitiesActionTypes.SetSidebarWidth:
                return this.setSidebarWidth(state, action);
            case ActivitiesActionTypes.SaveListScrollData:
                return this.saveListScrollData(state, action);
            case NotificationsActionTypes.GetNotificationsSuccess:
            case UserActionTypes.MUTE_PROJECT_NOTIFICATIONS:
            case UserActionTypes.MUTE_STYLEGUIDE_NOTIFICATIONS:
            case UserActionTypes.UNMUTE_PROJECT_NOTIFICATIONS:
            case UserActionTypes.UNMUTE_STYLEGUIDE_NOTIFICATIONS:
                return this.resetSelection(state);
            case NotificationsActionTypes.DeleteEvent:
                return this.checkSelectionOnEventDeleted(state, action);
            case ActivitiesActionTypes.SelectTermsUpdate:
                return this.selectTermsUpdate(state);
            case UserActionTypes.ACCEPT_TERMS:
                return this.deselectTermsUpdate(state);
            default:
                return state;
        }
    }
}

export default ActivitiesViewStore;
export { State as ActivitiesViewStoreState };
