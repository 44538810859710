enum ActionTypes {
    // #region Common Actions
    GET_STYLEGUIDES_SUCCESS = "styleguides/getStyleguidesSuccess",
    GET_STYLEGUIDE_REQUESTED = "styleguides/getStyleguideRequested",
    GET_STYLEGUIDE_SUCCESS = "styleguides/getStyleguideSuccess",
    GET_STYLEGUIDE_FAILED = "styleguides/getStyleguideFailed",
    UPDATE_STYLEGUIDE = "styleguides/updateStyleguide",
    TRANSFER_OWNERSHIP = "styleguides/transferOwnership",
    // #endregion

    // #region Styleguides Specific Actions
    SELECT_PARENT = "styleguides/selectParent",
    REMOVE_PARENT = "styleguides/removeParent",
    SELECT_PARENT_REQUEST = "styleguides/selectParentRequest",
    SELECT_PARENT_SUCCESS = "styleguides/selectParentSuccess",
    SELECT_PARENT_FAILED = "styleguides/selectParentFailed",
    // #endregion

    // #region Space Actions
    CREATE_STYLEGUIDE_REQUEST = "styleguides/createStyleguideRequest",
    CREATE_STYLEGUIDE_FINALIZE = "styleguides/createStyleguideFinalize",
    INIT_TRANSFER_OWNERSHIP = "styleguides/initTransferOwnership"
    // #endregion
}

export default ActionTypes;
