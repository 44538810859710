/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { AllPayloads } from "../payloads";

import * as Payloads from "./DragDropActionPayloads";
import DragDropActionTypes from "./DragDropActionTypes";

interface State {
    dragging: boolean;
    draggingItemType: string;
    dragData: { // TODO: Fully type this and related action
        id: string;
    } | null;
}

class DragDropStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            dragging: false,
            draggingItemType: "",
            dragData: null
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleStartDrag(state: State, {
        draggingItemType,
        dragData
    }: Payloads.StartDrag): State {
        return {
            ...state,
            dragging: true,
            draggingItemType,
            dragData
        };
    }

    handleEndDrag(state: State): State {
        return {
            ...state,
            dragging: false,
            draggingItemType: "",
            dragData: null
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case DragDropActionTypes.START_DRAG:
                return this.handleStartDrag(state, action);
            case DragDropActionTypes.END_DRAG:
                return this.handleEndDrag(state);
            default:
                return state;
        }
    }
}

export default DragDropStore;
export {
    State as DragDropStoreState
};
