import { h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

import { Keyboard, Mouse } from "../../../../../../../../foundation/enums";
import CSSProperties from "../../../../../../../../foundation/model/CSSProperties";
import classname from "../../../../../../../../foundation/utils/classname";

import { DragSourceProps } from "../../../../../../../../library/DragDropContainer";
import Input, { InputVariant } from "../../../../../../../../library/v2/Input";

import styles from "./BoardTabBarItem.module.css";

const NARROW_WIDTH = 96;

enum ReorderPosition {
    Left = "left",
    Right = "right"
}

interface Props extends DragSourceProps {
    tab: { id: string; name: string; };
    isSelected: boolean;
    isRenaming: boolean;
    isContextMenuOpen?: boolean;
    reorderProps?: {
        indexDelta: number;
    };
    mirrorProps?: { width: number; };
    onSelect?: () => void;
    onContextMenu?: (event: MouseEvent) => void;
    onSubmitRename?: (newName?: string) => void;
    onReorderHover?: (tabId: string, position: ReorderPosition) => void;
    onReorderDrop?: (tabId: string, position: ReorderPosition) => void;
}

export default function BoardTabBarItem({
    tab,
    isSelected,
    isRenaming,
    isContextMenuOpen = false,
    reorderProps,
    mirrorProps,
    dragging,
    onSelect,
    onContextMenu,
    onSubmitRename
}: Props) {
    const ref = useRef<HTMLDivElement>(null);
    const nameRef = useRef<HTMLDivElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const [isNarrow, setIsNarrow] = useState(false);
    const [isNameTruncated, setIsNameTruncated] = useState(false);
    const [nameInputValue, setNameInputValue] = useState<string | undefined>(undefined);

    useEffect(() => {
        setNameInputValue(isRenaming ? tab.name : undefined);
    }, [isRenaming]);

    // TODO: Use container query when browser support is better:
    // https://caniuse.com/css-container-queries
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            const [entry] = entries;
            const target = entry.target as HTMLDivElement;

            const { width } = target.getBoundingClientRect();
            setIsNarrow(width < NARROW_WIDTH);

            updateIsNameTruncated();
        });

        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        updateIsNameTruncated();
    }, [tab.name]);

    function updateIsNameTruncated() {
        const nameElement = nameRef.current;
        setIsNameTruncated(nameElement ? nameElement.offsetWidth < nameElement.scrollWidth : false);
    }

    function handleMouseDown(event: MouseEvent) {
        if (event.button !== Mouse.Button.Left) {
            return;
        }

        onSelect?.();
    }

    function handleNameInputFocus() {
        nameInputRef.current?.select();
    }

    function handleNameInput(event: Event) {
        const newValue = (event.target as HTMLInputElement).value;

        setNameInputValue(newValue);
    }

    function handleNameInputKeyDown(event: KeyboardEvent) {
        if (event.key === Keyboard.Key.Escape) {
            event.preventDefault();

            // Set to empty to cancel rename or create.
            setNameInputValue("");
            // Blur in next event cycle so that the state is up to date.
            setTimeout(() => {
                nameInputRef.current?.blur();
            });
        } else if (event.key === Keyboard.Key.Enter) {
            event.preventDefault();

            nameInputRef.current?.blur();
        }
    }

    function handleBlurNameInput() {
        const newName = nameInputValue?.trim();

        onSubmitRename?.(newName);
    }

    const style: CSSProperties = {};

    if (reorderProps && reorderProps.indexDelta !== 0) {
        // eslint-disable-next-line no-magic-numbers
        style["transform"] = `translateX(${reorderProps.indexDelta * 100}%)`;
    }

    if (mirrorProps) {
        style["width"] = `${mirrorProps.width}px`;
    }

    return (
        <div
            ref={ref}
            class={classname(
                styles.boardTabBarItem,
                "body-1",
                "medium",
                { [styles.narrow]: isNarrow },
                { [styles.renaming]: isRenaming },
                { [styles.dragging]: dragging },
                { [styles.reordering]: reorderProps !== undefined },
                { [styles.selected]: isSelected },
                { [styles.contextMenuOpen]: isContextMenuOpen }
            )}
            style={Object.keys(style).length ? style : undefined}
            onContextMenu={onContextMenu}
            onMouseDown={handleMouseDown}>
            <div class={styles.backdrop}/>
            {nameInputValue === undefined
                ? (
                    <div
                        ref={nameRef}
                        class={classname(styles.name, { [styles.truncated]: isNameTruncated })}
                        title={tab.name}>
                        {tab.name}
                    </div>
                )
                : (
                    <Input
                        inputRef={nameInputRef}
                        variant={InputVariant.Ninja}
                        class={styles.nameInputWrapper}
                        textClass="body-1 medium"
                        value={nameInputValue}
                        placeholder="Board name"
                        maxLength={100}
                        autoFocus
                        onFocus={handleNameInputFocus}
                        onInput={handleNameInput}
                        onKeyDown={handleNameInputKeyDown}
                        onBlur={handleBlurNameInput}/>
                )
            }
        </div>
    );
}

export {
    Props as BoardTabBarItemProps,
    ReorderPosition as BoardTabBarItemReorderPosition
};
