/* eslint-disable class-methods-use-this */
import { ProjectDetails } from "../../../foundation/api/model/barrels/Project";
import { StyleguideDetails } from "../../../foundation/api/model/barrels/Styleguide";
import ConnectedComponent from "../../../foundation/api/model/connectedComponents/ConnectedComponent";
import { FigmaIntegrationProgressData } from "../../../foundation/api/model/integrations/barrel/FigmaIntegration";
import ApiRemPreferences from "../../../foundation/api/model/spacings/RemPreferences";
import SpacingSection from "../../../foundation/api/model/spacings/SpacingSection";
import SpacingToken from "../../../foundation/api/model/spacings/SpacingToken";
import Barrel, { BarrelData } from "../../../foundation/model/Barrel";
import BarrelUser from "../../../foundation/model/BarrelUser";
import Color from "../../../foundation/model/color";
import Component from "../../../foundation/model/Component";
import ComponentSection from "../../../foundation/model/ComponentSection";
import Font from "../../../foundation/model/font";
import JiraAttachment from "../../../foundation/model/JiraAttachment";
import FoundationRemPreferences from "../../../foundation/model/RemPreferences";
import WorkflowStatus from "../../../foundation/model/WorkflowStatus";

import { sortArrayOfObjectsByPropertyName, arrayRemove, normalizeArray } from "../../../foundation/utils/array";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { TemporarySectionId } from "../../../foundation/utils/section";
import { uppercaseFirst } from "../../../foundation/utils/string";
import { KeysOfType, PartializeProps } from "../../../foundation/utils/UtilityTypes";

import EntryProjectData from "../../entries/app/model/EntryProjectData";
import EntryStyleguideData from "../../entries/app/model/EntryStyleguideData";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import * as ComponentActionPayloads from "../component/ComponentActionPayloads";
import * as FigmaLibrarySyncActionPayloads from "../figmaLibrarySync/FigmaLibrarySyncActionPayloads";
import * as OrganizationActionPayloads from "../organization/OrganizationActionPayloads";
import * as ProjectsActionPayloads from "../projects/ProjectsActionPayloads";
import * as StyleguideActionPayloads from "../styleguide/StyleguideActionPayloads";
import * as StyleguidesActionPayloads from "../styleguides/StyleguidesActionPayloads";

let newComponentSectionCount = 0;

class BarrelsReducer<
    State extends {
        [k in BarrelsPropertyName]: BasicRecord<Barrel>;
    } & {
        [k in LoadingBarrelIdPropertyName]: string | null;
    } & {
        [k in LoadingBarrelsPropertyName]: boolean;
    },
    BarrelsPropertyName extends ("projects" | "styleguides") & KeysOfType<State, BasicRecord<Barrel>>,
    LoadingBarrelIdPropertyName extends KeysOfType<State, string | null>,
    LoadingBarrelsPropertyName extends KeysOfType<State, boolean>,
> {
    barrelsPropertyName: BarrelsPropertyName;
    loadingBarrelIdPropertyName: LoadingBarrelIdPropertyName;
    loadingBarrelsPropertyName: LoadingBarrelsPropertyName;
    deleteBarrel: (
        state: State,
        { bid, barrelType }: Pick<BarrelActionPayloads.Remove, "bid" | "barrelType">,
        preserveLinkRelation?: boolean
    ) => State;

    constructor(options: {
        barrelsPropertyName: BarrelsPropertyName;
        loadingBarrelIdPropertyName: LoadingBarrelIdPropertyName;
        loadingBarrelsPropertyName: LoadingBarrelsPropertyName;
    }, functions: {
        deleteBarrel: (
            state: State,
            { bid, barrelType }: Pick<BarrelActionPayloads.Remove, "bid" | "barrelType">,
            preserveLinkRelation?: boolean
        ) => State;
    }) {
        this.barrelsPropertyName = options.barrelsPropertyName;
        this.loadingBarrelIdPropertyName = options.loadingBarrelIdPropertyName;
        this.loadingBarrelsPropertyName = options.loadingBarrelsPropertyName;
        this.deleteBarrel = functions.deleteBarrel;
    }

    private barrels(state: State) {
        return state[this.barrelsPropertyName] as BasicRecord<Barrel>;
    }

    getBarrelsSuccess(state: State, {
        [this.barrelsPropertyName]: barrels
    }: {
        [k in BarrelsPropertyName]: (EntryProjectData | EntryStyleguideData)[];
    }): State {
        const barrelModels = barrels.map(barrel => new Barrel(barrel));

        return {
            ...state,
            [this.loadingBarrelsPropertyName]: false,
            [this.barrelsPropertyName]: {
                ...this.barrels(state),
                ...normalizeArray(barrelModels, "_id")
            }
        };
    }

    getBarrelRequested(state: State, { bid }: {
        bid: string | null;
    }): State {
        return {
            ...state,
            [this.loadingBarrelIdPropertyName]: bid
        };
    }

    getBarrelSuccess(state: State, { barrel }: {
        barrel: Barrel | ProjectDetails | StyleguideDetails;
    }): State {
        const stateWithUpdatedBarrel = this.updateBarrel(state, {
            bid: barrel._id,
            data: barrel,
            upsert: true
        });

        return {
            ...stateWithUpdatedBarrel,
            [this.loadingBarrelIdPropertyName]: null
        };
    }

    getBarrelFailed(state: State, {
        error
    }: StyleguidesActionPayloads.GetStyleguideFailed | ProjectsActionPayloads.GetProjectFailed): State {
        return {
            ...state,
            [this.loadingBarrelIdPropertyName]: null,
            error
        };
    }

    addBarrel(state: State, {
        barrel
    }: Pick<BarrelActionPayloads.Add, "barrel">): State {
        return {
            ...state,
            [this.barrelsPropertyName]: {
                ...this.barrels(state),
                [barrel._id]: new Barrel(barrel)
            }
        };
    }

    updateBarrel(state: State, { bid, data, upsert }: {
        bid: string;
        data: Partial<BarrelData> | ((barrel: Barrel) => Partial<BarrelData>);
        upsert?: boolean;
    }): State {
        const targetBarrels = this.barrels(state);
        const targetBarrel = targetBarrels[bid];

        if (!targetBarrel && !upsert) {
            return state;
        }

        return {
            ...state,
            editingDescription: false,
            [this.barrelsPropertyName]: {
                ...targetBarrels,
                [bid]: new Barrel({
                    ...targetBarrel,
                    ...(typeof data === "function" ? data(targetBarrel) : data)
                })
            }
        };
    }

    updateName(state: State, {
        bid,
        name
    }: BarrelActionPayloads.UpdateName): State {
        return this.updateBarrel(state, { bid, data: { name } });
    }

    updateDescription(state: State, {
        bid,
        description
    }: BarrelActionPayloads.UpdateDescription): State {
        return this.updateBarrel(state, { bid, data: { description } });
    }

    updateDensityScale(state: State, {
        bid,
        densityScale
    }: BarrelActionPayloads.UpdateDensityScale): State {
        return this.updateBarrel(state, { bid, data: { densityScale } });
    }

    updateThumbnail(state: State, {
        bid,
        thumbnail
    }: BarrelActionPayloads.UpdateThumbnail): State {
        return this.updateBarrel(state, { bid, data: { thumbnail } });
    }

    setEditingDescription(state: State, {
        isEditing
    }: BarrelActionPayloads.EditDescription): State {
        return {
            ...state,
            editingDescription: isEditing
        };
    }

    updateFigmaSyncProgress(state: State, {
        stid,
        isSyncFinished,
        progressData,
        figmaFileKey,
        figmaFileName,
        syncEndedAt
    }: FigmaLibrarySyncActionPayloads.UpdateSyncProgress): State {
        return this.updateBarrelValue(state, {
            bid: stid,
            key: "integration",
            value: ({ integration }: Barrel) => {
                if (!integration) {
                    return;
                }

                function getExportObject(key: keyof FigmaIntegrationProgressData) {
                    return integration?.figma?.lastSync?.exportCounts?.[key]?.isSyncFinished ? (
                        integration.figma?.lastSync?.exportCounts?.[key]
                    ) : (
                        {
                            // eslint-disable-next-line no-sync
                            ...(integration?.figma?.lastSync?.exportCounts?.[key] || {}),
                            ...(progressData?.[key] || {})
                        }
                    );
                }

                return {
                    ...integration,
                    figma: {
                        ...(integration.figma || {}),
                        fileKey: figmaFileKey || integration.figma?.fileKey,
                        fileName: figmaFileName || integration.figma?.fileName,
                        isSyncing: !isSyncFinished,
                        lastSync: {
                            // eslint-disable-next-line no-sync
                            ...(integration.figma?.lastSync || {
                                startedAt: new Date()
                            }),
                            endedAt: syncEndedAt || new Date(),
                            exportCounts: {
                                // eslint-disable-next-line no-sync
                                ...(integration.figma?.lastSync?.exportCounts || {}),
                                components: getExportObject("components"),
                                colorStyles: getExportObject("colorStyles"),
                                textStyles: getExportObject("textStyles")
                            }
                        }
                    }
                };
            }
        });
    }

    addIntegration(state: State, {
        bid,
        intype,
        integration
    }: Pick<BarrelActionPayloads.AddIntegration, "bid" | "intype" | "integration">): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "integration",
            value: ({ integration: integrations }: Barrel) => {
                if (!integrations) {
                    return;
                }

                if (integrations[intype].find(existingIntegration => existingIntegration._id === integration._id)) {
                    return { ...integrations };
                }

                return {
                    ...integrations,
                    [intype]: [...integrations[intype], integration]
                };
            }
        });
    }

    removeIntegration(state: State, {
        bid,
        intype,
        inid
    }: BarrelActionPayloads.RemoveIntegration): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "integration",
            value: ({ integration }: Barrel) => {
                if (!integration) {
                    return;
                }

                return {
                    ...integration,
                    [intype]: integration[intype].filter(({ _id }) => _id !== inid)
                };
            }
        });
    }

    toggleScene(state: State, {
        bid,
        accessibility
    }: BarrelActionPayloads.ToggleScene): State {
        return this.updateBarrel(state, { bid, data: { public: accessibility } });
    }

    addMembers(state: State, {
        bid,
        members
    }: BarrelActionPayloads.AddMembers): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "users",
            value: (barrel: Barrel) => {
                const newMembers = members.filter(
                    member => !barrel.users.some(user => user.user._id === member.user._id)
                );
                return [...barrel.users, ...newMembers];
            }
        });
    }

    updateUserRole(state: State, {
        bid,
        uid,
        role
    }: BarrelActionPayloads.UpdateUserRole): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "users",
            value: ({ users }: Barrel) => users.map(user => {
                if (user.user._id !== uid) {
                    return user;
                }

                return { ...user, role } as BarrelUser;
            })
        });
    }

    removeMember(state: State, {
        bid,
        mid,
        shouldRemoveBarrel,
        barrelType
    }: BarrelActionPayloads.RemoveMember): State {
        if (shouldRemoveBarrel) {
            return this.deleteBarrel(state, { bid, barrelType }, true);
        }

        return this.updateBarrel(state, {
            bid,
            data(barrel: Barrel) {
                return {
                    ...barrel,
                    users: barrel.users.filter(({ user }) => user._id !== mid)
                };
            }
        });
    }

    addColor(state: State, {
        bid,
        color
    }: Pick<BarrelActionPayloads.AddColor, "bid" | "color">): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "colors",
            value: (barrel: Barrel) => {
                const { index } = color;

                let barrelColors = barrel.colors;

                const foundColor = barrelColors?.find(c => c.id === color._id);

                if (foundColor) {
                    return barrelColors;
                }

                if (!barrelColors) {
                    return;
                }

                if (typeof index === "undefined") {
                    barrelColors.push(new Color(color));
                } else {
                    barrelColors.splice(index, 0, new Color(color));
                }

                if (color.requestId) {
                    const colorsCreatedInSameBatch = barrelColors.filter(
                        projectColor => projectColor.requestId === color.requestId
                    ) ?? [];

                    const others = barrelColors.filter(
                        projectColor => !colorsCreatedInSameBatch.includes(projectColor)
                    ) ?? [];

                    colorsCreatedInSameBatch.sort((a, b) => a.index! - b.index!);

                    barrelColors = others.concat(colorsCreatedInSameBatch);
                }

                return [...barrelColors];
            }
        });
    }

    addMultipleColors(state: State, {
        bid,
        colors
    }: BarrelActionPayloads.AddMultipleColors): State {
        return colors.reduce((prevState, color) => this.addColor(prevState, { bid, color }), state);
    }

    updateColor(state: State, {
        bid,
        cid,
        colorData
    }: BarrelActionPayloads.UpdateColor): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "colors",
            value: ({ colors }: Barrel) => colors!.map(color => {
                if (color.id !== cid) {
                    return color;
                }

                return new Color({ ...color, ...colorData });
            })
        });
    }

    updateColorsName(state: State, {
        bid,
        colorsData
    }: BarrelActionPayloads.UpdateColorsName): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "colors",
            value: ({ colors }: Barrel) => colors!.map(color => {
                const colorToUpdate = colorsData.find(colorData =>
                    colorData._id === color.id || colorData.id === color.id
                );

                if (colorToUpdate) {
                    return new Color({ ...color, ...colorToUpdate });
                }

                return color;
            })
        });
    }

    removeColor(state: State, {
        bid,
        cid
    }: BarrelActionPayloads.RemoveColor): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "colors",
            value: ({ colors }: Barrel) => colors!.filter(({ id }) => id !== cid)
        });
    }

    removeColors(state: State, {
        bid,
        colorsToRemove
    }: BarrelActionPayloads.RemoveColors): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "colors",
            value: ({ colors }: Barrel) => {
                if (colors!.length === colorsToRemove!.length) {
                    return [];
                }

                return colors!.filter(color => !colorsToRemove?.includes(color));
            }
        });
    }

    migrateColors(state: State, {
        bid,
        colorsToUpdate,
        colorsToAdd
    }: BarrelActionPayloads.MigrateColors): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "colors",
            value: ({ colors }: Barrel) => {
                const updatedColors = colors!.map(color => {
                    const colorToUpdate = colorsToUpdate[color.id!];
                    if (colorToUpdate) {
                        return new Color({ ...color, ...colorToUpdate });
                    }

                    return color;
                });
                const addedColors = colorsToAdd.map(color => new Color(color));

                return [...updatedColors, ...addedColors];
            }
        });
    }

    updateColorsOrder(state: State, {
        bid,
        colors
    }: BarrelActionPayloads.UpdateColorsOrder): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "colors",
            value: (barrel: Barrel) => {
                colors.forEach(color => {
                    const { _id, to } = color;

                    const colorToRemove = arrayRemove(barrel.colors!, c => c.id === _id);
                    if (!colorToRemove) {
                        return;
                    }

                    barrel.colors!.splice(to, 0, colorToRemove);
                });

                return barrel.colors;
            }
        });
    }

    addTextStyle(state: State, {
        bid,
        textStyle
    }: Pick<BarrelActionPayloads.AddTextStyle, "bid" | "textStyle">): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "textStyles",
            value: ({ textStyles }: Barrel) => {
                const foundTextStyle = textStyles.find(ts => ts.id === textStyle._id);
                if (foundTextStyle) {
                    return textStyles;
                }

                return [...textStyles, new Font(textStyle)];
            }
        });
    }

    addMultipleTextStyles(state: State, {
        bid,
        textStyles
    }: BarrelActionPayloads.AddMultipleTextStyles): State {
        return textStyles.reduce((prevState, textStyle) => this.addTextStyle(prevState, { bid, textStyle }), state);
    }

    updateTextStyle(state: State, {
        bid,
        tsid,
        textStyleData
    }: BarrelActionPayloads.UpdateTextStyle): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "textStyles",
            value: ({ textStyles }: Barrel) => textStyles.map(textStyle => {
                if (textStyle.id !== tsid) {
                    return textStyle;
                }

                return new Font({ ...textStyle, ...textStyleData });
            })
        });
    }

    removeTextStyle(state: State, {
        bid,
        tsid
    }: BarrelActionPayloads.RemoveTextStyle): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "textStyles",
            value: ({ textStyles }: Barrel) => textStyles.filter(({ id }) => id !== tsid)
        });
    }

    removeAllTextStyles(state: State, {
        bid
    }: BarrelActionPayloads.RemoveAllTextStyles): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "textStyles",
            value: []
        });
    }

    addSpacingSection(state: State, {
        bid,
        spacingSection
    }: BarrelActionPayloads.AddSpacingSection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "spacingSections",
            value: ({ spacingSections }: Barrel) =>
                [...spacingSections!, { ...spacingSection, spacingTokens: spacingSection.spacingTokens ?? [] }]
        });
    }

    updateSpacingSection(state: State, {
        bid,
        ssid,
        updatedSpacingSection
    }: BarrelActionPayloads.UpdateSpacingSection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "spacingSections",
            value: ({ spacingSections }: Barrel) => spacingSections!.map(spacingSection => {
                if (spacingSection._id !== ssid) {
                    return spacingSection;
                }

                return { ...spacingSection, ...updatedSpacingSection };
            })
        });
    }

    removeSpacingSection(state: State, {
        bid,
        ssid
    }: BarrelActionPayloads.RemoveSpacingSection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "spacingSections",
            value: ({ spacingSections }: Barrel) => spacingSections!.filter(({ _id }) => _id !== ssid)
        });
    }

    addSpacingToken(state: State, {
        bid,
        ssid,
        isBaseToken,
        spacingToken
    }: BarrelActionPayloads.AddSpacingToken): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "spacingSections",
            value: ({ spacingSections }: Barrel) => spacingSections!.map(spacingSection => {
                if (spacingSection._id !== ssid) {
                    return spacingSection;
                }

                const { baseTokenId, spacingTokens } = spacingSection;
                const newSpacingTokens =
                    sortArrayOfObjectsByPropertyName([...spacingTokens, spacingToken as SpacingToken], "value");
                let newBaseTokenId = newSpacingTokens.length === 1 ? newSpacingTokens[0]._id : baseTokenId;

                if (isBaseToken) {
                    newBaseTokenId = spacingToken._id;
                }

                return {
                    ...spacingSection,
                    baseTokenId: newBaseTokenId,
                    spacingTokens: newSpacingTokens
                };
            })
        });
    }

    updateSpacingToken(state: State, {
        bid,
        ssid,
        sptid,
        updatedSpacingToken: data,
        oldSpacingToken
    }: BarrelActionPayloads.UpdateSpacingToken): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "spacingSections",
            value: ({ spacingSections }: Barrel) => spacingSections!.map(spacingSection => {
                if (spacingSection._id !== ssid) {
                    return spacingSection;
                }

                const { baseTokenId, spacingTokens } = spacingSection;
                const tokensWithoutUpdatedOne = spacingTokens.filter(({ _id }) => _id !== sptid);
                const updatedSpacingToken = { ...oldSpacingToken as SpacingToken, ...data };
                const newBaseTokenId = baseTokenId === sptid ? updatedSpacingToken._id : baseTokenId;

                return {
                    ...spacingSection,
                    baseTokenId: newBaseTokenId,
                    spacingTokens:
                        sortArrayOfObjectsByPropertyName([...tokensWithoutUpdatedOne, updatedSpacingToken], "value")
                };
            })
        });
    }

    removeSpacingToken(state: State, {
        bid,
        ssid,
        isBaseToken,
        sptid
    }: BarrelActionPayloads.RemoveSpacingToken): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "spacingSections",
            value({ spacingSections }: Barrel): SpacingSection[] | undefined {
                return spacingSections!.map(spacingSection => {
                    if (spacingSection._id !== ssid) {
                        return spacingSection;
                    }

                    const { baseTokenId, spacingTokens } = spacingSection;
                    const newSpacingTokens = spacingTokens.filter(({ _id }) => _id !== sptid);
                    let newBaseTokenId: string | null | undefined = baseTokenId;

                    if (isBaseToken) {
                        newBaseTokenId = (newSpacingTokens.length && newSpacingTokens[0]._id) || null;
                    }

                    return {
                        ...spacingSection!,
                        // TODO: below cast is wrong, consider adding Foundation SpacingSection model and adding null to
                        // its baseTokenId field's type
                        baseTokenId: newBaseTokenId as string | undefined,
                        spacingTokens: newSpacingTokens
                    };
                });
            }
        });
    }

    updateRemPreferences(state: State, {
        bid,
        updatedRemPreferences
    }: BarrelActionPayloads.UpdateRemPreferences): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "remPreferences",
            value: ({ remPreferences }: Barrel) => new FoundationRemPreferences({
                ...remPreferences,
                ...updatedRemPreferences
            } as ApiRemPreferences)
        });
    }

    addComponent(state: State, {
        bid,
        csids,
        component
    }: BarrelActionPayloads.AddComponent): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                if (barrel.findComponentById(component._id)) {
                    return barrel.componentSections;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const { index } = component;
                if (typeof index === "undefined") {
                    componentSection.components.push(component);
                } else {
                    componentSection.components.splice(index, 0, component);
                }

                if (component.requestId) {
                    const componentsCreatedInSameBatch = componentSection.components.filter(
                        sectionComponent => sectionComponent.requestId === component.requestId
                    );
                    const others = componentSection.components.filter(
                        sectionComponent =>
                            !componentsCreatedInSameBatch.includes(sectionComponent)
                    );

                    componentsCreatedInSameBatch.sort((a, b) => a.index! - b.index!);

                    componentSection.components = others.concat(componentsCreatedInSameBatch);
                }

                return [...barrel.componentSections];
            }
        });
    }

    updateComponent(state: State, {
        bid,
        csids,
        coid,
        componentData
    }: Pick<BarrelActionPayloads.UpdateComponent, "bid" | "csids" | "coid" | "componentData">): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const component = componentSection.components.find(co => co._id === coid);
                if (!component) {
                    return barrel.componentSections;
                }

                Object.assign(component, componentData);

                return [...barrel.componentSections];
            }
        });
    }

    updateCommitMessage(state: State, {
        bid,
        coid,
        csids,
        vid,
        message
    }: ComponentActionPayloads.UpdateCommitMessage) {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const componentIndex = componentSection.components.findIndex(co => co._id === coid);
                if (componentIndex === -1) {
                    return barrel.componentSections;
                }

                const component = componentSection.components[componentIndex];

                const newComponent = {
                    ...component
                };

                newComponent.versions = (newComponent.versions || [newComponent.latestVersion]).map((version => {
                    if (version._id !== vid) {
                        return version;
                    }

                    return {
                        ...version,
                        commit: {
                            ...version.commit,
                            message
                        }
                    };
                }));

                if (newComponent.latestVersion._id === vid) {
                    newComponent.latestVersion.commit.message = message;
                }

                componentSection.components.splice(componentIndex, 1, newComponent);

                return [...barrel.componentSections];
            }
        });
    }

    updateCommitColor(state: State, {
        bid,
        coid,
        csids,
        vid,
        color
    }: ComponentActionPayloads.UpdateCommitColor) {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const componentIndex = componentSection.components.findIndex(co => co._id === coid);
                if (componentIndex === -1) {
                    return barrel.componentSections;
                }

                const component = componentSection.components[componentIndex];

                const newComponent = {
                    ...component
                };

                newComponent.versions = (newComponent.versions || [newComponent.latestVersion]).map((version => {
                    if (version._id !== vid) {
                        return version;
                    }

                    return {
                        ...version,
                        commit: {
                            ...version.commit,
                            color
                        }
                    };
                }));

                if (newComponent.latestVersion._id === vid) {
                    newComponent.latestVersion.commit.color = color;
                }

                componentSection.components.splice(componentIndex, 1, newComponent);

                return [...barrel.componentSections];
            }
        });
    }

    removeVersion(state: State, {
        bid,
        csids,
        coid,
        vid
    }: ComponentActionPayloads.RemoveVersion) {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const componentIndex = componentSection.components.findIndex(co => co._id === coid);
                if (componentIndex === -1) {
                    return barrel.componentSections;
                }

                const component = componentSection.components[componentIndex];

                const newComponent = {
                    ...component
                };

                newComponent.versions = newComponent.versions!.filter(({ _id }) => _id !== vid);
                if (newComponent.totalVersionCount) {
                    newComponent.totalVersionCount--;
                }

                if (newComponent.latestVersion._id === vid) {
                    newComponent.latestVersion = newComponent.versions[0]!;
                }

                componentSection.components.splice(componentIndex, 1, newComponent);

                return [...barrel.componentSections];
            }
        });
    }

    setVersionDiffs(state: State, {
        bid, coid, csids, vid, prevVid, versionDiffs
    }: ComponentActionPayloads.SetVersionDiffs) {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const componentIndex = componentSection.components.findIndex(co => co._id === coid);
                if (componentIndex === -1) {
                    return barrel.componentSections;
                }

                const component = componentSection.components[componentIndex];
                const currentVersionDiffs = component.versionsDiffs ?? {};
                currentVersionDiffs[`${vid}-${prevVid}`] = versionDiffs;
                const newComponent = {
                    ...component,
                    versionsDiffs: currentVersionDiffs
                };
                componentSection.components.splice(componentIndex, 1, newComponent);

                return [...barrel.componentSections];
            }
        });
    }

    setComponentSnapshot(state: State, {
        bid,
        csids,
        coid,
        vid,
        snapshot
    }: BarrelActionPayloads.SetComponentSnapshot): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const component = componentSection.components.find(co => co._id === coid);
                if (!component) {
                    return barrel.componentSections;
                }

                const version = component.versions?.find(v => v._id === vid);

                if (version) {
                    Object.assign(version.snapshot, snapshot);
                }

                if (component.latestVersion._id === vid) {
                    Object.assign(component.latestVersion.snapshot, snapshot);
                    if (!component.versions) {
                        // FIX: versionSnapshot comes first, versions override this and removes layers
                        Object.assign(component, { versions: [component.latestVersion] });
                    }
                }

                return [...barrel.componentSections];
            }
        });
    }

    setMultipleComponentSnapshots(state: State, {
        bid,
        componentSnapshotPayloads
    }: BarrelActionPayloads.SetMultipleComponentSnapshots) {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                componentSnapshotPayloads.forEach(payload => {
                    const { coid, snapshot, csids, vid } = payload;

                    const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                    if (!componentSection) {
                        return barrel.componentSections;
                    }

                    const component = componentSection.components.find(co => co._id === coid);
                    if (!component || component.latestVersion._id !== vid) {
                        return barrel.componentSections;
                    }

                    Object.assign(component.latestVersion.snapshot, snapshot);
                });

                return [...barrel.componentSections];
            }
        });
    }

    removeComponent(state: State, {
        bid,
        csids,
        coid
    }: Pick<BarrelActionPayloads.RemoveComponent, "bid" | "csids" | "coid">): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                arrayRemove(componentSection.components, coid);

                return [...barrel.componentSections];
            }
        });
    }

    removeComponents(state: State, {
        bid,
        sectionComponents
    }: BarrelActionPayloads.RemoveComponents): State {
        let newState = state;
        sectionComponents.forEach(({ coids, csid }) => {
            coids.forEach(coid => {
                newState = this.removeComponent(newState, { bid, coid, csids: csid.split(",") });
            });
        });
        return newState;
    }

    addComponentVersion(state: State, {
        bid,
        csids,
        coid,
        componentVersion
    }: BarrelActionPayloads.AddComponentVersion): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const component = componentSection.components.find(({ _id }) => _id === coid);
                if (!component || component.latestVersion._id === componentVersion._id) {
                    return barrel.componentSections;
                }

                component.versions = [componentVersion, ...(component.versions || [component.latestVersion])];
                if (component.totalVersionCount) {
                    component.totalVersionCount++;
                }

                if (component.allowedVersionCount) { // for user to be able to see the version he was able to see before
                    component.allowedVersionCount++;
                }

                component.latestVersion = componentVersion;

                return [...barrel.componentSections];
            }
        });
    }

    addComponentSection(state: State, {
        bid,
        csids,
        componentSection,
        isMovingComponents,
        isCreateGroup
    }: BarrelActionPayloads.AddComponentSection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const parentComponentSection = barrel.findComponentSection(csids) as ComponentSection;
                if (!parentComponentSection) {
                    return barrel.componentSections;
                }

                if (barrel.componentSections.find(cs => cs._id === componentSection._id)) {
                    return barrel.componentSections;
                }

                // Below cast is wrong but missing data is filled below and eventually it fulfills ComponentSection type
                const newComponentSection = { ...componentSection } as ComponentSection;

                if (!newComponentSection._id) {
                    newComponentSection._id = TemporarySectionId.NewComponentSection;
                    newComponentSection.newSection = true;
                    newComponentSection.key = `${TemporarySectionId.NewComponentSection}-${newComponentSectionCount++}`;
                }

                if (!componentSection.components) {
                    newComponentSection.components = [];
                } else if (isMovingComponents) {
                    newComponentSection.components = (componentSection.components as {
                        coid: string;
                        csid: string[];
                    }[]).map(c => {
                        const sourceComponentSection = barrel.findComponentSection(c.csid);
                        if (!sourceComponentSection || sourceComponentSection instanceof Barrel) {
                            return null;
                        }

                        const component = arrayRemove(sourceComponentSection.components, c.coid)!;

                        if (!isCreateGroup && component.variant) {
                            delete component.variant;
                        }

                        return component;
                    }).filter(Boolean) as Component[];
                }

                if (!componentSection.componentSections) {
                    newComponentSection.componentSections = [];
                }

                if (typeof componentSection.index === "undefined") {
                    parentComponentSection.componentSections!.push(newComponentSection);
                } else {
                    parentComponentSection.componentSections!.splice(componentSection.index, 0, newComponentSection);
                }

                return [...barrel.componentSections];
            }
        });
    }

    updateComponentSection(state: State, {
        bid,
        csids,
        componentSectionData
    }: BarrelActionPayloads.UpdateComponentSection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids);
                if (!componentSection) {
                    return barrel.componentSections;
                }

                Object.assign(componentSection, componentSectionData);

                return [...barrel.componentSections];
            }
        });
    }

    mergeComponentSection(state: State, {
        bid,
        csids,
        tcsids,
        flatten
    }: BarrelActionPayloads.MergeComponentSection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const copyOfcsids = [...csids];
                const csid = copyOfcsids.pop();

                const parentComponentSection = barrel.findComponentSection(copyOfcsids);
                if (!parentComponentSection) {
                    return barrel.componentSections;
                }

                const targetComponentSection = barrel.findComponentSection(tcsids) as ComponentSection | null;
                if (!targetComponentSection) {
                    return barrel.componentSections;
                }

                const sourceComponentSection = arrayRemove(parentComponentSection.componentSections!, csid!);
                if (!sourceComponentSection) {
                    return barrel.componentSections;
                }

                const [sourceOldestAncestor] = csids;
                const [targetOldestAncestor] = tcsids;
                if (sourceOldestAncestor !== targetOldestAncestor) {
                    this.removeVariantFromComponents(sourceComponentSection);
                }

                if (flatten) {
                    targetComponentSection.components.push(...this.flattenComponents(sourceComponentSection));
                } else {
                    targetComponentSection.components.push(...sourceComponentSection.components);
                    targetComponentSection.componentSections.push(...sourceComponentSection.componentSections);
                }

                return [...barrel.componentSections];
            }
        });
    }

    removeComponentSection(state: State, {
        bid,
        csids
    }: BarrelActionPayloads.RemoveComponentSection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const copyOfcsids = [...csids];
                const csid = copyOfcsids.pop();

                const parentComponentSection = barrel.findComponentSection(copyOfcsids);
                if (!parentComponentSection) {
                    return barrel.componentSections;
                }

                arrayRemove(parentComponentSection.componentSections!, csid!);

                return [...barrel.componentSections];
            }
        });
    }

    removeComponentSections(state: State, {
        bid,
        componentSections
    }: BarrelActionPayloads.RemoveComponentSections): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                componentSections.forEach(csid => arrayRemove(barrel.componentSections!, csid));

                return [...barrel.componentSections];
            }
        });
    }

    updateComponentsOrder(state: State, {
        bid,
        components
    }: BarrelActionPayloads.UpdateComponentsOrder): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                components.forEach(component => {
                    const { coid, csid, tcsid, to } = component;
                    const sourceParentComponentSection = barrel.findComponentSection(csid) as ComponentSection | null;
                    const targetParentComponentSection = barrel.findComponentSection(tcsid) as ComponentSection | null;
                    if (!sourceParentComponentSection || !targetParentComponentSection) {
                        return barrel.componentSections;
                    }

                    const sourceComponent = arrayRemove(sourceParentComponentSection.components, coid);
                    if (!sourceComponent) {
                        return barrel.componentSections;
                    }

                    const targetIndex = typeof to === "undefined" ? targetParentComponentSection.components.length : to;

                    const [sourceOldestAncestor] = csid;
                    const [targetOldestAncestor] = tcsid;

                    if (sourceComponent.variant && (sourceOldestAncestor !== targetOldestAncestor)) {
                        delete sourceComponent.variant;
                    }

                    targetParentComponentSection.components.splice(targetIndex, 0, sourceComponent);
                });
                return [...barrel.componentSections];
            }
        });
    }

    updateComponentSectionsOrder(state: State, {
        bid,
        componentSections
    }: BarrelActionPayloads.UpdateComponentSectionsOrder): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                componentSections.forEach(componentSection => {
                    const { csid, tcsid, to } = componentSection;
                    const sourceParentComponentSection =
                        barrel.findComponentSection(csid.slice(0, -1)) as ComponentSection;
                    const targetComponentSection = tcsid ? barrel.findComponentSection(tcsid)! : barrel;
                    const removedSection =
                        arrayRemove(sourceParentComponentSection.componentSections, csid[csid.length - 1])!;
                    targetComponentSection.componentSections!.splice(to, 0, removedSection);
                });
                return [...barrel.componentSections];
            }
        });
    }

    attachJiraIssue(state: State, {
        bid,
        jira
    }: BarrelActionPayloads.AttachJiraIssue): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "integration",
            value: ({ integration }: Barrel) => {
                if (!integration?.jira) {
                    return {
                        ...integration,
                        jira
                    };
                }

                return {
                    ...integration,
                    jira: {
                        ...integration.jira,
                        attachments: [
                            ...integration.jira.attachments!,
                            ...jira.attachments!
                                .filter(att => !integration.jira!.attachments!.find(elm => elm.id === att.id))
                        ]
                    }
                };
            }
        });
    }

    removeJiraIssueAttachment(state: State, {
        bid,
        aid
    }: BarrelActionPayloads.RemoveJiraIssueAttachment): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "integration",
            value: ({ integration }: Barrel) => {
                if (!integration?.jira?.attachments) {
                    return integration;
                }

                return {
                    ...integration,
                    jira: {
                        ...integration.jira,
                        attachments: integration.jira.attachments.filter(attachment => attachment.id !== aid)
                    }
                };
            }
        });
    }

    updateJiraIssue(state: State, {
        bid,
        attachments
    }: BarrelActionPayloads.UpdateJiraIssue) {
        const attachmentsMap = attachments.reduce(
            (acc, curr) => ({
                ...acc,
                [curr._id]: new JiraAttachment(curr)
            }), {} as BasicRecord<JiraAttachment>);
        return this.updateBarrelValue(state, {
            bid,
            key: "integration",
            value: ({ integration }: Barrel) => {
                if (!integration?.jira?.attachments) {
                    return integration;
                }

                return {
                    ...integration,
                    jira: {
                        ...integration.jira,
                        attachments: integration.jira.attachments.map(attachment => (
                            attachmentsMap[attachment._id] ? {
                                ...attachment,
                                ...attachmentsMap[attachment._id]
                            }
                                : attachment
                        ))
                    }
                };
            }
        });
    }

    setLocalConnectedComponents(state: State, {
        bid,
        localConnectedComponents
    }: BarrelActionPayloads.SetLocalConnectedComponents): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "localConnectedComponents",
            value: () => localConnectedComponents
        });
    }

    updateConnectedComponents(state: State, {
        bid,
        connectedComponents
    }: BarrelActionPayloads.UpdateConnectedComponents): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "connectedComponents",
            value: () => connectedComponents
        });
    }

    createConnectedComponentItem(state: State, {
        bid,
        connectedComponentItem
    }: BarrelActionPayloads.CreateConnectedComponentItem): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "connectedComponents",
            value(barrel) {
                return [...(barrel.connectedComponents || []), connectedComponentItem as ConnectedComponent];
            }
        });
    }

    updateConnectedComponentItem(state: State, {
        bid,
        itemId,
        payload
    }: BarrelActionPayloads.UpdateConnectedComponentItem): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "connectedComponents",
            value({ connectedComponents }) {
                return connectedComponents!.map(connectedComponent => {
                    if (connectedComponent._id !== itemId) {
                        return connectedComponent;
                    }

                    return { ...connectedComponent, ...payload };
                });
            }
        });
    }

    deleteConnectedComponentItem(state: State, {
        bid,
        itemId
    }: BarrelActionPayloads.DeleteConnectedComponentItem): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "connectedComponents",
            value({ connectedComponents }) {
                return connectedComponents!.filter(({ _id }) => _id !== itemId);
            }
        });
    }

    updateWorkflowStatus(state: State, {
        bid,
        workflowStatus
    }: Pick<BarrelActionPayloads.UpdateWorkflowStatus, "bid" | "workflowStatus">): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "workflowStatus",
            value: workflowStatus
        });
    }

    deleteWorkflowStatus(state: State, {
        bid
    }: Pick<BarrelActionPayloads.DeleteWorkflowStatus, "bid">): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "workflowStatus",
            value: WorkflowStatus.NoStatus
        });
    }

    onOrganizationWorkflowStatusDelete(state: State, {
        workflowStatusId
    }: OrganizationActionPayloads.DeleteOrganizationWorkflowStatus) {
        const barrels = this.barrels(state);
        const affectedBarrels = Object.values(barrels).filter(barrel => barrel.workflowStatus._id === workflowStatusId);

        return affectedBarrels.reduce(
            (prevState, barrel) => this.deleteWorkflowStatus(prevState, { bid: barrel.id }),
            state
        );
    }

    removeUnjoinedBarrels(state: State, action: OrganizationActionPayloads.RemoveUnjoinedBarrels) {
        const barrels = this.barrels(state);
        const remainingBarrels = action[
            `remaining${uppercaseFirst(this.barrelsPropertyName)}` as "remainingProjects" | "remainingStyleguides"
        ];

        return {
            ...state,
            [this.barrelsPropertyName]: Object.fromEntries(
                Object.entries(barrels)
                    .filter(([id]) => remainingBarrels.includes(id))
            )
        };
    }

    // #region Helpers
    flattenComponents(componentSection: ComponentSection): Component[] {
        return componentSection.components.concat(
            ...componentSection.componentSections.map(components => this.flattenComponents(components))
        );
    }

    updateBarrelValue<K extends keyof BarrelData>(state: State, { bid, key, value }: {
        bid: string;
        key: K;
        value: BarrelData[K] | ((barrel: Barrel) => (BarrelData[K] | undefined));
    }): State {
        return this.updateBarrel(state, {
            bid,
            data: currentBarrel => new Barrel({
                ...currentBarrel,
                [key]: typeof value === "function" ? value(currentBarrel) : value
            })
        });
    }

    setComponentVersions(state: State, {
        bid,
        coid,
        csids,
        versions,
        allowedVersionCount,
        totalVersionCount
    }: ComponentActionPayloads.SetComponentVersions): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection(csids) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const componentIndex = componentSection.components.findIndex(co => co._id === coid);
                if (componentIndex === -1) {
                    return barrel.componentSections;
                }

                const component = componentSection.components[componentIndex];

                const newComponent = {
                    ...component,
                    versions,
                    allowedVersionCount,
                    totalVersionCount
                };

                componentSection.components.splice(componentIndex, 1, newComponent);

                return [...barrel.componentSections];
            }
        });
    }
    // #endregion

    // MARK: Styleguide reducers
    addComponentVariantProperty(state: State, {
        bid,
        csid,
        componentVariantProperty
    }: StyleguideActionPayloads.CreateComponentVariantProperty): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection([csid]) as ComponentSection | null;
                if (!componentSection?.variant?.properties) {
                    return barrel.componentSections;
                }

                componentSection.variant.properties.push(componentVariantProperty);

                return [...barrel.componentSections];
            }
        });
    }

    updateComponentVariantProperty(state: State, {
        bid,
        csid,
        vpid,
        data
    }: Pick<
        PartializeProps<StyleguideActionPayloads.UpdateComponentVariantProperty, "data">,
        "bid" | "csid" | "vpid" | "data"
    >): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection([csid]) as ComponentSection | null;
                if (!componentSection?.variant?.properties) {
                    return barrel.componentSections;
                }

                const property = componentSection.variant.properties.find(({ _id }) => _id === vpid);
                if (!property) {
                    return barrel.componentSections;
                }

                Object.assign(property, data);

                return [...barrel.componentSections];
            }
        });
    }

    updateComponentVariantPropertyName(state: State, {
        bid,
        csid,
        vpid,
        name
    }: StyleguideActionPayloads.UpdateComponentVariantPropertyName): State {
        return this.updateComponentVariantProperty(state, {
            bid,
            csid,
            vpid,
            data: { name }
        });
    }

    updateComponentVariantPropertyValue(state: State, {
        bid,
        csids,
        vpid,
        coid,
        value,
        oldValue,
        component
    }: StyleguideActionPayloads.UpdateComponentVariantPropertyValue): State {
        const componentUpdatedState = this.updateComponent(state, {
            bid,
            csids,
            coid,
            componentData: {
                variant: {
                    values: [
                        ...(component?.variant?.values.filter(({ propertyId }) => propertyId !== vpid) || []),
                        { propertyId: vpid, value }
                    ]
                }
            }
        });

        const variantsUpdatedState = this.updateBarrelValue(componentUpdatedState, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSectionByVariantId(vpid) as ComponentSection;
                if (!componentSection?.variant?.properties) {
                    return barrel.componentSections;
                }

                const flattenComponents = this.flattenComponents(componentSection);

                const hasComponentsWithOldVariantName = flattenComponents.filter(
                    c => c.variant?.values.some(({ propertyId, value: v }) => propertyId === vpid && v === oldValue)
                ).length > 0;

                const hasOtherComponentsWithNewVariantName = flattenComponents.filter(
                    c => c.variant?.values.some(({ propertyId, value: v }) => propertyId === vpid && v === value)
                ).length > 1;

                const { properties } = componentSection.variant;
                const propertyIndex = properties.findIndex(({ _id }) => _id === vpid);

                if (!hasComponentsWithOldVariantName) {
                    properties[propertyIndex] = {
                        ...properties[propertyIndex],
                        values: properties[propertyIndex].values.filter(v => v !== oldValue)
                    };
                }

                if (!hasOtherComponentsWithNewVariantName) {
                    properties[propertyIndex] = {
                        ...properties[propertyIndex],
                        values: [...properties[propertyIndex].values, value]
                    };
                }

                Object.assign(componentSection.variant, { properties });

                return [...barrel.componentSections];
            }
        });

        return variantsUpdatedState;
    }

    updateComponentVariantPropertyValues(state: State, {
        bid,
        csid,
        vpid,
        values
    }: StyleguideActionPayloads.UpdateComponentVariantPropertyValues): State {
        return this.updateComponentVariantProperty(state, {
            bid,
            csid,
            vpid,
            data: { values }
        });
    }

    updateComponentVariantPropertiesOrder(state: State, {
        bid,
        csid,
        properties
    }: StyleguideActionPayloads.UpdateComponentVariantPropertiesOrder): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection([csid]) as ComponentSection | null;
                if (!componentSection?.variant) {
                    return barrel.componentSections;
                }

                for (let i = 0; i < properties.length; i++) {
                    const { _id, to } = properties[i];
                    const removedProperty = arrayRemove(componentSection.variant.properties, p => p._id === _id);
                    componentSection.variant.properties.splice(to, 0, removedProperty!);
                }

                return [...barrel.componentSections];
            }
        });
    }

    setComponentVariantData(state: State, {
        bid,
        csid,
        name,
        sectionVariant,
        components
    }: StyleguideActionPayloads.SetComponentVariantData): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection([csid]) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                if (name) {
                    componentSection.name = name;
                }

                if (sectionVariant) {
                    componentSection.variant = sectionVariant;
                }

                if (components) {
                    components.forEach(({ _id, variant }) => {
                        const component = barrel.findComponentById(_id);
                        if (!component) {
                            return;
                        }

                        component.variant = variant;
                    });
                }

                return [...barrel.componentSections];
            }
        });
    }

    removeVariantPropertyFromComponents(componentSection: ComponentSection, vpid: string) {
        componentSection.components.forEach(component => {
            if (component.variant) {
                component.variant.values = component.variant.values.filter(property => property.propertyId !== vpid);
            }
        });
        componentSection.componentSections.forEach(
            innerComponentSection => this.removeVariantPropertyFromComponents(innerComponentSection, vpid)
        );
    }

    removeComponentVariantProperty(state: State, {
        bid,
        csid,
        vpid
    }: StyleguideActionPayloads.RemoveComponentVariantProperty): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection([csid]) as ComponentSection | null;
                if (!componentSection || !componentSection.variant || !componentSection.variant.properties) {
                    return barrel.componentSections;
                }

                componentSection.variant.properties = componentSection.variant.properties
                    .filter(({ _id }) => _id !== vpid);

                this.removeVariantPropertyFromComponents(componentSection, vpid);

                return [...barrel.componentSections];
            }
        });
    }

    removeVariantFromComponents(componentSection: ComponentSection) {
        componentSection.components.forEach(component => {
            delete component.variant;
        });

        componentSection.componentSections.forEach(
            componentGroup => this.removeVariantFromComponents(componentGroup)
        );
    }

    removeComponentVariantData(state: State, {
        bid,
        csid
    }: StyleguideActionPayloads.RemoveComponentVariantData): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection([csid]) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                delete componentSection.variant;

                this.removeVariantFromComponents(componentSection);

                return [...barrel.componentSections];
            }
        });
    }

    groupComponentsByVariantProperty(state: State, {
        bid,
        csid,
        components,
        componentSections
    }: StyleguideActionPayloads.GroupComponentsByVariantProperty): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "componentSections",
            value: (barrel: Barrel) => {
                if (!barrel.componentSections) {
                    return;
                }

                const componentSection = barrel.findComponentSection([csid]) as ComponentSection | null;
                if (!componentSection) {
                    return barrel.componentSections;
                }

                const allComponents = this.flattenComponents(componentSection);

                componentSections.forEach(componentGroup => {
                    componentGroup.components = componentGroup.components.map(({ _id: coid }) =>
                        allComponents.find(({ _id }) => _id === coid)!
                    );
                });
                componentSection.componentSections = componentSections as ComponentSection[];

                componentSection.components = components.map(({ _id: coid }) =>
                    allComponents.find(({ _id }) => _id === coid)!
                );

                return [...barrel.componentSections];
            }
        });
    }

    changeComponentAssetName(state: State, {
        bid,
        component,
        assetId,
        name
    }: BarrelActionPayloads.ChangeAssetName) {
        if (!component) {
            return state;
        }

        const {
            latestVersion: {
                snapshot: {
                    assets
                },
                snapshot
            },
            latestVersion
        } = component;

        const csids = component.idPath.slice(0, -1);
        const coid = component._id;

        // Find asset and change localName
        return this.updateComponent(state, {
            bid,
            csids,
            coid,
            componentData: {
                latestVersion: {
                    ...latestVersion,
                    snapshot: {
                        ...snapshot,
                        assets: assets?.map(asset => {
                            if (asset._id !== assetId) {
                                return asset;
                            }

                            return {
                                ...asset,
                                localName: name
                            };
                        })
                    }
                }
            }
        });
    }

    updateVariableCollectionDescription(state: State, {
        bid,
        vcid,
        description
    }: BarrelActionPayloads.UpdateVariableCollectionDescription): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "variableCollections",
            value: ({ variableCollections }: Barrel) => variableCollections?.map(variableCollection => {
                if (variableCollection._id !== vcid) {
                    return variableCollection;
                }

                return { ...variableCollection, description };
            })
        });
    }

    addVariableCollection(state: State, {
        bid,
        variableCollection
    }: BarrelActionPayloads.AddVariableCollection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "variableCollections",
            value: ({ variableCollections }: Barrel) => [...(variableCollections || []), variableCollection]
        });
    }

    deleteVariableCollection(state: State, {
        bid,
        vcid
    }: BarrelActionPayloads.DeleteVariableCollection): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "variableCollections",
            value: ({ variableCollections }: Barrel) => variableCollections?.filter(({ _id }) => _id !== vcid)
        });
    }

    updateVariableCollectionGroupDescription(state: State, {
        bid,
        vcid,
        gid,
        description
    }: BarrelActionPayloads.UpdateVariableCollectionGroupDescription): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "variableCollections",
            value: ({ variableCollections }: Barrel) => variableCollections?.map(variableCollection => {
                if (variableCollection._id !== vcid) {
                    return variableCollection;
                }

                return {
                    ...variableCollection,
                    groups: variableCollection.groups.map(group => {
                        if (group._id !== gid) {
                            return group;
                        }

                        return { ...group, description };
                    })
                };
            })
        });
    }

    updateVariableOrder(state: State, {
        bid,
        vcid,
        gid,
        variableId,
        to
    }: BarrelActionPayloads.UpdateVariableOrder): State {
        return this.updateBarrelValue(state, {
            bid,
            key: "variableCollections",
            value: ({ variableCollections }: Barrel) => variableCollections?.map(variableCollection => {
                if (variableCollection._id !== vcid) {
                    return variableCollection;
                }

                return {
                    ...variableCollection,
                    groups: variableCollection.groups.map(group => {
                        if (group._id === gid) {
                            const oldIndex = group.variables.findIndex(variable => variable._id === variableId);

                            if (oldIndex !== -1) {
                                const variableToReorder = group.variables[oldIndex];

                                group.variables.splice(oldIndex, 1);
                                group.variables.splice(to, 0, variableToReorder);
                            }
                        }

                        return group;
                    })
                };
            })
        });
    }
}

export default BarrelsReducer;
