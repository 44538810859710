import fluxRuntime from "../fluxRuntime";
import OrganizationHelpers from "../organization/OrganizationHelpers";

import BarrelType from "../../../foundation/model/BarrelType";
import { urlResolve } from "../../../foundation/utils/url";
import { isOrganizationProject } from "../../../foundation/utils/legacy";
import { sortArrayOfObjectsByPropertyName } from "../../../foundation/utils/array";
import isUserMemberOfBarrel from "../../../foundation/utils/barrel/isUserMemberOfBarrel";
import router from "../../../foundation/router";
import Barrel from "../../../foundation/model/Barrel";
import StyleguideData from "../../../foundation/model/StyleguideData";
import BarrelUser from "../../../foundation/model/BarrelUser";
import RemPreferences from "../../../foundation/model/RemPreferences";
import VariantGroup from "../../../foundation/model/VariantGroup";
import OrganizationMember from "../../../foundation/api/model/organizations/OrganizationMember";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import NoteType from "../../../foundation/model/NoteType";
import BarrelKind from "../../../foundation/api/model/barrels/BarrelKind";
import ApiBarrel from "../../../foundation/api/model/barrels/Barrel";

interface NavigateToWorkspaceParams {
    oid?: string | null;
    barrelType?: BarrelType;
    query?: BasicRecord<unknown>;
}

function hasProject(pid: string): boolean {
    const { projects } = fluxRuntime.ProjectsDataStore.getState();

    return projects && pid in projects;
}

function hasStyleguide(stid: string): boolean {
    const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();

    return styleguides && stid in styleguides;
}

function getBarrel({ bid }: {
    bid?: string | null;
} = {}): Barrel | null {
    let barrelId = bid;
    if (!barrelId) {
        ({ barrelId } = fluxRuntime.BarrelStore.getState());
    }

    if (!barrelId) {
        return null;
    }

    const { projects } = fluxRuntime.ProjectsDataStore.getState();
    if (projects && barrelId in projects) {
        return projects[barrelId];
    }

    const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();
    if (styleguides && barrelId in styleguides) {
        return styleguides[barrelId];
    }

    return null;
}

function getProject({ pid }: {
    pid?: string | null;
} = {}): Barrel | null {
    let projectId = pid;
    if (!projectId) {
        const { barrelId, barrelType } = fluxRuntime.BarrelStore.getState();
        if (barrelType === BarrelType.PROJECT) {
            projectId = barrelId;
        }
    }

    if (!projectId) {
        return null;
    }

    const { projects } = fluxRuntime.ProjectsDataStore.getState();
    if (projects && projectId in projects) {
        return projects[projectId];
    }

    return null;
}

function findUser({ bid, uid }: {
    bid?: string | null;
    uid?: string | null;
} = {}): BarrelUser | OrganizationMember | null | undefined {
    const barrel = getBarrel({ bid });

    if (!barrel) {
        return null;
    }

    let userId = uid;
    if (!userId) {
        ({ userId } = fluxRuntime.UserStore.getState());
    }

    if (isOrganizationProject(barrel)) {
        const { members } = OrganizationHelpers.getOrganization() || {};

        if (members?.length) {
            return members.find(({ user }) => user._id === userId);
        }
    }

    return barrel.users.find(({ user }) => user._id === userId);
}

function canUploadDesigns({ uid, pid }: {uid: string; pid?: string | null;}): boolean {
    const project = getProject({ pid });

    if (!project) {
        return false;
    }

    const currentUser = project.users.find(({ user }) => user._id === uid);

    if (!currentUser) {
        return false;
    }

    if (isOrganizationProject(project)) {
        return ["king", "queen", "rook"].includes(currentUser.role);
    }

    const projectOwner = project.users.find(user => user.role === "owner");
    if (projectOwner) {
        if (projectOwner.user.paymentPlan === "free") {
            return currentUser.role === "owner";
        }
        // Growing or starter plan
        return true;
    }
    return false;
}

function isUserRestricted(): boolean {
    return findUser()?.role === "pawn";
}

function getVariantGroupOfScreen(screenId: string): VariantGroup | undefined {
    const { variantGroups = [] } = getProject() || {};

    return variantGroups.find(({ screens }) => screens.some(({ id }) => id === screenId));
}

function findBarrelOrganizationId(bid: string): string | null {
    const barrel = getBarrel({ bid });

    if (!barrel) {
        return null;
    }

    const { organizationProjects, organizationStyleguides } = fluxRuntime.OrganizationStore.getState();

    for (const [oid, pids] of Object.entries(organizationProjects)) {
        if (pids.includes(bid)) {
            return oid;
        }
    }

    for (const [oid, stids] of Object.entries(organizationStyleguides)) {
        if (stids.includes(bid)) {
            return oid;
        }
    }

    return null;
}

function getWorkspaceURL({
    oid,
    barrelType: paramBarrelType,
    query
}: NavigateToWorkspaceParams = {}): string {
    // Passing oid is required when BarrelStore is reset, otherwise navigates to personal WS
    if (oid) {
        const resetWorkspaceBarrelType = paramBarrelType ?? BarrelType.PROJECT;
        const resetWorkspaceSection = resetWorkspaceBarrelType === BarrelType.PROJECT ? "projects" : "styleguides";
        return oid === "user"
            ? urlResolve(resetWorkspaceSection, { query })
            : urlResolve(`workspace-${resetWorkspaceSection}`, { url: { oid }, query });
    }

    const {
        organizationId,
        barrelType
    } = fluxRuntime.BarrelStore.getState();

    const barrelUser = findUser();

    const workspaceSectionBarrelType = paramBarrelType ?? barrelType;
    const workspaceSection = workspaceSectionBarrelType === BarrelType.PROJECT ? "projects" : "styleguides";

    const workspaceId = organizationId ?? "user";

    return workspaceId === "user" || !barrelUser
        ? urlResolve(workspaceSection, { query })
        : urlResolve(`workspace-${workspaceSection}`, { url: { oid: workspaceId }, query });
}

function navigateToWorkspace(params: NavigateToWorkspaceParams = {}) {
    const workspaceURL = getWorkspaceURL(params);

    setTimeout(() => router.history.push(workspaceURL), 4);
}

function getLinkedStyleguideIds(barrel: Barrel, barrelType: BarrelType) {
    if (barrelType === BarrelType.STYLEGUIDE) {
        return [...barrel.ancestors!].reverse();
    }

    if (!barrel.styleguideLink) {
        return [];
    }

    const { styleguide, status: linkStatus } = barrel.styleguideLink!;
    if (linkStatus === "active") {
        const reversedAncestors = [...styleguide.ancestors!].reverse();
        return [styleguide._id, ...reversedAncestors];
    }

    return [];
}

function getRemPreferences(remPreferences: Partial<RemPreferences>, linkedStyleguides: Barrel[]):
    Partial<RemPreferences> {
    let computedRemPreferences;

    if (remPreferences.status === "linked") {
        const styleguide = linkedStyleguides.find(linkedStyleguide => linkedStyleguide.remPreferences.status !== "linked");

        computedRemPreferences = styleguide && styleguide.remPreferences;
    }

    return computedRemPreferences || remPreferences;
}

function getParentStyleguideToInheritRemPreferences(
    remPreferences: Partial<RemPreferences>,
    linkedStyleguides: Barrel[]
): Pick<Barrel, "name" | "remPreferences"> | undefined {
    let styleguide;

    if (remPreferences.status !== "linked") {
        styleguide = linkedStyleguides.find(linkedStyleguide => linkedStyleguide.remPreferences.status !== "linked" && linkedStyleguide.type === "web");
    }

    return styleguide && {
        name: styleguide.name,
        remPreferences: styleguide.remPreferences
    };
}

/* TODO: This helper is used in some mappers, and it returns a new array reference each call.
*  We should find a way to memoize it's returned reference if nothing changed in this
*  function's dependencies(BarrelStore, StyleguidesDataStore, and ProjectsDataStore)
*/
function getStyleguidesData(): StyleguideData[] {
    const { loading, ...barrelState } = fluxRuntime.BarrelStore.getState();

    if (loading) {
        return [];
    }

    const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();
    const barrel = getBarrel()!;
    const barrelUser = findUser();
    const {
        barrelId,
        barrelType
    } = barrelState;

    const {
        name: barrelName,
        type,
        colors,
        textStyles,
        spacingSections,
        remPreferences,
        componentSections,
        connectedComponents,
        localConnectedComponents,
        dimensionSuffix,
        textDimensionSuffix,
        densityScale,
        variableCollections
    } = barrel;

    if (!barrelUser) {
        return [];
    }

    const barrelUserId = barrelUser.user._id;
    const isMemberOfBarrel = isUserMemberOfBarrel(barrel, barrelUserId);

    if (!isMemberOfBarrel) {
        return [];
    }

    const localStyleguideName = barrelType === BarrelType.STYLEGUIDE ? barrelName : "Local Styleguide";

    const linkedStyleguides = getLinkedStyleguideIds(barrel, barrelType)
        .map(ancestor => styleguides[ancestor])
        .filter(Boolean);

    const localStyleguide = {
        id: barrelId!,
        name: localStyleguideName,
        type,
        colors,
        textStyles,
        spacingSections: spacingSections!.map(spacingSection => ({
            ...spacingSection,
            spacingTokens: sortArrayOfObjectsByPropertyName(spacingSection.spacingTokens, "value")
        })),
        remPreferences: getRemPreferences(remPreferences, linkedStyleguides),
        parentStyleguideToInheritRemPreferences:
            getParentStyleguideToInheritRemPreferences(remPreferences, linkedStyleguides),
        componentSections,
        connectedComponents,
        localConnectedComponents,
        isMember: isMemberOfBarrel,
        isOrganizationBarrel: isOrganizationProject(barrel),
        dimensionSuffix,
        textDimensionSuffix,
        densityScale,
        variableCollections
    };

    const linkedStyleguidesData = linkedStyleguides.map((styleguide, index) => ({
        id: styleguide._id,
        name: styleguide.name,
        type: styleguide.type,
        colors: styleguide.colors,
        textStyles: styleguide.textStyles,
        spacingSections: styleguide.spacingSections!.map(spacingSection => ({
            ...spacingSection,
            spacingTokens: sortArrayOfObjectsByPropertyName(spacingSection.spacingTokens, "value")
        })),
        remPreferences: getRemPreferences(styleguide.remPreferences, linkedStyleguides.slice(index + 1)),
        componentSections: styleguide.componentSections,
        connectedComponents: styleguide.connectedComponents,
        localConnectedComponents: styleguide.localConnectedComponents,
        isMember: isUserMemberOfBarrel(styleguide, barrelUserId),
        isOrganizationBarrel: isOrganizationProject(styleguide),
        dimensionSuffix: styleguide.dimensionSuffix,
        textDimensionSuffix: styleguide.textDimensionSuffix,
        densityScale: styleguide.densityScale,
        variableCollections: styleguide.variableCollections
    }));

    return [localStyleguide, ...linkedStyleguidesData];
}

function getStyleguidesDataV2({
    barrel,
    barrelId,
    barrelType,
    barrelUser,
    styleguides
}: {
    barrel: Barrel;
    barrelId: string;
    barrelType: BarrelType;
    barrelUser: BarrelUser | OrganizationMember | null | undefined;
    styleguides: BasicRecord<Barrel>;
}): StyleguideData[] {
    if (!barrelUser) {
        return [];
    }

    const {
        name: barrelName,
        type,
        colors,
        textStyles,
        spacingSections,
        remPreferences,
        componentSections,
        connectedComponents,
        localConnectedComponents,
        dimensionSuffix,
        textDimensionSuffix,
        densityScale,
        variableCollections
    } = barrel;

    const barrelUserId = barrelUser.user._id;
    const isMemberOfBarrel = isUserMemberOfBarrel(barrel, barrelUserId);

    if (!isMemberOfBarrel) {
        return [];
    }

    const localStyleguideName = barrelType === BarrelType.STYLEGUIDE ? barrelName : "Local Styleguide";

    const linkedStyleguides = getLinkedStyleguideIds(barrel, barrelType)
        .map(ancestor => styleguides[ancestor])
        .filter(Boolean);

    const localStyleguide = {
        id: barrelId!,
        name: localStyleguideName,
        type,
        colors,
        textStyles,
        spacingSections: (spacingSections || []).map(spacingSection => ({
            ...spacingSection,
            spacingTokens: sortArrayOfObjectsByPropertyName(spacingSection.spacingTokens, "value")
        })),
        remPreferences: getRemPreferences(remPreferences, linkedStyleguides),
        parentStyleguideToInheritRemPreferences:
            getParentStyleguideToInheritRemPreferences(remPreferences, linkedStyleguides),
        componentSections,
        connectedComponents,
        localConnectedComponents,
        isMember: isMemberOfBarrel,
        isOrganizationBarrel: isOrganizationProject(barrel),
        dimensionSuffix,
        textDimensionSuffix,
        densityScale,
        variableCollections
    };

    const linkedStyleguidesData = linkedStyleguides.map((styleguide, index) => ({
        id: styleguide._id,
        name: styleguide.name,
        type: styleguide.type,
        colors: styleguide.colors,
        textStyles: styleguide.textStyles,
        spacingSections: (styleguide.spacingSections || []).map(spacingSection => ({
            ...spacingSection,
            spacingTokens: sortArrayOfObjectsByPropertyName(spacingSection.spacingTokens, "value")
        })),
        remPreferences: getRemPreferences(styleguide.remPreferences, linkedStyleguides.slice(index + 1)),
        componentSections: styleguide.componentSections,
        connectedComponents: styleguide.connectedComponents,
        localConnectedComponents: styleguide.localConnectedComponents,
        isMember: isUserMemberOfBarrel(styleguide, barrelUserId),
        isOrganizationBarrel: isOrganizationProject(styleguide),
        dimensionSuffix: styleguide.dimensionSuffix,
        textDimensionSuffix: styleguide.textDimensionSuffix,
        densityScale: styleguide.densityScale,
        variableCollections: styleguide.variableCollections
    }));

    return [localStyleguide, ...linkedStyleguidesData];
}

function navigateToBarrel(barrelType: BarrelType, bid: string) {
    const barrelURL = urlResolve(barrelType, { url: { bid } });
    router.history.push(barrelURL);
}

function reloadCurrentPage() {
    const { href: redirectUrl } = window.location;

    setTimeout(() => {
        router.history.replace(redirectUrl);
    }, 4);
}

function findNoteType(pid: string, ntid?: string): NoteType {
    if (!ntid) {
        return NoteType.None;
    }
    const { noteTypes = [] } = getProject({ pid }) || {};

    return noteTypes.find(noteType => noteType._id === ntid) || NoteType.None;
}

function findNoteTypeByName(pid: string, name: string): NoteType {
    if (!name) {
        return NoteType.None;
    }
    const { noteTypes = [] } = getProject({ pid }) || {};

    return noteTypes.find(noteType => noteType.name === name) || NoteType.None;
}

function noteTypeExists(pid: string, ntid?: string): boolean {
    if (!ntid) {
        return false;
    }
    const { noteTypes = [] } = getProject({ pid }) || {};
    return noteTypes.some(noteType => noteType._id === ntid);
}

function isOnboardingProject(project?: Barrel | ApiBarrel | null): boolean {
    let currentProject = project;

    if (!currentProject) {
        currentProject = getProject();
    }

    if (!currentProject) {
        return false;
    }

    return currentProject.kind === BarrelKind.Onboarding;
}

function onboardingProjectExists(): boolean {
    const { projects } = fluxRuntime.ProjectsDataStore.getState();

    return Object.values(projects).some(project => isOnboardingProject(project));
}

function shouldAutoFollowEmailNotifications({ projectId }: {
    projectId: string;
}) {
    const { emailNotificationSettings } = fluxRuntime.UserStore.getState();
    const { followedProjects, unfollowedProjects } = emailNotificationSettings!;

    const alreadyFollowing = followedProjects.has(projectId);
    const alreadyUnfollowed = unfollowedProjects.has(projectId);
    return !alreadyFollowing && !alreadyUnfollowed;
}

function isVersionDiffEnabled(bid: string) {
    const barrel = getBarrel({ bid });

    if (!barrel) {
        return false;
    }

    return isOrganizationProject(barrel);
}

export default {
    hasProject,
    hasStyleguide,
    getBarrel,
    getProject,
    findUser,
    isUserRestricted,
    getVariantGroupOfScreen,
    findBarrelOrganizationId,
    getWorkspaceURL,
    getStyleguidesData,
    getStyleguidesDataV2,
    navigateToWorkspace,
    canUploadDesigns,
    navigateToBarrel,
    reloadCurrentPage,
    findNoteType,
    findNoteTypeByName,
    noteTypeExists,
    isOnboardingProject,
    onboardingProjectExists,
    shouldAutoFollowEmailNotifications,
    isVersionDiffEnabled
};
