enum ActionTypes {
    GET_SCIM_SETTINGS_REQUEST = "workspaceSettings/getSCIMSettingsRequest",
    GET_SCIM_SETTINGS_SUCCESS = "workspaceSettings/getSCIMSettingsSuccess",
    GET_SCIM_SETTINGS_FAILURE = "workspaceSettings/getSCIMSettingsFailure",
    GET_SCIM_TOKENS_REQUEST = "workspaceSettings/getSCIMTokensRequest",
    GET_SCIM_TOKENS_SUCCESS = "workspaceSettings/getSCIMTokensSuccess",
    GET_SCIM_TOKENS_FAILURE = "workspaceSettings/getSCIMTokensFailure",
    CREATE_SCIM_TOKEN_REQUEST = "workspaceSettings/createSCIMTokenRequest",
    CREATE_SCIM_TOKEN_SUCCESS = "workspaceSettings/createSCIMTokenSuccess",
    CREATE_SCIM_TOKEN_FAILURE = "workspaceSettings/createSCIMTokenFailure",
    DELETE_SCIM_TOKEN_REQUEST = "workspaceSettings/deleteSCIMTokenRequest",
    DELETE_SCIM_TOKEN_SUCCESS = "workspaceSettings/deleteSCIMTokenSuccess",
    DELETE_SCIM_TOKEN_FAILURE = "workspaceSettings/deleteSCIMTokenFailure",

    UPDATE_SCIM_ROLE = "workspaceSettings/updateSCIMRoleRequest",
    UPDATE_SCIM_ACCESSIBILITY = "workspaceSettings/updateSCIMAccessibilityRequest",
    UPDATE_SCIM_STATUS = "workspaceSettings/updateSCIMStatusRequest",
}

export default ActionTypes;
