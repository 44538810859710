/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { AllPayloads } from "../payloads";
import * as SupportButtonActionPayloads from "./SupportButtonActionPayloads";
import SupportButtonActionTypes from "./SupportButtonActionTypes";

interface State {
    overridingCategory: string | null;
}

class SupportButtonStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            overridingCategory: null
        };
    }

    setOverridingCategory(state: State, {
        overridingCategory
    }: SupportButtonActionPayloads.SetOverridingCategory): State {
        if (overridingCategory === state.overridingCategory) {
            return state;
        }

        return {
            ...state,
            overridingCategory
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case SupportButtonActionTypes.SET_OVERRIDING_CATEGORY:
                return this.setOverridingCategory(state, action);

            default:
                return state;
        }
    }
}

export default SupportButtonStore;
export {
    State as SupportButtonStoreState
};
