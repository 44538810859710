enum ActionTypes {
    OPEN = "componentVariant/open",
    CLOSE = "componentVariant/close",
    SELECT_LAYER = "componentVariant/selectLayer",
    ENTER_LAYER = "componentVariant/enterLayer",
    LEAVE_LAYER = "componentVariant/leaveLayer",
    DESELECT_LAYER = "componentVariant/deselectLayer",
    CHANGE_VARIANT = "componentVariant/changeVariant"
}

export default ActionTypes;
