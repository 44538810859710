import { GlobalSearchResultType } from "../../../../../foundation/api/model";

export interface GlobalSearchFilterElement {
    label: string;
    value: GlobalSearchResultType[];
    index: number;
}

export const GLOBAL_SEARCH_FILTERS: GlobalSearchFilterElement[] = [{
    label: "All",
    value: [
        GlobalSearchResultType.Colors,
        GlobalSearchResultType.TextStyles,
        GlobalSearchResultType.Annotations,
        GlobalSearchResultType.DotComments,
        GlobalSearchResultType.Flows,
        GlobalSearchResultType.FlowGroups,
        GlobalSearchResultType.OrganizationSections,
        GlobalSearchResultType.Projects,
        GlobalSearchResultType.Styleguides,
        GlobalSearchResultType.Screens,
        GlobalSearchResultType.ProjectSections,
        GlobalSearchResultType.Components,
        GlobalSearchResultType.ComponentSections
    ],
    index: 0
},
{
    label: "Screens",
    value: [GlobalSearchResultType.Screens],
    index: 1
},
{
    label: "Projects",
    value: [GlobalSearchResultType.Projects],
    index: 2
},
{
    label: "Sections",
    value: [
        GlobalSearchResultType.ProjectSections,
        GlobalSearchResultType.OrganizationSections
    ],
    index: 3
},
{
    label: "Comments",
    value: [
        GlobalSearchResultType.DotComments,
        GlobalSearchResultType.Annotations
    ],
    index: 4
},
{
    label: "Components",
    value: [
        GlobalSearchResultType.Components,
        GlobalSearchResultType.ComponentSections
    ],
    index: 5
}];
