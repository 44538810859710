/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import OrganizationSection from "../../../foundation/api/model/organizations/OrganizationSection";
import { PreviewDialogParams } from "../../../foundation/dialogParams";
import { WorkspaceTabs, SortTypes, ProjectsScrolledState } from "../../../foundation/enums";
import ProjectSection from "../../../foundation/model/ProjectSection";
import WorkflowStatus from "../../../foundation/model/WorkflowStatus";

import * as AppActionPayloads from "../app/AppActionPayloads";
import AppActionTypes from "../app/AppActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import FigmaLibrarySyncActionTypes from "../figmaLibrarySync/FigmaLibrarySyncActionTypes";
import * as OrganizationActionPayloads from "../organization/OrganizationActionPayloads";
import OrganizationActionTypes from "../organization/OrganizationActionTypes";
import ProjectsActionTypes from "../projects/ProjectsActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./SpaceActionPayloads";
import SpaceActionTypes from "./SpaceActionTypes";
import { SpaceStoreState as State } from "./types";

class SpaceStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            loading: true,
            selectedTab: WorkspaceTabs.PROJECTS,
            selectedOrganizationId: "user",
            selectedProjectSortType: SortTypes.CREATED,
            showArchivedProjects: false,
            pinnedBarrelOnce: false,
            selectedWorkflowStatusFilter: WorkflowStatus.All._id,
            filterValue: "",
            highlightedBarrels: [],
            highlightedSection: null,
            selectedSection: null,
            collapsedSections: {},
            visibleDialogType: null,
            visibleDialogParams: {},
            offerReminderNextDate: null,
            workspaceMainDataLoading: false,
            createSampleProjectInProgress: false,
            projectsScrolledState: ProjectsScrolledState.Top
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    updatePageState(state: State, {
        updateState
    }: Payloads.UpdatePageState): State {
        return {
            ...state,
            ...updateState,
            loading: false
        };
    }

    changeProjectSelectedSortType(state: State, selectedProjectSortType: SortTypes): State {
        return {
            ...state,
            selectedProjectSortType
        };
    }

    toggleArchivedProjects(state: State, showArchivedProjects: boolean): State {
        return {
            ...state,
            showArchivedProjects
        };
    }

    setPinnedBarrelOnce(state: State): State {
        if (state.pinnedBarrelOnce) {
            return state;
        }

        return {
            ...state,
            pinnedBarrelOnce: true
        };
    }

    updateForUnpinBarrel(state: State, {
        bid
    }: OrganizationActionPayloads.RemoveBarrelFromFocus): State {
        return this.unhighlightBarrel(state, { bid });
    }

    changeWorkflowStatusFilter(state: State, {
        workflowStatusFilter
    }: Pick<Payloads.ChangeWorkflowStatusFilter, "workflowStatusFilter">): State {
        return {
            ...state,
            selectedWorkflowStatusFilter: workflowStatusFilter
        };
    }

    changeWorkflowStatusFilterOnDelete(state: State, {
        oldWorkflowStatusData
    }: OrganizationActionPayloads.DeleteOrganizationWorkflowStatus): State {
        if (state.selectedWorkflowStatusFilter === oldWorkflowStatusData._id) {
            return this.changeWorkflowStatusFilter(state, { workflowStatusFilter: WorkflowStatus.All._id });
        }

        return state;
    }

    highlightBarrel(state: State, {
        bid
    }: {
        bid: string;
    }): State {
        return {
            ...state,
            highlightedBarrels: [bid, ...state.highlightedBarrels]
        };
    }

    unhighlightBarrel(state: State, {
        bid
    }: Pick<Payloads.UnhighlightBarrel, "bid">): State {
        return {
            ...state,
            highlightedBarrels: state.highlightedBarrels.filter(id => id !== bid)
        };
    }

    highlightSection(state: State, {
        oseid
    }: Payloads.HighlightSection): State {
        const { collapsedSections } = state;
        if (collapsedSections[oseid]) {
            collapsedSections[oseid] = false;
        }

        return {
            ...state,
            collapsedSections,
            highlightedSection: oseid,
            selectedSection: oseid
        };
    }

    unhighlightSection(state: State): State {
        return {
            ...state,
            highlightedSection: null
        };
    }

    setSelectedSection(state: State, {
        oseid
    }: Payloads.SetSelectedSection): State {
        return {
            ...state,
            selectedSection: oseid
        };
    }

    setWorkspaceElementStates(state: State, {
        organization,
        workspaceMainDataLoading
    }: AppActionPayloads.LoadWorkspaceData): State {
        const stateWithDataLoading = { ...state, workspaceMainDataLoading };
        if (!organization) {
            return stateWithDataLoading;
        }

        const { sections } = organization;
        const { highlightedBarrels, selectedTab } = stateWithDataLoading;

        if (!sections || !highlightedBarrels.length || selectedTab !== WorkspaceTabs.PROJECTS) {
            return stateWithDataLoading;
        }

        const highlightedBarrelSectionIds = (sections as (ProjectSection | OrganizationSection)[])
            .filter(
                section =>
                    section.name !== "default" &&
                    section.projects.some(project =>
                        highlightedBarrels.includes(project)
                    )
            )
            .map(section => section._id);

        // Expand highlighted sections and update loading state
        return {
            ...stateWithDataLoading,
            collapsedSections: {
                ...stateWithDataLoading.collapsedSections,
                ...Object.fromEntries(highlightedBarrelSectionIds.map(oseid => [oseid, false]))
            },
            workspaceMainDataLoading
        };
    }

    expandSections(state: State, {
        oseids
    }: Pick<Payloads.ExpandSections, "oseids">): State {
        return {
            ...state,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(oseids.map(oseid => [oseid, false]))
            }
        };
    }

    collapseSections(state: State, {
        oseids
    }: Payloads.CollapseSections): State {
        return {
            ...state,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(oseids.map(oseid => [oseid, true]))
            }
        };
    }

    openDialog(state: State, {
        dialogType,
        dialogParams
    }: Payloads.OpenDialog): State {
        return {
            ...state,
            visibleDialogType: dialogType,
            visibleDialogParams: dialogParams
        };
    }

    closeDialog(state: State): State {
        return {
            ...state,
            visibleDialogType: null,
            visibleDialogParams: {}
        };
    }

    closePreviewDialog(state: State, {
        bid
    }: BarrelActionPayloads.Archive | BarrelActionPayloads.Remove): State {
        const { previewBarrelId } = state.visibleDialogParams as PreviewDialogParams;

        if (previewBarrelId === bid) {
            return this.closeDialog(state);
        }

        return state;
    }

    resetSortTypeAndFilters(state: State): State {
        let newState = state;

        if (state.selectedProjectSortType !== SortTypes.SECTIONS) {
            newState = this.changeProjectSelectedSortType(newState, SortTypes.SECTIONS);
        }

        if (state.selectedWorkflowStatusFilter !== WorkflowStatus.All._id) {
            newState = this.changeWorkflowStatusFilter(newState, { workflowStatusFilter: WorkflowStatus.All._id });
        }

        return newState;
    }

    resetFilters(state: State): State {
        return {
            ...state,
            selectedWorkflowStatusFilter: WorkflowStatus.All._id,
            filterValue: ""
        };
    }

    updateForAddProjectSection(state: State, {
        sectionData
    }: OrganizationActionPayloads.AddProjectSection): State {
        const { newSection } = sectionData;
        if (newSection) {
            // newSection means this is a temporary section added by the user.
            // after user completes naming the section, a real section will be created.
            return this.resetSortTypeAndFilters(state);
        }

        return state;
    }

    updateForUpdateProjectOrder(state: State, {
        syncWithApi
    }: OrganizationActionPayloads.UpdateProjectOrder): State {
        if (syncWithApi) {
            return this.resetSortTypeAndFilters(state);
        }

        return state;
    }

    postponeOfferReminder(state: State, {
        nextDate
    }: Payloads.PostponeOfferReminder): State {
        return {
            ...state,
            offerReminderNextDate: nextDate
        };
    }

    clearProjectSelection(state: State): State {
        return {
            ...state,
            highlightedBarrels: []
        };
    }

    changeFilterValue(state: State, {
        filterValue
    }: Payloads.ChangeFilterValue): State {
        return {
            ...state,
            filterValue
        };
    }

    createSampleProjectRequest(state: State): State {
        return {
            ...state,
            createSampleProjectInProgress: true
        };
    }

    createSampleProjectFinalize(state: State): State {
        return {
            ...state,
            createSampleProjectInProgress: false
        };
    }

    changeProjectsScrolledState(state: State, projectsScrolledState: ProjectsScrolledState): State {
        return {
            ...state,
            projectsScrolledState
        };
    }

    resetScrolledProjectsOnce(state: State): State {
        return {
            ...state,
            projectsScrolledState: ProjectsScrolledState.Top
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case SpaceActionTypes.UPDATE_PAGE_STATE:
                return this.updatePageState(state, action);
            case SpaceActionTypes.CHANGE_PROJECT_SELECTED_SORT_TYPE:
                return this.changeProjectSelectedSortType(state, action.sortType);
            case SpaceActionTypes.CHANGE_FILTER_VALUE:
                return this.changeFilterValue(state, action);
            case SpaceActionTypes.TOGGLE_ARCHIVED_PROJECTS:
                return this.toggleArchivedProjects(state, action.showArchivedProjects);
            case SpaceActionTypes.CHANGE_WORKFLOW_STATUS_FILTER:
                return this.changeWorkflowStatusFilter(state, action);
            case SpaceActionTypes.RESET_FILTERS:
                return this.resetFilters(state);
            case SpaceActionTypes.UNHIGHLIGHT_BARREL:
                return this.unhighlightBarrel(state, action);
            case SpaceActionTypes.EXPAND_SECTIONS:
                return this.expandSections(state, action);
            case SpaceActionTypes.COLLAPSE_SECTIONS:
                return this.collapseSections(state, action);
            case SpaceActionTypes.OPEN_DIALOG:
                return this.openDialog(state, action);
            case SpaceActionTypes.CLOSE_DIALOG:
                return this.closeDialog(state);
            case SpaceActionTypes.HIGHLIGHT_SECTION:
                return this.highlightSection(state, action);
            case SpaceActionTypes.UNHIGHLIGHT_SECTION:
                return this.unhighlightSection(state);
            case SpaceActionTypes.SET_SELECTED_SECTION:
                return this.setSelectedSection(state, action);
            case SpaceActionTypes.POSTPONE_OFFER_REMINDER:
                return this.postponeOfferReminder(state, action);
            case SpaceActionTypes.CLEAR_PROJECT_SELECTION:
                return this.clearProjectSelection(state);
            case SpaceActionTypes.CHANGE_PROJECTS_SCROLLED_STATE:
                return this.changeProjectsScrolledState(state, action.projectsScrolledState);

            case OrganizationActionTypes.ADD_BARREL_TO_FOCUS_SUCCESS:
                return this.setPinnedBarrelOnce(state);
            case OrganizationActionTypes.REMOVE_BARREL_FROM_FOCUS:
                return this.updateForUnpinBarrel(state, action);
            case OrganizationActionTypes.DELETE_ORGANIZATION_WORKFLOW_STATUS:
                return this.changeWorkflowStatusFilterOnDelete(state, action);
            case OrganizationActionTypes.ADD_PROJECT_SECTION:
                return this.updateForAddProjectSection(state, action);
            case OrganizationActionTypes.UPDATE_PROJECT_ORDER:
                return this.updateForUpdateProjectOrder(state, action);

            case ProjectsActionTypes.CREATE_SAMPLE_PROJECT_REQUEST:
                return this.createSampleProjectRequest(state);
            case ProjectsActionTypes.CREATE_SAMPLE_PROJECT_FINALIZE:
                return this.createSampleProjectFinalize(state);

            case FigmaLibrarySyncActionTypes.IMPORT_STYLEGUIDE_LIBRARY:
                return this.closeDialog(state);

            case BarrelActionTypes.ADD: {
                const { shouldHighlight, barrel: { _id: bid } } = action;

                if (shouldHighlight) {
                    return this.highlightBarrel(state, { bid });
                }

                return state;
            }

            case BarrelActionTypes.ARCHIVE:
            case BarrelActionTypes.REMOVE:
                return this.closePreviewDialog(state, action);

            case AppActionTypes.LOAD_WORKSPACE_DATA:
                return this.setWorkspaceElementStates(state, action);
            case AppActionTypes.RESET:
                return this.reset();
            case AppActionTypes.SET_PAGE:
                return this.resetScrolledProjectsOnce(state);

            default:
                return state;
        }
    }
}

export default SpaceStore;
export { State as SpaceStoreState };
