enum ActionTypes {
    CREATE_DOMAIN = "accountAdmin/createDomain",
    ADD_DOMAIN = "accountAdmin/addDomain",
    CREATE_DOMAIN_FAILURE = "accountAdmin/createDomainFailure",
    UPDATE_DOMAIN_CONFIGURATION_SETTINGS = "accountAdmin/updateConfigurationSettings",
    DELETE_DOMAIN = "accountAdmin/deleteDomain",
    CREATE_ADMIN = "accountAdmin/createAdmin",
    ADD_ADMIN = "accountAdmin/addAdmin",
    REMOVE_ADMIN = "accountAdmin/removeAdmin"
}

export default ActionTypes;
