/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import AppActionTypes from "../app/AppActionTypes";
import SpaceActionTypes from "./SpaceActionTypes";
import * as SpaceActionPayloads from "./SpaceActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import { AllPayloads } from "../payloads";

interface State {
    selectedProjectUniqueIds: string[];
}

class ProjectSelectionStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            selectedProjectUniqueIds: []
        };
    }

    setSelectedProjects(state: State, {
        uniqueIds
    }: SpaceActionPayloads.SetSelectedProjects): State {
        return {
            ...state,
            selectedProjectUniqueIds: Array.from(new Set(uniqueIds))
        };
    }

    clearProjectSelection(state: State): State {
        return {
            ...state,
            selectedProjectUniqueIds: []
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case SpaceActionTypes.SET_SELECTED_PROJECTS:
                return this.setSelectedProjects(state, action);
            case SpaceActionTypes.CLEAR_PROJECT_SELECTION:
            case BarrelActionTypes.DELETE_WORKFLOW_STATUSES:
            case BarrelActionTypes.UPDATE_WORKFLOW_STATUSES:
            case AppActionTypes.SET_PAGE:
                return this.clearProjectSelection(state);
            default:
                return state;
        }
    }
}

export default ProjectSelectionStore;
export {
    State as ProjectSelectionStoreState
};
