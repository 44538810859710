export interface ApproverLabelInfo {
    approver: string;
    status: ApprovalStatus;
    approvalId: string;
}

export enum ApprovalStatus {
    PENDING, APPROVED, CHANGES_REQUESTED
}

export const ApprovalStatusLabel = {
    [ApprovalStatus.PENDING]: "pending review",
    [ApprovalStatus.APPROVED]: "approved",
    [ApprovalStatus.CHANGES_REQUESTED]: "pending review" // this should also be displayed as pending review
};

export const ApprovalStatusValue: Record<string, ApprovalStatus> = {
    pending: ApprovalStatus.PENDING,
    approved: ApprovalStatus.APPROVED,
    changes_requested: ApprovalStatus.CHANGES_REQUESTED // eslint-disable-line camelcase
};

export const ApprovalStatusText = {
    [ApprovalStatus.PENDING]: "pending",
    [ApprovalStatus.CHANGES_REQUESTED]: "changes_requested",
    [ApprovalStatus.APPROVED]: "approved"
};

export const ApprovalStatusInfoLabel = {
    [ApprovalStatus.PENDING]: "Pending Approvals",
    [ApprovalStatus.APPROVED]: "Approved",
    [ApprovalStatus.CHANGES_REQUESTED]: "Changes Requested"
};
