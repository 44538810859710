/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import SlackPreferencesActionTypes from "./SlackPreferencesActionTypes";
import * as Payloads from "./SlackPreferencesActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";

import * as slackPreferencesUtil from "./slackPreferencesUtil";
import { filterOut } from "../../../foundation/utils/legacy";
import { AllPayloads } from "../payloads";
import BarrelType from "../../../foundation/model/BarrelType";

// #region Helpers

type EventGroups = { // Added for readability, equals to BasicRecord<string[]>
    [eventGroupName: string]: string[];
};

function getEventsWithUpdatedGroup(
    eventGroups: EventGroups,
    eventGroupName: string,
    checked: boolean,
    barrelType: BarrelType
): EventGroups {
    return checked
        ? {
            ...eventGroups,
            [eventGroupName]: slackPreferencesUtil.getEventsOfGroup(barrelType, eventGroupName)
        }
        : filterOut(eventGroups, eventGroupName);
}

function getEventsWithUpdatedEvent(
    eventGroups: EventGroups,
    event: string,
    enabled: boolean,
    barrelType: BarrelType
): EventGroups {
    const eventGroupName = slackPreferencesUtil.getGroupOfEvent(barrelType, event);
    let eventGroup = eventGroups[eventGroupName] ?? [];

    if (enabled && !eventGroup.includes(event)) { // Add event to group
        return {
            ...eventGroups,
            [eventGroupName]: eventGroup.concat(event)
        };
    } else if (!enabled && eventGroup.includes(event)) {
        eventGroup = eventGroup.filter(current => current !== event);

        return eventGroup.length === 0
            // Remove the group completely because there are no events remaining
            ? filterOut(eventGroups, eventGroupName)

            // Remove event from group
            : {
                ...eventGroups,
                [eventGroupName]: eventGroup
            };
    }

    /*
     Already present or non-present event found due to asynchrony, return original groups.
     This is rare and not crucial and may happen when two users change preferences simultaneously.
    */
    return eventGroups;
}
// #endregion

interface State {
    open: boolean;
    inid: string | null;
    channelName: string | null;
    eventGroupsByInid: { // This type is the same as BasicRecord<BasicRecord<string[]>>
        [inid: string]: EventGroups;
    };
    loading: string[]; // string[] = Event group names
    barrelType: BarrelType | null;
}

class SlackPreferencesStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            open: false,
            inid: null,
            channelName: null,
            eventGroupsByInid: {}, // { inid0: { eventGroup0: [event0], eventGroup1:[event1, event2]}, inid1: ... }
            loading: [], // [ eventGroup0, eventGroup1, ... ]
            barrelType: null
        };
    }

    load(state: State, inid: string, events: string[], barrelType: BarrelType): State {
        return {
            ...state,
            barrelType,
            eventGroupsByInid: {
                ...state.eventGroupsByInid,
                [inid]: slackPreferencesUtil.groupEvents(barrelType, events)
            }
        };
    }

    open(state: State, inid: string, channelName: string): State {
        return inid in state.eventGroupsByInid ? {
            ...state,
            open: true,
            inid,
            channelName
        } : state;
    }

    authenticatedSlackIntegration(state: State, {
        integration,
        events,
        barrelType,
        openPreferences
    }: BarrelActionPayloads.AuthenticatedSlackIntegration): State {
        const loadedState = this.load(state, integration._id, events, barrelType);
        if (openPreferences) {
            return this.open(loadedState, integration._id, integration.incomingWebHook.channel);
        }
        return loadedState;
    }

    close(state: State): State {
        return {
            ...state,
            open: false,
            inid: null,
            channelName: null,
            loading: []
        };
    }

    updateEventGroup(state: State, {
        eventGroup,
        checked
    }: Payloads.UpdateEventGroup): State {
        return {
            ...state,
            eventGroupsByInid: {
                ...state.eventGroupsByInid,
                [state.inid!]: getEventsWithUpdatedGroup(
                    state.eventGroupsByInid[state.inid!], eventGroup, checked, state.barrelType!
                )
            },
            loading: state.loading.concat(eventGroup)
        };
    }

    eventGroupUpdated(state: State, {
        inid,
        eventGroup
    }: Payloads.EventGroupUpdated): State {
        if (state.inid === inid) {
            return {
                ...state,
                loading: state.loading.filter(current => current !== eventGroup)
            };
        }
        return state;
    }

    eventGroupUpdateFailed(state: State, {
        inid,
        eventGroup
    }: Payloads.EventGroupUpdateFailed): State {
        return {
            ...state,
            eventGroupsByInid: {
                ...state.eventGroupsByInid,
                [inid]: getEventsWithUpdatedGroup(
                    state.eventGroupsByInid[inid],
                    eventGroup,
                    !state.eventGroupsByInid[inid][eventGroup],
                    state.barrelType!
                )
            },
            loading: state.inid === inid ? state.loading.filter(current => current !== eventGroup) : state.loading
        };
    }

    eventUpdated(state: State, {
        inid,
        event,
        enabled
    }: Payloads.EventUpdated): State {
        return state.eventGroupsByInid[inid] ? {
            ...state,
            eventGroupsByInid: {
                ...state.eventGroupsByInid,
                [inid]: getEventsWithUpdatedEvent(state.eventGroupsByInid[inid], event, enabled, state.barrelType!)
            }
        } : state;
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case SlackPreferencesActionTypes.LOAD:
                return this.load(state, action.inid, action.events, action.barrelType);
            case SlackPreferencesActionTypes.OPEN:
                return this.open(state, action.inid, action.channelName);
            case SlackPreferencesActionTypes.CLOSE:
                return this.close(state);
            case SlackPreferencesActionTypes.UPDATE_EVENT_GROUP:
                return this.updateEventGroup(state, action);
            case SlackPreferencesActionTypes.EVENT_GROUP_UPDATED:
                return this.eventGroupUpdated(state, action);
            case SlackPreferencesActionTypes.EVENT_GROUP_UPDATE_FAILED:
                return this.eventGroupUpdateFailed(state, action);
            case SlackPreferencesActionTypes.EVENT_UPDATED:
                return this.eventUpdated(state, action);

            case BarrelActionTypes.AUTHENTICATED_SLACK_INTEGRATION:
                return this.authenticatedSlackIntegration(state, action);
            default:
                return state;
        }
    }
}

export default SlackPreferencesStore;
export {
    State as SlackPreferencesStoreState
};
