/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import DashboardActionTypes from "./DashboardActionTypes";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as DashboardActionPayloads from "./DashboardActionPayloads";
import { AllPayloads } from "../payloads";

import { shallowDiffer } from "../../../foundation/utils/diff";

interface State {
    selectedScreens: string[];
}

class ScreenSelectionStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            selectedScreens: []
        };
    }

    setSelectedScreens(state: State, {
        sids
    }: DashboardActionPayloads.SetSelectedScreens): State {
        if (!shallowDiffer(state.selectedScreens, sids)) {
            return state;
        }

        const selectedScreenSet = new Set(sids);

        return {
            ...state,
            selectedScreens: Array.from(selectedScreenSet)
        };
    }

    clearScreenSelection(state: State): State {
        if (!state.selectedScreens.length) {
            return state;
        }

        return {
            ...state,
            selectedScreens: []
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case DashboardActionTypes.SET_SELECTED_SCREENS:
                return this.setSelectedScreens(state, action);
            case DashboardActionTypes.CLEAR_SCREEN_SELECTION:
            case ScreenActionTypes.CLOSE_TAG_MANAGER:
            case BarrelActionTypes.RESET:
            case DashboardActionTypes.DELETE_SCREENS:
                return this.clearScreenSelection(state);
            default:
                return state;
        }
    }
}

export default ScreenSelectionStore;
export {
    State as ScreenSelectionStoreState
};
