/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { AllPayloads } from "../payloads";
import ElectronActionTypes from "./ElectronActionTypes";

interface State {
    fullScreen: boolean;
}

class ElectronStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            fullScreen: false
        };
    }

    setFullScreen(state: State, fullScreen: boolean): State {
        return {
            ...state,
            fullScreen
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ElectronActionTypes.SET_FULL_SCREEN:
                return this.setFullScreen(state, action.fullScreen);
            default:
                return state;
        }
    }
}

export default ElectronStore;
export { State as ElectronStoreState };
