enum ActionTypes {
    // #region Common Actions
    GET_PROJECTS_SUCCESS = "projects/getProjectsSuccess",
    REQUEST_PROJECT = "projects/requestProject",
    GET_PROJECT_REQUESTED = "projects/getProjectRequested",
    GET_PROJECT_SUCCESS = "projects/getProjectSuccess",
    GET_PROJECT_FAILED = "projects/getProjectFailed",
    UPDATE_PROJECT = "projects/updateProject",
    TRANSFER_OWNERSHIP = "projects/transferOwnerShip",
    // #endregion

    // #region Space Actions
    CREATE_PROJECT_REQUEST = "projects/createProjectRequest",
    CREATE_PROJECT_FINALIZE = "projects/createProjectFinalize",
    INIT_TRANSFER_OWNERSHIP = "projects/initTransferOwnerShip",
    INVITE_USER = "projects/inviteUser",
    CREATE_SAMPLE_PROJECT_REQUEST = "projects/createSampleProjectRequest",
    CREATE_SAMPLE_PROJECT_FINALIZE = "projects/createSampleProjectFinalize",
    // #endregion
}

export default ActionTypes;
