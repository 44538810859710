enum ActionTypes {
    GET_BARRELS = "organizaton/getBarrels",
    REQUEST_INVITE = "organization/requestInvite",
    ADD_ORGANIZATION = "organization/addOrganization",
    ADD_ORGANIZATION_WORKFLOW_STATUS = "organization/addOrganizationWorkflowStatus",
    UPDATE_ORGANIZATION = "organization/updateOrganization",
    UPDATE_ORGANIZATION_SUBSCRIPTION = "organization/updateOrganizationSubscription",
    UPDATE_ORGANIZATION_WORKFLOW_STATUS = "organization/updateOrganizationWorkflowStatus",
    UPDATE_ORGANIZATION_MEMBER_ROLE = "organization/updateOrganizationMemberRole",
    DOWNGRADE_ORGANIZATION_SUBSCRIPTION = "organization/downgradeOrganizationSubscription",
    REMOVE_ORGANIZATION = "organization/removeOrganization",
    DELETE_ORGANIZATION_WORKFLOW_STATUS = "organization/deleteOrganizationWorkflowStatus",
    GET_ORGANIZATION_SECTIONS_SUCCESS = "organization/getOrganizationSectionsSuccess",
    ORGANIZATION_MEMBERS_REQUESTED = "organization/organizationMembersRequested",
    GET_ORGANIZATION_MEMBERS_SUCCESS = "organization/getOrganizationMembersSuccess",
    GET_ORGANIZATION_MEMBERS_FAILED = "organization/getOrganizationMembersFailed",
    ADD_ORGANIZATION_MEMBER_PROFILE = "organization/addOrganizationMemberProfile",
    ADD_BARREL_TO_FOCUS = "organization/addBarrelToFocus",
    ADD_BARREL_TO_FOCUS_SUCCESS = "organization/addBarrelToFocusSuccess",
    REMOVE_BARREL_FROM_FOCUS = "organization/removeBarrelFromFocus",
    UNPIN_BARREL_FROM_FOCUS = "organization/unpinBarrelFromFocus",
    UNPIN_BARREL_FROM_FOCUS_SUCCESS = "organization/unpinBarrelFromFocusSuccess",
    REPIN_BARREL_TO_FOCUS = "organization/repinBarrelToFocus",
    HIT_PIN_LIMIT = "organization/hitPinLimit",
    REMOVE_UNJOINED_BARRELS = "organization/removeUnjoinedBarrels",
    UPDATE_MEMBER_TAGS = "organization/updateMemberTags",
    CREATE_PROJECT_SECTION = "organization/createProjectSection",
    ADD_PROJECT_SECTION = "organization/addProjectSection",
    UPDATE_PROJECT_SECTION = "organization/updateProjectSection",
    REMOVE_PROJECT_SECTION = "organization/removeProjectSection",
    UPDATE_PROJECT_SECTION_ORDER = "organization/updateProjectSectionOrder",
    UPDATE_PROJECT_ORDER = "organization/updateProjectOrder",
    ADD_SECTION_LINK = "organization/createSectionLink",
    UPDATE_SECTION_LINK = "organization/updateSectionLink",
    REMOVE_SECTION_LINK = "organization/removeSectionLink",
    ADD_MEMBERS = "organization/addMembers",
    REMOVE_MEMBER = "organization/removeMember",
    UPDATE_FREE_TRIAL_EXPIRATION_HINTBOX_STATE = "organization/updateFreeTrialExpirationHintboxState",
    UPDATE_FREE_TRIAL_ENDED_DIALOG_STATE = "organization/updateFreeTrialEndedDialogState",
    UPDATE_TRANSFER_PROJECT_TOOLTIP_STATE = "organization/updateTransferProjectTooltipState",
    UPDATE_PROJECT_LIMIT_TEYZE_STATE = "organization/updateProjectLimitTeyzeState"
}

export default ActionTypes;
