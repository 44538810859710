import Konva from "konva-extended";

export const MOBILE_WIDTH = 400;
export const MOBILE_HEIGHT = 880;
export const DESKTOP_WIDTH = 1440;
export const DESKTOP_HEIGHT = 880;
export const PADDING_HORIZONTAL = 18;
export const PADDING_VERTICAL = 36;
export const STROKE_WIDTH = 2;
export const FONT_SIZE = 24;
export const SIMPLIFIED_FLOW_ZOOM_LIMIT = 0.25;
export const BORDER_RADIUS = 16;
export const DASH_VALUES = [16, 24];
export const getScaledDashValue = (stage: Konva.Stage) => {
    const scale = stage.scaleX();

    if (scale >= 0.25) {
        return DASH_VALUES;
    }

    if (scale > 0.1) {
        return DASH_VALUES.map(v => v / (scale * 3));
    }

    if (scale > 0.06) {
        return DASH_VALUES.map(v => v / (scale * 4));
    }

    return DASH_VALUES.map(v => v / (scale * 8));
};
