import HelpLinkIDs from "./HelpLinkIDs";
import { MenuItemDescription } from "../../../../foundation/electron/MenuItem";

const quickStartMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.QUICK_START,
    label: "Quick Start",
    submenu: [{
        id: HelpLinkIDs.WHAT_IS_ZEPLIN,
        label: "What is Zeplin?"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_DESIGNERS,
        label: "Getting started with Zeplin for designers"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_DEVELOPERS,
        label: "Getting started with Zeplin for developers"
    }, {
        id: HelpLinkIDs.COLLABORATING_WITH_YOUR_TEAM,
        label: "Collaborating with your team"
    }, {
        id: HelpLinkIDs.DOWNLOADING_MAC_AND_WINDOWS,
        label: "Downloading Mac and Windows apps"
    }]
});

const accountAndProfileMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.ACCOUNT_AND_PROFILE,
    label: "Account && Profile",
    submenu: [{
        id: HelpLinkIDs.CREATING_AN_ACCOUNT,
        label: "Creating an account"
    }, {
        id: HelpLinkIDs.GOOGLE_SSO_WITH_ZEPLIN,
        label: "Google SSO with Zeplin"
    }, {
        id: HelpLinkIDs.RESEND_VERIFICATION_EMAIL,
        label: "Resend verification email"
    }, {
        id: HelpLinkIDs.UPDATING_EMAIL_AND_USERNAME,
        label: "Updating email and username"
    }, {
        id: HelpLinkIDs.SETTING_YOUR_PROFILE_PICTURE,
        label: "Setting your profile picture"
    }, {
        id: HelpLinkIDs.FORGOT_PASSWORD,
        label: "Forgot password"
    }]
});

const pricingAndPlansMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.PRICING_AND_PLANS,
    label: "Pricing && Plans",
    submenu: [{
        id: HelpLinkIDs.CHOOSING_SUBSCRIPTION_PLAN,
        label: "Choosing a subscription plan"
    }, {
        id: HelpLinkIDs.TEAM_PLAN,
        label: "Team plan"
    }, {
        id: HelpLinkIDs.FAQ_TEAM_PLAN,
        label: "FAQ about the Team plan subscription"
    }, {
        id: HelpLinkIDs.ORGANIZATION_PLAN,
        label: "Organization plan"
    }, {
        id: HelpLinkIDs.FAQ_ORGANIZATION_PLAN,
        label: "FAQ about the Organization plan subscription"
    }, {
        id: HelpLinkIDs.FAQ_STARTER_AND_GROWING,
        label: "FAQ for Started and Growing business plan subscribers"
    }, {
        id: HelpLinkIDs.PURCHASING_ZEPLIN_AS_RESELLER,
        label: "Purchasing Zeplin as a Reseller"
    }, {
        id: HelpLinkIDs.VENDOR_DETAILS,
        label: "Vendor details for Zeplin"
    }, {
        id: HelpLinkIDs.EDUCATION_NGO_OPEN_SOURCE,
        label: "Zeplin for Education, NGOs and Open Source community"
    }, {
        id: HelpLinkIDs.COMPONENT_LIMITS_BY_PLAN,
        label: "Component limits by plan"
    }, {
        id: HelpLinkIDs.VERSION_LIMITS_BY_PLAN,
        label: "Version limits by plan"
    }, {
        id: HelpLinkIDs.SCREEN_VARIANT_LIMITS_BY_PLAN,
        label: "Screen Variants limits by plan"
    }, {
        id: HelpLinkIDs.STYLEGUIDE_LIMITS_BY_PLAN,
        label: "Styleguide limits by plan"
    }]
});

const workspaceAndSubscriptionManagementMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.WORKSPACE_AND_SUBSCRIPTION_MANAGEMENT,
    label: "Workspace && Subscription Management",
    submenu: [{
        id: HelpLinkIDs.WORKSPACE_SETTINGS,
        label: "Workspace settings"
    }, {
        id: HelpLinkIDs.ACCESS_WORKSPACE_BILLING,
        label: "Accessing workspace profile and billing information"
    }, {
        id: HelpLinkIDs.ADDING_SEATS,
        label: "Adding seats to your workspace"
    }, {
        id: HelpLinkIDs.REMOVING_SEATS,
        label: "Removing seats from your workspace"
    }, {
        id: HelpLinkIDs.INVITING_MEMBERS,
        label: "Inviting members to your workspace"
    }, {
        id: HelpLinkIDs.REMOVING_MEMBERS,
        label: "Removing workspace members"
    }, {
        id: HelpLinkIDs.MANAGING_MEMBERS_GRACE_PERIOD,
        label: "Managing members in the grace period"
    }, {
        id: HelpLinkIDs.ASSIGNING_ROLES,
        label: "Assigning roles to workspace members"
    }, {
        id: HelpLinkIDs.MANAGING_ACCESS_LEVELS,
        label: "Managing access level of members"
    }, {
        id: HelpLinkIDs.GROUPING_MEMBERS_BY_TAGS,
        label: "Grouping workspace members by tags"
    }, {
        id: HelpLinkIDs.GENERATE_REPORT,
        label: "Generate a report of your workspace members"
    }, {
        id: HelpLinkIDs.TRANSFERRING_WORKSPACE,
        label: "Transferring a workspace"
    }, {
        id: HelpLinkIDs.LEAVING_WORKSPACE,
        label: "Leaving a workspace"
    }, {
        id: HelpLinkIDs.ASSIGNING_ROLES_ON_PLANS,
        label: "Assinging roles to project members on Free, Started and Growing Business plans"
    }, {
        id: HelpLinkIDs.BILLING_FAQ,
        label: "Billing FAQ"
    }, {
        id: HelpLinkIDs.UPDATING_INVOICE_AND_BILLING,
        label: "Updating invoice and billing information"
    }, {
        id: HelpLinkIDs.UPDATING_CREDIT_CARD,
        label: "Updating credit card"
    }, {
        id: HelpLinkIDs.DOWNLOADING_SENDING_INVOICES,
        label: "Downloading && sending invoices"
    }, {
        id: HelpLinkIDs.CHANGE_SUBSCRIPTION_BILLING_CYCLE,
        label: "Changing your subscription's billing cycle"
    }, {
        id: HelpLinkIDs.UPGRADE_SUBSCRIPTION,
        label: "Upgrading your subscription"
    }, {
        id: HelpLinkIDs.DOWNGRADE_SUBSCRIPTION,
        label: "Downgrading your subscription"
    }, {
        id: HelpLinkIDs.HOW_PRORATION_WORKS,
        label: "How proration works"
    }, {
        id: HelpLinkIDs.CANCELLING_SUBSCRIPTION,
        label: "Cancelling your subscription"
    }, {
        id: HelpLinkIDs.TRANSFERRING_DEPRECATED_PLAN,
        label: "Transferring a deprecated personal plan - Starter and Growing Business plans"
    }, {
        id: HelpLinkIDs.FIX_PAYMENT_ISSUES,
        label: "Fixing payment issues when your credit card fails"
    }]
});

const figmaIntegrationMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.FIGMA_INTEGRATION,
    label: "Figma Integration",
    submenu: [{
        id: HelpLinkIDs.GETTING_STARTED_FIGMA_PLUGIN,
        label: "Getting started with Figma plugin"
    }, {
        id: HelpLinkIDs.SET_UP_FIGMA_PLUGIN,
        label: "Setting up your Figma plugin"
    }, {
        id: HelpLinkIDs.PUBLISH_DESIGNS_FROM_FIGMA,
        label: "Publishing designs from Figma"
    }, {
        id: HelpLinkIDs.EXPORTING_ASSETS_FROM_FIGMA,
        label: "Exporting assets from Figma"
    }, {
        id: HelpLinkIDs.EXPORTING_COMPONENTS_FROM_FIGMA,
        label: "Exporting components from Figma"
    }, {
        id: HelpLinkIDs.EXPORTING_COLORS_FROM_FIGMA,
        label: "Exporting colors from Figma"
    }, {
        id: HelpLinkIDs.EXPORTING_TEXT_STYLES_FROM_FIGMA,
        label: "Exporting text styles from Figma"
    }, {
        id: HelpLinkIDs.REEXPORTING,
        label: "Re-exporting frames and components with one click from Figma into Zeplin"
    }, {
        id: HelpLinkIDs.FIGMA_PLUGIN_TROUBLESHOOT,
        label: "Figma Plugin Troubleshooting Guide"
    }]
});

const sketchIntegrationMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.SKETCH_INTEGRATION,
    label: "Sketch Integration",
    submenu: [{
        id: HelpLinkIDs.PUBLISH_DESIGNS_FROM_SKETCH,
        label: "Publish designs from Sketch"
    }, {
        id: HelpLinkIDs.EXPORTING_COMPONENTS_FROM_SKETCH,
        label: "Exporting components from Sketch"
    }, {
        id: HelpLinkIDs.EXPORTING_ASSETS_FROM_SKETCH,
        label: "Exporting assets from Sketch"
    }, {
        id: HelpLinkIDs.EXPORTING_ASSETS_FROM_SYMBOLS_IN_SKETCH,
        label: "Exporting assets from symbols in Sketch"
    }, {
        id: HelpLinkIDs.EXPORTING_COLORS_FROM_SKETCH,
        label: "Exporting colors from Sketch"
    }, {
        id: HelpLinkIDs.EXPORTING_TEXT_STYLES_FROM_SKETCH,
        label: "Exporting text styles from Sketch"
    }, {
        id: HelpLinkIDs.KEEPING_GROUPED_LAYER_NAMES,
        label: "Keeping grouped layer names"
    }, {
        id: HelpLinkIDs.IT_TAKES_TOO_LONG_SKETCH,
        label: "It takes too long to export artboards from Sketch!"
    }, {
        id: HelpLinkIDs.FIX_SKETCH_ISSUES,
        label: "Fixing Sketch export issues"
    }, {
        id: HelpLinkIDs.INSTALL_SKETCH_PLUGIN,
        label: "Installing the Sketch plugin"
    }]
});

const adobeXdIntegrationMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.ADOBE_XD_INTEGRATION,
    label: "Adobe XD Integration",
    submenu: [{
        id: HelpLinkIDs.GETTING_STARTED_XD_INTEGRATION,
        label: "Getting started with the new XD integration"
    }, {
        id: HelpLinkIDs.PUBLISH_DESINGS_FROM_XD,
        label: "Publishing designs from Adobe XD"
    }, {
        id: HelpLinkIDs.EXPORTING_ASSETS_FROM_XD,
        label: "Exporting assets from Adobe XD"
    }, {
        id: HelpLinkIDs.EXPORTING_COMPONENTS_FROM_XD,
        label: "Exporting components from Adobe XD"
    }, {
        id: HelpLinkIDs.EXPORTING_COLORS_FROM_XD,
        label: "Exporting colors from Adobe XD"
    }, {
        id: HelpLinkIDs.EXPORTING_TEXT_STYLES_FROM_XD,
        label: "Exporting text styles from Adobe XD"
    }, {
        id: HelpLinkIDs.LIMITATIONS_XD_PLUGIN,
        label: "Limitations of the new Adobe XD plugin"
    }, {
        id: HelpLinkIDs.FIX_XD_ISSUES,
        label: "Fixing Adobe XD export issues"
    }]
});

const photoshopIntegrationMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.PHOTOSHOP_INTEGRATION,
    label: "Photoshop Integration",
    submenu: [{
        id: HelpLinkIDs.PUBLISH_DESIGNS_FROM_PHOTOSHOP,
        label: "Publishing designs from Photoshop"
    }, {
        id: HelpLinkIDs.EXPORTING_ASSETS_FROM_PHOTOSHOP,
        label: "Exporting assets from Photoshop"
    }, {
        id: HelpLinkIDs.WORKING_WITH_ARTBOARDS_PHOTOSHOP,
        label: "Working with artboards in Photoshop"
    }, {
        id: HelpLinkIDs.KEEPING_GROUPED_TOGETHER_PHOTOSHOP,
        label: "Keeping groups together in Photoshop"
    }, {
        id: HelpLinkIDs.FIX_NO_USER_SLICES_ISSUE,
        label: "Fixing “No user slices“ issue"
    }, {
        id: HelpLinkIDs.SET_UP_PROXY_PHOTOSHOP_MAC,
        label: "Setting up proxy for Photoshop plugin on Mac"
    }, {
        id: HelpLinkIDs.SET_UP_PROXY_PHOTOSHOP_WINDOWS,
        label: "Setting up proxy for Photoshop plugin on Windows"
    }, {
        id: HelpLinkIDs.INSTALL_PHOTOSHOP_MAC,
        label: "Installing Photoshop plugin for Mac"
    }, {
        id: HelpLinkIDs.INSTALL_PHOTOSHOP_WINDOWS,
        label: "Installing Photoshop plugin for Windows"
    }]
});

const projectsAndScreensMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.PROJECTS_AND_SCREENS,
    label: "Projects && Screens",
    submenu: [{
        id: HelpLinkIDs.CREATING_PROJECT,
        label: "Creating a project"
    }, {
        id: HelpLinkIDs.CHOOSING_CORRECT_TYPE_AND_DENSITY,
        label: "Choosing the correct project type and density"
    }, {
        id: HelpLinkIDs.TRACK_PROGRESS_WITH_PROJECT_STATUS,
        label: "Track your progress with Project Status"
    }, {
        id: HelpLinkIDs.PIN_PROJECTS,
        label: "Pin projects in the workspace"
    }, {
        id: HelpLinkIDs.ORGANIZING_PROJECTS_WITH_SECTIONS,
        label: "Organizing projects with sections"
    }, {
        id: HelpLinkIDs.ADD_DESCRIPTION_STYLEGUIDE_LINKS_TO_SECTIONS,
        label: "Add a description, styleguide and links to sections"
    }, {
        id: HelpLinkIDs.ADD_USER_TO_PROJECT,
        label: "Adding a user to a project"
    }, {
        id: HelpLinkIDs.REMOVE_USER_FROM_PROJECT,
        label: "Removing a user from a project"
    }, {
        id: HelpLinkIDs.CHANGE_PROJECT_THUMBNAIL,
        label: "Changing the project thumbnail"
    }, {
        id: HelpLinkIDs.RENAME_PROJECT,
        label: "Renaming a project"
    }, {
        id: HelpLinkIDs.MAKING_COPY_OF_PROJECT,
        label: "Making a copy of a project"
    }, {
        id: HelpLinkIDs.TRANSFERRING_PROJECT,
        label: "Transferring a project"
    }, {
        id: HelpLinkIDs.ARCHIVING_PROJECT,
        label: "Archiving a project"
    }, {
        id: HelpLinkIDs.ACTIVATING_PROJECT,
        label: "Activation an archived project"
    }, {
        id: HelpLinkIDs.LEAVING_PROJECT,
        label: "Leaving a project"
    }, {
        id: HelpLinkIDs.DELETING_PROJECT,
        label: "Deleting a project"
    }, {
        id: HelpLinkIDs.UPDATING_SCREEN,
        label: "Updating a screen"
    }, {
        id: HelpLinkIDs.RENAMING_SCREEN,
        label: "Renaming a screen"
    }, {
        id: HelpLinkIDs.STORING_SCREEN_VERSIONS,
        label: "Storing screen versions"
    }, {
        id: HelpLinkIDs.IMPORTING_IMAGES_AS_SCREENS,
        label: "Importing images as screens"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_WITH_SCREEN_VARIANTS,
        label: "Getting started with Screen Variants"
    }, {
        id: HelpLinkIDs.MOVING_SCREENS_BETWEEN_PROJECTS,
        label: "Moving screens between projects"
    }, {
        id: HelpLinkIDs.ORGANIZING_SCREENS_WITH_SECTIONS,
        label: "Organizing screens with Sections"
    }, {
        id: HelpLinkIDs.USING_SECTIONS_AND_TAGS,
        label: "Using Sections and Tags to organize screens"
    }, {
        id: HelpLinkIDs.FILTERING_SCREENS_USING_TAGS,
        label: "Filtering screens using Tags"
    }, {
        id: HelpLinkIDs.DOWNLOADING_SCREENS_AS_IMAGES,
        label: "Downloading screens as images"
    }, {
        id: HelpLinkIDs.DELETING_SCREEN,
        label: "Deleting a screen"
    }]
});

const collaborationMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.COLLABORATION,
    label: "Collaboration",
    submenu: [{
        id: HelpLinkIDs.SHARINGS_DESIGNS_AND_STYLEGUDE_PUBLICLY,
        label: "Sharing desings and styleguide publicly"
    }, {
        id: HelpLinkIDs.SHARING_APP_URIS,
        label: "Sharing App URIs"
    }, {
        id: HelpLinkIDs.STAGE_MODE,
        label: "Stage Mode in Zeplin"
    }, {
        id: HelpLinkIDs.HOTSPOTS_IN_STAGE_MODE,
        label: "Hotspots in Stage Mode"
    }, {
        id: HelpLinkIDs.FLOW_LINKS_IN_STAGE_MODE,
        label: "Flow links in Stage Mode"
    }, {
        id: HelpLinkIDs.DESKTOP_AND_EMAIL_NOTIFICATIONS,
        label: "Staying up to date with desktop and email notifications"
    }, {
        id: HelpLinkIDs.NOTIFICATIONS,
        label: "Notifications in Zeplin"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_WITH_COMMENTS,
        label: "Getting started with Comments"
    }, {
        id: HelpLinkIDs.GROUPING_COMMENTS_BY_COLORS,
        label: "Grouping comments by colors"
    }, {
        id: HelpLinkIDs.VIEW_AND_FILTER_COMMENTS_BY_STATUS_AND_COLORS,
        label: "Viewing and filtering comments by status and colors"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_WITH_ANNOTATIONS,
        label: "Getting started with Annotations"
    }, {
        id: HelpLinkIDs.ADDING_ANNOTATIONS_TO_SCREEN,
        label: "Adding annotations to a screen"
    }, {
        id: HelpLinkIDs.LINKING_ANNOTATIONS_TO_COMPONENTS,
        label: "Linking annotations to components"
    }, {
        id: HelpLinkIDs.FILTERING_ANNOTATIONS_ON_SCREEN,
        label: "Filtering annotations on a screen"
    }, {
        id: HelpLinkIDs.DELETING_ANNOTATION,
        label: "Deleting an annotation"
    }]
});

const developingWithZeplinMeuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.DEVELOPING_WITH_ZEPLIN,
    label: "Developing with Zeplin",
    submenu: [{
        id: HelpLinkIDs.DEVELOPING_IOS_PROJECTS,
        label: "Developing iOS projects using Zeplin"
    }, {
        id: HelpLinkIDs.DEVELOPING_WEB_PROJECTS,
        label: "Developing Web projects using Zeplin"
    }, {
        id: HelpLinkIDs.DEVELOPING_ANDROID_PROJECTS,
        label: "Developing Android projects using Zeplin"
    }, {
        id: HelpLinkIDs.DOWNLOADING_ASSETS,
        label: "Downloading assets"
    }, {
        id: HelpLinkIDs.CHANGE_DOWNLOAD_PATH_WINDOWS,
        label: "Changing download path for assets on the Windows app"
    }, {
        id: HelpLinkIDs.AUTO_EXPORTED_ASSETS,
        label: "Auto-exported assets"
    }, {
        id: HelpLinkIDs.OPTIMIZED_ASSETS_IN_ZEPLIN,
        label: "Optimized Assets in Zeplin"
    }, {
        id: HelpLinkIDs.RENAMING_ASSETS,
        label: "Renaming assets"
    }, {
        id: HelpLinkIDs.EXPORT_DESIGN_TOKENS,
        label: "Export Design Tokens"
    }, {
        id: HelpLinkIDs.COMPARE_USING_POP_OUT,
        label: "Using the Pop Out feature"
    }, {
        id: HelpLinkIDs.WORKING_WITH_PERCENTAGE_VALUES,
        label: "Working with percentage values"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_WITH_LAYOUT_SPECS,
        label: "Getting stated with Layout Specs"
    }, {
        id: HelpLinkIDs.INSPECT_AUTO_LAYOUT_PROPERTIES,
        label: "Inpecting Auto Layout properties"
    }, {
        id: HelpLinkIDs.INSPECT_CONSTRAINTS,
        label: "Inspect Constraints in Zeplin"
    }, {
        id: HelpLinkIDs.ADDING_EXTENSION_TO_PROJECT,
        label: "Adding an extension to a project"
    }, {
        id: HelpLinkIDs.ADDING_EXTENSION_TO_STYLEGUIDE,
        label: "Adding an extension to a styleguide"
    }, {
        id: HelpLinkIDs.EXPORTING_CSS_AND_HTML,
        label: "Exporting CSS and HTML"
    }, {
        id: HelpLinkIDs.EXPORTING_REACT_NATIVE_SNIPPETS,
        label: "Exporting React Native code snippets"
    }, {
        id: HelpLinkIDs.BUILDING_AND_PUBLISHING_ZEPLIN_EXTENSION,
        label: "Building and publishing a Zeplin extension"
    }]
});

const globalStyleguideMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.GLOBAL_STYLEGUIDES,
    label: "Global Styleguides",
    submenu: [{
        id: HelpLinkIDs.ADVANTAGE_OF_STYLEGUIDES,
        label: "Advantages of using Styleguides"
    }, {
        id: HelpLinkIDs.CREATING_STYLEGUIDE,
        label: "Creating a styleguide"
    }, {
        id: HelpLinkIDs.CHOOSING_STYLEGUIDE_TYPE,
        label: "Choosing a styleguide type"
    }, {
        id: HelpLinkIDs.DEFINING_PARENT_AND_CHILD_STYLEGUIDES,
        label: "Defining parent and child styleguides"
    }, {
        id: HelpLinkIDs.STYLEGUIDE_THUMBNAIL,
        label: "Setting styleguide thumbnail"
    }, {
        id: HelpLinkIDs.LINK_PROJECT_TO_STYLEGUIDE,
        label: "Linking a project to a styleguide"
    }, {
        id: HelpLinkIDs.EXPORTING_TO_STYLEGUIDE,
        label: "Exporting to a styleguide"
    }, {
        id: HelpLinkIDs.CREATING_SPACING_SYSTEM,
        label: "Creating your spacing system"
    }, {
        id: HelpLinkIDs.ORGANIZE_COMPONENTS_USING_SECTIONS_AND_GROUPS,
        label: "Organizing components using Sections and Groups"
    }, {
        id: HelpLinkIDs.ORGANIZE_STYLEGUIDE_COLORS,
        label: "Organizing styleguide colors"
    }, {
        id: HelpLinkIDs.MODIFYING_STYLEGUIDE_TEXT_STYLES,
        label: "Modifying styleguide text styles"
    }, {
        id: HelpLinkIDs.ENABLE_REM_UNIT,
        label: "Enabling rem unit"
    }, {
        id: HelpLinkIDs.ADD_USER_TO_STYLEGUIDE,
        label: "Adding a user to a styleguide"
    }, {
        id: HelpLinkIDs.REMOVE_USER_FROM_STYLEGUIDE,
        label: "Removing a member from a styleguide"
    }, {
        id: HelpLinkIDs.MOVE_COLOR_TEXTSTYLES_BETWEEN_STYLEGUIDES,
        label: "Moving colors, text styles or components between styleguides"
    }, {
        id: HelpLinkIDs.EXPORT_SEMANTIC_COLORS,
        label: "Exporting semantic colors"
    }, {
        id: HelpLinkIDs.RENAME_STYLEGUIDE,
        label: "Renaming a styleguide"
    }, {
        id: HelpLinkIDs.MAKING_COPY_OF_STYLEGUIDE,
        label: "Making a copy of a styleguide"
    }, {
        id: HelpLinkIDs.TRANSFERRING_STYLEGUIDE,
        label: "Transferring a styleguide"
    }, {
        id: HelpLinkIDs.ARCHIVING_STYLEGUIDE,
        label: "Archiving a styleguide"
    }, {
        id: HelpLinkIDs.ACTIVATING_STYLEGUIDE,
        label: "Activating an archived styleguide"
    }, {
        id: HelpLinkIDs.DELETING_STYLEGUIDE,
        label: "Deleting a styleguide"
    }]
});

const componentsInZeplinMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.COMPONENTS_IN_ZEPLIN,
    label: "Component in Zeplin",
    submenu: [{
        id: HelpLinkIDs.INSPECTING_COMPONENT_IN_SCREEN,
        label: "Inspecting components in a screen"
    }, {
        id: HelpLinkIDs.DISCOVERING_REUSABLE_COMPONENTS_IN_SCREEN,
        label: "Discovering reusable components in a screen"
    }, {
        id: HelpLinkIDs.HIGHLIGHTING_COMPONENTS_IN_SCREENS,
        label: "Highlighting components in screens"
    }, {
        id: HelpLinkIDs.COMPONENT_VARIANTS,
        label: "Component Variants in Zeplin"
    }, {
        id: HelpLinkIDs.COMPONENT_VARIANTS_DOCUMENT,
        label: "Document when and how to use Component Variants"
    }, {
        id: HelpLinkIDs.SETTING_UP_COMPONENT_VARIANTS_FIGMA,
        label: "Setting up Component Variants from Figma"
    }, {
        id: HelpLinkIDs.SETTING_UP_COMPONENT_VARIANTS_SKETCH,
        label: "Setting up Component Variants from Sketch"
    }, {
        id: HelpLinkIDs.SETTING_UP_COMPONENT_VARIANTS_XD,
        label: "Setting up Component Variants from Adobe XD"
    }, {
        id: HelpLinkIDs.SETTING_UP_COMPONENT_VARIANTS_MANUALLY,
        label: "Setting up Component Variants from manually"
    }, {
        id: HelpLinkIDs.VIEWING_COMPONENT_VARIANTS_WITHIN_DESINGS,
        label: "Viewing Component Variants within designs"
    }, {
        id: HelpLinkIDs.CREATING_GROUPS_WITHIN_VARIANT_SECTIONS,
        label: "Creating groups within variant sections"
    }, {
        id: HelpLinkIDs.FILTERING_VARIANTS_BY_PROPERTIES,
        label: "Filtering variants by properties"
    }, {
        id: HelpLinkIDs.GENERATING_CSS_SNIPPETS_FROM_COMPONENT_VARIANTS,
        label: "Generating CSS snippets from Component Variants"
    }, {
        id: HelpLinkIDs.CONNECTING_COMPONENTS,
        label: "Connecting your components"
    }, {
        id: HelpLinkIDs.ADDING_STORYBOOK_LINKS_TO_COMPONENTS,
        label: "Adding Storybook links to your components"
    }, {
        id: HelpLinkIDs.ADDING_STYLEGUIDIST_LINKS_TO_COMPONENTS,
        label: "Adding Styleguidist links to your components"
    }, {
        id: HelpLinkIDs.ADDING_CUSTOM_LINKS_TO_COMPONENTS,
        label: "Adding custom links to your components"
    }, {
        id: HelpLinkIDs.ADDING_REPOSITORY_LINKS_TO_COMPONENTS,
        label: "Adding repository links to your components"
    }, {
        id: HelpLinkIDs.ENABLING_LOCAL_CONFIGURATION,
        label: "Enabling local configuration"
    }, {
        id: HelpLinkIDs.BUILDING_PLUGIN,
        label: "Building your own plugin"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_WITH_STORYBOOK_INTEGRATION,
        label: "Getting started with Storybook Integration"
    }, {
        id: HelpLinkIDs.CONNECTING_STORYBOOK_INSTANCE_WITH_ZEPLIN,
        label: "Connecting your Storybook Instance with Zeplin"
    }, {
        id: HelpLinkIDs.LINK_COMPONENTS_IN_ZEPLIN_WITH_STORIES,
        label: "Linking your components in Zeplin with stories in Storybook"
    }, {
        id: HelpLinkIDs.UNLINK_COMPONENTS_FROM_STORIES,
        label: "Unlinking components in Zeplin from stories"
    }, {
        id: HelpLinkIDs.REMOVE_STORYBOOK_INTEGRATION_FROM_PROJECTS_AND_STYLEGUIDES,
        label: "Removing a Storybook integration from projects/styleguides"
    }, {
        id: HelpLinkIDs.TROUBLESHOOTING_TIPS_FOR_STORYBOOK_INTEGRATION,
        label: "Troubleshooting tips for Storybook integration"
    }, {
        id: HelpLinkIDs.WHY_STORYBOOK_INTEGRATION_DOESNT_REQUIRE_CLI,
        label: "Why Zeplin's new Storybook integration doesn't require CLI"
    }]
});

const flowsMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.FLOWS,
    label: "Flows",
    submenu: [{
        id: HelpLinkIDs.GETTING_STARTED_WITH_FLOWS,
        label: "Getting started with Flows"
    }, {
        id: HelpLinkIDs.ADD_SCREENS_TO_FLOW,
        label: "Adding screens to a flow"
    }, {
        id: HelpLinkIDs.ADD_SCREEN_VARIANTS_TO_FLOW,
        label: "Adding screen variants to a flow"
    }, {
        id: HelpLinkIDs.ADD_MULTIPLE_COPIES_TO_FLOW,
        label: "Adding multiple copies of a screen to a flow"
    }, {
        id: HelpLinkIDs.REMOVE_SCREENS_FROM_FLOW,
        label: "Removing screens from a flow"
    }, {
        id: HelpLinkIDs.CONNECTING_SCREENS_WITHIN_FLOW,
        label: "Connecting screens within a flow"
    }, {
        id: HelpLinkIDs.DOCUMENTING_IN_A_FLOW_VIA_LABELS,
        label: "Documenting in a flow via labels"
    }, {
        id: HelpLinkIDs.ADD_COMMENTS_ON_FLOWS,
        label: "Adding comments on flows"
    }, {
        id: HelpLinkIDs.UPDATING_CONNECTOR_STYLES,
        label: "Updating connector styles"
    }, {
        id: HelpLinkIDs.CREATING_GROUPS_WITHIN_FLOW,
        label: "Creating groups within a flow"
    }, {
        id: HelpLinkIDs.CREATING_BOARDS_WITHIN_FLOWS,
        label: "Creating boards within Flows"
    }, {
        id: HelpLinkIDs.LAYING_OUT_SCREENS_WITHIN_FLOW,
        label: "Laying out screens within a flow"
    }, {
        id: HelpLinkIDs.NAVIGATING_BETWEEN_SCREENS_IN_FLOW,
        label: "Navigating between screens in a flow"
    }, {
        id: HelpLinkIDs.DOWNLOADING_FLOW_AS_PDF,
        label: "Downloading a flow as PDF"
    }]
});

const appsAndWorkflowIntegrationsMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.APPS_AND_WORKFLOW_INTEGRATIONS,
    label: "Apps && Workflow Integrations",
    submenu: [{
        id: HelpLinkIDs.WORKFLOW_INTEGRATION,
        label: "Workflow Integrations with Zeplin"
    }, {
        id: HelpLinkIDs.USING_THIRD_PARTY_APPS,
        label: "Using third-party apps"
    }, {
        id: HelpLinkIDs.THIRD_PARTY_APP_PERMISSIONS,
        label: "Third-party app permissions"
    }, {
        id: HelpLinkIDs.INSTALLING_ZEPLIN_FOR_JIRA_APP,
        label: "Installing the Zeplin for Jira app"
    }, {
        id: HelpLinkIDs.CONNECTIONG_JIRA_TO_ZEPLIN_ACCOUNT,
        label: "Connecting Jira to your Zeplin account"
    }, {
        id: HelpLinkIDs.ENABLING_ZEPLIN_FOR_SPECIFIC_JIRA_PROJECTS,
        label: "Enabling Zeplin for specific Jira projects"
    }, {
        id: HelpLinkIDs.ATTACHING_DESIGNS_TO_JIRA_ISSUES,
        label: "Attaching designs to Jira issues"
    }, {
        id: HelpLinkIDs.VIEWING_JIRA_ISSUES_IN_ZEPLIN,
        label: "Viewing Jira issues in Zeplin"
    }, {
        id: HelpLinkIDs.FILTERING_DESIGNS_BY_JIRA_ISSUES,
        label: "Filtering designs by Jira issues"
    }, {
        id: HelpLinkIDs.REMOVING_DESINGS_FROM_JIRA_ISSUES,
        label: "Removing designs from Jira issues"
    }, {
        id: HelpLinkIDs.SLACK_INTEGRATION,
        label: "Stay up-to-date on projects and styleguides with the Slack integration"
    }, {
        id: HelpLinkIDs.ADD_PROJECT_TO_SLACK,
        label: "Adding a project to Slack"
    }, {
        id: HelpLinkIDs.ADD_STYLEGUIDE_TO_SLACK,
        label: "Adding a styleguide to Slack"
    }, {
        id: HelpLinkIDs.SLACK_PREFERENCES,
        label: "Adjusting Slack preferences in a project or styleguide"
    }, {
        id: HelpLinkIDs.INSTALLING_ZEPLIN_FOR_MICROSOFT_TEAMS,
        label: "Installing Zeplin for Microsoft Teams"
    }, {
        id: HelpLinkIDs.CONNECTING_PROJECT_STYLGUIDE_TO_MICROSOFT_TEAMS,
        label: "Connecting a project or a styleguide to Microsoft Teams"
    }, {
        id: HelpLinkIDs.ADJUST_CHANNEL_NOTIFICATIONS,
        label: "Adjust channel notifications for connected projects or styleguides"
    }, {
        id: HelpLinkIDs.CREATING_FIRST_WEBHOOK,
        label: "Creating your first webhook"
    }, {
        id: HelpLinkIDs.ALLOWLISTING_WEBHOOK_REQUESTS,
        label: "Allowlisting webhook requests"
    }, {
        id: HelpLinkIDs.REMOVING_WEBHOOKS_CREATED_BY_ZEPLIN_APPS,
        label: "Removing webhooks created by Zeplin apps"
    }, {
        id: HelpLinkIDs.VISUAL_STUDIO_CODE_EXTENSION,
        label: "Zeplin Visual Studio Code Extension"
    }, {
        id: HelpLinkIDs.ENABLING_ZEPLIN_POWER_UP_ON_TRELLO,
        label: "Enabling the Zeplin Power-up on Trello"
    }]
});

const tipsAndTricksMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.TIPS_AND_TRICKS,
    label: "Tips && Tricks",
    submenu: [{
        id: HelpLinkIDs.GETTING_STARTED_WITH_GLOBAL_SEARCH,
        label: "Getting started with Global Search"
    }, {
        id: HelpLinkIDs.PUBLISHING_DESIGNS_IN_ORDER,
        label: "Publishing designs in order"
    }, {
        id: HelpLinkIDs.EXPORTING_DESIGNS_WITH_PROTOTYPING_LINKS,
        label: "Exporting designs with prototyping links to Zeplin"
    }, {
        id: HelpLinkIDs.USING_MARKDOWN_IN_COMMENTS_AND_SECTIONS,
        label: "Using Markdown in Comments and Sections for projects"
    }, {
        id: HelpLinkIDs.USING_MARKDOWN_IN_STYLEGUIDE_AND_SPACING_SYSTEM_DESCRIPTIONS,
        label: "Using Markdown in your stylguide and spacing system descriptions"
    }, {
        id: HelpLinkIDs.USE_QUICK_LOOK_TO_PREVIEW_THUMBNAILS,
        label: "Use Quick Look to preview thumbnails"
    }, {
        id: HelpLinkIDs.CHANGING_COLOR_FORMATS,
        label: "Changing color formats"
    }, {
        id: HelpLinkIDs.EMBED_SCREENS_ON_A_CONFLUENCE_PAGE_OR_JIRA_TICKET,
        label: "Embed your screens on a Confluence page or Jira ticket"
    }, {
        id: HelpLinkIDs.EMBED_ZEPLIN_DESIGNS_IN_NOTION,
        label: "Embed your Zeplin designs in Notion"
    }, {
        id: HelpLinkIDs.KEYBOARD_SHORTCUTS_WINDOWS_WEB,
        label: "Keyboard shortcuts for the Windows and Web app"
    }, {
        id: HelpLinkIDs.KEYBOARD_SHORTCUTS_MAC,
        label: "Keyboard shortcuts for the Mac app"
    }, {
        id: HelpLinkIDs.EXPORTING_GRID_LAYOUTS,
        label: "Exporting grid layouts from design tools"
    }]
});

const faqMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.FAQ,
    label: "FAQ",
    submenu: [{
        id: HelpLinkIDs.SUPPORTED_VERSIONS,
        label: "Supported versions"
    }, {
        id: HelpLinkIDs.USING_ZEPLIN_IN_LINUX,
        label: "Using Zeplin on Linux"
    }, {
        id: HelpLinkIDs.ENABLING_DARK_MODE,
        label: "Enabling Dark Mode"
    }, {
        id: HelpLinkIDs.CHANGING_PROJECT_TYPES,
        label: "Changing project types"
    }, {
        id: HelpLinkIDs.ALL_THE_MEASUREMENTS_ARE_WRONG,
        label: "All the measurements are wrong, half/twice the size!"
    }, {
        id: HelpLinkIDs.CHANGING_UNITS,
        label: "Changing units"
    }, {
        id: HelpLinkIDs.THIRD_PARTY_SOFTWARE_LICENCES,
        label: "Third-Party Software Licences"
    }, {
        id: HelpLinkIDs.EXPORTING_COMPONENTS_AS_SCREENS,
        label: "Exporting components as screens"
    }, {
        id: HelpLinkIDs.UNINSTALL_MAC_AND_WINDOWS,
        label: "Uninstalling Mac and Windows apps"
    }, {
        id: HelpLinkIDs.BUSINESS_CONTINUITY_PLAN_COVID_19,
        label: "Business Continuity plan for COVID-19"
    }]
});

const enterprisePlanMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.ENTERPRISE_PLAN,
    label: "Enterprise Plan",
    submenu: [{
        id: HelpLinkIDs.ENTERPRISE_SSO,
        label: "Enterprise SSO with Zeplin"
    }, {
        id: HelpLinkIDs.MULTIPLE_WORKSPACES_WITH_SSO_AND_SCIM,
        label: "Multiple workspaces with SSO and SCIM"
    }, {
        id: HelpLinkIDs.USER_PROVISIONING_WITH_SCIM,
        label: "User Provisioning with SCIM"
    }, {
        id: HelpLinkIDs.GENERIC_IDENTITY_PROVIDER_AND_SAML,
        label: "Generic Identity Provider and SAML 2.0"
    }, {
        id: HelpLinkIDs.OKTA_SAML_SCIM,
        label: "Okta, SAML 2.0 and SCIM"
    }, {
        id: HelpLinkIDs.AZURE_AD_SAML_SCIM,
        label: "Azure AD, SAML 2.0 and SCIM"
    }, {
        id: HelpLinkIDs.CENTRIFY_SAML,
        label: "Centrify(Idaptive) and SAML 2.0"
    }, {
        id: HelpLinkIDs.GSUITE_SAML,
        label: "GSuite and SAML 2.0"
    }, {
        id: HelpLinkIDs.IBM_CLOUD_IDENTITY_AND_SAML,
        label: "IBM Cloud Identity and SAML 2.0"
    }, {
        id: HelpLinkIDs.JUMPCLOUD_SAML_AND_SCIM,
        label: "JumpCloud, SAML 2.0 and SCIM"
    }, {
        id: HelpLinkIDs.KEYCLOAK_SAML,
        label: "Keycloak and SAML 2.0"
    }, {
        id: HelpLinkIDs.MICROSOFT_ADFS_SAML,
        label: "Microsoft ADFS and SAML 2.0"
    }, {
        id: HelpLinkIDs.NETIQ_SAML,
        label: "NetIQ and SAML 2.0"
    }, {
        id: HelpLinkIDs.ONELOGIN_SAML_SCIM,
        label: "OneLogin, SAML 2.0 and SCIM"
    }, {
        id: HelpLinkIDs.PING_IDENTITY_SAML,
        label: "Ping identity and SAML 2.0"
    }, {
        id: HelpLinkIDs.GETTING_STARTED_WITH_DOMAIN_CAPTURE,
        label: "Getting started with Domain Capture"
    }, {
        id: HelpLinkIDs.SETTING_UP_ACCOUNT_ADMIN,
        label: "Setting up an Account Admin"
    }, {
        id: HelpLinkIDs.SETTING_UP_NEW_USER_ACTIONS_FOR_DOMAINS,
        label: "Setting up new user actions for domains"
    }, {
        id: HelpLinkIDs.MANAGING_DOMAINS,
        label: "Managing domains"
    }]
});

const accessingZeplinMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.ACCESSING_ZEPLIN,
    label: "Accessing Zeplin",
    submenu: [{
        id: HelpLinkIDs.FIXING_YOUR_SESSION_EXPIRED,
        label: "Fixing “Your session has expired“ issue"
    }, {
        id: HelpLinkIDs.FIXING_CONNECTIVITY,
        label: "Fixing connectivity issues"
    }, {
        id: HelpLinkIDs.TROUBLESHOOTING_EMAIL_ISSUES,
        label: "Troubleshooting email issues"
    }, {
        id: HelpLinkIDs.FIXING_FAILED_TO_EXTRACT_INSTALLER,
        label: "Fixing “Failed to extract installer“ issue"
    }, {
        id: HelpLinkIDs.FIXING_REQUEST_FAILED_FORBIDDEN,
        label: "Fixing “Request failed: forbidden (403)“ issue"
    }, {
        id: HelpLinkIDs.FIXING_REQUEST_TIMEOUT,
        label: "Fixing “Request Timeout“ issue"
    }]
});

const privacyAndSecurityMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.PRIVACY_AND_SECURITY,
    label: "Privacy && Security",
    submenu: [{
        id: HelpLinkIDs.CODE_OF_CONDUCT_AND_ETHICS,
        label: "Zeplin's Code of Conduct and Ethics"
    }, {
        id: HelpLinkIDs.SECURITY_WHITEPAPER,
        label: "Security Whitepaper"
    }, {
        id: HelpLinkIDs.REGULATORY_COMPLIANCE,
        label: "Regulatory Compliance"
    }, {
        id: HelpLinkIDs.GDPR,
        label: "GDPR"
    }, {
        id: HelpLinkIDs.DATA_PROCESSING_ADDENDUM,
        label: "Data Processing Addendum or DPA"
    }, {
        id: HelpLinkIDs.TYPES_OF_PERSONAL_INFORMATION,
        label: "Types of personal information in Zeplin"
    }, {
        id: HelpLinkIDs.HOW_ZEPLIN_USES_PERSONAL_INFORMATION,
        label: "How Zeplin uses personal information"
    }, {
        id: HelpLinkIDs.OPT_OUT,
        label: "Opt-Out"
    }, {
        id: HelpLinkIDs.DELETING_YOUR_ACCOUNT,
        label: "Deleting your account"
    }, {
        id: HelpLinkIDs.LOG4J_VULNERABILITY,
        label: "Log4j Vulnerability (CVE-2021-44228)"
    }, {
        id: HelpLinkIDs.SECURITY_PENETRATION_TEST,
        label: "Zeplin Security Penetration Test"
    }, {
        id: HelpLinkIDs.RESPONSIBLE_DISCLOSURE,
        label: "Responsible Disclosure"
    }, {
        id: HelpLinkIDs.DATA_ENCRYPTION_AT_REST_AND_IN_TRANSIT,
        label: "Data encryption at rest and in transit"
    }]
});

const releaseNotesMenuItem = (): MenuItemDescription => ({
    id: HelpLinkIDs.RELEASE_NOTES,
    label: "Release Notes",
    submenu: [{
        id: HelpLinkIDs.ZEPLIN_HIGHLIGHTS,
        label: "Zeplin Highlights"
    }, {
        id: HelpLinkIDs.RELEASE_NOTES_MAC,
        label: "Release Notes: Zeplin for Mac"
    }, {
        id: HelpLinkIDs.RELEASE_NOTES_WEB_WINDOWS,
        label: "Release Notes: Zeplin for Web app && Windows app"
    }]
});

export default function helpSubmenuLinks(): MenuItemDescription[] {
    return [
        quickStartMenuItem(),
        accountAndProfileMenuItem(),
        pricingAndPlansMenuItem(),
        workspaceAndSubscriptionManagementMenuItem(),
        figmaIntegrationMenuItem(),
        sketchIntegrationMenuItem(),
        adobeXdIntegrationMenuItem(),
        photoshopIntegrationMenuItem(),
        projectsAndScreensMenuItem(),
        collaborationMenuItem(),
        developingWithZeplinMeuItem(),
        globalStyleguideMenuItem(),
        componentsInZeplinMenuItem(),
        flowsMenuItem(),
        appsAndWorkflowIntegrationsMenuItem(),
        tipsAndTricksMenuItem(),
        faqMenuItem(),
        enterprisePlanMenuItem(),
        accessingZeplinMenuItem(),
        privacyAndSecurityMenuItem(),
        releaseNotesMenuItem()
    ];
}
