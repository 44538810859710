import fluxRuntime from "../../../data/fluxRuntime";
import OrganizationHelpers from "../../../data/organization/OrganizationHelpers";
import DashboardHelpers from "../../../data/dashboard/DashboardHelpers";
import StyleguideHelpers from "../../../data/styleguide/StyleguideHelpers";
import ScreenHelpers from "../../../data/screen/ScreenHelpers";

import MenuItem, { MenuItemDescription } from "../../../../foundation/electron/MenuItem";
import { electronAppSupports } from "../../../../foundation/electron/electronAppSupports";
import { WorkspaceTabs, SortTypes, ProjectViewTypes } from "../../../../foundation/enums";
import { ThemePreference } from "../../../../foundation/theme";
import BarrelType from "../../../../foundation/model/BarrelType";
import { isMacOS } from "../../../../foundation/utils/ua-utils";

import MenuViewType from "./MenuViewType";
import MenuItemID from "./MenuItemID";
import separator from "./separator";

const zoomInMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.ZOOM_IN,
    label: "Zoom In",
    enabled,
    accelerator: "CmdOrCtrl+Plus"
});

const zoomOutMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.ZOOM_OUT,
    label: "Zoom Out",
    enabled,
    accelerator: "CmdOrCtrl+-"
});

const actualSizeMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.ACTUAL_SIZE,
    label: "Actual Size",
    enabled,
    accelerator: "CmdOrCtrl+0"
});

const zoomToFitMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.ZOOM_TO_FIT,
    label: "Zoom to Fit",
    enabled,
    accelerator: "CmdOrCtrl+1"
});

const zoomToSelectionMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.ZOOM_TO_SELECTION,
    label: "Zoom to Selection",
    enabled,
    accelerator: "CmdOrCtrl+2"
});

const popScreenOutMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.POP_SCREEN_OUT,
    label: "Pop Screen Out",
    enabled,
    accelerator: "CmdOrCtrl+O"
});

const workspaceMenuItem = (
    { menuViewType }: { menuViewType: MenuViewType; }
): MenuItemDescription => ({
    id: MenuItemID.WORKSPACE,
    label: "Workspace",
    type: "checkbox",
    enabled: menuViewType !== MenuViewType.LOGIN,
    checked: menuViewType === MenuViewType.WORKSPACE_PROJECTS || menuViewType === MenuViewType.WORKSPACE_STYLEGUIDES,
    accelerator: "CmdOrCtrl+Shift+A"
});

const dashboardMenuItem = (
    { menuViewType }: { menuViewType: MenuViewType; }
): MenuItemDescription => ({
    id: MenuItemID.DASHBOARD,
    label: "Dashboard",
    type: "checkbox",
    enabled: menuViewType !== MenuViewType.LOGIN ? isInsideProject(menuViewType) : false,
    checked: menuViewType === MenuViewType.PROJECT_DASHBOARD,
    accelerator: "CmdOrCtrl+Shift+D"
});

const flowMenuItem = (
    { menuViewType }: { menuViewType: MenuViewType; }
): MenuItemDescription => ({
    id: MenuItemID.FLOW,
    label: "Flow",
    type: "checkbox",
    enabled: menuViewType !== MenuViewType.LOGIN ? isInsideProject(menuViewType) : false,
    checked: menuViewType === MenuViewType.PROJECT_FLOWS,
    accelerator: "CmdOrCtrl+Shift+L"
});

const styleguideMenuItem = (
    { menuViewType }: { menuViewType: MenuViewType; }
): MenuItemDescription => ({
    id: MenuItemID.STYLEGUIDE,
    label: "Styleguide",
    type: "checkbox",
    enabled: menuViewType !== MenuViewType.LOGIN ? isInsideProject(menuViewType) : false,
    checked: menuViewType === MenuViewType.PROJECT_STYLEGUIDE,
    accelerator: "CmdOrCtrl+Shift+G"
});

const searchMenuItem = (enabled = true): MenuItemDescription => ({
    id: MenuItemID.SEARCH,
    label: "Search…",
    enabled,
    accelerator: "CmdOrCtrl+K"
});

const expandAllSectionsMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.EXPAND_ALL_SECTIONS,
    label: "Expand All Sections",
    enabled,
    accelerator: isMacOS() ? "CmdOrCtrl+Ctrl+Down" : "CmdOrCtrl+Alt+Down"
});

const collapseAllSectionsMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.COLLAPSE_ALL_SECTIONS,
    label: "Collapse All Sections",
    enabled,
    accelerator: isMacOS() ? "CmdOrCtrl+Ctrl+Up" : "CmdOrCtrl+Alt+Up"
});

const showOrHideVersionsMenuItem = (
    { enabled, versionsVisible }: { enabled: boolean; versionsVisible: boolean; }
): MenuItemDescription => ({
    id: MenuItemID.SHOW_OR_HIDE_VERSIONS,
    label: versionsVisible ? "Hide Versions" : "Show Versions",
    enabled,
    accelerator: "CmdOrCtrl+Alt+V"
});

const showScreenGrid = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.SHOW_SCREEN_GRID,
    label: "Show Screen Grid",
    enabled,
    accelerator: "CmdOrCtrl+Alt+G"
});

const clipScreenThumbnailsMenuItem = ({ enabled }: { enabled: boolean; }): MenuItemDescription => ({
    id: MenuItemID.CLIP_SCREEN_THUMBNAILS,
    label: "Clip Screen Thumbnails",
    enabled
});

const showBadgeMenuItem = ({ showBadge }: { showBadge: boolean; }): MenuItemDescription => ({
    id: MenuItemID.SHOW_BADGE,
    type: "checkbox",
    label: isMacOS() ? "Show Dock Badge" : "Show Badge",
    checked: showBadge
});

const resetAppZoomFactorMenuItem = (withResetAppZoomFactor: boolean): MenuItemDescription[] => {
    if (!withResetAppZoomFactor) {
        return [];
    }
    return [
        separator,
        {
            id: MenuItemID.RESET_APP_ZOOM_FACTOR,
            label: "Reset App Zoom Factor"
        }
    ];
};

const appearanceMenuItem = (themePreference?: ThemePreference): MenuItemDescription[] => {
    if (!electronAppSupports.designRefresh2024 || !themePreference) {
        return [];
    }

    return [{
        id: MenuItemID.APPEARANCE,
        label: "Appearance",
        submenu: [{
            id: MenuItemID.APPEARANCE_SYSTEM,
            type: "radio",
            label: "System",
            checked: themePreference === ThemePreference.System
        }, {
            id: MenuItemID.APPEARANCE_LIGHT,
            type: "radio",
            label: "Light",
            checked: themePreference === ThemePreference.Light
        }, {
            id: MenuItemID.APPEARANCE_DARK,
            type: "radio",
            label: "Dark",
            checked: themePreference === ThemePreference.Dark
        }]
    }];
};

const toggleFullScreenMenuItem = (): MenuItemDescription => ({
    id: MenuItemID.TOGGLE_FULL_SCREEN,
    label: "Toggle Full Screen",
    role: "toggleFullScreen"
});

const reloadMenuItem = (): MenuItemDescription => ({
    id: MenuItemID.RELOAD,
    label: "Reload",
    accelerator: "CmdOrCtrl+R"
});

const reloadIgnoringCacheMenuItem = (): MenuItemDescription => ({
    id: MenuItemID.RELOAD_IGNORING_CACHE,
    label: "Reload Ignoring Cache",
    accelerator: "CmdOrCtrl+Shift+R"
});

const toggleDevToolsMenuItem = (): MenuItemDescription => ({
    id: MenuItemID.TOGGLE_DEV_TOOLS,
    label: "Toggle Dev Tools",
    accelerator: "CmdOrCtrl+Shift+I"
});

const toggleDevToolsHostMenuItem = (): MenuItemDescription => ({
    id: MenuItemID.TOGGLE_DEV_TOOLS_HOST,
    label: "Toggle Dev Tools (Host)",
    role: "toggleDevTools"
});

function isInsideProject(menuviewType: MenuViewType): boolean {
    return (
        menuviewType === MenuViewType.PROJECT_DASHBOARD ||
        menuviewType === MenuViewType.PROJECT_FLOWS ||
        menuviewType === MenuViewType.PROJECT_STYLEGUIDE ||
        menuviewType === MenuViewType.SCREEN
    );
}

function shouldEnableExpandAndCollapse() {
    const { page } = fluxRuntime.AppStore.getState();
    const { selectedTab, selectedProjectSortType, selectedOrganizationId } = fluxRuntime.SpaceStore.getState();
    const { barrelType, selectedView } = fluxRuntime.BarrelStore.getState();
    const { loadingOrganization } = fluxRuntime.OrganizationStore.getState();

    if (loadingOrganization) {
        return {
            expandEnabled: false,
            collapseEnabled: false
        };
    }

    let numberOfSections = 0;
    let numberOfCollapsedSections = 0;

    if (page === "workspace" && selectedTab === WorkspaceTabs.PROJECTS) {
        if (selectedProjectSortType === SortTypes.SECTIONS) {
            numberOfSections = OrganizationHelpers.getCollapsableSectionIds(selectedOrganizationId).length;
            numberOfCollapsedSections = OrganizationHelpers.getCollapsedSectionIds(selectedOrganizationId).length;
        }
    } else if (page === "barrel" && barrelType === BarrelType.PROJECT && selectedView === ProjectViewTypes.DASHBOARD) {
        const { sortType } = fluxRuntime.DashboardStore.getState();
        if (sortType === SortTypes.SECTIONS) {
            numberOfSections = DashboardHelpers.getCollapsableSectionIds().length;
            numberOfCollapsedSections = DashboardHelpers.getCollapsedSectionIds().length;
        }
    } else if (page === "barrel" && barrelType === BarrelType.PROJECT && selectedView === ProjectViewTypes.STYLEGUIDE) {
        const { sortType, selectedStyleguideSection } = fluxRuntime.StyleguideStore.getState();
        if (sortType === SortTypes.SECTIONS && selectedStyleguideSection.toLowerCase() === "components") {
            numberOfSections = StyleguideHelpers.getCollapsableComponentSectionIds().length;
            numberOfCollapsedSections = StyleguideHelpers.getCollapsedSectionIds("component").length;
        } else if (selectedStyleguideSection.toLowerCase() === "colors") {
            numberOfSections = StyleguideHelpers.getCollapsableColorSectionIds().length;
            numberOfCollapsedSections = StyleguideHelpers.getCollapsedSectionIds("color").length;
        }
    } else if (page === "barrel" && barrelType === BarrelType.STYLEGUIDE) {
        const { sortType, selectedStyleguideSection } = fluxRuntime.StyleguideStore.getState();
        if (sortType === SortTypes.SECTIONS && selectedStyleguideSection.toLowerCase() === "components") {
            numberOfSections = StyleguideHelpers.getCollapsableComponentSectionIds().length;
            numberOfCollapsedSections = StyleguideHelpers.getCollapsedSectionIds("component").length;
        } else if (selectedStyleguideSection.toLowerCase() === "colors") {
            numberOfSections = StyleguideHelpers.getCollapsableColorSectionIds().length;
            numberOfCollapsedSections = StyleguideHelpers.getCollapsedSectionIds("color").length;
        }
    }

    return {
        expandEnabled: numberOfCollapsedSections > 0,
        collapseEnabled: numberOfSections !== 0 && numberOfSections !== numberOfCollapsedSections
    };
}

function shouldEnableScreenGrid(menuViewType: MenuViewType) {
    if (menuViewType !== MenuViewType.SCREEN) {
        return false;
    }

    const version = ScreenHelpers.getVersion();
    if (!version) {
        return false;
    }

    const { snapshot: { grid } } = version;
    return !!grid;
}

function enabledViewMenu(
    menuViewType: MenuViewType,
    showBadge: boolean,
    withResetAppZoomFactor: boolean,
    themePreference?: ThemePreference
): MenuItem {
    const { stageMode, versionsVisible, selectedLayer } = fluxRuntime.InspectableViewStore.getState();

    const isScreenView = menuViewType === MenuViewType.SCREEN && !stageMode;
    const zoomItemsEnabled = isScreenView || menuViewType === MenuViewType.PROJECT_FLOWS;
    const { expandEnabled, collapseEnabled } = shouldEnableExpandAndCollapse();

    return new MenuItem({
        id: MenuItemID.VIEW_SUBMENU,
        label: "View",
        submenu: [
            zoomInMenuItem({ enabled: zoomItemsEnabled }),
            zoomOutMenuItem({ enabled: zoomItemsEnabled }),
            separator,
            actualSizeMenuItem({ enabled: zoomItemsEnabled }),
            zoomToFitMenuItem({ enabled: isScreenView }),
            zoomToSelectionMenuItem({ enabled: !!selectedLayer }),
            separator,
            popScreenOutMenuItem({ enabled: electronAppSupports.popout && isScreenView }),
            separator,
            workspaceMenuItem({ menuViewType }),
            separator,
            dashboardMenuItem({ menuViewType }),
            flowMenuItem({ menuViewType }),
            styleguideMenuItem({ menuViewType }),
            separator,
            searchMenuItem(),
            separator,
            expandAllSectionsMenuItem({ enabled: expandEnabled }),
            collapseAllSectionsMenuItem({ enabled: collapseEnabled }),
            separator,
            showOrHideVersionsMenuItem({ enabled: isScreenView, versionsVisible }),
            showScreenGrid({ enabled: shouldEnableScreenGrid(menuViewType) }),
            // clipScreenThumbnailsMenuItem({ enabled: false }), // TODO: discuss this
            showBadgeMenuItem({ showBadge }),
            ...resetAppZoomFactorMenuItem(withResetAppZoomFactor),
            ...appearanceMenuItem(themePreference),
            separator,
            toggleFullScreenMenuItem(),
            separator,
            reloadMenuItem(),
            reloadIgnoringCacheMenuItem(),
            toggleDevToolsMenuItem(),
            toggleDevToolsHostMenuItem()
        ]
    });
}

function loginViewMenu(
    menuViewType: MenuViewType,
    showBadge: boolean,
    withResetAppZoomFactor: boolean
): MenuItem {
    return new MenuItem({
        id: MenuItemID.VIEW_SUBMENU,
        label: "View",
        submenu: [
            zoomInMenuItem({ enabled: false }),
            zoomOutMenuItem({ enabled: false }),
            separator,
            actualSizeMenuItem({ enabled: false }),
            zoomToFitMenuItem({ enabled: false }),
            zoomToSelectionMenuItem({ enabled: false }), // TODO: decide if we should be implement this
            separator,
            popScreenOutMenuItem({ enabled: false }),
            separator,
            workspaceMenuItem({ menuViewType }),
            separator,
            dashboardMenuItem({ menuViewType }),
            flowMenuItem({ menuViewType }),
            styleguideMenuItem({ menuViewType }),
            separator,
            searchMenuItem(false),
            separator,
            expandAllSectionsMenuItem({ enabled: false }),
            collapseAllSectionsMenuItem({ enabled: false }),
            separator,
            showOrHideVersionsMenuItem({ enabled: false, versionsVisible: false }),
            showScreenGrid({ enabled: false }),
            clipScreenThumbnailsMenuItem({ enabled: false }), // TODO: discuss this
            showBadgeMenuItem({ showBadge }),
            ...resetAppZoomFactorMenuItem(withResetAppZoomFactor),
            separator,
            toggleFullScreenMenuItem(),
            separator,
            reloadMenuItem(),
            reloadIgnoringCacheMenuItem(),
            toggleDevToolsMenuItem(),
            toggleDevToolsHostMenuItem()
        ]
    });
}

function disabledViewMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.VIEW_SUBMENU,
        label: "View",
        enabled: false
    });
}

export default function viewSubmenu(menuViewType: MenuViewType | null, {
    showBadge, withResetAppZoomFactor, themePreference
}: {
    showBadge: boolean;
    withResetAppZoomFactor: boolean;
    themePreference?: ThemePreference;
}): MenuItem {
    switch (menuViewType) {
        case MenuViewType.WORKSPACE_PROJECTS:
        case MenuViewType.WORKSPACE_STYLEGUIDES:
        case MenuViewType.ACTIVITIES:
        case MenuViewType.PROJECT_DASHBOARD:
        case MenuViewType.PROJECT_FLOWS:
        case MenuViewType.PROJECT_STYLEGUIDE:
        case MenuViewType.STYLEGUIDE:
        case MenuViewType.SCREEN:
            return enabledViewMenu(menuViewType, showBadge, withResetAppZoomFactor, themePreference);
        case MenuViewType.LOGIN:
            return loginViewMenu(menuViewType, showBadge, withResetAppZoomFactor);
        default:
            return disabledViewMenu();
    }
}
