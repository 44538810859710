enum MenuViewType {
    WORKSPACE_PROJECTS = "workspaceProjects",
    WORKSPACE_STYLEGUIDES = "workspaceStyleguides",
    ACTIVITIES = "activities",
    PROJECT_DASHBOARD = "projectDashboard",
    PROJECT_FLOWS = "projectFlows",
    PROJECT_STYLEGUIDE = "projectStyleguide",
    STYLEGUIDE = "styleguide",
    SCREEN = "screen",
    LOGIN = "login"
}

export default MenuViewType;
