/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import BarrelActionTypes from "../barrel/BarrelActionTypes";
import ExtensionsActionTypes from "./ExtensionsActionTypes";
import * as Payloads from "./ExtensionsActionPayloads";
import { WebappExtension } from "../../../foundation/extensions";
import { AllPayloads } from "../payloads";

interface State {
    loading: boolean;
    dialogOpen?: boolean;
    extensionId?: string | null;
    extensions: WebappExtension[];
    enabledExtensions: string[];
}

class ExtensionsStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            loading: true,
            dialogOpen: false,
            extensionId: null,
            extensions: [],
            enabledExtensions: []
        };
    }

    reset(state: State): State {
        state.extensions.forEach(extension => {
            extension.terminate();
        });

        return this.getInitialState();
    }

    loadExtensions(state: State, {
        bid,
        currentBid,
        extensions,
        enabledExtensions
    }: Payloads.LoadExtensions): State {
        if (currentBid !== bid) {
            return state;
        }
        state.extensions.forEach(extension => {
            extension.terminate();
        });

        return {
            loading: false,
            enabledExtensions,
            extensions
        };
    }

    reload(state: State): State {
        const localExtensions = state.extensions.filter(e => e.local);

        if (!localExtensions.length) {
            return state;
        }

        localExtensions.forEach(extension => {
            // eslint-disable-next-line no-console
            console.log(`Local extension "${extension.name}" reloaded`);
            extension.reload();
        });

        return { ...state };
    }

    openSettings(state: State, {
        extensionId
    }: Pick<Payloads.OpenSettings, "extensionId">): State {
        return {
            ...state,
            dialogOpen: true,
            extensionId
        };
    }

    closeSettings(state: State): State {
        return {
            ...state,
            dialogOpen: false,
            extensionId: null
        };
    }

    add(state: State, {
        bid,
        extension,
        local,
        openSettings,
        currentBid
    }: Payloads.Add): State {
        const { extensions, enabledExtensions } = state;
        const found = extensions.find(e => extension.id === e.id);

        let newState = state;
        if (openSettings) {
            newState = this.openSettings(newState, { extensionId: extension.id });
        }

        if (found) {
            if (local) {
                found.reload();
            }

            return this.enable(newState, { eid: found.id });
        }

        if (bid !== currentBid) {
            return newState;
        }

        return {
            ...newState,
            enabledExtensions: enabledExtensions.concat(extension.id),
            extensions: extensions.concat(extension)
        };
    }

    enable(state: State, {
        eid
    }: Pick<Payloads.Enable, "eid">): State {
        const { enabledExtensions } = state;

        return {
            ...state,
            enabledExtensions: enabledExtensions.concat(eid)
        };
    }

    updateOption(state: State, {
        eid,
        name,
        value
    }: Payloads.UpdateOption): State {
        const extensions = state.extensions.map(ext => {
            if (ext.id !== eid) {
                return ext;
            }

            const extension = new WebappExtension({ ...ext });
            extension.setOption(name, value);
            return extension;
        });

        return {
            ...state,
            extensions
        };
    }

    disable(state: State, {
        eid
    }: Payloads.Disable): State {
        const { enabledExtensions } = state;

        return {
            ...state,
            enabledExtensions: enabledExtensions.filter(extId => extId !== eid)
        };
    }

    remove(state: State, {
        eid
    }: Payloads.Remove): State {
        const { extensions, enabledExtensions } = state;

        return {
            ...state,
            enabledExtensions: enabledExtensions.filter(extId => extId !== eid),
            extensions: extensions.filter(ext => ext.id !== eid)
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.RESET:
                return this.reset(state);

            case ExtensionsActionTypes.LOAD_EXTENSIONS:
                return this.loadExtensions(state, action);
            case ExtensionsActionTypes.RELOAD:
                return this.reload(state);
            case ExtensionsActionTypes.OPEN_SETTINGS:
                return this.openSettings(state, action);
            case ExtensionsActionTypes.CLOSE_SETTINGS:
                return this.closeSettings(state);
            case ExtensionsActionTypes.ADD:
                return this.add(state, action);
            case ExtensionsActionTypes.ENABLE:
                return this.enable(state, action);
            case ExtensionsActionTypes.UPDATE_OPTION:
                return this.updateOption(state, action);
            case ExtensionsActionTypes.DISABLE:
                return this.disable(state, action);
            case ExtensionsActionTypes.REMOVE:
                return this.remove(state, action);
            default:
                return state;
        }
    }
}

export default ExtensionsStore;
export {
    State as ExtensionsStoreState
};
