/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import CommentStatusFilter from "../../../foundation/model/RemarkStatusFilters";
import RemarkType from "../../../foundation/model/RemarkType";
import { isDraftDot, isDraftAnnotation } from "../../../foundation/utils/dot";
import { PartializeProps } from "../../../foundation/utils/UtilityTypes";
import ColorName from "../../../library/ColorDot/ColorName";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import ComponentActionTypes from "../component/ComponentActionTypes";
import InspectableViewActionTypes from "../inspectableView/InspectableViewActionTypes";

import ProjectActionTypes from "../project/ProjectActionTypes";
import { AllPayloads } from "../payloads";

import * as ScreenActionPayloads from "./ScreenActionPayloads";
import ScreenActionTypes from "./ScreenActionTypes";

type State = {
    loadingDots: boolean;
    loadingProjectDots: boolean;
    statusFilter: CommentStatusFilter;
    colorFilter: ColorName | null;
    lastNoteColorUsed: string;
    flash: boolean;
    dotId: string | null;
    highlightedDots: string[];
    highlightedComments: string[];
    savedNotes: {
        [sid: string]: {
            [dotId: string]: string;
        };
    };
};

class DotsStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            loadingDots: true,
            loadingProjectDots: true,
            statusFilter: CommentStatusFilter.Open,
            colorFilter: null,
            lastNoteColorUsed: "yellow",
            flash: false,
            dotId: null,
            highlightedDots: [],
            highlightedComments: [],
            savedNotes: {}
        };
    }

    reset(state: State): State {
        const { savedNotes } = state;

        return {
            ...this.getInitialState(),
            savedNotes
        };
    }

    load(state: State, {
        dots: update = {}
    }: PartializeProps<BarrelActionPayloads.Load, "dots">): State {
        const { savedNotes } = state;

        return {
            ...state,
            ...update,
            savedNotes
        };
    }

    setDots(state: State, {
        dotId,
        statusFilter,
        colorFilter
    }: ScreenActionPayloads.SetDots): State {
        return {
            ...state,
            loadingDots: false,
            dotId,
            statusFilter,
            colorFilter
        };
    }

    setDotsForIntermediateLoading(state: State, {
        dotsDetails: {
            dotId
        },
        openScreenData: {
            commentId
        },
        barrelData: {
            dots: update
        }
    }: BarrelActionPayloads.LoadIntermediate): State {
        if (state.dotId === dotId) {
            return {
                ...state,
                ...update,
                loadingDots: false,
                dotId
            };
        }

        let highlightedComments: string[] = [];
        if (Array.isArray(commentId)) {
            highlightedComments = commentId;
        } else if (commentId) {
            highlightedComments = [commentId];
        }

        return {
            ...state,
            ...update,
            loadingDots: false,
            dotId,
            highlightedComments,
            highlightedDots: state.highlightedDots.filter(id => id !== dotId)
        };
    }

    createNote(state: State, {
        dot: {
            _id: dotId
        }
    }: ScreenActionPayloads.CreateNote): State {
        return {
            ...state,
            dotId,
            statusFilter: CommentStatusFilter.Open,
            colorFilter: null
        };
    }

    updateDot(state: State, {
        did: oldDid,
        dotData: {
            _id: newDid
        }
    }: ScreenActionPayloads.UpdateDot): State {
        if (state.dotId !== oldDid && oldDid === newDid) {
            return state;
        }

        return {
            ...state,
            dotId: newDid
        };
    }

    updateDotStatus(state: State, {
        did: dotId,
        status,
        statusFilter: newStatusFilter
    }: ScreenActionPayloads.UpdateDotStatus): State {
        const statusFilter = newStatusFilter || state.statusFilter;

        if (state.dotId === dotId && statusFilter as string !== status) {
            // If status of currently opened dot is being changed
            // and status filter (current or next) doesn't match with current status filter
            // unselect the dot
            return {
                ...state,
                statusFilter,
                dotId: null
            };
        }

        if (newStatusFilter) {
            return {
                ...state,
                statusFilter
            };
        }

        return state;
    }

    updateLastNoteColorUsed(state: State, {
        colorName: lastNoteColorUsed
    }: ScreenActionPayloads.UpdateDotColor): State {
        return {
            ...state,
            lastNoteColorUsed
        };
    }

    saveNote(state: State, sid: string, did: string, note: string): State {
        const { savedNotes } = state;
        const dotId = isDraftDot(did) ? "newDot" : did;

        return {
            ...state,
            savedNotes: {
                ...savedNotes,
                [sid]: {
                    ...savedNotes[sid],
                    [dotId]: note
                }
            }
        };
    }

    removeDot(state: State, {
        sid,
        did,
        note
    }: ScreenActionPayloads.RemoveDot): State {
        let { savedNotes } = state;
        if (isDraftDot(did)) {
            savedNotes = {
                ...savedNotes,
                [sid]: {
                    ...savedNotes[sid],
                    newDot: note!
                }
            };
        }

        if (state.dotId !== did) {
            if (!isDraftDot(did)) {
                return state;
            }

            return {
                ...state,
                savedNotes
            };
        }

        return {
            ...state,
            savedNotes,
            dotId: null
        };
    }

    changeDotFilter(state: State, {
        status: statusFilter,
        color: colorFilter,
        dotId
    }: ScreenActionPayloads.ChangeDotFilter): State {
        return {
            ...state,
            colorFilter,
            statusFilter,
            dotId
        };
    }

    flash(state: State, {
        flash
    }: ScreenActionPayloads.EnableNoteMode): State {
        const dotId = flash ? null : state.dotId; // deselect dot to close popup

        return {
            ...state,
            flash,
            dotId
        };
    }

    dotsFlashed(state: State): State {
        return {
            ...state,
            flash: false
        };
    }

    selectDot(state: State, {
        dotId,
        commentId
    }: ScreenActionPayloads.SelectDot | ScreenActionPayloads.OpenScreen): State {
        if (state.dotId === dotId) {
            return state;
        }

        let highlightedComments: string[] = [];
        if (Array.isArray(commentId)) {
            highlightedComments = commentId;
        } else if (commentId) {
            highlightedComments = [commentId];
        }

        return {
            ...state,
            dotId,
            highlightedComments,
            highlightedDots: state.highlightedDots.filter(id => id !== dotId)
        };
    }

    deselectDot(state: State): State {
        return {
            ...state,
            dotId: null
        };
    }

    unhighlightComment(state: State, {
        cmid
    }: ScreenActionPayloads.UnhighlightComment): State {
        return {
            ...state,
            highlightedComments: state.highlightedComments.filter(id => id !== cmid)
        };
    }

    updateDotPopupRemarkType(state: State, {
        remark,
        did,
        remarkType,
        sid,
        textContent
    }: ScreenActionPayloads.UpdateDotPopupRemarkType): State {
        if (remarkType === RemarkType.Dot) {
            const { savedNotes } = state;
            return {
                ...state,
                dotId: remark!._id,
                savedNotes: {
                    ...savedNotes,
                    [sid]: {
                        ...savedNotes[sid],
                        [remark!._id]: textContent ?? ""
                    }
                },
                statusFilter: CommentStatusFilter.Open,
                colorFilter: null
            };
        }

        if (state.dotId === did) {
            return {
                ...state,
                dotId: null
            };
        }

        return state;
    }

    handleAddAnnotation(state: State, { sid, annotation: { _id: atid } }: ScreenActionPayloads.AddAnnotation): State {
        if (!isDraftAnnotation(atid)) {
            return state;
        }

        const { savedNotes } = state;
        return {
            ...state,
            savedNotes: {
                ...savedNotes,
                [sid]: {
                    ...savedNotes[sid],
                    newDot: ""
                }
            }
        };
    }

    handleChangeSelectedView(state: State): State {
        const { savedNotes, lastNoteColorUsed, loadingProjectDots } = state;

        return {
            ...this.getInitialState(),
            savedNotes,
            lastNoteColorUsed,
            loadingProjectDots
        };
    }

    handleOpenComponent(): State {
        return {
            ...this.getInitialState(),
            loadingDots: false
        };
    }

    getProjectDots(state: State): State {
        return {
            ...state,
            loadingProjectDots: true
        };
    }

    setProjectDots(state: State): State {
        return {
            ...state,
            loadingProjectDots: false
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            // Barrel action reducers
            case BarrelActionTypes.RESET:
                return this.reset(state);
            case BarrelActionTypes.CHANGE_SELECTED_VIEW:
                return this.handleChangeSelectedView(state);
            case BarrelActionTypes.LOAD:
                return this.load(state, action);
            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.setDotsForIntermediateLoading(state, action);

            // Screen action reducers
            case ScreenActionTypes.SET_DOTS:
                return this.setDots(state, action);

            case ScreenActionTypes.CREATE_NOTE:
                return this.createNote(state, action);

            case ScreenActionTypes.UPDATE_DOT:
                return this.updateDot(state, action);

            case ScreenActionTypes.UPDATE_DOT_STATUS:
                return this.updateDotStatus(state, action);

            case ScreenActionTypes.UPDATE_DOT_COLOR:
                return this.updateLastNoteColorUsed(state, action);

            case ScreenActionTypes.SAVE_NOTE:
                return this.saveNote(state, action.sid, action.did, action.note);
            case ScreenActionTypes.ADD_DOT:
                return this.saveNote(state, action.sid, action.dot._id, "");
            case ScreenActionTypes.CREATE_COMMENT:
                return this.saveNote(state, action.sid, action.did, "");

            case ScreenActionTypes.REMOVE_DOT:
                return this.removeDot(state, action);

            case ScreenActionTypes.CHANGE_DOT_FILTER:
                return this.changeDotFilter(state, action);

            case ScreenActionTypes.ENABLE_NOTE_MODE:
                return this.flash(state, action);

            case ScreenActionTypes.DOTS_FLASHED:
                return this.dotsFlashed(state);

            case ScreenActionTypes.OPEN_SCREEN:
            case ScreenActionTypes.SELECT_DOT:
                return this.selectDot(state, action);

            case InspectableViewActionTypes.SELECT_LAYER:
            case InspectableViewActionTypes.DESELECT_LAYER:
            case ScreenActionTypes.CLOSE_DOT_POPUP:
            case ScreenActionTypes.OPEN_DOT_MENU:
            case ScreenActionTypes.SHOW_FLOW_LINKS:
            case ScreenActionTypes.SELECT_ANNOTATION:
            case ScreenActionTypes.CREATE_ANNOTATION:
            case ScreenActionTypes.ENTER_STAGE_MODE:
            case ScreenActionTypes.CLOSE_STAGE_MODE_SIDEBAR:
                return this.deselectDot(state);

            case ScreenActionTypes.UNHIGHLIGHT_COMMENT:
                return this.unhighlightComment(state, action);

            case ScreenActionTypes.UPDATE_DOTPOPUP_REMARK_TYPE:
                return this.updateDotPopupRemarkType(state, action);

            case ScreenActionTypes.ADD_ANNOTATION:
                return this.handleAddAnnotation(state, action);

            case ComponentActionTypes.OPEN_COMPONENT:
                return this.handleOpenComponent();

            // Project action reducers
            case ProjectActionTypes.GET_PROJECT_DOTS:
                return this.getProjectDots(state);
            case ProjectActionTypes.SET_PROJECT_DOTS:
                return this.setProjectDots(state);

            default:
                return state;
        }
    }
}

export default DotsStore;
export { State as DotsStoreState };
