import { h } from "preact";

import "./style.css";

interface Props {
    showPassword: boolean;
    onClick: (event: Event) => void;
}

function ToggleShowPassword({ showPassword, onClick }: Props) {
    return (
        <button
            type="button"
            class="toggleShowPassword"
            onClick={onClick}>
            {showPassword ? "Hide" : "Show"}
        </button>
    );
}

export default ToggleShowPassword;
