/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { ProjectViewTypes } from "../../../foundation/enums";
import AssetDownloadTarget from "../../../foundation/model/AssetDownloadTarget";
import BarrelType from "../../../foundation/model/BarrelType";
import BarrelUser from "../../../foundation/model/BarrelUser";
import ColorFormatPreference from "../../../foundation/model/ColorFormatPreference";
import NamingConvention from "../../../foundation/model/NamingConvention";
import SidebarTabType from "../../../foundation/model/SidebarTab";
import BasicRecord from "../../../foundation/utils/BasicRecord";

import * as AppActionPayloads from "../app/AppActionPayloads";
import AppActionTypes from "../app/AppActionTypes";
import ApprovalsActionTypes from "../approvals/ApprovalsActionTypes";
import ComponentActionTypes from "../component/ComponentActionTypes";
import * as DashboardActionPayloads from "../dashboard/DashboardActionPayloads";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import * as InspectableViewActionPayloads from "../inspectableView/InspectableViewActionPayloads";
import InspectableViewActionTypes from "../inspectableView/InspectableViewActionTypes";
import OrganizationActionTypes from "../organization/OrganizationActionTypes";
import * as ProjectActionPayloads from "../project/ProjectActionPayloads";
import ProjectActionTypes from "../project/ProjectActionTypes";
import * as ScreenActionPayloads from "../screen/ScreenActionPayloads";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./BarrelActionPayloads";
import BarrelActionTypes from "./BarrelActionTypes";
import ConnectedComponentsEditorInfo from "./ConnectedComponentsEditorInfo";

type State = {
    loading: boolean;
    loadingJoin: boolean;
    initingNewlyIntegratedSlackChannel: boolean;
    organizationId: string | null;
    barrelType: BarrelType;
    barrelId: string | null;
    selectedView: string | undefined;
    assetDownloadTargets: AssetDownloadTarget[] | null;
    assetDensityScalePrefs: BasicRecord<number[]> | null;
    assetNamingConvention: NamingConvention;
    showOptimizedSVG: boolean;
    showOptimizedPNG: boolean;
    showOptimizedJPG: boolean;
    colorFormatPreference: ColorFormatPreference;
    noVectorAssetInfoHidden: boolean;
    densityMismatchWarningHidden: boolean;
    noResourceInfoDismissed: boolean;
    sourceFileInfoHidden: boolean;
    flowLinkInfoDismissed: boolean;
    showSlackReconnectionAuntie: boolean;
    linkingProjectToStyleguide: string;
    movingResourcesToStyleguide: string;
    connectedComponentsDevModeEnabled: boolean;
    connectedComponentsLocalWorkspace: string | null;
    componentVariantsOnboardingHintboxSeen: boolean;
    connectedComponentsEditorInfo: Partial<ConnectedComponentsEditorInfo>;
    screenVersionsLastSeen: BasicRecord<Date> | null;
    screenApprovalHintSeen: BasicRecord<boolean> | null;
    screenApprovalHintClosed: BasicRecord<boolean> | null;
    barrelUser?: BarrelUser;
    screenLastOpened: BasicRecord<Date> | null;
    onboardingHighlightScreenOpened: boolean;
    onboardingLayerCompleted: boolean;
    onboardingDotCompleted: boolean;
    onboardingVersionsCompleted: boolean;
    onboardingAssetsCompleted: boolean;
    intermediateScreenLoading?: boolean;
    preloadingDashboard: boolean;
    reviewerHintboxSeen: boolean;
    componentVersionsNewBadgeMenuSeen: boolean;
    androidResFolderReminderSeen: boolean;
    selectedSidebarTab: SidebarTabType;
    areSuggestedStatusTagsAutoCreated?: boolean;
};

class BarrelStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            loading: true,
            loadingJoin: false,
            initingNewlyIntegratedSlackChannel: false,
            organizationId: null,
            barrelType: BarrelType.PROJECT,
            barrelId: null,
            selectedView: undefined,
            assetDownloadTargets: null,
            assetDensityScalePrefs: null, // Calculated wrt project type
            assetNamingConvention: NamingConvention.CamelCase,
            showOptimizedSVG: true,
            showOptimizedPNG: true,
            showOptimizedJPG: true,
            colorFormatPreference: ColorFormatPreference.Auto,
            noVectorAssetInfoHidden: false,
            densityMismatchWarningHidden: false,
            noResourceInfoDismissed: false,
            sourceFileInfoHidden: false,
            flowLinkInfoDismissed: false,
            showSlackReconnectionAuntie: false, // TODO-electron-styleguide-redesign: This is not used in the new design, fix it.
            linkingProjectToStyleguide: "", // / id of the styleguide being linked,
            movingResourcesToStyleguide: "", // `id` of the styleguide resources (colors, textStyles or components) are moving to
            connectedComponentsDevModeEnabled: false,
            connectedComponentsLocalWorkspace: null,
            componentVariantsOnboardingHintboxSeen: false,
            connectedComponentsEditorInfo: {},
            screenVersionsLastSeen: null,
            screenApprovalHintSeen: null,
            screenApprovalHintClosed: null,
            screenLastOpened: null,
            onboardingHighlightScreenOpened: false,
            onboardingLayerCompleted: false,
            onboardingDotCompleted: false,
            onboardingAssetsCompleted: false,
            onboardingVersionsCompleted: false,
            intermediateScreenLoading: false,
            preloadingDashboard: false,
            reviewerHintboxSeen: false,
            componentVersionsNewBadgeMenuSeen: false,
            androidResFolderReminderSeen: false,
            selectedSidebarTab: SidebarTabType.INTEGRATIONS,
            areSuggestedStatusTagsAutoCreated: false
        };
    }

    reset(action?: Payloads.Reset): State {
        // Do not forget to set selectedView if required
        const { barrel = {} } = action || {};

        return {
            ...this.getInitialState(),
            ...barrel
        };
    }

    resetSelectedViewIfNecessary(state: State, action: AppActionPayloads.SetPage): State {
        // this is just to let us have some leeway to control on resetting the view for barrel pages
        // it is helpful to reset the view and not render anything while it is set to a correct usable view value
        if (action.nextViewHint && action.nextViewHint !== state.selectedView) {
            return {
                ...state,
                selectedView: undefined
            };
        }

        return state;
    }

    load(state: State, {
        barrel
    }: Payloads.Load): State {
        return {
            ...state,
            loading: false,
            ...barrel
        };
    }

    loadIntermediate(state: State, {
        barrelData: {
            barrel
        },
        openScreenData
    }: Payloads.LoadIntermediate): State {
        const updatedState = this.updateScreenDatesOnVersionRelatedActions(state, openScreenData!);

        const { isOnboardingHighlightScreen } = openScreenData!;
        const openScreen = {
            ...updatedState,
            selectedView: ProjectViewTypes.SCREEN,
            onboardingHighlightScreenOpened: isOnboardingHighlightScreen,
            intermediateScreenLoading: barrel.intermediateScreenLoading
        };

        return {
            ...this.getInitialState(),
            ...openScreen,
            ...barrel
        };
    }

    preloadProjectDashboard(state: State, {
        barrel
    }: Payloads.PreloadDashboard): State {
        return {
            ...state,
            preloadingDashboard: true,
            selectedView: ProjectViewTypes.DASHBOARD,
            ...barrel
        };
    }

    setOrganizationIdWhilePreloadingDashboard(state: State, organizationId: string): State {
        if (state.preloadingDashboard) {
            return {
                ...state,
                organizationId
            };
        }

        return state;
    }

    authenticatingSlackIntegration(state: State, {
        bid
    }: Payloads.AuthenticateSlackIntegration): State {
        if (state.barrelId !== bid) {
            return state;
        }

        return { ...state, initingNewlyIntegratedSlackChannel: true };
    }

    authenticatedSlackIntegration(state: State, {
        bid
    }: Payloads.AuthenticatedSlackIntegration | Payloads.AuthenticatingSlackIntegrationFailed): State {
        if (state.barrelId !== bid || !state.initingNewlyIntegratedSlackChannel) {
            return state;
        }

        return { ...state, initingNewlyIntegratedSlackChannel: false };
    }

    updateForArchive(state: State, {
        bid
    }: Payloads.Archive): State {
        if (bid === state.barrelId) {
            return this.reset();
        }

        return state;
    }

    updateForRemoveMember(state: State, {
        bid,
        mid,
        currentUserId
    }: Payloads.RemoveMember): State {
        if (bid === state.barrelId && mid === currentUserId) {
            return this.reset();
        }

        return state;
    }

    updateForRemove(state: State, {
        bid
    }: Payloads.Remove): State {
        if (bid === state.barrelId) {
            return this.reset();
        }

        return state;
    }

    setOrganizationId(state: State, {
        oid: organizationId
    }: Payloads.SetOrganizationId): State {
        return {
            ...state,
            organizationId
        };
    }

    changeSelectedView(state: State, selectedView: string): State {
        return {
            ...state,
            selectedView
        };
    }

    setAssetDownloadTargets(state: State, {
        assetDownloadTargets
    }: Payloads.SetAssetExportTarget): State {
        return {
            ...state,
            assetDownloadTargets
        };
    }

    changeAssetDensityScalePref(state: State, {
        format,
        assetDensityScalePrefsOfFormat
    }: Payloads.ChangeAssetDensityScalePref): State {
        const assetDensityScalePrefs = {
            ...state.assetDensityScalePrefs,
            [format]: assetDensityScalePrefsOfFormat
        };

        return {
            ...state,
            assetDensityScalePrefs
        };
    }

    changeAssetNamingConvention(state: State, {
        assetNamingConvention
    }: Payloads.ChangeAssetNamingConvention): State {
        return {
            ...state,
            assetNamingConvention
        };
    }

    toggleSVGOptimization(state: State, {
        showOptimizedSVG
    }: Payloads.ToggleSVGOptimization): State {
        return {
            ...state,
            showOptimizedSVG
        };
    }

    togglePNGOptimization(state: State, {
        showOptimizedPNG
    }: Payloads.TogglePNGOptimization): State {
        return {
            ...state,
            showOptimizedPNG
        };
    }

    toggleJPGOptimization(state: State, {
        showOptimizedJPG
    }: Payloads.ToggleJPGOptimization): State {
        return {
            ...state,
            showOptimizedJPG
        };
    }

    changeColorFormatPreference(state: State, {
        colorFormatPreference
    }: Payloads.ChangeColorFormatPreference): State {
        return {
            ...state,
            colorFormatPreference
        };
    }

    dismissNoResourceInfo(state: State): State {
        return {
            ...state,
            noResourceInfoDismissed: true
        };
    }

    hideNoVectorAssetInfo(state: State): State {
        return {
            ...state,
            noVectorAssetInfoHidden: true
        };
    }

    hideDensityMismatchWarning(state: State): State {
        return {
            ...state,
            densityMismatchWarningHidden: true
        };
    }

    hideSourceFileInfo(state: State): State {
        return {
            ...state,
            sourceFileInfoHidden: true
        };
    }

    dismissFlowLinkInfo(state: State): State {
        return {
            ...state,
            flowLinkInfoDismissed: true
        };
    }

    hideSlackReconnectionAuntie(state: State): State {
        return {
            ...state,
            showSlackReconnectionAuntie: false
        };
    }

    hideComponentVariantsOnboardingHintbox(state: State): State {
        return {
            ...state,
            componentVariantsOnboardingHintboxSeen: true
        };
    }

    join(state: State): State {
        return {
            ...state,
            loadingJoin: true
        };
    }

    stopLoadingJoin(state: State): State {
        return {
            ...state,
            loadingJoin: false
        };
    }

    moveResourcesRequest(state: State, {
        targetStyleguideId
    }: Payloads.MoveResourcesRequest): State {
        return {
            ...state,
            movingResourcesToStyleguide: targetStyleguideId
        };
    }

    moveResourcesSuccess(state: State): State {
        return {
            ...state,
            movingResourcesToStyleguide: ""
        };
    }

    moveResourcesFail(state: State): State {
        return {
            ...state,
            movingResourcesToStyleguide: ""
        };
    }

    linkProjectToStyleguideRequest(state: State, {
        styleguide
    }: ProjectActionPayloads.LinkProjectToStyleguideRequest): State {
        return {
            ...state,
            linkingProjectToStyleguide: styleguide._id
        };
    }

    linkProjectToStyleguideReset(state: State): State {
        return {
            ...state,
            linkingProjectToStyleguide: ""
        };
    }

    updateForOpenScreen(state: State, action: ScreenActionPayloads.OpenScreen): State {
        const updatedState = this.updateScreenDatesOnVersionRelatedActions(state, action);

        const { isOnboardingHighlightScreen } = action;

        const onboardingHighlightScreenOpened = isOnboardingHighlightScreen || state.onboardingHighlightScreenOpened;

        return {
            ...updatedState,
            selectedView: ProjectViewTypes.SCREEN,
            onboardingHighlightScreenOpened
        };
    }

    updateForOpenComponent(state: State): State {
        return {
            ...state,
            selectedView: ProjectViewTypes.COMPONENT
        };
    }

    updateForDeleteScreens(state: State, {
        removingCurrentScreen
    }: DashboardActionPayloads.DeleteScreens) {
        const { selectedView } = state;

        if (selectedView !== ProjectViewTypes.SCREEN || !removingCurrentScreen) {
            return state;
        }

        return this.changeSelectedView(state, ProjectViewTypes.DASHBOARD);
    }

    updateForRemoveScreen(state: State, {
        removingCurrentScreen
    }: ScreenActionPayloads.RemoveScreen): State {
        const { selectedView } = state;

        if (selectedView !== ProjectViewTypes.SCREEN || !removingCurrentScreen) {
            return state;
        }

        return this.changeSelectedView(state, ProjectViewTypes.DASHBOARD);
    }

    completeLayerOnboarding(state: State, {
        shouldCompleteLayerOnboarding
    }: {
        shouldCompleteLayerOnboarding: boolean;
    }): State {
        if (!shouldCompleteLayerOnboarding) {
            return state;
        }

        return {
            ...state,
            onboardingLayerCompleted: true
        };
    }

    completeDotOnboarding(state: State, {
        shouldCompleteDotOnboarding
    }: {
        shouldCompleteDotOnboarding: boolean;
    }): State {
        if (!shouldCompleteDotOnboarding) {
            return state;
        }

        return {
            ...state,
            onboardingDotCompleted: true
        };
    }

    completeVersionsOnboarding(state: State, {
        shouldCompleteVersionsOnboarding
    }: {
        shouldCompleteVersionsOnboarding: boolean;
    }): State {
        if (!shouldCompleteVersionsOnboarding) {
            return state;
        }

        return {
            ...state,
            onboardingVersionsCompleted: true
        };
    }

    completeAssetsOnboarding(state: State, {
        shouldCompleteAssetsOnboarding
    }: {
        shouldCompleteAssetsOnboarding: boolean;
    }): State {
        if (!shouldCompleteAssetsOnboarding) {
            return state;
        }

        return {
            ...state,
            onboardingAssetsCompleted: true
        };
    }

    completeScreenOnboardingStep(state: State, { step }: ScreenActionPayloads.CompleteOnboardingStep): State {
        // There is no case for step "layer" because its instruction doesn't have "Got it" option.
        // If "Got it" is added to layer instructon, its case should be added here.
        switch (step) {
            case "dot":
                return this.completeDotOnboarding(state, { shouldCompleteDotOnboarding: true });
            case "assets":
                return this.completeAssetsOnboarding(state, { shouldCompleteAssetsOnboarding: true });
            case "versions":
                return this.completeVersionsOnboarding(state, { shouldCompleteVersionsOnboarding: true });
            default:
                return state;
        }
    }

    enableDevMode(state: State): State {
        return {
            ...state,
            connectedComponentsDevModeEnabled: true
        };
    }

    disableDevMode(state: State): State {
        return {
            ...state,
            connectedComponentsDevModeEnabled: false
        };
    }

    setConnectedComponentsLocalWorkspace(state: State, {
        workspace
    }: Payloads.SetConnectedComponentsLocalWorkspace): State {
        return {
            ...state,
            connectedComponentsLocalWorkspace: workspace
        };
    }

    setConnectedComponentsEditorInfo(state: State, {
        editorInfo
    }: Payloads.SetConnectedComponentsEditorInfo): State {
        return {
            ...state,
            connectedComponentsEditorInfo: editorInfo
        };
    }

    setConnectedComponentsEditorPreference(state: State, {
        extension,
        editor
    }: Payloads.SetConnectedComponentsEditorPreference): State {
        const { connectedComponentsEditorInfo } = state;
        const { defaultEditorPreferences, ...others } = connectedComponentsEditorInfo;
        const { [extension]: _, ...newPreferences } = defaultEditorPreferences!;

        if (editor) {
            newPreferences[extension] = editor;
        }

        return {
            ...state,
            connectedComponentsEditorInfo: {
                ...others,
                defaultEditorPreferences: newPreferences
            }
        };
    }

    setConnectedComponentsWorkspacePath(state: State, {
        workspacePath
    }: Payloads.SetConnectedComponentsWorkspacePath): State {
        const { connectedComponentsEditorInfo } = state;
        return {
            ...state,
            connectedComponentsEditorInfo: {
                ...connectedComponentsEditorInfo,
                workspacePath
            }
        };
    }

    updateScreenVersionsLastSeen(state: State, { sid, date }: {
        sid?: string;
        date?: Date;
    }): State {
        if (!state.screenVersionsLastSeen || !sid) {
            return state;
        }

        return {
            ...state,
            screenVersionsLastSeen: {
                ...state.screenVersionsLastSeen,
                [sid]: date!
            }
        };
    }

    updateScreenApprovalHintSeen(state: State, { sid, screenApprovalHintSeen }: {
        sid: string;
        screenApprovalHintSeen: boolean;
    }): State {
        return {
            ...state,
            screenApprovalHintSeen: {
                ...state.screenApprovalHintSeen,
                [sid]: screenApprovalHintSeen
            }
        };
    }

    updateScreenApprovalHintClosed(state: State, { sid, closed }: {
        sid: string;
        closed: boolean;
    }): State {
        return {
            ...state,
            screenApprovalHintClosed: {
                ...state.screenApprovalHintClosed,
                [sid]: closed
            }
        };
    }

    setScreenLastOpened(state: State, { sid, date }: {
        sid?: string;
        date?: Date;
    }): State {
        if (!state.screenLastOpened || !sid) {
            return state;
        }

        return {
            ...state,
            screenLastOpened: {
                ...state.screenLastOpened,
                [sid]: date!
            }
        };
    }

    updateScreenDatesOnVersionRelatedActions(state: State, action:
        Omit<ScreenActionPayloads.OpenScreen, "type"> |
        InspectableViewActionPayloads.HideVersions |
        ScreenActionPayloads.AddVersion |
        InspectableViewActionPayloads.ShowVersions |
        InspectableViewActionPayloads.ToggleVersions |
        ScreenActionPayloads.SetLastOpenDates): State {
        let updatedState = this.setScreenLastOpened(state, action.screenLastOpened);
        updatedState = this.updateScreenVersionsLastSeen(updatedState, action.screenVersionsLastSeen);

        return updatedState;
    }

    markComponentVersionsNewBadgeMenuAsSeen(state: State): State {
        return {
            ...state,
            componentVersionsNewBadgeMenuSeen: true
        };
    }

    markReviewerHintboxAsSeen(state: State): State {
        return {
            ...state,
            reviewerHintboxSeen: true
        };
    }

    markAndroidResFolderReminderAsSeen(state: State): State {
        return {
            ...state,
            androidResFolderReminderSeen: true
        };
    }

    setSelectedSidebarTab(state: State, {
        selectedSidebarTab
    }: Payloads.SetSelectedSidebarTab): State {
        return {
            ...state,
            selectedSidebarTab
        };
    }

    setActiveSidebarTab(state: State, {
        sids
    }: {
        sids: string[];
    }): State {
        return {
            ...state,
            selectedSidebarTab: sids.length ? SidebarTabType.COMMENTS : state.selectedSidebarTab
        };
    }

    markSuggestedStatusTagsAsAutoCreated(state: State): State {
        return {
            ...state,
            areSuggestedStatusTagsAutoCreated: true
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
                return this.reset();

            case AppActionTypes.SET_PAGE:
                return this.resetSelectedViewIfNecessary(state, action);

            case BarrelActionTypes.RESET:
                return this.reset(action);

            case BarrelActionTypes.AUTHENTICATE_SLACK_INTEGRATION:
                return this.authenticatingSlackIntegration(state, action);

            case BarrelActionTypes.AUTHENTICATE_SLACK_INTEGRATION_FAILED:
                return this.authenticatedSlackIntegration(state, action);

            case BarrelActionTypes.AUTHENTICATED_SLACK_INTEGRATION:
                return this.authenticatedSlackIntegration(state, action);

            case BarrelActionTypes.ARCHIVE:
                return this.updateForArchive(state, action);

            case BarrelActionTypes.REMOVE_MEMBER:
                return this.updateForRemoveMember(state, action);

            case BarrelActionTypes.REMOVE:
                return this.updateForRemove(state, action);

            case BarrelActionTypes.LOAD:
                return this.load(state, action);

            case BarrelActionTypes.PRELOAD_PROJECT_DASHBOARD:
                return this.preloadProjectDashboard(state, action);

            case OrganizationActionTypes.ADD_ORGANIZATION:
                return this.setOrganizationIdWhilePreloadingDashboard(state, action.organization._id);

            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.loadIntermediate(state, action);

            case BarrelActionTypes.SET_ORGANIZATION_ID:
                return this.setOrganizationId(state, action);

            case BarrelActionTypes.CHANGE_SELECTED_VIEW:
                return this.changeSelectedView(state, action.selectedView);

            case BarrelActionTypes.SET_ASSET_DOWNLOAD_TARGETS:
                return this.setAssetDownloadTargets(state, action);

            case BarrelActionTypes.CHANGE_ASSET_DENSITY_SCALE_PREF:
                return this.changeAssetDensityScalePref(state, action);

            case BarrelActionTypes.CHANGE_ASSET_NAMING_CONVENTION:
                return this.changeAssetNamingConvention(state, action);

            case BarrelActionTypes.TOGGLE_SVG_OPTIMIZATION:
                return this.toggleSVGOptimization(state, action);

            case BarrelActionTypes.TOGGLE_PNG_OPTIMIZATION:
                return this.togglePNGOptimization(state, action);

            case BarrelActionTypes.TOGGLE_JPG_OPTIMIZATION:
                return this.toggleJPGOptimization(state, action);

            case BarrelActionTypes.CHANGE_COLOR_FORMAT_PREFERENCE:
                return this.changeColorFormatPreference(state, action);

            case BarrelActionTypes.HIDE_NO_VECTOR_ASSET_INFO:
                return this.hideNoVectorAssetInfo(state);

            case BarrelActionTypes.HIDE_DENSITY_MISMATCH_WARNING:
                return this.hideDensityMismatchWarning(state);

            case BarrelActionTypes.HIDE_SOURCE_FILE_INFO:
                return this.hideSourceFileInfo(state);

            case BarrelActionTypes.DISMISS_FLOW_LINK_INFO:
                return this.dismissFlowLinkInfo(state);

            case BarrelActionTypes.DISMISS_NO_RESOURCE_INFO:
                return this.dismissNoResourceInfo(state);

            case BarrelActionTypes.HIDE_SLACK_RECONNECTION_AUNTIE:
                return this.hideSlackReconnectionAuntie(state);

            case BarrelActionTypes.JOIN:
                return this.join(state);

            case BarrelActionTypes.STOP_JOIN_LOADING:
                return this.stopLoadingJoin(state);

            case BarrelActionTypes.MOVE_RESOURCES_REQUEST:
                return this.moveResourcesRequest(state, action);

            case BarrelActionTypes.MOVE_RESOURCES_SUCCESS:
                return this.moveResourcesSuccess(state);

            case BarrelActionTypes.MOVE_RESOURCES_FAIL:
                return this.moveResourcesFail(state);

            case BarrelActionTypes.ENABLE_DEV_MODE:
                return this.enableDevMode(state);

            case BarrelActionTypes.DISABLE_DEV_MODE:
                return this.disableDevMode(state);

            case BarrelActionTypes.SET_SELECTED_SIDEBAR_TAB:
                return this.setSelectedSidebarTab(state, action);

            case ProjectActionTypes.LINK_PROJECT_REQUEST:
                return this.linkProjectToStyleguideRequest(state, action);

            case ProjectActionTypes.LINK_PROJECT_SUCCESS:
            case ProjectActionTypes.UNLINK_PROJECT:
                return this.linkProjectToStyleguideReset(state);

            case DashboardActionTypes.DELETE_SCREENS:
                return this.updateForDeleteScreens(state, action);

            case ScreenActionTypes.OPEN_SCREEN:
                return this.updateForOpenScreen(state, action);

            case ComponentActionTypes.OPEN_COMPONENT:
                return this.updateForOpenComponent(state);

            case ScreenActionTypes.SET_LAST_OPEN_DATES:
            case ScreenActionTypes.ADD_VERSION:
            case InspectableViewActionTypes.SHOW_VERSIONS:
            case InspectableViewActionTypes.HIDE_VERSIONS:
            case InspectableViewActionTypes.TOGGLE_VERSIONS:
                return this.updateScreenDatesOnVersionRelatedActions(state, action);

            case ScreenActionTypes.REMOVE_SCREEN:
                return this.updateForRemoveScreen(state, action);

            case ScreenActionTypes.SELECT_DOT:
                return this.completeDotOnboarding(state, action);

            case InspectableViewActionTypes.SELECT_LAYER:
                return this.completeLayerOnboarding(state, action);

            case InspectableViewActionTypes.SET_VERSION_ID:
                return this.completeVersionsOnboarding(state, action);

            case ScreenActionTypes.NOTIFY_ASSETS_TAB_OPENED:
                return this.completeAssetsOnboarding(state, action);

            case ScreenActionTypes.COMPLETE_ONBOARDING_STEP:
                return this.completeScreenOnboardingStep(state, action);

            case BarrelActionTypes.SET_CONNECTED_COMPONENTS_LOCAL_WORKSPACE:
                return this.setConnectedComponentsLocalWorkspace(state, action);

            case BarrelActionTypes.SET_CONNECTED_COMPONENTS_EDITOR_INFO:
                return this.setConnectedComponentsEditorInfo(state, action);

            case BarrelActionTypes.SET_CONNECTED_COMPONENTS_EDITOR_PREFERENCE:
                return this.setConnectedComponentsEditorPreference(state, action);

            case BarrelActionTypes.SET_CONNECTED_COMPONENTS_WORKSPACE_PATH:
                return this.setConnectedComponentsWorkspacePath(state, action);

            case BarrelActionTypes.MARK_COMPONENT_VERSIONS_NEW_BADGE_MENU_AS_SEEN:
                return this.markComponentVersionsNewBadgeMenuAsSeen(state);

            case BarrelActionTypes.MARK_REVIEWER_HINTBOX_AS_SEEN:
                return this.markReviewerHintboxAsSeen(state);

            case BarrelActionTypes.MARK_ANDROID_RES_FOLDER_REMINDER_AS_SEEN:
                return this.markAndroidResFolderReminderAsSeen(state);

            case ApprovalsActionTypes.SET_SCREEN_APPROVAL_HINT_SEEN:
                return this.updateScreenApprovalHintSeen(state, action);

            case ApprovalsActionTypes.SET_SCREEN_APPROVAL_HINT_CLOSED:
                return this.updateScreenApprovalHintClosed(state, action);

            case DashboardActionTypes.SET_SELECTED_SCREENS:
                return this.setActiveSidebarTab(state, action);

            case BarrelActionTypes.MARK_SUGGESTED_STATUS_TAGS_AS_AUTO_CREATED:
                return this.markSuggestedStatusTagsAsAutoCreated(state);

            default:
                return state;
        }
    }
}

export default BarrelStore;
export { State as BarrelStoreState };
