/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import FlowActionTypes from "../FlowActionTypes";
import * as FlowActionPayloads from "../FlowActionPayloads";
import { AllPayloads } from "../../payloads";
import DotsReducer from "./DotsReducer";
import Dispatcher from "flux/lib/Dispatcher";
import CreateDotParameters from "../../../../foundation/api/model/dots/CreateDotParameters";
import BarrelActionTypes from "../../barrel/BarrelActionTypes";
import * as BarrelActionPayloads from "../../barrel/BarrelActionPayloads";
import FlowDot, { FlowDraftDot } from "../../../../foundation/api/model/flow/FlowDot";

type State = {
    dots: {
        [pid: string]: {
            [flid: string]: FlowDot[];
        };
    };
};

type DotType = FlowDot | FlowDraftDot | (CreateDotParameters & { _id: string; });

class FlowDotsStore extends ReduceStore<State, AllPayloads> {
    reducer: DotsReducer<State, DotType>;

    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
        this.reducer = new DotsReducer();
    }

    getInitialState(): State {
        return {
            dots: {}
        };
    }

    setDots(state: State, {
        pid,
        flid,
        dots
    }: FlowActionPayloads.SetDots): State {
        return this.reducer.setDots(
            state,
            { pid, flid, dots }
        );
    }

    createNote(
        state: State,
        { pid, flid, dot }: { pid: string; flid: string; dot: DotType; }
    ) {
        return this.reducer.insertDot(
            state,
            { pid, flid, dot }
        );
    }

    updateDot(state: State, {
        pid,
        flid,
        did: oldDid,
        dotData
    }: Omit<FlowActionPayloads.UpdateDot, "oldDotData" | "syncWithApi" | "type">): State {
        return this.reducer.updateDot(
            state,
            { pid, flid, did: oldDid, dot: dotData }
        );
    }

    updateDotStatus(state: State, {
        pid,
        flid,
        did,
        status
    }: FlowActionPayloads.UpdateDotStatus): State {
        return this.reducer.updateDot(
            state,
            { pid, flid, did, dot: { status } }
        );
    }

    updateDotColor(
        state: State,
        { pid, flid, did, color }: FlowActionPayloads.UpdateDotColor
    ): State {
        return this.reducer.updateDot(
            state,
            { pid, flid, did, dot: { color } }
        );
    }

    addDot(
        state: State,
        { pid, flid, dot }: { pid: string; flid: string; dot: DotType; }
    ) {
        return this.reducer.insertDot(
            state,
            { pid, flid, dot }
        );
    }

    createComment(state: State, {
        pid,
        flid,
        did,
        comment
    }: FlowActionPayloads.CreateComment): State {
        return this.reducer.insertDotComment(
            state,
            { pid, flid, did, comment }
        );
    }

    addComment(state: State, {
        pid,
        flid,
        did,
        comment
    }: FlowActionPayloads.AddComment): State {
        return this.reducer.insertDotComment(
            state,
            { pid, flid, did, comment }
        );
    }

    updateComment(state: State, {
        pid,
        flid,
        did,
        cmid,
        commentData
    }: FlowActionPayloads.UpdateComment): State {
        return this.reducer.updateDotComment(state, {
            pid, flid, did, cmid, commentData
        });
    }

    removeComment(state: State, {
        pid,
        flid,
        did,
        cmid
    }: FlowActionPayloads.RemoveComment): State {
        return this.reducer.removeDotComment(state, {
            pid, flid, did, cmid
        });
    }

    removeDot(state: State, {
        pid,
        flid,
        did
    }: FlowActionPayloads.RemoveDot): State {
        return this.reducer.removeDot(
            state,
            { pid: pid!, flid: flid!, did }
        );
    }

    updateUserData(state: State, {
        bid,
        uid,
        userData
    }: BarrelActionPayloads.UpdateUserData): State {
        const projectDots = state.dots[bid];

        // eslint-disable-next-line guard-for-in
        for (const flowId in projectDots) {
            const screenDots: FlowDot[] = projectDots[flowId];

            if (screenDots && screenDots.length) {
                const newScreenDots: FlowDot[] = screenDots.map(dot => {
                    const comments = dot.comments.map(comment => {
                        if (comment.createdBy && comment.createdBy._id === uid) {
                            return { ...comment, author: { ...comment.createdBy, ...userData } };
                        }

                        return comment;
                    });

                    if (dot.createdBy && dot.createdBy._id === uid) {
                        return { ...dot, comments, createdBy: { ...dot.createdBy, ...userData } };
                    }

                    return { ...dot, comments };
                });

                return this.reducer.setDots(
                    state,
                    { pid: bid, flid: flowId, dots: newScreenDots }
                );
            }
        }

        return state;
    }

    transferToAnotherFlow(
        state: State,
        action: FlowActionPayloads.TransferGroupsToAnotherFlow | FlowActionPayloads.TransferNodesToAnotherFlow
    ) {
        const {
            pid, flid, targetFlid, transferredDots
        } = action;
        const { dots } = state;
        const projectDots = dots[pid!];
        const sourceDots = projectDots[flid!];
        const targetDots = projectDots[targetFlid];

        if (!sourceDots || !targetDots || !transferredDots || transferredDots.length === 0) {
            return state;
        }

        const transferredDotIds = transferredDots!.map(({ _id }) => _id);
        const updatedSourceDots = sourceDots.filter(({ _id }) => !transferredDotIds.includes(_id));
        const updatedTargetDots: FlowDot[] = [];
        for (const dot of transferredDots) {
            targetDots.push(dot);
        }

        return {
            ...state,
            dots: {
                ...state.dots,
                [pid!]: {
                    ...state.dots[pid!],
                    [flid!]: updatedSourceDots,
                    [targetFlid]: updatedTargetDots
                }
            }
        };
    }

    updateTransferredGroupData(state: State, { pid, flid, dots }: FlowActionPayloads.UpdateTransferredData) {
        let updatedState = state;
        for (const dot of dots) {
            updatedState = this.updateDot(updatedState, { pid, flid, dotData: dot, did: dot._id });
        }

        return updatedState;
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            // Screen action reducers
            case FlowActionTypes.SET_DOTS:
                return this.setDots(state, action);

            case FlowActionTypes.CREATE_NOTE:
                return this.createNote(state, action);

            case FlowActionTypes.UPDATE_DOT:
                return this.updateDot(state, action);

            case FlowActionTypes.UPDATE_DOT_STATUS:
                return this.updateDotStatus(state, action);

            case FlowActionTypes.UPDATE_DOT_COLOR:
                return this.updateDotColor(state, action);

            case FlowActionTypes.ADD_DOT:
                return this.addDot(state, action);

            case FlowActionTypes.CREATE_COMMENT:
                return this.createComment(state, action);

            case FlowActionTypes.ADD_COMMENT:
                return this.addComment(state, action);

            case FlowActionTypes.UPDATE_COMMENT:
                return this.updateComment(state, action);

            case FlowActionTypes.REMOVE_COMMENT:
                return this.removeComment(state, action);

            case FlowActionTypes.REMOVE_DOT:
                return this.removeDot(state, action);

            case BarrelActionTypes.UPDATE_USER_DATA:
                return this.updateUserData(state, action);

            case FlowActionTypes.TRANSFER_NODES_TO_ANOTHER_FLOW:
                return this.transferToAnotherFlow(state, action);

            case FlowActionTypes.UPDATE_TRANSFERRED_DATA:
                return this.updateTransferredGroupData(state, action);

            default:
                return state;
        }
    }
}

export default FlowDotsStore;
export { State as FlowDotsStoreState };
