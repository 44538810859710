import electron from "../../../foundation/electron";
import { electronAppSupports } from "../../../foundation/electron/electronAppSupports";
import AppActions from "../../data/app/AppActions";

function initAppUpdates() {
    electron.actions.onAppUpdateDownloaded((_, version) => {
        AppActions.setUpdateNotificationBannerDetails({
            appUpdate: {
                newVersion: version
            }
        });
    });
}

function initPluginUpdates() {
    electron.actions.onPluginUpdateDownloaded((_, updateInfo) => {
        AppActions.setUpdateNotificationBannerDetails({
            photoshopPluginUpdate: {
                pluginNewVersion: updateInfo.plugin.remote,
                panelNewVersion: updateInfo.panel.remote
            }
        });
    });
}

function init() {
    // we added SPA case because we force update old apps to update in another component: WindowsAppForceUpdateNotifier
    if (!electron || !electronAppSupports.SPA) {
        return;
    }

    electron.actions.onCloseAllBanners(() => {
        AppActions.resetUpdateNotificationBannerDetails();
    });
    initAppUpdates();
    initPluginUpdates();
}

init();
