enum ActionTypes {
    INIT = "extensions/init",
    LOAD_EXTENSIONS = "extensions/loadExtensions",
    LOAD = "extensions/load",
    RELOAD = "extensions/reload",
    OPEN_SETTINGS = "extensions/openSettings",
    CLOSE_SETTINGS = "extensions/closeSettings",
    ADD = "extensions/add",
    ENABLE = "extensions/enable",
    UPDATE_OPTION = "extensions/updateOption",
    DISABLE = "extensions/disable",
    REMOVE = "extensions/remove"
}

export default ActionTypes;
