import fluxRuntime from "../fluxRuntime";
import BarrelHelpers from "../barrel/BarrelHelpers";
import permissions from "../../../foundation/utils/permissions";
import BarrelType from "../../../foundation/model/BarrelType";

const createPermissionFns = {
    [BarrelType.PROJECT]: permissions.canCreateProject,
    [BarrelType.STYLEGUIDE]: permissions.canCreateStyleguide
};

function canCreateBarrel(barrelType: BarrelType): boolean {
    const { barrelId } = fluxRuntime.BarrelStore.getState();

    const { selectedOrganizationId } = fluxRuntime.SpaceStore.getState();
    const { organizations, organizationProfiles } = fluxRuntime.OrganizationStore.getState();

    const oid = barrelId ? BarrelHelpers.findBarrelOrganizationId(barrelId) : selectedOrganizationId;

    if (oid === "user") {
        return true;
    }

    const selectedOrganization = organizations.find(organization => organization._id === oid);
    const currentOrganizationProfile = organizationProfiles[oid!];

    return Boolean(selectedOrganization && selectedOrganization.status !== "suspended" &&
        currentOrganizationProfile && createPermissionFns[barrelType](currentOrganizationProfile));
}

function canCreateProject(): boolean {
    return canCreateBarrel(BarrelType.PROJECT);
}

function canCreateStyleguide(): boolean {
    return canCreateBarrel(BarrelType.STYLEGUIDE);
}

export default {
    canCreateProject,
    canCreateStyleguide
};
