import BarrelType from "../../../foundation/model/BarrelType";
import BasicRecord from "../../../foundation/utils/BasicRecord";

type EventGroup = {
    name: string;
    info: string;
    events: string[];
};

const eventGroupsByBarrelType: Record<BarrelType, BasicRecord<EventGroup>> = {
    [BarrelType.PROJECT]: {
        screens: {
            name: "Screens",
            info: "Screen added",
            events: ["project.screen.created"]
        },
        versions: {
            name: "Screen versions",
            info: "Version added to screen",
            events: ["project.screen.version.created"]
        },
        components: {
            name: "Components",
            info: "Component added, removed or updated on Styleguide",
            events: ["project.component.created", "project.component.version.created", "project.component.deleted"]
        },
        colors: {
            name: "Colors",
            info: "Color added, removed or updated on Styleguide",
            events: ["project.color.created", "project.color.updated", "project.color.deleted"]
        },
        textStyles: {
            name: "Text styles",
            info: "Text style added, removed or updated on Styleguide",
            events: ["project.textstyle.created", "project.textstyle.updated", "project.textstyle.deleted"]
        },
        spacingTokens: {
            name: "Spacing tokens",
            info: "Spacing token added, removed or updated on Styleguide",
            events: ["project.spacingtoken.created", "project.spacingtoken.updated", "project.spacingtoken.deleted"]
        },
        notes: {
            name: "Comments",
            info: "Comment added",
            events: ["project.dot.created"]
        },
        replies: {
            name: "Replies",
            info: "Replied to comment",
            events: ["project.dot.comment.created"]
        },
        members: {
            name: "Members",
            info: "Member invited",
            events: ["project.user.invited"]
        },
        extensions: {
            name: "Extensions",
            info: "Extension added or removed",
            events: ["project.extension.added", "project.extension.removed"]
        },
        projectStatus: {
            name: "Project status",
            info: "Project archived or activated",
            events: ["project.archived", "project.activated"]
        },
        annotations: {
            name: "Annotations",
            info: "Annotation added",
            events: ["project.annotation.created"]
        },
        screenAssigns: {
            name: "Screen assigns",
            info: "Screen assigned",
            events: ["project.membertag.added"]
        },
        screenStatus: {
            name: "Status updates",
            info: "The status of a screen updated",
            events: ["project.statustag.added"]
        }
    },
    [BarrelType.STYLEGUIDE]: {
        components: {
            name: "Components",
            info: "Component added, removed or updated on Styleguide",
            events: ["styleguide.component.created", "styleguide.component.version.created", "styleguide.component.deleted"]
        },
        colors: {
            name: "Colors",
            info: "Color added, removed or updated on Styleguide",
            events: ["styleguide.color.created", "styleguide.color.updated", "styleguide.color.deleted"]
        },
        textStyles: {
            name: "Text styles",
            info: "Text style added, removed or updated on Styleguide",
            events: ["styleguide.textstyle.created", "styleguide.textstyle.updated", "styleguide.textstyle.deleted"]
        },
        spacingTokens: {
            name: "Spacing tokens",
            info: "Spacing token added, removed or updated on Styleguide",
            events: ["styleguide.spacingtoken.created", "styleguide.spacingtoken.updated", "styleguide.spacingtoken.deleted"]
        },
        members: {
            name: "Members",
            info: "Member invited",
            events: ["styleguide.user.invited"]
        },
        extensions: {
            name: "Extensions",
            info: "Extension added or removed",
            events: ["styleguide.extension.added", "styleguide.extension.removed"]
        },
        styleguideStatus: {
            name: "Styleguide status",
            info: "Styleguide archived or activated",
            events: ["styleguide.archived", "styleguide.activated"]
        }
    }
};

function createEventToEventGroupDict(barrelType: BarrelType): BasicRecord {
    return Object.keys(eventGroupsByBarrelType[barrelType]).reduce(
        (accumulatedDictionary: BasicRecord, eventGroup: string) =>
            eventGroupsByBarrelType[barrelType][eventGroup].events.reduce(
                (previous: BasicRecord, event: string) => {
                    previous[event] = eventGroup;
                    return previous;
                },
                accumulatedDictionary
            ),
        {}
    );
}

const eventToGroupDictionary = {
    project: createEventToEventGroupDict(BarrelType.PROJECT),
    styleguide: createEventToEventGroupDict(BarrelType.STYLEGUIDE)
};

function groupEvents(barrelType: BarrelType, events: string[]): BasicRecord<string[]> {
    return events.reduce(
        (previous: BasicRecord<string[]>, event: string) => {
            const eventGroup = getGroupOfEvent(barrelType, event);

            if (eventGroup) {
                (previous[eventGroup] ??= []).push(event);
            }

            return previous;
        },
        {}
    );
}

function getEventsOfGroup(barrelType: BarrelType, eventGroup: string): string[] {
    return eventGroupsByBarrelType[barrelType][eventGroup].events;
}

function getGroupOfEvent(barrelType: BarrelType, event: string): string {
    return eventToGroupDictionary[barrelType][event];
}

export {
    getEventsOfGroup,
    getGroupOfEvent,
    groupEvents,
    eventGroupsByBarrelType
};
