export enum GuidanceEventGroup {
    Dashboard = "dashboard",
    Screen = "screen",
    Styleguide = "styleguide"
}

export enum GuidanceEvent {
    // Dashboard
    ViewScreen = "viewScreen",
    InviteUser = "inviteUser",
    CreateFlow = "createFlow",
    ViewFlow = "viewFlow",
    ViewOrganizationWorkspace = "viewOrganizationWorkspace",
    CreateSubSection = "createSubsection",
    CreateSection = "createSection", // This is not used as a tooltip but we still track it for future usages.
    // Screen
    InspectLayer = "inspectLayer",
    ViewAnnotation = "viewAnnotation",
    ViewComment = "viewComment",
    CreateAnnotation = "createAnnotation",
    CreateComment = "createComment",
    ViewScreenVariant = "viewScreenVariant",
    DownloadAssets = "downloadAssets",
    ViewComponentVersions = "viewComponentVersions",
    ViewComponentOuterLayer = "viewComponentOuterLayer",
    ViewVersionDiff = "viewVersionDiff",
    ViewOldVersion = "viewOldVersion",
    ViewConnectedComponents = "viewConnectedComponents",
    SelectAnnotationType = "selectAnnotationType", // This is used after the CreateAnnotation tooltip. Not defined in priority list.
    // Styleguide
    ImportLibraryFromFigma = "importLibraryFromFigma",
    // Utility
    None = "$none" // This is used to memoize that there is no guidance event to be display as tooltip
}

export type GuidancePriorities = {
    [GuidanceEventGroup.Dashboard]: GuidanceEvent[];
    [GuidanceEventGroup.Screen]: GuidanceEvent[];
    [GuidanceEventGroup.Styleguide]: GuidanceEvent[];
};

export enum GuidanceDismissType {
    DismissedTooltip = "dismissedTooltip",
    ClickedToAction = "clickedToAction"
}
