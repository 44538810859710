enum AppUpdaterState {
    Idle = "IDLE",
    CheckingForUpdates = "CHECKING_FOR_UPDATES",
    DownloadingUpdates = "DOWNLOADING_UPDATES"
}

const appUpdateLabels = {
    [AppUpdaterState.Idle]: "Check for Updates",
    [AppUpdaterState.CheckingForUpdates]: "Checking for Updates",
    [AppUpdaterState.DownloadingUpdates]: "Downloading Updates"
};

enum PluginUpdaterState {
    Disabled = "DISABLED",
    Idle = "IDLE",
    CheckingForUpdates = "CHECKING_FOR_UPDATES",
    DownloadingUpdates = "DOWNLOADING_UPDATES"
}

const pluginUpdateLabels = {
    [PluginUpdaterState.Idle]: "Check for Plugin Updates",
    [PluginUpdaterState.CheckingForUpdates]: "Checking for Plugin Updates",
    [PluginUpdaterState.DownloadingUpdates]: "Downloading Plugin Updates"
};

type SketchPreferences = {
    keepGroupedLayerNames: boolean;
    keepGroupedSymbolNames: boolean;
    useSymbolInstanceNames: boolean;
    jpgQuality: number;
};

export {
    AppUpdaterState,
    appUpdateLabels,
    PluginUpdaterState,
    pluginUpdateLabels,
    SketchPreferences
};
