enum ActivityType {
    AddProjectExtension = "AddProjectExtension",
    AddStyleguideExtension = "AddStyleguideExtension",
    ActivateProject = "ActivateProject",
    ActivateStyleguide = "ActivateStyleguide",
    ArchiveProject = "ArchiveProject",
    ArchiveStyleguide = "ArchiveStyleguide",
    AuthProjectSlack = "AuthProjectSlack",
    AuthStyleguideSlack = "AuthStyleguideSlack",
    CreateProjectComponent = "CreateProjectComponent",
    CreateStyleguideComponent = "CreateStyleguideComponent",
    CreateProjectComponentVersion = "CreateProjectComponentVersion",
    CreateStyleguideComponentVersion = "CreateStyleguideComponentVersion",
    CreateProjectColor = "CreateProjectColor",
    CreateStyleguideColor = "CreateStyleguideColor",
    CreateDot = "CreateDot",
    CreateDotMention = "CreateDot-Mention",
    CreateDotCommentMention = "CreateDotComment-Mention",
    CreateDotComment = "CreateDotComment",
    CreateScreen = "CreateScreen",
    CreateScreenVersion = "CreateScreenVersion",
    CreateProjectTextStyle = "CreateProjectTextStyle",
    CreateStyleguideTextStyle = "CreateStyleguideTextStyle",
    DeleteProjectColor = "DeleteProjectColor",
    DeleteStyleguideColor = "DeleteStyleguideColor",
    DeleteProjectComponent = "DeleteProjectComponent",
    DeleteStyleguideComponent = "DeleteStyleguideComponent",
    DeleteScreen = "DeleteScreen",
    DeleteProject = "DeleteProject",
    DeleteStyleguide = "DeleteStyleguide",
    DeleteProjectTextStyle = "DeleteProjectTextStyle",
    DeleteStyleguideTextStyle = "DeleteStyleguideTextStyle",
    RemoveExtensionFromProject = "RemoveExtensionFromProject",
    RemoveExtensionFromStyleguide = "RemoveExtensionFromStyleguide",
    ResolveDot = "ResolveDot",
    UpdateOrganizationMemberRole = "UpdateOrganizationMemberRole",
    UpdateProjectColor = "UpdateProjectColor",
    UpdateStyleguideColor = "UpdateStyleguideColor",
    UpdateProjectUserRole = "UpdateProjectUserRole",
    UpdateStyleguideUserRole = "UpdateStyleguideUserRole",
    UpdateProjectTextStyle = "UpdateProjectTextStyle",
    UpdateStyleguideTextStyle = "UpdateStyleguideTextStyle",
    UserJoined = "UserJoined",
    ProjectUserJoined = "ProjectUserJoined",
    StyleguideUserJoined = "StyleguideUserJoined",
    RemoveUserFromProject = "RemoveUserFromProject",
    RemoveUserFromStyleguide = "RemoveUserFromStyleguide",
    InviteMember = "InviteMember",
    InviteProjectUser = "InviteProjectUser",
    InviteStyleguideUser = "InviteStyleguideUser",
    TransferProjectOwnership = "TransferProjectOwnership",
    TransferStyleguideOwnership = "TransferStyleguideOwnership",
    CreateProjectSpacingToken = "CreateProjectSpacingToken",
    CreateStyleguideSpacingToken = "CreateStyleguideSpacingToken",
    UpdateProjectSpacingToken = "UpdateProjectSpacingToken",
    UpdateStyleguideSpacingToken = "UpdateStyleguideSpacingToken",
    DeleteProjectSpacingToken = "DeleteProjectSpacingToken",
    DeleteStyleguideSpacingToken = "DeleteStyleguideSpacingToken",
    AttachProjectToJiraIssue = "AttachProjectToJiraIssue",
    AttachSectionToJiraIssue = "AttachSectionToJiraIssue",
    AttachScreenToJiraIssue = "AttachScreenToJiraIssue",
    RemoveProjectJiraIssueAttachment = "RemoveProjectJiraIssueAttachment",
    RemoveSectionJiraIssueAttachment = "RemoveSectionJiraIssueAttachment",
    RemoveScreenJiraIssueAttachment = "RemoveScreenJiraIssueAttachment",
    CreateFlowDot = "CreateFlowDot",
    CreateFlowDotMention = "CreateFlowDot-Mention",
    CreateFlowDotComment = "CreateFlowDotComment",
    CreateFlowDotCommentMention = "CreateFlowDotComment-Mention",
    ResolveFlowDot = "ResolveFlowDot",
    AddProjectMemberTag = "AddProjectMemberTag",
    AddProjectStatusTag = "AddProjectStatusTag",
    CreateAnnotation = "CreateAnnotation",
    CreateAnnotationMention = "CreateAnnotation-Mention"
}

export default ActivityType;
