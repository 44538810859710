import { h, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import LoginSignupTemplate from "../../LoginSignupTemplate";
import LoginParams from "../../types/LoginParams";
import FigmaSSOButton from "../../FigmaSSOButton";
import GoogleSSOButton from "../../GoogleSSOButton";

import electron from "../../../../../foundation/electron";
import { electronAppSupports } from "../../../../../foundation/electron/electronAppSupports";
import calculateDragRegions from "../../../../../foundation/electron/calculateDragRegions";
import { urlResolve } from "../../../../../foundation/utils/url";
import {
    CLI_CLIENT_ID,
    EXTENSIONS_WEB_URL,
    TRELLO_CLIENT_ID,
    VSCODE_CLIENT_ID
} from "../../../../../foundation/constants";
import LoginForm from "../../forms/LoginForm";
import getRedirectUrl from "../../../../../foundation/utils/get-redirect-url";
import BasicRecord from "../../../../../foundation/utils/BasicRecord";
import * as dialog from "../../../../../foundation/dialog";
import LoadingOverlay from "../../../../../library/LoadingOverlay";
import ResponsiveImage from "../../../../../library/ResponsiveImage";
import AppMenuButton from "../../../../../library/v2/Windows/AppMenuButton";
import { AppType, getAppType } from "../../../../../foundation/utils/ua-utils";
import WindowCommands from "../../../../../library/v2/Windows/WindowCommands";

import "./style.css";

const nonDraggableClass = "nonDrag";

interface Props {
    loginParams: LoginParams;
}

function getSubHeaderText(clientId?: string, source?: string) {
    switch (clientId) {
        case TRELLO_CLIENT_ID: {
            return "You can login to connect with Trello";
        }
        case VSCODE_CLIENT_ID: {
            return "You can login to connect with VS Code";
        }
        case CLI_CLIENT_ID: {
            return "You can login to connect with Zeplin CLI";
        }
    }

    return source === "extensions"
        ? "You can login to add extensions"
        : "You can login to access your workspace.";
}

export function LoginPage({ loginParams }: Props) {
    const [handle, setHandle] = useState("");
    const [loading, setLoading] = useState<boolean>(false);

    // eslint-disable-next-line camelcase
    const { client_id, source } = loginParams;
    const ssoQuery: BasicRecord = { action: "login" };
    const redirectToFromSession = loginParams.redirect || sessionStorage.getItem("redirectTo");
    if (redirectToFromSession) {
        ssoQuery["redirect"] = getRedirectUrl(redirectToFromSession, "/");
    }

    function calculateAndSendDragRegions() {
        if (!electron || !electronAppSupports.dragRegions) {
            return;
        }

        const nonDragElements = Array.from(document.querySelectorAll(`.${nonDraggableClass}`));

        const dragRegions = calculateDragRegions(nonDragElements, 40);

        electron.actions.sendUpdateHeaderDragRegions(dragRegions);
    }

    function handleFigmaSSOAnchorClick(event: Event, href: string) {
        if (source === "extensions") {
            event.preventDefault();
            window.parent.postMessage({ googleLoginURL: href }, EXTENSIONS_WEB_URL);
        }

        if (electron) {
            if (electronAppSupports.newFigmaSSOFlow) {
                setLoading(true);
                electron.actions.onceSsoFlowInitiated(() => {
                    setLoading(false);
                });
            } else {
                event.preventDefault();
                dialog.create({
                    title: "Login with Figma isn't supported in this version",
                    message: "Please update your application to login with Figma.",
                    done: {
                        name: "Got it"
                    }
                });
            }
        }
    }

    function handleGoogleSSOAnchorClick(event: Event, href: string) {
        if (source === "extensions") {
            event.preventDefault();
            window.parent.postMessage({ googleLoginURL: href }, EXTENSIONS_WEB_URL);
        }

        if (electron) {
            if (electronAppSupports.newGoogleSSOFlow) {
                setLoading(true);
                electron.actions.onceSsoFlowInitiated(() => {
                    setLoading(false);
                });
            } else {
                event.preventDefault();
                dialog.create({
                    title: "Login with Google isn't supported in this version anymore",
                    message: "Please update your application to login with Google.",
                    done: {
                        name: "Got it"
                    }
                });
            }
        }
    }

    function handleOpenAppMenu() {
        if (electron) {
            electron.actions.sendOpenMenu();
        }
    }

    function handleAppMinimize() {
        if (electron) {
            electron.actions.sendMinimize();
        }
    }

    function handleToggleMaximize() {
        if (electron) {
            electron.actions.sendToggleMaximize();
        }
    }

    function handleAppClose() {
        if (electron) {
            electron.actions.sendClose();
        }
    }

    function renderWindowsAppHeader() {
        if (electron && getAppType() === AppType.WindowsApp && electronAppSupports.designRefresh2024) {
            return (
                <div class="windowsAppLoginPageHeader">
                    <AppMenuButton
                        class={nonDraggableClass}
                        onClick={handleOpenAppMenu}/>
                    <WindowCommands
                        class={nonDraggableClass}
                        onMinimize={handleAppMinimize}
                        onToggleMaximize={handleToggleMaximize}
                        onClose={handleAppClose}/>
                </div>
            );
        }

        return null;
    }

    useEffect(() => {
        calculateAndSendDragRegions();

        window.addEventListener("resize", calculateAndSendDragRegions);

        () => {
            window.removeEventListener("resize", calculateAndSendDragRegions);
        };
    });

    return (
        <>
            {loading && <LoadingOverlay/>}
            {renderWindowsAppHeader()}
            <LoginSignupTemplate
                illustration={<ResponsiveImage asset="img/onboarding-illustration-1.png"/>}
                displayIllustrationOnMobile={false}
                header="Great to have you here!"
                subHeader={getSubHeaderText(client_id, source)}
                actionButtons={(
                    <>
                        <FigmaSSOButton
                            type="login"
                            query={ssoQuery}
                            onClick={handleFigmaSSOAnchorClick}/>
                        <GoogleSSOButton
                            type="login"
                            query={ssoQuery}
                            onClick={handleGoogleSSOAnchorClick}/>
                    </>
                )}
                form={<LoginForm loginParams={loginParams} onHandleChange={setHandle}/>}
                footer={(
                    <div class="loginPageFooter">
                        <a href={urlResolve("forgotPassword", { query: { email: handle } })}>Forgot password?</a>
                        <span>
                            No account yet? <a href={urlResolve("signup", { query: { source: loginParams.source, email: handle } })}>Sign up.</a>
                        </span>
                    </div>
                )}/>
        </>
    );
}

export default LoginPage;

