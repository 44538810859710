/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import NoteType from "../../../foundation/model/NoteType";
import { AnnotationStatusFilter } from "../../../foundation/model/RemarkStatusFilters";
import RemarkType from "../../../foundation/model/RemarkType";
import { isDraftDot, isDraftAnnotation } from "../../../foundation/utils/dot";
import { PartializeProps } from "../../../foundation/utils/UtilityTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import ComponentActionTypes from "../component/ComponentActionTypes";
import InspectableViewActionTypes from "../inspectableView/InspectableViewActionTypes";
import { AllPayloads } from "../payloads";

import * as ScreenActionPayloads from "./ScreenActionPayloads";
import ScreenActionTypes from "./ScreenActionTypes";

// TODO: filterId and statusFilter here indicates TYPE related filters. Update the naming and usages to prevent misunderstanding.
type State = {
    annotationFirstTimeLandingHidden: boolean;
    loadingAnnotations: boolean;
    lastNoteTypeUsed: string | undefined;
    annotationId: string | null;
    filterId: string | undefined;
    statusFilter: string;
    annotationConfigFilter: AnnotationStatusFilter;
    savedAnnotations: {
        [sid: string]: {
            [annotationId: string]: string;
        };
    };
};

class AnnotationsStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            annotationFirstTimeLandingHidden: false,
            loadingAnnotations: true,
            annotationId: null,
            filterId: undefined,
            statusFilter: "All",
            annotationConfigFilter: AnnotationStatusFilter.All,
            lastNoteTypeUsed: undefined,
            savedAnnotations: {}
        };
    }

    reset(state: State): State {
        const { savedAnnotations, annotationFirstTimeLandingHidden } = state;
        return {
            ...this.getInitialState(),
            savedAnnotations,
            annotationFirstTimeLandingHidden
        };
    }

    setAnnotations(state: State, {
        annotationId
    }: ScreenActionPayloads.SetAnnotations): State {
        return {
            ...state,
            loadingAnnotations: false,
            annotationId
        };
    }

    setAnnotationsForIntermediateLoading(state: State, {
        barrelData: {
            annotations: update
        },
        annotationsDetails: {
            annotationId
        }
    }: BarrelActionPayloads.LoadIntermediate): State {
        const { savedAnnotations } = state;

        if (state.annotationId === annotationId || (!state.annotationId && !annotationId)) {
            return {
                ...state,
                ...update,
                loadingAnnotations: false,
                annotationId,
                lastNoteTypeUsed: update?.lastNoteTypeUsed !== NoteType.None._id ? update?.lastNoteTypeUsed : undefined,
                annotationFirstTimeLandingHidden: !!update?.annotationFirstTimeLandingHidden,
                savedAnnotations
            };
        }

        return {
            ...state,
            ...update,
            annotationId: state.annotationId,
            lastNoteTypeUsed: update?.lastNoteTypeUsed !== NoteType.None._id ? update?.lastNoteTypeUsed : undefined,
            annotationFirstTimeLandingHidden: !!update?.annotationFirstTimeLandingHidden,
            savedAnnotations,
            loadingAnnotations: false
        };
    }

    selectAnnotation(state: State, {
        annotationId
    }: ScreenActionPayloads.SelectAnnotation | ScreenActionPayloads.OpenScreen): State {
        if (state.annotationId === annotationId || (!state.annotationId && !annotationId)) {
            return state;
        }

        return {
            ...state,
            annotationId,
            annotationFirstTimeLandingHidden: true
        };
    }

    deselectAnnotation(state: State): State {
        return {
            ...state,
            annotationId: null
        };
    }

    removeAnnotation(state: State, {
        atid,
        sid,
        text
    }: ScreenActionPayloads.RemoveAnnotation): State {
        let { savedAnnotations } = state;
        if (isDraftAnnotation(atid)) {
            savedAnnotations = {
                ...savedAnnotations,
                [sid]: {
                    ...savedAnnotations[sid],
                    newAnnotation: text!
                }
            };
        }

        if (state.annotationId !== atid) {
            if (!isDraftAnnotation(atid)) {
                return state;
            }

            return {
                ...state,
                savedAnnotations
            };
        }

        return {
            ...state,
            savedAnnotations,
            annotationId: null
        };
    }

    createAnnotation(state: State, {
        annotation: {
            _id: annotationId
        }
    }: ScreenActionPayloads.CreateAnnotation) {
        // TODO: Save to configfilter to prefs maybe?
        return {
            ...state,
            annotationId,
            annotationConfigFilter: AnnotationStatusFilter.All,
            annotationFirstTimeLandingHidden: true
        };
    }

    updateAnnotation(state: State, {
        atid: oldAtid,
        annotationData: {
            _id: newAtid
        }
    }: ScreenActionPayloads.UpdateAnnotation): State {
        if (state.annotationId !== oldAtid && oldAtid === newAtid) {
            return state;
        }

        return {
            ...state,
            annotationId: newAtid
        };
    }

    updateDotPopupRemarkType(state: State, {
        remark,
        atid,
        remarkType,
        sid,
        textContent
    }: ScreenActionPayloads.UpdateDotPopupRemarkType): State {
        if (remarkType === RemarkType.Annotation) {
            const { savedAnnotations } = state;
            return {
                ...state,
                annotationId: remark!._id,
                savedAnnotations: {
                    ...savedAnnotations,
                    [sid]: {
                        ...savedAnnotations[sid],
                        [remark!._id]: textContent ?? ""
                    }
                },
                annotationFirstTimeLandingHidden: true,
                annotationConfigFilter: AnnotationStatusFilter.All
            };
        }

        if (state.annotationId === atid) {
            return {
                ...state,
                annotationId: null
            };
        }

        return state;
    }

    updateLastNoteTypeUsed(state: State, {
        atid,
        newType
    }: ScreenActionPayloads.UpdateAnnotationType): State {
        if (state.annotationId !== atid) {
            return state;
        }

        return {
            ...state,
            lastNoteTypeUsed: newType
        };
    }

    changeAnnotationFilter(
        state: State,
        { filterId, statusFilter }: ScreenActionPayloads.ChangeAnnotationFilter
    ): State {
        return {
            ...state,
            statusFilter,
            filterId
        };
    }

    deleteLastNoteTypeUsed(state: State, {
        atid
    }: ScreenActionPayloads.DeleteAnnotationType): State {
        if (state.annotationId !== atid) {
            return state;
        }

        return {
            ...state,
            lastNoteTypeUsed: undefined
        };
    }

    load(state: State, {
        annotations: update = {}
    }: PartializeProps<BarrelActionPayloads.Load, "annotations">): State {
        const { savedAnnotations } = state;
        return {
            ...state,
            ...update,
            annotationId: state.annotationId,
            lastNoteTypeUsed: update.lastNoteTypeUsed !== NoteType.None._id ? update.lastNoteTypeUsed : undefined,
            annotationFirstTimeLandingHidden: !!update.annotationFirstTimeLandingHidden,
            savedAnnotations
        };
    }

    handleChangeSelectedView(state: State): State {
        const {
            lastNoteTypeUsed,
            savedAnnotations,
            annotationFirstTimeLandingHidden,
            annotationConfigFilter
        } = state;

        return {
            ...this.getInitialState(),
            lastNoteTypeUsed,
            savedAnnotations,
            annotationFirstTimeLandingHidden,
            annotationConfigFilter
        };
    }

    handleEnableNoteMode(state: State, action: ScreenActionPayloads.EnableNoteMode): State {
        const noteType = action.selectedType ?? state.lastNoteTypeUsed; // set noteType selected from menu
        const annotationId = action.flash ? null : state.annotationId; // deselect annotation to close popup

        return {
            ...state,
            lastNoteTypeUsed: noteType,
            annotationId
        };
    }

    saveAnnotationText(state: State, sid: string, atid: string, text: string): State {
        const { savedAnnotations } = state;
        const annotationId = isDraftAnnotation(atid) ? "newAnnotation" : atid;

        return {
            ...state,
            savedAnnotations: {
                ...savedAnnotations,
                [sid]: {
                    ...savedAnnotations[sid],
                    [annotationId]: text
                }
            }
        };
    }

    handleAddDot(state: State, { sid, dot: { _id: did } }: ScreenActionPayloads.AddDot): State {
        if (!isDraftDot(did)) {
            return state;
        }

        const { savedAnnotations } = state;
        return {
            ...state,
            savedAnnotations: {
                ...savedAnnotations,
                [sid]: {
                    ...savedAnnotations[sid],
                    newAnnotation: ""
                }
            }
        };
    }

    hideAnnotationFirstTimeLanding(state: State): State {
        return {
            ...state,
            annotationFirstTimeLandingHidden: true
        };
    }

    changeAnnotationConfigFilter(state: State, {
        configFilter,
        annotationId
    }: ScreenActionPayloads.ChangeAnnotationConfigFilter) {
        return {
            ...state,
            annotationConfigFilter: configFilter,
            annotationId
        };
    }

    handleOpenComponent(): State {
        return {
            ...this.getInitialState(),
            loadingAnnotations: false
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.RESET:
                return this.reset(state);

            case BarrelActionTypes.CHANGE_SELECTED_VIEW:
                return this.handleChangeSelectedView(state);

            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.setAnnotationsForIntermediateLoading(state, action);

            case ScreenActionTypes.ENABLE_NOTE_MODE:
                return this.handleEnableNoteMode(state, action);

            case ScreenActionTypes.SET_ANNOTATIONS:
                return this.setAnnotations(state, action);

            case InspectableViewActionTypes.SELECT_LAYER:
            case InspectableViewActionTypes.DESELECT_LAYER:
            case ScreenActionTypes.SHOW_FLOW_LINKS:
            case ScreenActionTypes.SELECT_DOT:
            case ScreenActionTypes.OPEN_DOT_MENU:
            case ScreenActionTypes.CLOSE_DOT_POPUP:
            case ScreenActionTypes.CREATE_NOTE:
            case ScreenActionTypes.ENTER_STAGE_MODE:
                return this.deselectAnnotation(state);

            case ScreenActionTypes.OPEN_SCREEN:
            case ScreenActionTypes.SELECT_ANNOTATION:
                return this.selectAnnotation(state, action);

            case ScreenActionTypes.REMOVE_ANNOTATION:
                return this.removeAnnotation(state, action);
            case ScreenActionTypes.CREATE_ANNOTATION:
                return this.createAnnotation(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION:
                return this.updateAnnotation(state, action);

            case ScreenActionTypes.CHANGE_ANNOTATION_FILTER:
                return this.changeAnnotationFilter(state, action);

            case ScreenActionTypes.UPDATE_DOTPOPUP_REMARK_TYPE:
                return this.updateDotPopupRemarkType(state, action);

            case ScreenActionTypes.UPDATE_ANNOTATION_TYPE:
                return this.updateLastNoteTypeUsed(state, action);

            case ScreenActionTypes.DELETE_ANNOTATION_TYPE:
                return this.deleteLastNoteTypeUsed(state, action);

            case BarrelActionTypes.LOAD:
                return this.load(state, action);

            case ScreenActionTypes.ADD_ANNOTATION:
                return this.saveAnnotationText(state, action.sid, action.annotation._id, "");

            case ScreenActionTypes.SAVE_ANNOTATION_TEXT:
                return this.saveAnnotationText(state, action.sid, action.atid, action.annotationText);

            case ScreenActionTypes.ADD_DOT:
                return this.handleAddDot(state, action);

            case ScreenActionTypes.HIDE_ANNOTATION_LANDING:
                return this.hideAnnotationFirstTimeLanding(state);

            case ScreenActionTypes.CHANGE_ANNOTATION_CONFIG_FILTER:
                return this.changeAnnotationConfigFilter(state, action);

            case ComponentActionTypes.OPEN_COMPONENT:
                return this.handleOpenComponent();

            default:
                return state;
        }
    }
}

export default AnnotationsStore;
export { State as AnnotationsStoreState };
