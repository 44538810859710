/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import MultiFactorAuthenticationActionTypes from "./MultiFactorAuthenticationActionTypes";
import * as Payloads from "./MultiFactorAuthenticationActionPayloads";

import { AllPayloads } from "../payloads";
import { MFAAvailabilityOrganization } from "../../../foundation/api/model/mfa/MFAAvailability";

interface State {
    canRemoveIndividualMFA: boolean;
    canResetMFA: boolean;
    canSetupIndividualMFA: boolean;
    mfaRequiredOrganizations: MFAAvailabilityOrganization[];
    samlRequiredOrganizations: MFAAvailabilityOrganization[];
}

class MultiFactorAuthenticationStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            canRemoveIndividualMFA: false,
            canResetMFA: false,
            canSetupIndividualMFA: false,
            mfaRequiredOrganizations: [],
            samlRequiredOrganizations: []
        };
    }

    loadMFAAvailabilityData(state: State, availability: Omit<Payloads.GetMFAAvailabilitySuccess, "type">): State {
        return {
            ...state,
            ...availability
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case MultiFactorAuthenticationActionTypes.GET_MFA_AVAILABILITY_SUCCESS:
                return this.loadMFAAvailabilityData(state, action);

            default:
                return state;
        }
    }
}

export default MultiFactorAuthenticationStore;
export {
    State as MultiFactorAuthenticationStoreState
};
