enum ActionTypes {
    UPDATE_STATE = "styleguide/updateState",
    CHANGE_LEFT_SIDEBAR_WIDTH = "styleguide/changeLeftSidebarWidth",
    CHANGE_RIGHT_SIDEBAR_WIDTH = "styleguide/changeRightSidebarWidth",
    CHANGE_SORT_TYPE = "styleguide/changeSortType",
    CHANGE_COMPONENT_FILTER_VALUE = "styleguide/changeComponentFilterValue",
    CHANGE_SELECTED_NAVIGATOR_ID = "styleguide/changeSelectedNavigatorId",
    HIGHLIGHT_COLORS = "styleguide/highlightColors",
    UNHIGHLIGHT_COLOR = "styleguide/unhighlightColor",
    HIGHLIGHT_TEXT_STYLES = "styleguide/highlightTextStyles",
    UNHIGHLIGHT_TEXT_STYLE = "styleguide/unhighlightTextStyle",
    HIGHLIGHT_SPACING_TOKENS = "styleguide/highlightSpacingTokens",
    UNHIGHLIGHT_SPACING_TOKEN = "styleguide/unhighlightSpacingToken",
    HIGHLIGHT_TREEVIEW_SECTION = "styleguide/highlightTreeViewSection",
    SECTION_SCROLLED = "styleguide/sectionScrolled",
    CHANGE_SELECTION = "styleguide/changeSelection",
    EXPAND_SECTIONS = "styleguide/expandSections",
    EXPAND_ALL_SECTIONS = "styleguide/expandAllSections",
    COLLAPSE_SECTIONS = "styleguide/collapseSections",
    COLLAPSE_ALL_SECTIONS = "styleguide/collapseAllSections",
    HIDE_SUGGESTED_COLORS = "styleguide/hideSuggestedColors",
    HIDE_SUGGESTED_TEXT_STYLES = "stylegudie/hideSuggestedTextStyles",
    OPEN_MOVE_RESOURCES_POPUP = "styleguide/openMoveResourcesPopup",
    CLOSE_MOVE_RESOURCES_POPUP = "styleguide/closeMoveResourcesPopup",
    SELECT_MOVE_RESOURCES_TARGET_STYLEGUIDE = "styleguide/selectMoveResourcesTargetStyleguide",
    DESELECT_MOVE_RESOURCES_TARGET_STYLEGUIDE = "styleguide/deselectMoveResourcesTargetStyleguide",
    CONVERT_SECTION_TO_VARIANT_SECTION = "styleguide/convertSectionToVariantSection",
    CONVERT_VARIANT_SECTION_TO_SECTION = "styleguide/convertVariantSectionToSection",
    CREATE_COMPONENT_VARIANT_PROPERTY = "styleguide/createComponentVariantProperty",
    ADD_COMPONENT_VARIANT_PROPERTY = "styleguide/addComponentVariantProperty",
    UPDATE_COMPONENT_VARIANT_PROPERTY = "styleguide/updateComponentVariantProperty",
    UPDATE_COMPONENT_VARIANT_PROPERTY_NAME = "styleguide/updateComponentVariantPropertyName",
    UPDATE_COMPONENT_VARIANT_PROPERTY_VALUES = "styleguide/updateComponentVariantPropertyValues",
    UPDATE_COMPONENT_VARIANT_PROPERTIES_ORDER = "styleguide/updateComponentVariantPropertiesOrder",
    UPDATE_COMPONENT_VARIANT_PROPERTY_VALUE = "styleguide/updateComponentVariantPropertyValue",
    SET_COMPONENT_VARIANT_DATA = "styleguide/setComponentVariantData",
    REMOVE_COMPONENT_VARIANT_PROPERTY = "styleguide/removeComponentVariantProperty",
    REMOVE_COMPONENT_VARIANT_DATA = "styleguide/removeComponentVariantData",
    CREATE_COMPONENT_SECTION_VARIANT_GROUPS = "styleguide/createComponentSectionVariantGroups",
    GROUP_COMPONENTS_BY_VARIANT_PROPERTY = "styleguide/groupComponentsByVariantProperty",
    HIDE_COMPONENT_VARIANTS_ONBOARDING_DIALOG = "styleguide/hideComponentVariantsOnboardingDialog",
    UPDATE_SCREEN_USED_IN_INFO = "styleguide/updateScreenUsedInInfo",
    UPDATE_COMPONENTS_UPDATED_LAST_SEEN = "styleguide/updateComponentsUpdatedLastSeen"
}

export default ActionTypes;
