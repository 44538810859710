/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";
import { AllPayloads } from "../payloads";
import Dispatcher from "flux/lib/Dispatcher";
import ApprovalsActionTypes from "./ApprovalsActionTypes";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import ProjectsActionTypes from "../projects/ProjectsActionTypes";
import * as ApprovalsActionPayloads from "./ApprovalsActionPayloads";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import * as DashboardActionPayloads from "../dashboard/DashboardActionPayloads";
import * as ProjectsActionPayloads from "../projects/ProjectsActionPayloads";

import Approval from "../../../foundation/api/model/approvals/Approval";
import ProjectApprovalScreen from "../../../foundation/api/model/approvals/ProjectApprovalScreen";
import WorkspaceProjectApproval from "../../../foundation/api/model/approvals/WorkspaceProjectApproval";

type State = {
    [oid: string]: WorkspaceProjectApproval[];
};

class ApprovalsWorkspaceDataStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {};
    }

    getAddedProjectScreens(sid: string, screens: ProjectApprovalScreen[], approval: Approval) {
        if (approval.requestedFrom?._id === Zeplin.user._id) {
            const projectScreens = [...screens];
            const projectScreenIndex = projectScreens.findIndex(({ _id }) => _id === sid);

            if (projectScreenIndex > -1) {
                projectScreens[projectScreenIndex].approvals.push(approval);
            } else {
                projectScreens.push({
                    _id: sid,
                    approvals: [approval]
                });
            }

            return projectScreens;
        }

        return screens;
    }

    updateApproval(state: State, {
        pid,
        sid,
        oid,
        approval
    }: ApprovalsActionPayloads.UpdateApproval): State {
        if (!oid) {
            return state;
        }

        const workspaceApprovalList = state[oid]?.map(workspaceApproval => (
            workspaceApproval._id === pid ? (
                {
                    ...workspaceApproval,
                    screens: workspaceApproval.screens.map(screen =>
                        (screen._id !== sid ? screen : (
                            {
                                ...screen,
                                approvals: screen.approvals.map(screenApproval =>
                                    (screenApproval._id !== approval._id ? screenApproval : approval))
                            }
                        ))
                    )
                }
            ) : workspaceApproval));

        return workspaceApprovalList ? {
            ...state,
            [oid]: workspaceApprovalList
        } : state;
    }

    setWorkspaceApprovals(state: State, {
        oid,
        approvals
    }: ApprovalsActionPayloads.SetWorkspaceApprovals): State {
        if (!oid) {
            return state;
        }

        return {
            ...state,
            [oid]: approvals
        };
    }

    deleteApproval(state: State, {
        pid,
        sid,
        oid,
        apid
    }: ApprovalsActionPayloads.DeleteApproval): State {
        if (!oid) {
            return state;
        }

        const workspaceApprovalList = state[oid]?.map(workspaceApproval => (
            workspaceApproval._id === pid ? (
                {
                    ...workspaceApproval,
                    screens: workspaceApproval.screens.map(screen =>
                        (screen._id !== sid ? screen : (
                            { ...screen, approvals: screen.approvals.filter(approval => approval._id !== apid) }
                        ))
                    )
                }
            ) : workspaceApproval));

        return workspaceApprovalList ? {
            ...state,
            [oid]: workspaceApprovalList
        } : state;
    }

    addWorkspaceApproval(state: State, {
        oid,
        pid,
        sid,
        approval
    }: ApprovalsActionPayloads.AddWorkspaceApproval): State {
        if (!oid || approval.requestedFrom?._id !== Zeplin.user._id) {
            return state;
        }

        const projectIndex = state[oid]?.findIndex(workspaceProject => workspaceProject._id === pid);

        // project is available at store
        if (projectIndex > -1) {
            const addedProjectScreens = this.getAddedProjectScreens(
                sid,
                state[oid][projectIndex].screens,
                approval
            );

            const workspaceProjects = state[oid].map(project => (
                project._id === pid ? ({
                    _id: pid,
                    screens: addedProjectScreens
                }) : project
            ));

            return {
                ...state,
                [oid]: workspaceProjects
            };
        }

        // workspace have been initialized but not for this project
        return {
            ...state,
            [oid]: [
                ...(state[oid] || []),
                {
                    _id: pid,
                    screens: [{
                        _id: sid,
                        approvals: [approval]
                    }]
                }]
        };
    }

    updateWorkspaceApprovalStatus(state: State, {
        oid,
        pid,
        sid,
        apid,
        status
    }: ApprovalsActionPayloads.UpdateWorkspaceApprovalStatus): State {
        if (!oid) {
            return state;
        }

        const workspaceApprovals = [...(state[oid] || [])];
        const projectIndex = workspaceApprovals.findIndex(project => project._id === pid);
        let screenIndex;
        let approvalIndex;

        if (projectIndex > -1) {
            screenIndex = state[oid][projectIndex].screens.findIndex(screen => screen._id === sid);

            if (screenIndex > -1) {
                approvalIndex =
                    state[oid][projectIndex].screens[screenIndex].approvals.findIndex(
                        approval => approval._id === apid
                    );

                if (approvalIndex > -1) {
                    workspaceApprovals[projectIndex].screens[screenIndex].approvals[approvalIndex].status = status;
                }
            }
        }

        return {
            ...state,
            [oid]: workspaceApprovals
        };
    }

    deleteWorkspaceApproval(state: State, {
        oid,
        pid,
        sid,
        apid
    }: ApprovalsActionPayloads.DeleteWorkspaceApproval): State {
        if (!oid) {
            return state;
        }

        const workspaceApprovals = [...(state[oid] || [])];
        const projectIndex = workspaceApprovals.findIndex(project => project._id === pid);
        let screenIndex;
        let approvalIndex;

        if (projectIndex > -1) {
            screenIndex = state[oid][projectIndex].screens.findIndex(screen => screen._id === sid);

            if (screenIndex > -1) {
                approvalIndex =
                    state[oid][projectIndex].screens[screenIndex].approvals.findIndex(
                        approval => approval._id === apid
                    );
                if (approvalIndex > -1) {
                    workspaceApprovals[projectIndex].screens[screenIndex].approvals.splice(approvalIndex, 1);
                }
            }
        }

        return {
            ...state,
            [oid]: workspaceApprovals
        };
    }

    deleteWorkspaceProjectApprovals(state: State, {
        oid,
        bid
    }: BarrelActionPayloads.Remove): State {
        if (!oid) {
            return state;
        }

        const workspaceApprovals = [...(state[oid] || [])].filter(project => project._id !== bid);

        return {
            ...state,
            [oid]: workspaceApprovals
        };
    }

    deleteProjectScreens(state: State, {
        pid,
        items
    }: DashboardActionPayloads.DeleteScreens) {
        const oid =
            Object.keys(state).find(organizationId => state[organizationId]?.find(project => project._id !== pid));

        if (!oid) {
            return state;
        }

        const workspaceApprovalProjectIndex = state[oid].findIndex(project => project._id === pid);

        if (workspaceApprovalProjectIndex > -1) {
            const organizationProjects = [...state[oid]];
            const deletedScreens = items.filter(item => item.type === "screen").map(({ _id }) => _id);

            const projectApprovalList =
                organizationProjects[workspaceApprovalProjectIndex].screens.filter(
                    screen => !deletedScreens.includes(screen._id));
            organizationProjects[workspaceApprovalProjectIndex].screens = projectApprovalList;

            return {
                ...state,
                [oid]: organizationProjects
            };
        }

        return state;
    }

    transferOwnership(state: State, {
        bid,
        targetId,
        toOrganization,
        oid
    }: ProjectsActionPayloads.TransferOwnership)
        : State {
        const workspaceApprovals = [...(state[oid] || [])];
        const projectApprovalsIndex = workspaceApprovals.findIndex(project => project._id === bid);

        if (!toOrganization || projectApprovalsIndex === -1) {
            return state;
        }

        const targetOrganizationProjects = state[targetId] || [];
        targetOrganizationProjects.push(workspaceApprovals[projectApprovalsIndex]);
        workspaceApprovals.splice(projectApprovalsIndex, 1);

        return {
            ...state,
            [oid]: workspaceApprovals,
            [targetId]: targetOrganizationProjects
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ApprovalsActionTypes.SET_WORKSPACE_APPROVALS:
                return this.setWorkspaceApprovals(state, action);

            case ApprovalsActionTypes.UPDATE_APPROVAL:
                return this.updateApproval(state, action);

            case ApprovalsActionTypes.DELETE_APPROVAL:
                return this.deleteApproval(state, action);

            case ApprovalsActionTypes.ADD_WORKSPACE_APPROVAL:
                return this.addWorkspaceApproval(state, action);

            case ApprovalsActionTypes.UPDATE_WORKSPACE_APPROVAL_STATUS:
                return this.updateWorkspaceApprovalStatus(state, action);

            case ApprovalsActionTypes.DELETE_WORKSPACE_APPROVAL:
                return this.deleteWorkspaceApproval(state, action);

            case BarrelActionTypes.REMOVE:
                return this.deleteWorkspaceProjectApprovals(state, action);

            case DashboardActionTypes.DELETE_SCREENS:
                return this.deleteProjectScreens(state, action);

            case ProjectsActionTypes.TRANSFER_OWNERSHIP:
                return this.transferOwnership(state, action);
            default:
                return state;
        }
    }
}

export default ApprovalsWorkspaceDataStore;
export {
    State as ApprovalsWorkspaceDataStoreState
};
