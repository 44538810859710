import { h } from "preact";
import { urlResolve } from "../../../../foundation/utils/url";
import BasicRecord from "../../../../foundation/utils/BasicRecord";
import classname from "../../../../foundation/utils/classname";
import IconGoogle from "../../../../library/Icons/IconGoogle";
import IconGoogleMonochrome from "../../../../library/Icons/IconGoogleMonochrome";

import "./style.css";

interface Props {
    type: "login" | "signup";
    query?: BasicRecord;
    disabled?: boolean;
    onClick?: (event: Event, href: string) => void;
}

function GoogleSSOButton({ type, query, disabled, onClick }: Props) {
    const href = urlResolve("authGoogle", { query });

    function handleAnchorKeyPress(event: KeyboardEvent) {
        if (event.keyCode === Zeplin.KEY_CODE.SPACE) {
            handleAnchorClick(event);
            location.assign(href);
        }
    }

    function handleAnchorClick(event: Event) {
        if (onClick) {
            onClick(event, href);
        }
    }

    return (
        <a
            class={classname("googleSSOButton", { disabled })}
            href={href}
            onKeyPress={handleAnchorKeyPress}
            onClick={handleAnchorClick}>
            {disabled ? <IconGoogleMonochrome/> : <IconGoogle/>}
            <span class="googleSSOButtonText">{type === "login" ? "Login" : "Sign up"} with Google</span>
        </a>
    );
}

export default GoogleSSOButton;
