/* eslint-disable class-methods-use-this */
import { ReactSDKClient } from "@optimizely/react-sdk";
import ReduceStore from "flux/lib/FluxReduceStore";
import { OptimizelyDatafile } from "../../../foundation/model/optimizely";
import { AllPayloads } from "../payloads";
import * as Payloads from "./ExperimentationActionPayloads";
import ExperimentationActionTypes from "./ExperimentationActionTypes";

interface State {
    datafile: OptimizelyDatafile | undefined;
    optimizely: ReactSDKClient | undefined;
    usePusher: boolean;
    lastLoadTime: number;
}

class ExperimentationStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            datafile: undefined,
            optimizely: undefined,
            usePusher: false,
            lastLoadTime: 0
        };
    }

    loadDatafile(state: State, { datafile, optimizely, usePusher, loadTime }: Payloads.LoadDatafile): State {
        return {
            ...state,
            datafile,
            optimizely,
            usePusher,
            lastLoadTime: loadTime
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ExperimentationActionTypes.LOAD_DATA_FILE:
                return this.loadDatafile(state, action);
            default:
                return state;
        }
    }
}

export default ExperimentationStore;
export { State as ExperimentationStoreState };
