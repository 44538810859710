enum ActionTypes {
    LINK_PROJECT = "project/linkProject",
    LINK_PROJECT_REQUEST = "project/linkProjectRequest",
    LINK_PROJECT_SUCCESS = "project/linkProjectSuccess",
    UNLINK_PROJECT = "project/unlinkProject",
    UPDATE_PROJECT_STYLEGUIDE_LINK = "project/updateProjectStyleguideLink",

    // Tag actions
    CREATE_TAG = "project/createTag",
    REMOVE_TAG = "project/removeTag",
    UPDATE_TAG = "project/updateTag",
    UPDATE_TAG_ORDER = "project/updateTagOrder",
    ADD_TAG_TO_SCREENS = "project/addTagToScreens",
    RECOVER_OLD_STATUS_TAGS = "project/recoverOldStatusTags",
    REMOVE_TAG_FROM_SCREENS = "project/removeTagFromScreens",
    RECOVER_DELETED_TAG_GROUP_TAGS = "project/recoverDeletedTagGroupTags",
    CREATE_TAG_GROUP = "project/createTagGroup",
    CREATE_TAG_GROUP_WITH_TAGS = "project/createTagGroupWithTags",
    UPDATE_TAG_GROUP = "project/updateTagGroup",
    UPDATE_TAG_GROUP_ORDER = "project/updateTagGroupOrder",
    UPDATE_TAG_GROUP_ORDER_BY = "project/updateTagGroupOrderBy",
    REMOVE_TAG_GROUP = "project/removeTagGroup",

    PRELOAD_DASHBOARD = "project/preloadDashboard",

    // Dot actions
    GET_PROJECT_DOTS = "project/getDots",
    SET_PROJECT_DOTS = "project/setDots"
}

export default ActionTypes;
