enum MenuItemID {
    APPLICATION_SUBMENU = "APPLICATION_SUBMENU",
    SKETCH_INTEGRATION = "SKETCH_INTEGRATION",
    SKETCH_KEEP_GROUPED_LAYER_NAMES = "SKETCH_KEEP_GROUPED_LAYER_NAMES",
    SKETCH_KEEP_GROUPED_SYMBOL_NAMES = "SKETCH_KEEP_GROUPED_SYMBOL_NAMES",
    SKETCH_USE_SYMBOL_INSTANCE_NAMES = "SKETCH_USE_SYMBOL_INSTANCE_NAMES",
    SKETCH_JPG_QUALITY = "SKETCH_JPG_QUALITY",
    SKETCH_JPG_QUALITY_100 = "SKETCH_JPG_QUALITY_100",
    SKETCH_JPG_QUALITY_90 = "SKETCH_JPG_QUALITY_90",
    SKETCH_JPG_QUALITY_80 = "SKETCH_JPG_QUALITY_80",
    SKETCH_JPG_QUALITY_70 = "SKETCH_JPG_QUALITY_70",
    SKETCH_JPG_QUALITY_60 = "SKETCH_JPG_QUALITY_60",
    SKETCH_JPG_QUALITY_50 = "SKETCH_JPG_QUALITY_50",
    FIGMA_INTEGRATION = "FIGMA_INTEGRATION",
    FIGMA_PLUGIN_LEARN_MORE = "FIGMA_PLUGIN_LEARN_MORE",
    ADOBE_PHOTOSHOP_INTEGRATION = "ADOBE_PHOTOSHOP_INTEGRATION",
    LOG_OUT = "LOG_OUT",

    PROJECT_SUBMENU = "PROJECT",
    NEW_PROJECT = "NEW_PROJECT",
    NEW_WINDOW = "NEW_WINDOW",
    OPEN_RECENT_PROJECT_SUBMENU = "OPEN_RECENT_PROJECT_SUBMENU",
    OPEN_RECENT_PROJECT = "OPEN_RECENT_PROJECT",
    CLEAR_RECENT_PROJECTS = "CLEAR_RECENT_PROJECTS",
    ASSETS = "ASSETS",
    NAMING_CONVENTION = "NAMING_CONVENTION",
    NAMING_CONVENTION_DEFAULT = "NAMING_CONVENTION_DEFAULT",
    NAMING_CONVENTION_CAMEL_CASE = "NAMING_CONVENTION_CAMEL_CASE",
    NAMING_CONVENTION_SNAKE_CASE = "NAMING_CONVENTION_SNAKE_CASE",
    NAMING_CONVENTION_KEBAB_CASE = "NAMING_CONVENTION_KEBAB_CASE",
    NAMING_CONVENTION_ORIGINAL = "NAMING_CONVENTION_ORIGINAL",
    BITMAP_SCALES = "BITMAP_SCALES",
    PROJECT_DENSITY_SCALE = "PROJECT_DENSITY_SCALE",
    OPTIMIZE_ASSETS = "OPTIMIZE_ASSETS",
    OPTIMIZE_ASSETS_PNG = "OPTIMIZE_ASSETS_PNG",
    OPTIMIZE_ASSETS_JPG = "OPTIMIZE_ASSETS_JPG",
    OPTIMIZE_ASSETS_SVG = "OPTIMIZE_ASSETS_SVG",
    OPTIMIZE_ASSETS_ABOUT = "OPTIMIZE_ASSETS_ABOUT",

    STYLEGUIDE_SUBMENU = "STYLEGUIDE",
    NEW_STYLEGUIDE = "NEW_STYLEGUIDE",
    OPEN_RECENT_STYLEGUIDE_SUBMENU = "OPEN_RECENT_STYLEGUIDE_SUBMENU",
    OPEN_RECENT_STYLEGUIDE = "OPEN_RECENT_STYLEGUIDE",
    CLEAR_RECENT_STYLEGUIDES = "CLEAR_RECENT_STYLEGUIDES",

    EDIT_SUBMENU = "EDIT",
    LEAVE_PROJECT = "LEAVE_PROJECT",
    LEAVE_STYLEGUIDE = "LEAVE_STYLEGUIDE",
    DELETE_PROJECT = "DELETE_PROJECT",
    DELETE_STYLEGUIDE = "DELETE_STYLEGUIDE",
    RENAME_SCREEN = "RENAME_SCREEN",
    DELETE_SCREEN = "DELETE_SCREEN",
    SET_AS_THUMBNAIL = "SET_AS_THUMBNAIL",
    ADD_OR_MANAGE_TAG = "ADD_OR_MANAGE_TAG",
    NEW_SECTION_FROM_SELECTION = "NEW_SECTION_FROM_SELECTION",
    IMPORT_PNGS = "IMPORT_PNGS",
    ADD_ANOTHER_COPY = "ADD_ANOTHER_COPY",
    GROUP = "GROUP",
    SELECT_ALL = "SELECT_ALL",

    VIEW_SUBMENU = "VIEW",
    ZOOM_IN = "ZOOM_IN",
    ZOOM_OUT = "ZOOM_OUT",
    ACTUAL_SIZE = "ACTUAL_SIZE",
    ZOOM_TO_FIT = "ZOOM_TO_FIT",
    ZOOM_TO_SELECTION = "ZOOM_TO_SELECTION",
    POP_SCREEN_OUT = "POP_SCREEN_OUT",
    WORKSPACE = "WORKSPACE",
    DASHBOARD = "DASHBOARD",
    FLOW = "FLOW",
    STYLEGUIDE = "STYLEGUIDE",
    SEARCH = "SEARCH",
    EXPAND_ALL_SECTIONS = "EXPAND_ALL_SECTIONS",
    COLLAPSE_ALL_SECTIONS = "COLLAPSE_ALL_SECTIONS",
    SHOW_OR_HIDE_VERSIONS = "SHOW_OR_HIDE_VERSIONS",
    SHOW_SCREEN_GRID = "SHOW_SCREEN_GRID",
    CLIP_SCREEN_THUMBNAILS = "CLIP_SCREEN_THUMBNAILS",
    SHOW_BADGE = "SHOW_BADGE",
    RESET_APP_ZOOM_FACTOR = "RESET_APP_ZOOM_FACTOR",
    APPEARANCE = "APPEARANCE",
    APPEARANCE_SYSTEM = "APPEARANCE_SYSTEM",
    APPEARANCE_DARK = "APPEARANCE_DARK",
    APPEARANCE_LIGHT = "APPEARANCE_LIGHT",
    TOGGLE_FULL_SCREEN = "TOGGLE_FULL_SCREEN",
    RELOAD = "RELOAD",
    RELOAD_IGNORING_CACHE = "RELOAD_IGNORING_CACHE",
    TOGGLE_DEV_TOOLS = "TOGGLE_DEV_TOOLS",
    TOGGLE_DEV_TOOLS_HOST = "TOGGLE_DEV_TOOLS_HOST",

    DEVELOP_SUBMENU = "DEVELOP",
    CONNECTED_COMPONENTS = "CONNECTED_COMPONENTS",
    ENABLE_LOCAL_CONFIGURATION = "ENABLE_LOCAL_CONFIGURATION",
    EXTENSIONS = "EXTENSIONS",
    MANAGE_EXTENSIONS = "MANAGE_EXTENSIONS",
    EXPLORE_EXTENSIONS = "EXPLORE_EXTENSIONS",

    WINDOW_SUBMENU = "WINDOW",

    HELP_SUBMENU = "HELP",
    CHECK_FOR_UPDATES = "CHECK_FOR_UPDATES",
    CHECK_FOR_PLUGIN_UPDATES = "CHECK_FOR_PLUGIN_UPDATES",
    INSTALL_UPDATES_AUTOMATICALLY = "INSTALL_UPDATES_AUTOMATICALLY",
    INSTALL_FIGMA_PLUGIN = "INSTALL_FIGMA_PLUGIN",
    INSTALL_PHOTOSHOP_PLUGIN = "INSTALL_PHOTOSHOP_PLUGIN",
    SHOW_LOGS_IN_EXPLORER = "SHOW_LOGS_IN_EXPLORER",
    SHOW_LOGS_IN_FINDER = "SHOW_LOGS_IN_FINDER",
    RESET_SUGGESTED_COLORS = "RESET_SUGGESTED_COLORS",
    RESET_SUGGESTED_TEXT_STYLES = "RESET_SUGGESTED_TEXT_STYLES",
    CLEAR_CACHE_AND_RESTART = "CLEAR_CACHE_AND_RESTART",
    RESET_ZEPLIN = "RESET_ZEPLIN",
    OPEN_HELP_CENTER = "OPEN_HELP_CENTER",
    ABOUT_ZEPLIN = "ABOUT_ZEPLIN"
}

export default MenuItemID;
