/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { getCurrentSystemTheme, Theme, ThemePreference } from "../../../foundation/theme";
import { AllPayloads } from "../payloads";

import * as Payloads from "./ThemeActionPayloads";
import ThemeActionTypes from "./ThemeActionTypes";

interface State {
    themePreference: ThemePreference;
    activeTheme: Theme;
}

function calculateActiveTheme(themePreference: ThemePreference, systemTheme: Theme): Theme {
    if (themePreference === ThemePreference.System) {
        return systemTheme;
    }

    if (themePreference === ThemePreference.Dark) {
        return Theme.Dark;
    }

    return Theme.Light;
}

class ThemeStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            themePreference: ThemePreference.System,
            activeTheme: getCurrentSystemTheme()
        };
    }

    updateThemePreference(state: State, {
        themePreference,
        systemTheme
    }: Payloads.UpdateThemePreference): State {
        const activeTheme = calculateActiveTheme(themePreference, systemTheme);

        return {
            ...state,
            themePreference,
            activeTheme
        };
    }

    updateActiveTheme(state: State, {
        themePreference,
        systemTheme
    }: Payloads.UpdateActiveTheme): State {
        const activeTheme = calculateActiveTheme(themePreference, systemTheme);

        return {
            ...state,
            activeTheme
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case ThemeActionTypes.UPDATE_THEME_PREFERENCE:
                return this.updateThemePreference(state, action);

            case ThemeActionTypes.UPDATE_ACTIVE_THEME:
                return this.updateActiveTheme(state, action);

            default:
                return state;
        }
    }
}

export default ThemeStore;
export { State as ThemeStoreState };
